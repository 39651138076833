import React from 'react';
import {CardContent, DialogContent, DialogProps} from '@mui/material';
import {pick} from 'lodash';

import Modal from 'components/common/ui/Modal';
import NotificationForm, {IExistNotification} from 'components/notifs/NotificationForm';
import {useEditNotificationMutation} from 'api/notifs';

interface IEditNotificationModal {
	notification: IExistNotification;
}

const EditNotifModal: React.FC<IEditNotificationModal & DialogProps> = ({
	notification,
	...props
}) => {
	const [editNotification, {loading, error}] = useEditNotificationMutation();

	return (
		<Modal
			title="Редактирование оповещения"
			maxWidth={'xl'}
			open={props.open}
			onClose={props.onClose}
		>
			<DialogContent>
				<CardContent>
					<NotificationForm
						notification={notification}
						loading={loading}
						error={error}
						onSubmit={(values) => {
							editNotification({
								variables: {
									notification: {
										...pick(values, [
											'_id',
											'title',
											'description',
											'remaining',
										]),
									} as IExistNotification,
								},
							}).then(() => props.onClose && props.onClose({}, 'backdropClick'));
						}}
					/>
				</CardContent>
			</DialogContent>
		</Modal>
	);
};

export default EditNotifModal;
