import React, {useMemo} from 'react';
import {DialogContent, DialogProps, Checkbox, Grid, TextField, Button} from '@mui/material';
import {Formik, Form} from 'formik';

import Modal from 'components/common/ui/Modal';
import {useEditUserMutation} from 'api/users';
import css from './UserEditModal.pcss';
import {UserBaseFragment} from '../../gqlApp/graphql';

interface IUserEditModal extends DialogProps {
	user: UserBaseFragment;
}

interface IValues {
	isActive: boolean;
	email: string;
	isEmailVerified: boolean;
	phone: string;
	isPhoneVerified: boolean;
	name: string;
}

const UserEditModal: React.FC<IUserEditModal> = ({user, onClose, open}) => {
	const [editUser, {loading, error}] = useEditUserMutation();

	const initialValues: IValues = useMemo(
		() => ({
			isActive: user.isActive,
			email: user.email,
			isEmailVerified: user.isEmailVerified,
			phone: user.phone || '',
			isPhoneVerified: user.isPhoneVerified,
			name: user.name || '',
		}),
		[
			user.email,
			user.isActive,
			user.isEmailVerified,
			user.isPhoneVerified,
			user.name,
			user.phone,
		],
	);

	return (
		<Modal
			title={<>Редактирование {user.name || user.email}</>}
			open={open}
			onClose={onClose}
			maxWidth="sm"
			fullWidth
		>
			<Formik
				key={user._id}
				initialValues={initialValues}
				onSubmit={(values) =>
					editUser({
						variables: {
							...values,
							id: user._id,
						},
					}).then(() => onClose && onClose({}, 'backdropClick'))
				}
			>
				{({values, handleChange, errors}) => (
					<Form>
						<DialogContent>
							<Grid container alignItems="center">
								<Grid item xs>
									Имя
								</Grid>

								<Grid item xs>
									<TextField
										defaultValue={values.name}
										onChange={handleChange}
										name="name"
										size="medium"
										className={css.input}
										fullWidth
										error={!!errors.name}
										helperText={errors.name}
									/>
								</Grid>
							</Grid>

							<Grid container alignItems="center">
								<Grid item xs>
									E-mail
								</Grid>

								<Grid item xs>
									<TextField
										defaultValue={values.email}
										onChange={handleChange}
										name="email"
										type="email"
										className={css.input}
										fullWidth
									/>
								</Grid>
							</Grid>

							<Grid container alignItems="center">
								<Grid item xs>
									Номер телефона
								</Grid>

								<Grid item xs>
									<TextField
										defaultValue={values.phone}
										onChange={handleChange}
										name="phone"
										className={css.input}
										fullWidth
									/>
								</Grid>
							</Grid>

							<Grid container alignItems="center">
								<Grid item xs>
									Активен
								</Grid>

								<Grid item xs>
									<Checkbox
										defaultChecked={values.isActive}
										onChange={handleChange}
										value={true}
										color="primary"
										name="isActive"
									/>
								</Grid>
							</Grid>

							<Grid container alignItems="center">
								<Grid item xs>
									E-mail подтвержден
								</Grid>

								<Grid item xs>
									<Checkbox
										defaultChecked={values.isEmailVerified}
										onChange={handleChange}
										value={true}
										color="primary"
										name="isEmailVerified"
									/>
								</Grid>
							</Grid>

							<Grid container alignItems="center">
								<Grid item xs>
									Номер подтвержден
								</Grid>

								<Grid item xs>
									<Checkbox
										defaultChecked={values.isPhoneVerified}
										onChange={handleChange}
										value={true}
										color="primary"
										name="isPhoneVerified"
									/>
								</Grid>
							</Grid>
						</DialogContent>

						{!error ? (
							<DialogContent />
						) : (
							<DialogContent className={css.error}>{error.message}</DialogContent>
						)}

						<DialogContent>
							<Grid container spacing={2}>
								<Grid xs />

								<Grid item>
									<Button
										variant="outlined"
										onClick={(e: any) => onClose && onClose(e, 'backdropClick')}
									>
										Отменить
									</Button>
								</Grid>

								<Grid item>
									<Button type="submit" disabled={loading} color="primary">
										Сохранить
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};

export default UserEditModal;
