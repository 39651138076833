import React from 'react';
import {Alert} from '@mui/material';

interface IAlertInfo {
	fromDate: number | undefined;
	toDate: number | undefined;
}
const AlertInfo: React.FC<IAlertInfo> = ({fromDate, toDate}) => {
	const isAlert = toDate && fromDate && fromDate <= 1655758800000 && toDate >= 1655758800000;
	return (
		<div>
			{isAlert && (
				<Alert style={{margin: '20px 0px'}} severity="info">
					С 21.06.22 ≈13:00 по 27.06.22 запись данных DAU и MAU была приостановлена.
				</Alert>
			)}
		</div>
	);
};

export default AlertInfo;
