import React, {useMemo} from 'react';
import {FormikHelpers, useFormik} from 'formik';
import {Grid, TextField, Theme, useMediaQuery} from '@mui/material';
import {ApolloError} from '@apollo/client';
import {number, object, SchemaOf, string} from 'yup';
import {Button} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import css from './NotificationForm.pcss';
import {EditNotificationInput} from '../../gqlApp/graphql';

interface INotificationForm {
	onSubmit: (
		values: INewNotification | IExistNotification,
		formikHelpers: FormikHelpers<INewNotification | IExistNotification>,
	) => void | (Promise<any> & (() => void));
	notification?: INewNotification | IExistNotification;
	loading: boolean;
	error?: ApolloError;
	onCancel?: () => void;
}

export interface INewNotification extends Omit<EditNotificationInput, '_id'> {}

export interface IExistNotification extends EditNotificationInput {}

const validationSchema: SchemaOf<INewNotification | IExistNotification> = object().shape({
	title: string().nullable(),

	description: string().nullable(),

	url: string().nullable(),

	remaining: number().required('Лимит необходим').min(10, 'Должно быть 10 или больше'),
});

const NotificationForm: React.FC<INotificationForm> = ({
	onSubmit,
	notification,
	loading,
	error,
}) => {
	const isFromMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const initialValues = useMemo<INewNotification | IExistNotification>(
		() =>
			notification || {
				remaining: 0,
			},
		[notification],
	);

	const isEditMode = useMemo(() => !!notification, [notification]);

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container spacing={isFromMd ? 1 : 3}>
				<Grid item xs={isFromMd ? 8 : 4}>
					<TextField
						autoFocus={!isEditMode}
						name="title"
						label="Название"
						defaultValue={formik.values.title}
						variant={'outlined'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.title && !!formik.errors.title}
						helperText={formik.touched.title && formik.errors.title}
					/>
				</Grid>

				<Grid item xs={isFromMd ? 4 : 2}>
					<TextField
						name="remaining"
						type="number"
						label="Лимит показов"
						defaultValue={formik.values.remaining}
						variant={'outlined'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.remaining && !!formik.errors.remaining}
						helperText={formik.touched.remaining && formik.errors.remaining}
					/>
				</Grid>
			</Grid>

			<br />

			<Grid container spacing={isFromMd ? 1 : 3}>
				<Grid item xs={12}>
					<TextField
						autoFocus={!isEditMode}
						name="description"
						label="Описание"
						multiline
						defaultValue={formik.values.description}
						variant={'outlined'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.description && !!formik.errors.description}
						helperText={formik.touched.description && formik.errors.description}
					/>
				</Grid>
			</Grid>

			<br />

			{error && (
				<>
					<div className={css.error}>{error.message}</div>

					<br />
				</>
			)}

			<Grid container spacing={3}>
				<Grid item xs={isFromMd}>
					<Button
						type="submit"
						loading={loading}
						disabled={formik.isSubmitting}
						fullWidth
					>
						{isEditMode ? 'Сохранить' : 'Создать'}
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default NotificationForm;
