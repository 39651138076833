import React from 'react';

import PollsTable from 'components/polls/PollsTable';
import AppWrapper from 'components/common/AppWrapper';

const PollsPage: React.FC = () => (
	<AppWrapper title={'Опросы'}>
		<PollsTable />
	</AppWrapper>
);

export default PollsPage;
