import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {
	CircularProgress,
	Grid,
	IconButton,
	Link,
	TextField,
	Theme,
	useMediaQuery,
} from '@mui/material';
import {Check, Clear} from '@mui/icons-material';
import {format} from 'date-fns';
import classNames from 'classnames';
import {GraphQLError} from 'graphql';
import {ApolloError} from '@apollo/client';
import {useNavigate} from 'react-router';

import {useConfirmPhoneByCodeMutation, useSendPhoneVerificationCodeBySmsMutation} from 'api/users';
import {validatePhone} from 'utils/auth';
import routeUrls from 'constants/routeUrls';
import PhoneField from './PhoneField';
import css from './EnterCode.pcss';

interface IEnterPhoneCode {
	phoneBase: string;
	onConfirm?: (phone: string) => void;
	changeNumberIsActive?: boolean;
	error?: ApolloError;
	loading?: boolean;
	onChangePhone?: (newPhone: string) => Promise<any>;
	onSuccessChangePhone?: (newPhone: string) => void;
	verifyPhone?: boolean;
}

const EnterPhoneCode: React.FC<IEnterPhoneCode> = ({
	phoneBase,
	onConfirm,
	changeNumberIsActive,
	error,
	loading,
	onChangePhone,
	onSuccessChangePhone,
}) => {
	const resendInterval = 40;
	const isFromMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const [phone, setPhone] = useState(phoneBase.replace('+', ''));
	const [phoneForResend, setPhoneForResend] = useState(phoneBase.replace('+', ''));
	const [phoneChangeMode, setPhoneChangeMode] = useState(false);
	const [timerResend, setTimerResend] = useState(false);
	const [inputError, setInputError] = useState(false);
	const [stopFetch, setStopFetch] = useState(false);
	const navigate = useNavigate();

	const [confirmPhoneByCode, {error: errorConfirmPhoneByCode}] = useConfirmPhoneByCodeMutation();

	const [
		sendPhoneVerificationCodeBySms,
		{
			loading: loadingSendPhoneVerificationCodeBySms,
			error: errorSendPhoneVerificationCodeBySms,
			data: dataSendPhoneVerificationCodeBySms,
		},
	] = useSendPhoneVerificationCodeBySmsMutation();

	const [reverseCount, setReverseCount] = useState(resendInterval);

	const [errorAll, setErrorAll] = useState<ApolloError>();

	useEffect(() => {
		setErrorAll(errorSendPhoneVerificationCodeBySms);
	}, [errorSendPhoneVerificationCodeBySms]);

	useEffect(() => {
		setErrorAll(errorConfirmPhoneByCode);
	}, [errorConfirmPhoneByCode]);

	useEffect(() => {
		setErrorAll(error);
	}, [error]);

	useEffect(() => {
		if (timerResend && !stopFetch) {
			setTimeout(
				() => {
					setTimerResend(false);

					if (errorSendPhoneVerificationCodeBySms) {
						setPhoneChangeMode(true);
					}
				},
				errorSendPhoneVerificationCodeBySms ? 5000 : 10000,
			);
		}
	}, [errorSendPhoneVerificationCodeBySms, timerResend, stopFetch]);

	useEffect(() => {
		if (reverseCount === 0) {
			return;
		}

		const timer = setTimeout(() => setReverseCount(reverseCount - 1), 1000);

		return () => {
			clearTimeout(timer);
		};
	}, [reverseCount]);

	const handleCodeChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const {value} = e.target;
		const modifiedValue = value.replace(/\D/, '');

		if (modifiedValue.length !== 4) {
			return;
		}

		confirmPhoneByCode({variables: {phone: `+${phone}`, code: modifiedValue}})
			.then(() => {
				setStopFetch(true);
				if (!onConfirm) {
					navigate(routeUrls.APP_DASHBOARD);
				} else {
					onConfirm(phone);
				}
			})
			.catch(() => {});
	};

	const handleRequestCodeAgain = useCallback(
		(e: any) => {
			e.preventDefault();
			e.stopPropagation();

			if (loadingSendPhoneVerificationCodeBySms) {
				return;
			}

			sendPhoneVerificationCodeBySms({variables: {phone: `+${phone}`}})
				.then(() => {
					setErrorAll(undefined);
					setReverseCount(resendInterval);
				})
				.catch(() => setReverseCount(resendInterval));
		},
		[loadingSendPhoneVerificationCodeBySms, phone, sendPhoneVerificationCodeBySms],
	);

	const resendCode = useMemo(() => {
		if (phoneChangeMode) {
			return null;
		}

		if (reverseCount > 0) {
			return (
				<div className={css.help}>
					Повторно запросить код можно через {format(reverseCount * 1000, 'mm:ss')}
				</div>
			);
		}

		return (
			<div className={css.help}>
				Не пришёл код?{' '}
				<Link onClick={handleRequestCodeAgain} href="#">
					{loadingSendPhoneVerificationCodeBySms ? 'Загрузка...' : 'Запросить через SMS'}
				</Link>
			</div>
		);
	}, [
		phoneChangeMode,
		reverseCount,
		handleRequestCodeAgain,
		loadingSendPhoneVerificationCodeBySms,
	]);

	const renderErrors = useMemo(() => {
		if (errorAll) {
			return <div className={classNames(css.help, css.error)}>{errorAll.message}</div>;
		}

		return null;
	}, [errorAll]);

	const handleCancelPhoneChange = useCallback(() => {
		setPhoneForResend(phone);
		setPhoneChangeMode(false);
	}, [phone]);

	const handleChangePhone = useCallback(() => {
		if (phoneForResend === phone) {
			handleCancelPhoneChange();

			return;
		}

		onChangePhone &&
			onChangePhone(`+${phoneForResend}`)
				.then(() => {
					setErrorAll(undefined);
					setPhoneChangeMode(false);
					setPhone(phoneForResend);
					setReverseCount(resendInterval);

					onSuccessChangePhone && onSuccessChangePhone(phoneForResend);
				})
				.catch((errors) => {
					errors.graphQLErrors.forEach((error: GraphQLError) => {
						console.log(error);
						switch (error.message) {
							case 'call_limit_exceeded':
								setPhoneChangeMode(false);
								setPhone(phoneForResend);

								break;
						}

						setReverseCount(resendInterval);
					});
				});
	}, [phoneForResend, phone, onChangePhone, handleCancelPhoneChange, onSuccessChangePhone]);

	const handleInputChange = useCallback(
		(e: any) => {
			const {value} = e.target;
			const isPhoneValid = validatePhone(value);

			if (stopFetch) {
				return;
			}

			if (!isPhoneValid && !inputError) {
				setInputError(true);
			} else if (isPhoneValid && inputError) {
				setInputError(false);
			}

			setPhoneForResend(e.target.value);
		},
		[inputError, stopFetch],
	);

	const [useSms, setUseSms] = useState(false);

	useEffect(() => {
		setUseSms(false);
	}, [phone]);

	useEffect(() => {
		setUseSms(!!dataSendPhoneVerificationCodeBySms?.sendPhoneVerificationCodeBySms);
	}, [dataSendPhoneVerificationCodeBySms]);

	const message = useMemo(() => {
		if (useSms) {
			return <Grid item>Вам поступит код в SMS. Введите его в поле ниже.</Grid>;
		}

		return (
			<Grid item>
				Вам поступит звонок с <b>уникального номера</b>.
				<br />
				Введите последние 4 цифры этого номера
			</Grid>
		);
	}, [useSms]);

	const placeholder = useMemo(() => {
		if (useSms) {
			return 'Код из SMS';
		}

		return 'Последние 4 цифры звонящего номера';
	}, [useSms]);

	return (
		<>
			{message}

			<br />
			<Grid item xs>
				{renderErrors}

				<Grid container>
					<Grid item xs>
						{!phoneChangeMode && (
							<TextField
								name="code"
								type="number"
								onChange={handleCodeChange}
								fullWidth
								placeholder={placeholder}
								style={{minWidth: !useSms ? 340 : undefined}}
								autoFocus
							/>
						)}

						{phoneChangeMode && (
							<PhoneField
								name="phone"
								defaultValue={phoneForResend.replace(/^[7,375,380,996,374]/, '')}
								onChange={handleInputChange}
								error={inputError}
								disabled={loading}
								autoFocus
								InputProps={{
									endAdornment: loading ? (
										<div>
											<CircularProgress size={18} />
										</div>
									) : (
										<>
											<IconButton onClick={handleCancelPhoneChange}>
												<Clear fontSize="small" />
											</IconButton>

											{!inputError && (
												<IconButton onClick={handleChangePhone}>
													<Check fontSize="small" />
												</IconButton>
											)}
										</>
									),
								}}
							/>
						)}
					</Grid>

					{!isFromMd && !phoneChangeMode && <Grid item xs />}
				</Grid>

				{resendCode}
			</Grid>

			{changeNumberIsActive && (
				<Grid item>
					<br />
					<Link onClick={() => setPhoneChangeMode(true)}>Указать другой номер</Link>
				</Grid>
			)}
		</>
	);
};

export default EnterPhoneCode;
