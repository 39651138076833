import {useLazyQuery, useMutation, useQuery} from '@apollo/client';

import apolloClient from 'utils/apolloClient';
import {graphql} from '../gqlApp';
import {AllPollsQuery, AllPollsQueryVariables} from '../gqlApp/graphql';

graphql(`
	fragment QuestionBase on FormQuestionResponse {
		_id
		title
		subTitle
		questionType
		variants
		haveAnotherVariant
		isRequired
	}
`);

graphql(`
	fragment PollBase on FormTypeResponse {
		_id
		name
		title
		subTitle
		questions {
			...QuestionBase
		}
		isPublic
		isActive
		emails
		userIds
		tariffIds
		userCreatedAt
		haveOzonKey
		haveWbKey
		limit
		answeredCount
	}
`);

const ALL_POLLS_QUERY = graphql(`
	query AllPolls($input: PagesInput!) {
		formList(input: $input) {
			items {
				...PollBase
			}
			total
		}
	}
`);

export const useAllPollsQuery = (variables?: AllPollsQueryVariables) =>
	useQuery(ALL_POLLS_QUERY, {variables});

export const allPollsQuery = (variables?: AllPollsQueryVariables) =>
	apolloClient.query<AllPollsQuery, AllPollsQueryVariables>({
		query: ALL_POLLS_QUERY,
		variables,
	});

const CREATE_POLL_MUTATION = graphql(`
	mutation CreatePoll($input: CreateFormInput!) {
		createForm(input: $input) {
			...PollBase
		}
	}
`);

export const useCreatePollMutation = () =>
	useMutation(CREATE_POLL_MUTATION, {
		// update: (cache, {data}) => {
		// 	if (!data) {
		// 		return;
		// 	}
		//
		// 	const {createPoll} = data;
		//
		// 	cache.updateQuery({query: ALL_POLLS_QUERY, variables}, (data) => ({
		// 		allPolls: {
		// 			total: data.allPolls.total + 1,
		// 			items: [createPoll, ...(data?.allPolls.items || [])],
		// 		},
		// 	}));
		// },
	});

const EDIT_POLL_MUTATION = graphql(`
	mutation EditPoll($input: EditFormInput!) {
		editForm(input: $input) {
			...PollBase
		}
	}
`);

export const useEditPollMutation = () => useMutation(EDIT_POLL_MUTATION);

const REMOVE_POLL_MUTATION = graphql(`
	mutation RemovePoll($formId: String!) {
		removeForm(formId: $formId)
	}
`);

export const useRemovePollMutation = () => useMutation(REMOVE_POLL_MUTATION);

const CHANGE_FORM_ACTIVE_QUERY = graphql(`
	query ChangeFormActive($input: ChangeFormActiveInput!) {
		changeFormActive(input: $input) {
			...PollBase
		}
	}
`);

export const useChangeFormActiveQuery = () =>
	useLazyQuery(CHANGE_FORM_ACTIVE_QUERY, {fetchPolicy: 'network-only'});
