import React from 'react';

import CallsUserStatsTable from 'components/calls/table/CallsUserStatsTable';
import AppWrapper from 'components/common/AppWrapper';

const CallsUserStatsPage: React.FC = () => (
	<AppWrapper title="Статистика пользователя">
		<CallsUserStatsTable />
	</AppWrapper>
);

export default CallsUserStatsPage;
