import React from 'react';
import {DateRange} from '@mui/lab/DateRangePicker';
import {subDays} from 'date-fns';
import {
	ContentBlock,
	DateRangeTemplate,
	SelectRangeDate,
	VisualChart,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {useAnalyticsKeyStats} from 'api/users';

const KeyApiChartBlock: React.FC = () => {
	const [dateRangeValue, setDateRangeValue] = React.useState<DateRange<Date>>([
		subDays(new Date(), 30),
		new Date(),
	]);

	const onDateRangeChange = (newValue: DateRange<Date>) => {
		setDateRangeValue(newValue);
	};

	const {data, loading, error} = useAnalyticsKeyStats({
		dateRange: {
			fromDate: dateRangeValue[0]?.getTime(),
			toDate: dateRangeValue[1]?.getTime(),
		},
	});

	return (
		<ContentBlock
			label="Ключи API"
			rightHeaderBlock={
				<SelectRangeDate
					onChange={onDateRangeChange}
					dateRangeTemplate={DateRangeTemplate.month}
				/>
			}
			loading={loading}
			error={!!error}
		>
			<VisualChart labels={data.labels} datasets={data.datasets} />
		</ContentBlock>
	);
};

export default KeyApiChartBlock;
