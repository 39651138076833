import {useMemo} from 'react';
import {useQuery} from '@apollo/client';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import sortBy from 'lodash/sortBy';
import {TooltipItem} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {graphql} from '../gqlApp';
import {PaymentsStatsQuery, PaymentsStatsQueryVariables} from '../gqlApp/graphql';

const SUBSCRIPTION_STAT_QUERY = graphql(`
	query SubscriptionStat {
		subscriptionStat {
			trialCount
			defaultCount
			paidCount
		}
	}
`);

export const useSubscriptionStatQuery = () => useQuery(SUBSCRIPTION_STAT_QUERY);

const PAYMENTS_STATS_QUERY = graphql(`
	query PaymentsStats($dateRange: DateRangeInput!) {
		paymentsStats(dateRange: $dateRange) {
			date
			priceSum
			priceAmount
			priceQuartersSum
			priceQuartersAmount
			priceHalfYearSum
			priceHalfYearAmount
			priceYearSum
			priceYearAmount
		}
	}
`);

export const usePaymentsStats = (variables: PaymentsStatsQueryVariables) => {
	const {data, loading, error} = useQuery(PAYMENTS_STATS_QUERY, {variables});

	return useMemo(() => {
		const makeData = (data?: PaymentsStatsQuery['paymentsStats']) => {
			const sortedDataByDate = sortBy(data, 'date');

			return {
				labels: sortedDataByDate?.map(({date}) => format(date, 'dd.MM.yyyy', {locale: ru})),
				datasets: [
					{
						label: '1 месяц ',
						unit: '₽',
						type: 'line',
						color: 'rgba(128,152,255,0.84)',
						data: sortedDataByDate.map(({priceSum}) => Math.round(priceSum)),
						expandData: sortedDataByDate.map(({priceAmount}) =>
							Math.round(priceAmount),
						),
						tooltipReplace:
							sortedDataByDate?.map(({priceSum, priceAmount, date}) => (
								<TooltipItem
									label={'1 месяц '}
									sum={priceSum}
									count={priceAmount}
									date={date}
								/>
							)) || [],
					},
					{
						label: '3 месяца ',
						unit: '₽',
						type: 'line',
						color: 'rgb(0,32,199)',
						data: sortedDataByDate.map(({priceQuartersSum}) =>
							Math.round(priceQuartersSum),
						),
						expandData: sortedDataByDate.map(({priceQuartersAmount}) =>
							Math.round(priceQuartersAmount),
						),
						tooltipReplace:
							sortedDataByDate?.map(({priceQuartersSum, priceQuartersAmount}) => (
								<TooltipItem
									label={'3 месяца '}
									sum={priceQuartersSum}
									count={priceQuartersAmount}
								/>
							)) || [],
					},
					{
						label: '6 месяцев ',
						unit: '₽',
						type: 'line',
						color: 'rgb(60,155,21)',
						data: sortedDataByDate.map(({priceHalfYearSum}) =>
							Math.round(priceHalfYearSum),
						),
						expandData: sortedDataByDate.map(({priceHalfYearAmount}) =>
							Math.round(priceHalfYearAmount),
						),
						tooltipReplace:
							sortedDataByDate?.map(({priceHalfYearSum, priceHalfYearAmount}) => (
								<TooltipItem
									label={'6 месяцев '}
									sum={priceHalfYearSum}
									count={priceHalfYearAmount}
								/>
							)) || [],
					},
					{
						label: 'Год ',
						unit: '₽',
						type: 'line',
						color: 'rgb(47,112,66)',
						data: sortedDataByDate.map(({priceYearSum}) => Math.round(priceYearSum)),
						expandData: sortedDataByDate.map(({priceYearAmount}) =>
							Math.round(priceYearAmount),
						),
						tooltipReplace:
							sortedDataByDate?.map((props) => (
								<>
									<TooltipItem
										label={'Год '}
										sum={props.priceYearSum}
										count={props.priceYearAmount}
									/>
									<TooltipItem
										label={'Итого:'}
										sum={
											props.priceSum +
											props.priceQuartersSum +
											props.priceHalfYearSum +
											props.priceYearSum
										}
										count={
											props.priceAmount +
											props.priceQuartersAmount +
											props.priceHalfYearAmount +
											props.priceYearAmount
										}
										isTotal
									/>
								</>
							)) || [],
					},
				],
			};
		};

		return {
			data: makeData(data?.paymentsStats),
			loading,
			error,
		};
	}, [data?.paymentsStats, error, loading]);
};
