import React from 'react';

import AppWrapper from 'components/common/AppWrapper';
import PromoCodesTable from 'components/promoCodes/PromoCodesTable';

const PromoCodesPage: React.FC = () => (
	<AppWrapper title="Промо-коды">
		<PromoCodesTable />
	</AppWrapper>
);

export default PromoCodesPage;
