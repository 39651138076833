import React from 'react';
import {CardContent, Dialog, DialogContent, DialogProps, DialogTitle} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';

import {useUpdateUTM} from 'api/utm_source';
import FormUtmSource from 'components/utm_source/FormUtmSource';

interface IEditUtmSourceModal {
	_id: string;
	source: string;
	comment?: string | null; // комментарий к метке
	price: number; // цена рекламы
	refetch: () => void;
}

const EditUtmSourceModal: React.FC<IEditUtmSourceModal & DialogProps> = (props) => {
	const {refetch, onClose, comment, price, source, _id} = props;

	const [updateUTM, {loading}] = useUpdateUTM();

	const {enqueueSnackbar} = useSnackbar();

	const formik = useFormik({
		initialValues: {
			comment, // комментарий к метке
			price, // цена рекламы
			source,
		},
		onSubmit: ({comment, price}) =>
			updateUTM({
				variables: {
					input: {
						_id,
						comment,
						price,
					},
				},
			})
				.then(() => {
					enqueueSnackbar('Настройки успешно сохранены', {variant: 'success'});
					onClose && onClose({}, 'backdropClick');
					refetch();
				})
				.catch((error) => {
					enqueueSnackbar(error.message, {variant: 'error'});
				}),
	});

	return (
		<Dialog {...props} fullWidth>
			<DialogTitle style={{textAlign: 'center'}}>Редактирование UTM-метки</DialogTitle>

			<DialogContent>
				<CardContent>
					<FormUtmSource formik={formik} loading={loading} mode={'edit'} />
				</CardContent>
			</DialogContent>
		</Dialog>
	);
};

export default EditUtmSourceModal;
