import {useLazyQuery, useMutation, useQuery} from '@apollo/client';

import {graphql} from '../gqlApp';
import {AllNotificationsQueryVariables} from '../gqlApp/graphql';

graphql(`
	fragment NotificationBase on NotificationType {
		_id
		title
		description
		view
		remaining
	}
`);

const ALL_NOTIFICATIONS_QUERY = graphql(`
	query AllNotifications($filter: AllNotificationsInput!) {
		allNotifications(filter: $filter) {
			total
			items {
				...NotificationBase
			}
		}
	}
`);

export const useAllNotificationsQuery = (variables?: AllNotificationsQueryVariables) =>
	useQuery(ALL_NOTIFICATIONS_QUERY, {
		variables,
	});

export const useAllNotificationsLazyQuery = () => useLazyQuery(ALL_NOTIFICATIONS_QUERY);

const CREATE_NOTIFICATION_MUTATION = graphql(`
	mutation CreateNotification($notification: CreateNotificationInput!) {
		createNotification(notification: $notification) {
			...NotificationBase
		}
	}
`);

export const useCreateNotificationMutation = () => useMutation(CREATE_NOTIFICATION_MUTATION);

const EDIT_NOTIFICATION_MUTATION = graphql(`
	mutation EditNotification($notification: EditNotificationInput!) {
		editNotification(notification: $notification) {
			...NotificationBase
		}
	}
`);

export const useEditNotificationMutation = () => useMutation(EDIT_NOTIFICATION_MUTATION);

const REMOVE_NOTIFICATION_MUTATION = graphql(`
	mutation RemoveNotification($notificationId: String!) {
		removeNotification(notificationId: $notificationId) {
			_id
		}
	}
`);

export const useRemoveNotificationMutation = () => useMutation(REMOVE_NOTIFICATION_MUTATION);
