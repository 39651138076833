import React from 'react';

import CallsPopularActionsTable from 'components/calls/table/CallsPopularActionsTable';
import AppWrapper from 'components/common/AppWrapper';

const CallsPopularActionsPage: React.FC = () => (
	<AppWrapper title="Популярные запросы">
		<CallsPopularActionsTable />
	</AppWrapper>
);

export default CallsPopularActionsPage;
