import React from 'react';
import {IModal, Modal} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {useFormik} from 'formik';
import {CardContent, DialogContent} from '@mui/material';
import {useSnackbar} from 'notistack';

import {useCreateUTMSource} from 'api/utm_source';
import {object, string} from 'yup';
import FormUtmSource from 'components/utm_source/FormUtmSource';

interface ICreateUtmSourceModal {
	refetch: () => void;
}

const CreateUtmSourceModal: React.FC<ICreateUtmSourceModal & IModal> = (props) => {
	const {refetch} = props;

	const [createUTM, {loading}] = useCreateUTMSource();

	const {enqueueSnackbar} = useSnackbar();

	const formik = useFormik({
		initialValues: {
			source: '', // название метки
			comment: undefined, // комментарий к метке
			price: 0, // цена рекламы
			campaign: undefined, // доп. поле метки
			medium: undefined, // доп.поле метки
			term: undefined, // доп.поле метки
			content: undefined, // доп.поле метки
		},
		onSubmit: ({source, comment, content, medium, price, term, campaign}) =>
			createUTM({
				variables: {
					input: {
						comment,
						price,
						term,
						source,
						campaign,
						content,
						medium,
					},
				},
			})
				.then(() => {
					enqueueSnackbar('Utm - метка создана', {variant: 'success'});
					props.onClose && props.onClose();
					refetch();
				})
				.catch((error) => {
					enqueueSnackbar(
						<>
							Ошибка: <br />${error.message}
						</>,
						{variant: 'error'},
					);
				}),
		validationSchema: object().shape({
			source: string().required('Укажите название utm - метки'),
			// price: number().required('Укажите стоимость').min(1, 'Укажите стоимость'),
		}),
	});

	return (
		<Modal {...props} title={'Создание Utm - метки'}>
			<DialogContent>
				<CardContent>
					<FormUtmSource formik={formik} loading={loading} />
				</CardContent>
			</DialogContent>
		</Modal>
	);
};

export default CreateUtmSourceModal;
