import React from 'react';

import CallsActiveUsersTable from 'components/calls/table/CallsActiveUsersTable';
import AppWrapper from 'components/common/AppWrapper';

const CallsActiveUsersPage: React.FC = () => (
	<AppWrapper title="Активные пользователи">
		<CallsActiveUsersTable />
	</AppWrapper>
);

export default CallsActiveUsersPage;
