import React, {createContext, useState} from 'react';

import appConsts from 'constants/appConsts';
import {AllNotificationsQueryVariables, AllPollsQueryVariables} from '../gqlApp/graphql';

interface ILayoutContext {
	drawerIsOpen: boolean;
	toggleDrawer: (isOpen: boolean, noSave?: boolean) => void;
	pollsVariables?: AllPollsQueryVariables;
	setPollsVariables: (variables: AllPollsQueryVariables) => void;
	notifsVariables?: AllNotificationsQueryVariables;
	setNotifsVariables: (variables: AllNotificationsQueryVariables) => void;
}

export const LayoutContext = createContext<ILayoutContext>({
	drawerIsOpen: appConsts.STORAGE.DRAWER_IS_OPEN() === 'true',
	toggleDrawer: () => {},
	setPollsVariables: () => {},
	setNotifsVariables: () => {},
});

interface ILayoutContextProvider {
	children?: React.ReactNode;
}

export const LayoutContextProvider: React.FC<ILayoutContextProvider> = ({children}) => {
	const [drawerIsOpen, cToggleDrawer] = useState(localStorage.getItem('drawerIsOpen') === 'true');

	const toggleDrawer = (open: boolean, noSave = true) => {
		if (!noSave) {
			localStorage.setItem('drawerIsOpen', `${open}`);
		}

		cToggleDrawer(open);
	};

	const [pollsVariables, setPollsVariables] = useState<AllPollsQueryVariables>();
	const [notifsVariables, setNotifsVariables] = useState<AllNotificationsQueryVariables>();

	return (
		<LayoutContext.Provider
			value={{
				drawerIsOpen,
				toggleDrawer,
				pollsVariables,
				setPollsVariables,
				notifsVariables,
				setNotifsVariables,
			}}
		>
			{children}
		</LayoutContext.Provider>
	);
};

export const useLayoutContext = () => React.useContext(LayoutContext);
