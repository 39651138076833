import React, {useEffect, useMemo, useState} from 'react';
import uniqid from 'uniqid';
import {FormikHelpers, useFormik} from 'formik';
import {
	Checkbox,
	Collapse,
	Fab,
	FormControlLabel,
	Grid,
	IconButton,
	TextField,
	Theme,
	Typography,
	useMediaQuery,
} from '@mui/material';
import {Add, ExpandMore} from '@mui/icons-material';
import {ApolloError} from '@apollo/client';
import {array, boolean, number, object, SchemaOf, string} from 'yup';
import classNames from 'classnames';
import {useConfirm} from 'material-ui-confirm';
import {useDebounce} from 'use-debounce';
import {DatePicker} from '@mui/lab';
import {Button} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import Question from 'components/polls/QuestionForm';
import Autocomplete from 'components/common/ui/Automplete';
import {useUsersLazyQuery} from 'api/users';
import {useTariffs} from 'api/tariffs';
import css from './PollForm.pcss';
import {
	FormQuestionResponse,
	FormTypeResponse,
	QuestionTypeEnum,
	UserBaseFragment,
} from '../../gqlApp/graphql';

interface IPollForm {
	onSubmit: (
		values: IEditPoll,
		formikHelpers: FormikHelpers<IEditPoll>,
	) => void | (Promise<any> & (() => void));
	poll?: FormTypeResponse;
	loading: boolean;
	error?: ApolloError;
	onCancel?: () => void;
}

export interface IEditPoll extends Omit<FormTypeResponse, '__typename' | 'answeredCount'> {}

const validationSchema: SchemaOf<IEditPoll> = object().shape({
	_id: string().required(),
	name: string().trim().required('Название обязательно'),
	title: string().trim().required('Название обязательно'),
	questions: array(),
	userIds: array().nullable(true),
	emails: array().nullable(true),
	haveOzonKey: boolean().nullable(true),
	haveWbKey: boolean().nullable(true),
	isPublic: boolean().nullable(true),
	limit: number().nullable(true),
	subTitle: string().nullable(true),
	tariffIds: array().nullable(true),
	userCreatedAt: number().nullable(true),
	isActive: boolean().nullable(true),
});

const PollForm: React.FC<IPollForm> = ({onSubmit, poll, loading, error}) => {
	const isFromMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const initialValues = useMemo<FormTypeResponse>(
		() =>
			poll || {
				_id: 'new',
				name: '',
				title: '',
				questions: [
					{
						_id: uniqid(),
						title: '',
						questionType: QuestionTypeEnum.Radio,
					},
				],
				isActive: false,
				isPublic: false,
			},
		[poll],
	);

	const isEditMode = useMemo(() => !!poll, [poll]);

	const [variantsError, setVariantsError] = useState(false);

	const formik = useFormik({
		initialValues,
		onSubmit: (values, formikHelpers) => {
			let haveVariantsError = false;
			values.questions
				.filter(({questionType}) => questionType !== QuestionTypeEnum.Text)
				.forEach(({variants}) => {
					if (!variants || variants.length < 1) {
						haveVariantsError = true;
					}

					variants?.forEach((variant) => {
						if (!variant.trim()) {
							haveVariantsError = true;
						}
					});
				});

			if (haveVariantsError) {
				return setVariantsError(haveVariantsError);
			}

			setVariantsError(haveVariantsError);

			onSubmit(values, formikHelpers);
		},
		validationSchema,
	});

	const [expanded, setExpanded] = useState(
		isEditMode &&
			!!(
				initialValues.haveWbKey ||
				initialValues.haveOzonKey ||
				initialValues.emails?.length ||
				initialValues.userIds?.length ||
				initialValues.tariffIds?.length ||
				initialValues.userCreatedAt
			),
	);

	const confirm = useConfirm();
	const [getUsers, {loading: usersLoading}] = useUsersLazyQuery();
	const [options, setOptions] = useState<UserBaseFragment[]>([]);
	const [openEmailsAutocomplete, setOpenEmailsAutocomplete] = useState(false);
	const [usersSearch, setUsersSearch] = useState('');

	const [usersSearchDebounce] = useDebounce(usersSearch, 1000);

	useEffect(() => {
		if (!openEmailsAutocomplete) {
			setUsersSearch('');

			return;
		}

		getUsers({variables: {search: usersSearchDebounce}}).then(
			(data) => data && setOptions(data.data?.users.items || []),
		);
	}, [getUsers, openEmailsAutocomplete, usersSearchDebounce]);

	const {data: tariffsOptions, loading: tariffsLoading} = useTariffs();

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container spacing={isFromMd ? 1 : 3}>
				<Grid item xs={isFromMd ? 8 : 4}>
					<TextField
						autoFocus={!isEditMode}
						name="name"
						label="Название опроса"
						defaultValue={formik.values.name}
						variant={'outlined'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.name && !!formik.errors.name}
						helperText={formik.touched.name && formik.errors.name}
					/>
				</Grid>

				<Grid item xs={isFromMd ? 4 : 2}>
					<TextField
						name="limit"
						type="number"
						label="Лимит показов"
						defaultValue={formik.values.limit}
						variant={'outlined'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.limit && !!formik.errors.limit}
						helperText={formik.touched.limit && formik.errors.limit}
					/>
				</Grid>
			</Grid>

			<br />

			<Grid container spacing={isFromMd ? 1 : 3}>
				<Grid item xs={isFromMd ? 8 : 4}>
					<TextField
						name="title"
						label="Заголовок опроса"
						defaultValue={formik.values.title}
						variant={'outlined'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.title && !!formik.errors.title}
						helperText={formik.touched.title && formik.errors.title}
					/>
				</Grid>

				{/*<Grid item xs={isFromMd ? 8 : 4}>
					<TextField
						name="title"
						label="Подзаголовок опроса"
						defaultValue={formik.values.subTitle}
						variant={'outlined'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.subTitle && !!formik.errors.subTitle}
						helperText={formik.touched.subTitle && formik.errors.subTitle}
					/>
				</Grid>*/}
			</Grid>

			<br />

			<div className={css.expandOuter}>
				<Grid container spacing={1} alignItems={'center'}>
					<Grid item>
						<IconButton
							className={classNames(css.expand, {
								[css.expandOpen]: expanded,
							})}
							onClick={() => setExpanded(!expanded)}
						>
							<ExpandMore />
						</IconButton>
					</Grid>

					<Grid item>Фильтры</Grid>
				</Grid>

				<Collapse
					in={expanded}
					timeout="auto"
					unmountOnExit
					classes={{wrapperInner: css.expandInner}}
				>
					<br />

					<Grid container spacing={isFromMd ? 1 : 3}>
						<Grid item xs={isFromMd ? 12 : 6}>
							<DatePicker
								label="По дате регистрации (От)"
								mask={'__.__.____'}
								onChange={(date) => formik.setFieldValue('userCreatedAt', +date)}
								value={formik.values.userCreatedAt}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										error={
											formik.touched.userCreatedAt &&
											!!formik.errors.userCreatedAt
										}
										// helperText={formik.touched.userCreatedAt && formik.errors.userCreatedAt}
										inputProps={{
											...params.inputProps,
											placeholder: 'dd.MM.yyyy',
										}}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={isFromMd ? 8 : 3}>
							<FormControlLabel
								control={
									<Checkbox
										checked={!!formik.values.haveWbKey}
										onChange={(e) =>
											formik.setFieldValue(e.target.name, e.target.checked)
										}
										name="haveWbKey"
									/>
								}
								label="С ключами Wb"
							/>
						</Grid>

						<Grid item xs={isFromMd ? 8 : 3}>
							<FormControlLabel
								control={
									<Checkbox
										checked={!!formik.values.haveOzonKey}
										onChange={(e) =>
											formik.setFieldValue(e.target.name, e.target.checked)
										}
										name="haveOzonKey"
									/>
								}
								label="С ключами Ozon"
							/>
						</Grid>
					</Grid>

					<br />

					<Grid container spacing={isFromMd ? 1 : 3}>
						<Grid item xs={isFromMd ? 12 : 6}>
							<Autocomplete
								onChange={(_, value: UserBaseFragment[]) =>
									formik.setFieldValue(
										'emails',
										value.map(({email}) => email),
									)
								}
								defaultValue={formik.values.emails?.map((value) => ({
									email: value,
								}))}
								options={options}
								loading={usersLoading}
								open={openEmailsAutocomplete}
								onOpen={() => setOpenEmailsAutocomplete(true)}
								onClose={() => setOpenEmailsAutocomplete(false)}
								onInputChange={(_, value) => {
									setUsersSearch(value);
								}}
								isOptionEqualToValue={(option, value) =>
									option.email === value.email
								}
								getOptionLabel={(option) => option.email}
								TextFieldProps={{label: 'По E-mail пользователей'}}
							/>
						</Grid>

						<Grid item xs={isFromMd ? 12 : 6}>
							<Autocomplete
								onChange={(_, value) => formik.setFieldValue('userIds', value)}
								freeSolo
								options={[]}
								defaultValue={formik.values.userIds}
								TextFieldProps={{label: 'По ID пользователей'}}
							/>
						</Grid>
					</Grid>

					<br />

					<Grid container spacing={isFromMd ? 1 : 3} alignItems={'center'}>
						<Grid item xs={isFromMd ? 12 : 6}>
							<Autocomplete
								onChange={(_, value: UserBaseFragment[]) =>
									formik.setFieldValue(
										'tariffIds',
										value.map(({_id}) => _id),
									)
								}
								value={tariffsOptions?.tariffs.filter(({_id}) =>
									formik.values.tariffIds?.includes(_id),
								)}
								options={tariffsOptions?.tariffs || []}
								loading={tariffsLoading}
								isOptionEqualToValue={(option, value) => option._id === value}
								getOptionLabel={(option) => option.name}
								TextFieldProps={{label: 'По тарифам'}}
							/>
						</Grid>

						<Grid xs />
					</Grid>
				</Collapse>
			</div>

			<br />

			<Typography variant={'h6'} gutterBottom>
				Вопросы
			</Typography>

			<Grid container spacing={3}>
				{formik.values.questions.map((question) => (
					<Grid key={question._id} item xs={12} md={6} lg={4} xl={3}>
						<Question
							onChange={(a) => {
								setVariantsError(false);

								formik.setFieldValue(
									'questions',
									formik.values.questions.map((vQuestion) =>
										vQuestion._id === a._id ? a : vQuestion,
									),
								);
							}}
							question={question}
							variantsError={variantsError}
						/>

						{formik.values.questions.length > 1 && (
							<Grid item container justifyContent={'center'}>
								<Grid item>
									<Button
										variant={'outlined'}
										style={{borderRadius: '0 0 10px 10px', borderTop: 'none'}}
										onClick={() => {
											confirm({title: 'Точно удалить?'})
												.then(() => {
													formik.setFieldValue(
														'questions',
														formik.values.questions.filter(
															(vQuestion) =>
																vQuestion._id !== question._id,
														),
													);
												})
												.catch(() => {});
										}}
									>
										Удалить вопрос
									</Button>
								</Grid>
							</Grid>
						)}
					</Grid>
				))}

				<Grid item xs={3} container alignItems={'center'} justifyContent={'center'}>
					<Grid item>
						<Fab
							color={'primary'}
							onClick={() =>
								formik.setFieldValue('questions', [
									...formik.values.questions,
									{
										_id: uniqid(),
										questionType: QuestionTypeEnum.Radio,
									} as FormQuestionResponse,
								])
							}
						>
							<Add />
						</Fab>
					</Grid>
				</Grid>
			</Grid>

			<br />

			{error && (
				<>
					<div className={css.error}>{error.message}</div>

					<br />
				</>
			)}

			<Grid container spacing={3}>
				<Grid item xs={isFromMd}>
					<Button type="submit" loading={loading} fullWidth>
						{isEditMode ? 'Сохранить' : 'Создать'}
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default PollForm;
