import {ReactComponent as IconYaMarket} from 'components/common/icons/IconYaMarket.svg';
import {ReactComponent as IconWildberries} from '../components/common/icons/IconWildberries.svg';
import {ReactComponent as IconWildberriesS} from '../components/common/icons/IconWildberriesS.svg';
import {ReactComponent as IconOzon} from '../components/common/icons/IconOzon.svg';
import {ReactComponent as IconOzonS} from '../components/common/icons/IconOzonS.svg';
import {ReactComponent as IconAliexpress} from '../components/common/icons/IconAliexpress.svg';
import {ReactComponent as IconAliexpressS} from '../components/common/icons/IconAliexpressS.svg';
import {ReactComponent as IconYaMarketS} from '../components/common/icons/IconYaMarketS.svg';

class AppConsts {
	readonly GRAPHQL_URI =
		process.env.REACT_APP_GRAPHQL_URI && process.env.REACT_APP_GRAPHQL_URI.replace(/\/$/, '');

	readonly FRONTEND_DOMAIN =
		process.env.REACT_APP_FRONTEND_DOMAIN &&
		process.env.REACT_APP_FRONTEND_DOMAIN.replace(/\/$/, '');

	readonly AUTH_TOKEN = 'auth_token';

	DRAWER_WIDTH = 270;

	readonly MIN_PASSWORD_LENGTH = 7;

	readonly DEBOUNCED_SEARCH_MS = 1000;

	readonly STORAGE = {
		ALERT: {
			PERMISSIONS_CLOSED: 'alertPermissionClosed',
		},
		DRAWER_IS_OPEN: () => localStorage.getItem('drawerIsOpen'),
	};

	readonly MARKET_PLACE = {
		WILDBERRIES: {
			label: 'Wildberries',
			name: 'wildberries',
			icon: {
				small: IconWildberriesS,
				normal: IconWildberries,
			},
			color: {
				main: '#FA0D87',
				light: '#FBE9F3',
			},
		},
		OZON: {
			label: 'Ozon',
			name: 'ozon',
			icon: {
				small: IconOzonS,
				normal: IconOzon,
			},
			color: {
				main: '#0069FF',
				light: '#E5F0FF',
			},
		},
		ALIEXPRESS: {
			label: 'Aliexpress',
			name: 'aliexpress',
			icon: {
				small: IconAliexpressS,
				normal: IconAliexpress,
			},
			color: {
				main: '#E43225',
				light: '#FCEBE9',
			},
		},
		YANDEXMARKET: {
			label: 'Яндекс.Маркет',
			name: 'yandexMarket',
			icon: {
				small: IconYaMarketS,
				normal: IconYaMarket,
			},
			color: {
				main: '#ffde43',
				light: '#ffde43',
			},
		},
	};

	readonly MAX_PAGE_COUNT = 30;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AppConsts();
