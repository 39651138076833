import {useLazyQuery, useMutation} from '@apollo/client';
import {graphql} from '../gqlApp';

const UTM_STATS_QUERY = graphql(`
	query GetUtmSourceStats($input: UtmSourceStatsInput!) {
		getUtmSourceStats(input: $input) {
			source
			dateToShow
			paymentAmount
			paymentCount
			registrationCount
		}
	}
`);

export const useUtmStatsQuery = () => useLazyQuery(UTM_STATS_QUERY);

const UPDATE_UTM_MUTATION = graphql(`
	mutation UpdateUtmSource($input: UpdateUtmSourceInput!) {
		updateUtmSource(input: $input) {
			_id
			source
			medium
			campaign
			term
			content
			price
			comment
			usersCount
			paymentAmount
			paymentCount
			payersCount
		}
	}
`);

export const useUpdateUTM = () => useMutation(UPDATE_UTM_MUTATION);

const CREATE_UTM_SOURCE = graphql(`
	mutation CreateUtmSource($input: CreateUtmSourceInput!) {
		createUtmSource(input: $input) {
			_id
			source
			medium
			campaign
			term
			content
			price
			comment
			usersCount
			paymentAmount
			paymentCount
			payersCount
		}
	}
`);

export const useCreateUTMSource = () => useMutation(CREATE_UTM_SOURCE);

const GET_UTM_SOURCE_LIST = graphql(`
	query GetUtmSourceList($input: UtmListOptionsInput!) {
		getUtmSourceList(input: $input) {
			total
			items {
				_id
				price
				comment
				root
				source
				campaign
				medium
				term
				content
				usersCount
				paymentAmount
				paymentCount
				payersCount
				campaignStats {
					_id
					price
					comment
					root
					source
					campaign
					medium
					term
					content
					usersCount
					paymentAmount
					paymentCount
					payersCount
					mediumStats {
						_id
						price
						comment
						root
						source
						campaign
						medium
						term
						content
						usersCount
						paymentAmount
						paymentCount
						payersCount
						contentStats {
							_id
							price
							comment
							root
							source
							campaign
							medium
							term
							content
							usersCount
							paymentAmount
							paymentCount
							payersCount
							termStats {
								_id
								price
								comment
								root
								source
								campaign
								medium
								term
								content
								usersCount
								paymentAmount
								paymentCount
								payersCount
							}
						}
					}
				}
			}
		}
	}
`);

export const useGetUtmSourceList = () => useLazyQuery(GET_UTM_SOURCE_LIST);
