import React, {useMemo, useState} from 'react';
import {subDays} from 'date-fns';
import {
	ContentBlock,
	DateRangeTemplate,
	ITableRow,
	SelectRangeDate,
	TableAdaptive,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {DateRange} from '@mui/lab/DateRangePicker';
import {ITableColumn} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';

import {getCallsDescription} from 'components/calls/calls';
import {useApiCallsPopularActions} from 'api/calls';

const columns: ITableColumn[] = [
	{name: 'action', title: 'Запрос'},
	{name: 'count', title: 'Количество вызовов'},
	{name: 'description', title: 'Описание'},
];

const CallsPopularActionsTable: React.FC = () => {
	const [value, setValue] = useState<DateRange<Date>>([subDays(new Date(), 30), new Date()]);

	const onChangeDate = (newValue: DateRange<Date>) => {
		setValue(newValue);
	};

	const {data, loading, refetch} = useApiCallsPopularActions({
		fromDate: value[0]?.getTime(),
		toDate: value[1]?.getTime(),
	});

	const rows: ITableRow[] = useMemo(() => {
		if (!data) {
			return [];
		}

		return data.apiCallsPopularActions.map(({action, count}) => ({
			action,
			count,
			description: getCallsDescription(action),
		}));
	}, [data]);

	return (
		<ContentBlock
			loading={loading}
			rightHeaderBlock={
				<SelectRangeDate
					onChange={onChangeDate}
					dateRangeTemplate={DateRangeTemplate.month}
				/>
			}
			refetchButton={refetch}
		>
			<TableAdaptive rows={rows} columns={columns} />
		</ContentBlock>
	);
};

export default CallsPopularActionsTable;
