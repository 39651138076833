import React from 'react';
import format from 'date-fns/format';

interface ITariffTableCellProps {
	value: Date;
}

const TariffTableCell: React.FC<ITariffTableCellProps> = ({value}) => (
	<>{value ? <span>{format(value, 'dd.MM.yyyy')}</span> : <span>∞</span>}</>
);
export default TariffTableCell;
