class RouteUrls {
	APP_DASHBOARD = '/';
	APP_USERS = '/users';
	APP_POLLS = '/polls';
	APP_NOTIFS = '/notifs';
	APP_KEY_API = '/key-api';
	APP_TARIFFS = '/tariffs';
	APP_PAYMENT = '/payment';
	APP_PROMO_CODES = '/promo-codes';
	APP_UTM_SOURCE = '/utm-source';

	API_CALLS_POPULAR_ACTIONS = '/api-calls/popular-actions';
	API_CALLS_ACTIVE_USERS = '/api-calls/active-users';
	API_CALLS_USER_STATS = '/api-calls/user-stats/:userId';

	AUTH_LOGIN = '/auth/login';
	AUTH_LOGIN_BY_EMAIL = '/auth/login-by-email';

	SHOPSTAT_PROD = 'https://app.shopstat.ru/';
	SHOPSTAT_DEV = 'https://app.develop.shopstat.ru/';

	ADMINS = {
		OTVETO_RU: 'https://fb-bot-admin.shopstat.ru/',
		BOT_LIMITS: 'https://mp-bot-admin.shopstat.ru/',
	};
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RouteUrls();
