import React from 'react';
import {CardContent, DialogContent, DialogProps} from '@mui/material';

import Modal from 'components/common/ui/Modal';
import PromoCodeForm from 'components/promoCodes/PromoCodeForm';
import {useCreatePromoCodeMutation, usePromoCodesListQuery} from 'api/promoCodes';

interface ICreatePromoCodeModal {}

const CreatePromoCodeModal: React.FC<ICreatePromoCodeModal & DialogProps> = ({open, onClose}) => {
	const [create, {loading, error}] = useCreatePromoCodeMutation();

	const {refetch: refetchPromoCodesList} = usePromoCodesListQuery({
		input: {},
	});

	return (
		<Modal title="Создание промо-кода" maxWidth={'sm'} open={open} onClose={onClose}>
			<DialogContent>
				<CardContent>
					<PromoCodeForm
						loading={loading}
						error={error}
						onSubmit={(input) => {
							create({
								variables: {
									input,
								},
							}).then(() => {
								onClose && onClose('', 'backdropClick');
								refetchPromoCodesList();
							});
						}}
					/>
				</CardContent>
			</DialogContent>
		</Modal>
	);
};

export default CreatePromoCodeModal;
