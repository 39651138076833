import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ITableColumn} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';
import {
	Button,
	ContentBlock,
	ITableRow,
	PiecesCellFormat,
	RubleCellFormat,
	TableAdaptive,
	Tooltip,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {Grid, IconButton, Tooltip as TooltipMui} from '@mui/material';
import {mdiSquareEditOutline} from '@mdi/js';
import Icon from '@mdi/react';
import Pagination from '@mui/lab/Pagination';
import {TableOrder} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/utils';

import {useGetUtmSourceList} from 'api/utm_source';
import CreateUtmSourceModal from 'components/utm_source/CreateUtmSourceModal';
import EditUtmSourceModal from 'components/utm_source/EditUtmSourceModal';
import {SortDirection, UtmSort} from '../../gqlApp/graphql';

const UtmSourceTable = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [getUtmSource, {data, loading, error, refetch}] = useGetUtmSourceList();
	const [open, setOpen] = useState<boolean>(false);
	const [utmSource, setEditUtmSource] = useState<string | undefined>(undefined);

	const [sort, setSort] = useState<UtmSort>(UtmSort.CreatedAt);
	const [sortDirection, setSortDirection] = useState<TableOrder>('desc');

	const limit = 10;
	const pagesCount = useMemo(
		() => Math.ceil((data?.getUtmSourceList.total || 0) / limit),
		[data?.getUtmSourceList.total],
	);

	const handleSorting = useCallback(
		({columnName, direction}: {columnName: string; direction: TableOrder}) => {
			setSort(columnName as UtmSort);
			setSortDirection(direction);
		},
		[],
	);

	useEffect(() => {
		getUtmSource({
			variables: {
				input: {
					sort,
					sortDirection:
						sortDirection === 'desc' ? SortDirection.Desc : SortDirection.Asc,
					limit,
					page: currentPage,
				},
			},
		});
	}, [getUtmSource, currentPage, sort, sortDirection]);

	const columns = useMemo<ITableColumn[]>(
		() => [
			{
				name: 'collapse',
				title: '',
			},
			{
				name: 'manage',
				title: '',
				sortingEnabled: false,
				width: 35,
			},
			{
				name: 'name',
				title: 'UTM-метка',
				format: (value, row) => (
					<Tooltip content={row.comment}>
						<div style={{textDecoration: row.comment ? 'underline' : undefined}}>
							{value}
						</div>
					</Tooltip>
				),
			},
			// {
			// 	// name: 'root',
			// 	name: 'name',
			// 	title: 'Root',
			// 	align: 'right',
			// 	// format: (value) => <PiecesCellFormat value={value as number} />,
			// 	tooltip:
			// 		'Если в корне то будет равен source, ' +
			// 		'если в статистике campaignStats равен  ' +
			// 		'campaign и тд. medium -> term -> content',
			// },
			// {
			// 	name: 'campaign',
			// 	title: 'UTM campaign',
			// },
			// {
			// 	name: 'medium',
			// 	title: 'UTM medium',
			// },
			// {
			// 	name: 'term',
			// 	title: 'UTM term',
			// },
			// {
			// 	name: 'content',
			// 	title: 'UTM content',
			// },
			{
				name: 'price',
				title: 'Цена',
				align: 'right',
				format: (value) => <RubleCellFormat value={value as number} />,
				tooltip: 'Цена рекламы',
			},
			{
				name: 'paymentCount',
				title: 'Оплат',
				align: 'right',
				format: (value) => <PiecesCellFormat value={value as number} />,
				tooltip: 'Количество оплат по метке',
			},
			{
				name: 'paymentAmount',
				title: 'Сумма',
				align: 'right',
				format: (value) => <PiecesCellFormat value={value as number} />,
				tooltip: 'Сумма оплат по метке',
			},
			{
				name: 'usersCount',
				title: 'Кол-во пользователей',
				align: 'right',
				format: (value) => <PiecesCellFormat value={value as number} />,
				tooltip: 'Число зарегистрированных людей',
			},
			{
				name: 'payersCount',
				title: 'Плативших',
				align: 'right',
				format: (value) => <RubleCellFormat value={value as number} />,
				tooltip: 'Сумма платежей от пользователей зареганных по ссылке',
			},
		],
		[],
	);

	const rows = useMemo<ITableRow[]>(
		() =>
			data?.getUtmSourceList?.items?.map(
				({
					_id,
					price,
					comment,
					paymentAmount,
					paymentCount,
					payersCount,
					usersCount,
					campaignStats,

					root,
					source,
					campaign,
					medium,
					term,
					content,
				}) => ({
					manage: (
						<div key={_id}>
							<EditUtmSourceModal
								_id={_id}
								source={source}
								price={price}
								comment={comment}
								refetch={refetch}
								open={utmSource === source}
								onClose={() => setEditUtmSource(undefined)}
							/>

							<TooltipMui title="Редактировать" arrow>
								<IconButton onClick={() => setEditUtmSource(source)}>
									<Icon path={mdiSquareEditOutline} size={0.8} />
								</IconButton>
							</TooltipMui>
						</div>
					),
					collapse:
						campaignStats?.map((campaign) => ({
							...campaign,
							name: campaign.campaign,
							comment: undefined,
							collapse:
								campaign?.mediumStats?.map((medium) => ({
									...medium,
									name: medium.medium,
									comment: undefined,
									collapse:
										medium?.contentStats?.map((content) => ({
											...content,
											name: content.content,
											comment: undefined,
											collapse:
												content?.termStats?.map((term) => ({
													...term,
													name: term.term,
													comment: undefined,
												})) ?? [],
										})) ?? [],
								})) ?? [],
						})) ?? [],
					name: root,
					paymentAmount,
					paymentCount,
					campaign,
					comment,
					content,
					medium,
					price,
					payersCount,
					term,
					usersCount,
				}),
			) || [],

		[data?.getUtmSourceList, refetch, utmSource],
	);

	return (
		<div style={{marginTop: 20}}>
			<div>
				<CreateUtmSourceModal
					open={open}
					onClose={() => setOpen(false)}
					refetch={refetch}
				/>
				<Button onClick={() => setOpen(true)}>+ Создать метку</Button>
			</div>

			<ContentBlock loading={loading} error={!!error}>
				<TableAdaptive
					columns={columns}
					rows={rows}
					onSorting={handleSorting}
					sortDirection={sortDirection}
					sortColumnName={sort}
				/>

				<br />

				{pagesCount > 1 && (
					<Grid container justifyContent="center">
						<Pagination
							page={currentPage}
							count={pagesCount}
							onChange={(_, page) => setCurrentPage(page)}
							siblingCount={1}
							boundaryCount={1}
						/>
					</Grid>
				)}
			</ContentBlock>
		</div>
	);
};

export default UtmSourceTable;
