import React from 'react';
import {Navigate} from 'react-router-dom';
import {ConfirmProvider} from 'material-ui-confirm';
import {SnackbarProvider} from 'notistack';
import {LoadingPage} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {LayoutContextProvider} from 'layouts/LayoutContext';
import {useMeQuery} from 'api/users';
import routeUrls from 'constants/routeUrls';
import css from './ClientLayoutRoute.pcss';

interface IClientLayoutRoute {
	children?: React.ReactNode;
}

const ClientLayoutRoute: React.FC<IClientLayoutRoute> = ({children}) => {
	const {loading, data} = useMeQuery();

	if (loading) {
		return <LoadingPage hideTitle />;
	}

	if (!data?.me) {
		return <Navigate to={routeUrls.AUTH_LOGIN} replace />;
	}

	return (
		<SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
			<ConfirmProvider
				defaultOptions={{
					cancellationText: 'Нет',
					confirmationText: 'Да',
					dialogProps: {maxWidth: 'xs'},
					confirmationButtonProps: {variant: 'contained'},
				}}
			>
				<LayoutContextProvider>
					<section className={css.container}>{children}</section>
				</LayoutContextProvider>
			</ConfirmProvider>
		</SnackbarProvider>
	);
};

export default ClientLayoutRoute;
