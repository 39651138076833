import React, {useEffect, useMemo, useState} from 'react';
import Pagination from '@mui/lab/Pagination';
import {Grid, IconButton} from '@mui/material';
import Icon from '@mdi/react';
import {mdiDeleteCircleOutline, mdiSquareEditOutline} from '@mdi/js';
import {ITableColumn} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';
import {TableAdaptive} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {useLayoutContext} from 'layouts/LayoutContext';
import EditNotifModal from 'components/notifs/EditNotifModal';
import {useAllNotificationsQuery, useRemoveNotificationMutation} from 'api/notifs';
import theme from '../../muiTheme';

export default function NotifsTable() {
	const [currentPage, setCurrentPage] = useState(1);

	const notificationsVariables = useMemo(
		() => ({
			filter: {page: currentPage, limit: 10},
		}),
		[currentPage],
	);

	const {data, refetch: refetchNotifications} = useAllNotificationsQuery(notificationsVariables);

	const {setNotifsVariables} = useLayoutContext();

	useEffect(() => {
		setNotifsVariables(notificationsVariables);
	}, [notificationsVariables, setNotifsVariables]);

	const pagesCount = useMemo(
		() => Math.ceil((data?.allNotifications.total || 0) / 10),
		[data?.allNotifications.total],
	);

	const [editedNotification, setEditedNotification] = useState<string>();

	const [removeNotification] = useRemoveNotificationMutation();

	const columns = useMemo<ITableColumn[]>(
		() => [
			{
				name: 'manage',
				width: 120,
				title: '',
				sortingEnabled: false,
				format: (_value, row) => {
					const fondNotification = data?.allNotifications.items.find(
						({_id}) => _id === row._id,
					);

					return (
						<>
							{fondNotification && (
								<EditNotifModal
									notification={fondNotification}
									open={editedNotification === row._id}
									onClose={() => setEditedNotification(undefined)}
								/>
							)}

							<IconButton onClick={() => setEditedNotification(row._id)}>
								<Icon
									path={mdiSquareEditOutline}
									size={0.8}
									color={theme.palette.primary.main}
								/>
							</IconButton>

							<IconButton
								onClick={async () => {
									if (window.confirm('Точно удалить?')) {
										await removeNotification({
											variables: {notificationId: row._id},
										});
										refetchNotifications(notificationsVariables);
									}
								}}
							>
								<Icon
									path={mdiDeleteCircleOutline}
									size={1}
									color={theme.palette.primary.main}
								/>
							</IconButton>
						</>
					);
				},
			},
			{
				name: 'title',
				title: 'Название',
				sortingEnabled: false,
			},
			{
				name: 'remaining',
				align: 'right',
				title: 'Осталось показать',
			},
			{
				name: 'view',
				align: 'right',
				title: 'Показано',
			},
			{
				name: 'collapse',
				align: 'right',
				title: 'Описание',
				sortingEnabled: false,
			},
		],
		[
			data?.allNotifications.items,
			editedNotification,
			notificationsVariables,
			refetchNotifications,
			removeNotification,
		],
	);

	const rows = useMemo(
		() =>
			data?.allNotifications.items.map(({_id, title, description, remaining, view}) => ({
				manage: '',
				_id,
				title,
				description,
				remaining,
				view,
			})) || [],
		[data?.allNotifications.items],
	);

	return (
		<>
			<TableAdaptive
				columns={columns}
				rows={rows}
				defaultSortDirection="asc"
				defaultSortColumnName="remaining"
			/>

			<br />

			{pagesCount > 1 && (
				<>
					<Grid container justifyContent="center">
						<Pagination
							page={currentPage}
							count={pagesCount}
							onChange={(_, page) => setCurrentPage(page)}
							siblingCount={1}
							boundaryCount={1}
						/>
					</Grid>

					<br />
				</>
			)}
		</>
	);
}
