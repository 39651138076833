import React, {ChangeEvent, useCallback, useMemo, useState} from 'react';
import {
	Button,
	Checkbox,
	DialogProps,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from '@mui/material';
import format from 'date-fns/format';
import {addDays} from 'date-fns';
import {FormikProvider, useFormik} from 'formik';
import {bool, number, object, string} from 'yup';
import Switch from '@mui/material/Switch';

import Modal from 'components/common/ui/Modal';
import Snackbar from 'components/common/ui/Snackbar';
import {addTariffToUserMutation, useActiveTariffs, useAddFreeDaysToTariff} from 'api/tariffs';
import {PaymentTypeEnum, UserBaseFragment} from '../../../../gqlApp/graphql';
import css from './AddTariffToUserModal.pcss';

interface IAddTariffToUserModal extends DialogProps {
	user: UserBaseFragment;
	updateTableUsers: () => void;
}

interface IItemPaymentType {
	value: PaymentTypeEnum;
	name: string;
}

interface IFormikValues {
	tariffId: string;
	checked: boolean;
	countFreeDays: number;
	paymentType?: PaymentTypeEnum;
}

interface ISnackBar {
	isOpen: boolean;
	message?: string;
}

const validationSchema = object().shape({
	tariffId: string(),
	checked: bool(),
	countFreeDays: number(),
	paymentType: string(),
});

const AddTariffToUserModal: React.FC<IAddTariffToUserModal> = ({
	updateTableUsers,
	user,
	open,
	onClose,
}) => {
	const [addFreeDaysToTariff] = useAddFreeDaysToTariff();
	const {data: tariffData} = useActiveTariffs();

	const [openSuccess, setOpenSuccess] = useState<boolean>(false);
	const [openError, setOpenError] = useState<ISnackBar>({
		isOpen: false,
		message: 'Произошла ошибка',
	});

	const [isDaysSwitch, setIsDaysSwitch] = useState<boolean>(false);

	const handleClose = () => onClose && onClose({}, 'backdropClick');

	const initialValues = useMemo(
		() => ({
			tariffId: user.activeTariffId,
			checked: false,
			countFreeDays: 0,
			paymentType: PaymentTypeEnum.One,
		}),
		[user.activeTariffId],
	);

	const onSubmit = async (values: IFormikValues) => {
		const errorDaysAdd =
			(isTrialSelected() || isPaidTariffWithDaysSelected()) && values.countFreeDays < 1;
		const errorDaysAddOpen = () =>
			setOpenError({isOpen: true, message: 'Необходимо указать количество дней'});

		let subDays = 0;

		if (values.tariffId !== user.activeTariffId) {
			if (errorDaysAdd) {
				return errorDaysAddOpen();
			}

			await addTariffToUserMutation({
				tariffId: values.tariffId,
				userId: user._id,
				paymentType: values.paymentType,
				withPayment: values.checked,
			})
				.then(() => {
					handleClose();
					setOpenSuccess(true);
					subDays = isPaidTariffWithDaysSelected() ? 31 : 3;
				})
				.catch(() =>
					setOpenError({isOpen: true, message: 'Тариф не изменён. Произошла ошибка'}),
				);
		}

		if (isTrialSelected() || isPaidTariffWithDaysSelected()) {
			if (errorDaysAdd) {
				return errorDaysAddOpen();
			}

			await addFreeDaysToTariff({
				variables: {
					input: {
						userId: user._id,
						count: +values.countFreeDays - subDays,
					},
				},
			})
				.then(() => {
					formik.setFieldValue('countFreeDays', 0);
					handleClose();
					setIsDaysSwitch(false);
				})
				.catch(() =>
					setOpenError({isOpen: true, message: 'Ошибка в добавлении бесплатных дней'}),
				);
		}

		updateTableUsers();
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	});

	const isPaidTariffSelect = useMemo(
		() =>
			formik.values.tariffId ===
			tariffData?.activeTariffs.find(({isDefault, isTrial}) => !isDefault && !isTrial)?._id,
		[formik.values.tariffId, tariffData?.activeTariffs],
	);

	const toDateFree = useMemo(
		() =>
			addDays(
				user.subscription?.toDate > new Date() ? user.subscription?.toDate : new Date(),
				formik.values.countFreeDays,
			),
		[formik.values.countFreeDays, user.subscription?.toDate],
	);

	const handleInputChange = useCallback(
		(e: {target: {name: string; value: string}}) =>
			formik.setFieldValue(
				e.target.name,
				e.target.value,
				!!Object.keys(formik.errors).length,
			),
		[formik],
	);

	const handleCheckedChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			formik.setFieldValue(e.target.name, e.target.value === 'false');
		},
		[formik],
	);

	const itemPaymentType: IItemPaymentType[] = [
		{value: PaymentTypeEnum.One, name: '1 месяц'},
		{value: PaymentTypeEnum.Quarter, name: '3 месяца'},
		{value: PaymentTypeEnum.Halfyear, name: '6 месяцев'},
		{value: PaymentTypeEnum.Year, name: '1 год'},
	];

	const isTrialSelected = useCallback(
		() =>
			formik.values.tariffId === tariffData?.activeTariffs.find(({isTrial}) => isTrial)?._id,
		[formik.values.tariffId, tariffData?.activeTariffs],
	);
	const isPaidTariffWithDaysSelected = useCallback(
		() => isPaidTariffSelect && isDaysSwitch,
		[isDaysSwitch, isPaidTariffSelect],
	);

	return (
		<FormikProvider value={formik}>
			<Modal
				title={
					<>
						Настройка тарифа пользователя <b>{user.email}</b>
					</>
				}
				subtitle={
					<>
						Активный тариф{' '}
						<b>
							{
								tariffData?.activeTariffs.filter(
									(tariff) => tariff._id === user.activeTariffId,
								)[0]?.name
							}
							,{' '}
						</b>
						действует до {format(user.subscription?.toDate, 'dd.MM.yyyy HH:mm')}
					</>
				}
				open={open}
				onClose={onClose}
				maxWidth="sm"
				fullWidth
				id={'userTariff'}
			>
				<form onSubmit={formik.handleSubmit} className={css.dialogContainer}>
					<Grid container direction={'column'} spacing={2}>
						<Grid item xs>
							<Grid container direction={'row'} alignItems={'center'}>
								<Grid item>Тариф:</Grid>
								<Grid item>
									<FormControl sx={{m: 1, minWidth: 140}} size={'small'}>
										<Select
											name={'tariffId'}
											value={formik.values.tariffId}
											onChange={handleInputChange}
										>
											{tariffData &&
												tariffData.activeTariffs.map((tariff) => (
													<MenuItem value={tariff._id} key={tariff._id}>
														{tariff.name}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>

						{isPaidTariffSelect && (
							<>
								<Grid item>
									<FormGroup>
										<FormControlLabel
											control={
												<Switch
													value={isDaysSwitch}
													onChange={() => setIsDaysSwitch(!isDaysSwitch)}
												/>
											}
											label="Добавление дней"
										/>
									</FormGroup>
								</Grid>

								{!isDaysSwitch && (
									<>
										<Grid item>
											<FormControl>
												Период продления:
												<RadioGroup
													onChange={handleInputChange}
													defaultValue={formik.initialValues.paymentType}
													row={true}
													value={formik.values.paymentType}
													name={'paymentType'}
												>
													{itemPaymentType.map(({value, name}, i) => (
														<FormControlLabel
															key={i}
															classes={{label: css.content}}
															value={value}
															control={<Radio size={'small'} />}
															label={name}
														/>
													))}
												</RadioGroup>
											</FormControl>
										</Grid>

										<Grid item>
											<FormGroup>
												<FormControlLabel
													name={'checked'}
													classes={{label: css.content}}
													control={
														<Checkbox
															size={'small'}
															value={formik.values.checked}
															title={'Отслеживать'}
															checked={formik.values.checked}
															onChange={handleCheckedChange}
														/>
													}
													label="Создать платёж для статистики (Рекомендуется для безналичной оплаты)"
												/>
											</FormGroup>

											<div className={css.explanation}>
												* Без выбранного чекбокса осуществится перевод
												пользователя, как без оплаты <br />В статистике не
												будет отображаться оплата через сервис
											</div>
										</Grid>
									</>
								)}
							</>
						)}

						{(isTrialSelected() || isPaidTariffWithDaysSelected()) && (
							<>
								<Grid item xs justifySelf={'center'}>
									{!isPaidTariffSelect && (
										<div>
											Добавление{' '}
											<b>{isTrialSelected() ? 'бесплатных ' : ''}дней</b>:{' '}
										</div>
									)}
									<TextField
										name={'countFreeDays'}
										className={css.freeDays}
										size={'small'}
										label="Дней"
										type={'number'}
										value={formik.values.countFreeDays}
										onChange={handleInputChange}
										error={!!formik.errors?.countFreeDays}
										helperText={formik.errors?.countFreeDays}
									/>
									<br />

									{!!formik.values.countFreeDays &&
										`${
											isTrialSelected() ? 'Бесплатное продление' : 'Продление'
										} до: ${format(toDateFree, 'dd.MM.yyyy HH:mm')}`}
								</Grid>
							</>
						)}

						<Grid item xs>
							<br />

							<Grid container spacing={2}>
								<Grid item xs />

								<Grid item>
									<Button variant={'outlined'} onClick={handleClose}>
										Отменить
									</Button>
								</Grid>

								<Grid item>
									<Button variant={'outlined'} type="submit">
										Сохранить
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</Modal>

			<Snackbar
				open={openSuccess}
				onClose={() => setOpenSuccess(false)}
				message="Тариф успешно изменен!"
				alertProps={{severity: 'success'}}
			/>

			<Snackbar
				open={openError.isOpen}
				onClose={() => setOpenError({isOpen: false})}
				message={openError.message}
				alertProps={{severity: 'error'}}
			/>
		</FormikProvider>
	);
};

export default AddTariffToUserModal;
