export const getCallsDescription = (action: string): string => {
	switch (action) {
		case 'Me':
			return 'Данные пользователя';

		case 'subMe':
			return 'Данные пользователя 2';

		case 'wbAnalyticsComingPayments':
		case 'ozonAnalyticsComingPayments':
			return 'Сводка. Ближайшие выплаты';

		case 'wbAnalyticsSummary':
		case 'ozonAnalyticsSummary':
			return 'Сводные данные. Сегодня, вчера, 7 дней, месяц';

		case 'wbAnalyticsLastOrders':
		case 'ozonAnalyticsLastOrders':
			return 'Поток заказов';

		case 'wbAnalyticsSalesDynamic':
		case 'ozonAnalyticsSalesDynamic':
			return 'Динамика продаж. Диаграммы';

		case 'wbAnalyticsStocksAmount':
		case 'ozonAnalyticsStocksAmount':
			return 'Информационная панель';

		case 'wbAnalyticsSalesPerMonth':
		case 'ozonAnalyticsSalesPerMonth':
			return 'История выкупов. Календарь';

		case 'wbAnalyticsSalesPerDay':
		case 'ozonAnalyticsSalesPerDay':
			return 'История выкупов. График Динамика выкупов';

		case 'wbAnalyticsSalesStats':
		case 'ozonAnalyticsSalesStats':
			return 'История выкупов. Таблица Статистика выкупов';

		case 'wbAnalyticsSalesForPayByBrands':
		case 'ozonAnalyticsSalesForPayByBrands':
			return 'История выкупов. К оплате по брендам';

		case 'wbAnalyticsOrdersPerMonth':
		case 'ozonAnalyticsOrdersPerMonth':
			return 'История заказов. Календарь';

		case 'wbAnalyticsOrdersPerDay':
		case 'ozonAnalyticsOrdersPerDay':
			return 'История заказов. График динамика заказов';

		case 'wbAnalyticsOrdersStats':
		case 'ozonAnalyticsOrdersStats':
			return 'История заказов. Таблица Статистика заказов';

		case 'wbAnalyticsSalesByRegion':
		case 'ozonAnalyticsSalesByRegion':
			return 'География продаж. Продажи по регионам';

		case 'wbAnalyticsOrderPlan':
		case 'ozonAnalyticsOrderPlan':
			return 'Планировщик заказа. Количества товара к заказу';

		case 'wbAnalyticsStocksByWarehouse':
		case 'ozonAnalyticsStocksByWarehouse':
			return 'Склад. Остатки на складах';

		case 'wbAnalyticsStocksByProduct':
		case 'ozonAnalyticsStocksByProduct':
			return 'Движение товара. Количество товара в пути ';

		case 'wbAnalyticsSalesReport':
		case 'ozonAnalyticsSalesReport':
			return 'Логистика. К оплате за логистику';

		case 'wbAnalyticsSalesLogisticAmount':
		case 'ozonAnalyticsSalesLogisticAmount':
			return 'Логистика. Диаграмма';

		// Статистика МП
		case 'wbStats':
		case 'ozonStats':
			return 'Общее сравнение МП. Таблица';

		case 'wbStatsChart':
		case 'ozonStatsChart':
			return 'Общее сравнение МП. График';

		case 'wbProducts':
		case 'ozonProducts':
			return 'Поиск товара в маркетплейсе. Карточка продавца. Карточка бренда';

		case 'wbCategoryStatsChart':
		case 'ozonCategoryStatsChart':
			return 'Динамика по категориям. График';

		case 'wbTopProducts':
		case 'ozonTopProducts':
			return 'Топ товаров за вчерашний день';

		case 'wbTopCategories':
		case 'ozonTopCategories':
			return 'Топ категорий за вчерашний день';

		//категории
		case 'wbCategoryProducts':
		case 'ozonCategoryProducts':
			return 'Категории. Таблица товаров по категории';

		case 'wbSubcategoriesSales':
		case 'ozonSubcategoriesSales':
			return 'Подкатегории. Диаграмма Продажи';

		case 'wbSubcategoriesRevenue':
		case 'ozonSubcategoriesRevenue':
			return 'Подкатегории. Диаграмма Выручка';

		case 'wbSubcategoriesStats':
		case 'ozonSubcategoriesStats':
			return 'Подкатегории. Общая таблица';

		case 'wbCategoryBrandsSales':
		case 'ozonCategoryBrandsSales':
			return 'Бренды. Диаграмма Продажи';

		case 'wbCategoryBrandsRevenue':
		case 'ozonCategoryBrandsRevenue':
			return 'Бренды. Диаграмма Выручка';

		case 'wbCategoryBrands':
		case 'ozonCategoryBrands':
			return 'Бренды. Общая таблица';

		case 'wbTrendsChart':
		case 'ozonTrendsChart':
			return 'Тренд. Диаграмма Сравнение';

		case 'wbTrends':
		case 'ozonTrends':
			return 'Тренд. Таблица';

		case 'wbCategoryDailyStats':
		case 'ozonCategoryDailyStats':
			return 'По дням. Диаграмма Сравнение. Таблица';

		case 'wbSellers':
		case 'ozonSellers':
			return 'Поиск по поставщикам';
		case 'wbBrands':
		case 'ozonBrands':
			return 'Поиск по Брендам';

		//Карточка товара
		case 'wbProduct':
		case 'ozonProduct':
			return 'Карточка товара. Общие сведения о товаре';

		case 'wbProductSummary':
		case 'ozonProductSummary':
			return 'Карточка товара. Сводка по товару';

		case 'wbProductDailyHistory':
		case 'ozonProductDailyHistory':
			return 'Карточка товара. График Динамика по продажам. Статистика товара ';

		case 'wbProductHistory':
		case 'ozonProductHistory':
			return 'Карточка товара. Изменения контента в карточке товара';

		case 'productSearchQueries':
			return 'Карточка товара. Позиции товара по поисковым запросам';

		case 'wbProductPositionHistory':
		case 'ozonProductPositionHistory':
			return 'Карточка товара. Категории и позиции ';

		case 'wbQuantityPerWarehouse':
		case 'ozonQuantityPerWarehouse':
			return 'Карточка товара. Продажи и остатки по складам';

		case 'wbProductVariants':
		case 'ozonProductVariants':
			return 'Карточка товара. Размеры и цвета';

		//Карточка продавца
		case 'wbSeller':
		case 'ozonSeller':
			return 'Карточка продавца. Сводка. Присутствие';

		//Карточка бренда
		case 'wbBrand':
		case 'ozonBrand':
			return 'Карточка бренда. Сводка. Присутствие';

		case 'addBannerMetricsItemPre':
			return 'Действия с баннерами';

		case 'activePoll':
			return 'Активные опросы';

		case 'favorites':
			return 'Избранное';

		case 'favoritesGroups':
			return 'Избранное';

		case 'AddToFavorites':
			return 'Избранное. Добавление товаров в группу';

		case 'CreateFavoritesGroup':
			return 'Избранное. Создание групп избранного';

		case 'wbCategories':
		case 'ozonCategories':
			return 'Маркетплейсы. Динамика по продажам. Поиск по продавцам. Поиск по брендам';

		case 'marketplacesStats':
			return 'Главная. Сводка по маркетплейсам';

		case 'marketplacesStatsChart':
			return 'Главная. Оборот маркетплейсов';

		case 'wbSectionsStats':
		case 'ozonSectionsStats':
			return 'Маркетплейсы. Кнопки блоков';

		case 'mySearchPositionsRequests':
			return 'Позиции товара по поисковым запросам';

		default:
			return '';
	}
};
