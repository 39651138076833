import React, {useCallback, useState} from 'react';
import {FormikHelpers, FormikProvider, useFormik} from 'formik';
import {object, SchemaOf, string} from 'yup';
import {Card, Grid, Link} from '@mui/material';
import {GraphQLError} from 'graphql';
import {useNavigate} from 'react-router';
import {Button} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import PhoneField from 'components/auth/PhoneField';
import EnterPhoneCode from 'components/auth/EnterPhoneCode';
import {useLoginByPhoneMutation} from 'api/users';
import routeUrls from 'constants/routeUrls';
import css from './LoginPage.pcss';
import {LoginByPhoneMutationVariables} from '../../gqlApp/graphql';

const initialValues: LoginByPhoneMutationVariables = {phone: ''};

const validationSchema: SchemaOf<LoginByPhoneMutationVariables> = object().shape({
	phone: string().required('Укажите ваш номер телефона'),
});

const LoginByPhonePage: React.FC = () => {
	const navigate = useNavigate();

	const [phoneState, setPhoneState] = useState('');
	const [enterCodeMode, setEnterCodeMode] = useState(false);

	const [loginByPhone, {loading}] = useLoginByPhoneMutation();

	const onSubmit = useCallback(
		(
			{phone}: LoginByPhoneMutationVariables,
			actions: FormikHelpers<LoginByPhoneMutationVariables>,
		) =>
			loginByPhone({variables: {phone: `+${phone}`}})
				.then(() => {
					setPhoneState(phone.toString());
					setEnterCodeMode(true);
				})
				.catch((err) => {
					err.graphQLErrors.forEach((error: GraphQLError) => {
						if (error.extensions?.code === 'FORBIDDEN') {
							actions.setErrors({phone: error.message});

							return;
						}

						actions.setErrors({
							[Object.keys(error.extensions?.exception || {})[0]]: error.message,
						});
					});
				}),
		[loginByPhone],
	);

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	return (
		<FormikProvider value={formik}>
			<Card className={css.logInPage}>
				<div className={css.title}>Вход</div>

				<div className={css.description}>
					Используйте номер телефона для входа в аккаунт <br />
					или <Link href={routeUrls.AUTH_LOGIN_BY_EMAIL}>воспользуйтесь</Link> стандартным
					входом
				</div>

				<div className={css.form}>
					{enterCodeMode ? (
						<EnterPhoneCode
							phoneBase={phoneState}
							onConfirm={() => navigate(routeUrls.APP_DASHBOARD)}
							loading={loading}
						/>
					) : (
						<form onSubmit={formik.handleSubmit}>
							<Grid container spacing={4}>
								<Grid item xs>
									<PhoneField
										name="phone"
										onChange={(e) =>
											formik.setFieldValue(
												e.target.name as string,
												e.target.value.trim(),
												!!Object.keys(formik.errors).length,
											)
										}
										error={!!formik.errors?.phone}
										helperText={formik.errors?.phone}
									/>
								</Grid>
							</Grid>

							<div style={{height: 20}} />

							<Button
								loading={loading}
								type="submit"
								className={css.logIn}
								variant="outlined"
								color="primary"
								fullWidth
								size="large"
								label="Войти"
							/>
						</form>
					)}
				</div>
			</Card>
		</FormikProvider>
	);
};

export default LoginByPhonePage;
