import React, {useMemo, useState} from 'react';
import {subDays} from 'date-fns';
import {DateRange} from '@mui/lab/DateRangePicker';
import {SelectChangeEvent} from '@mui/material';
import {
	ContentBlock,
	DateRangeTemplate,
	FilterVisualChartWrapper,
	SelectRangeDate,
	VisualChart,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {useRegistrationStatsQuery} from 'api/users';
import {UserTypeFilter} from '../../gqlApp/graphql';

const RegistrationChartBlock: React.FC = () => {
	const [value, setValue] = useState<DateRange<Date>>([subDays(new Date(), 30), new Date()]);

	const [filter, setFilter] = useState<UserTypeFilter>(UserTypeFilter.All);

	const handleChange = (event: SelectChangeEvent) => {
		setFilter(event.target.value as UserTypeFilter);
	};

	const filters: FilterVisualChartWrapper = useMemo(
		() => ({
			value: filter,
			handleChange,
			inputLabel: 'Сервис',
			filtersItems: [
				{
					value: UserTypeFilter.All,
					title: 'Все',
				},
				{
					value: UserTypeFilter.Yandex,
					title: 'Яндекс',
				},
				{
					value: UserTypeFilter.Default,
					title: 'Shopstat',
				},
			],
		}),
		[filter],
	);

	const onChangeDate = (newValue: DateRange<Date>) => {
		setValue(newValue);
	};

	const {data, loading} = useRegistrationStatsQuery({
		dateRange: {
			fromDate: value[0]?.getTime(),
			toDate: value[1]?.getTime(),
		},
		type: filter,
	});

	return (
		<ContentBlock
			loading={loading}
			label="Статистика регистраций"
			rightHeaderBlock={
				<SelectRangeDate
					onChange={onChangeDate}
					dateRangeTemplate={DateRangeTemplate.month}
				/>
			}
		>
			<VisualChart
				labels={data.labels}
				datasets={data.datasets}
				hideTotal
				filters={filters}
			/>
		</ContentBlock>
	);
};

export default RegistrationChartBlock;
