import React, {useState} from 'react';
import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	Grid,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';
import {FormikHelpers, FormikProvider, useFormik} from 'formik';
import {bool, number, object, SchemaOf, string} from 'yup';

import Checkbox from 'components/common/ui/Checkbox';
import {useAddTariffMutation} from 'api/tariffs';

interface IAddTariffModal {
	isOpen: boolean;
	onClose: () => void;
}

interface IValues {
	name: string;
	description: string;
	price: number;
	price3: number;
	price6: number;
	price12: number;
	duration?: number | null;
	isPublic: boolean;
	isActive: boolean;
	isDefault: boolean;
}

const validationSchema: SchemaOf<IValues> = object().shape({
	name: string().required('Укажите название'),
	description: string().required('Укажите описание'),
	price: number().required('Укажите стоимость за 1 месяц'),
	price3: number().required('Укажите стоимость за 3 месяца'),
	price6: number().required('Укажите стоимость за 6 месяцев'),
	price12: number().required('Укажите стоимость за 12 месяцев'),
	isActive: bool().required(),
	isPublic: bool().required(),
	isDefault: bool().required(),
	duration: number().required(),
});

const AddTariffModal: React.FC<IAddTariffModal> = ({isOpen, onClose}) => {
	const [error, setError] = useState('');
	const initialValues: IValues = {
		name: '',
		description: '',
		price: 0,
		price3: 0,
		price6: 0,
		price12: 0,
		duration: undefined,
		isPublic: false,
		isActive: true,
		isDefault: false,
	};

	const handleClose = () => {
		setError('');
		onClose();
	};

	const [addTariffMutation, {loading}] = useAddTariffMutation();

	const onSubmitHandler = (newTariffInput: IValues, actions: FormikHelpers<IValues>) => {
		addTariffMutation({variables: {newTariffInput}})
			.then(() => {
				setError('');
				onClose();
			})
			.catch((err) => {
				console.log(JSON.stringify(err));
				actions.setSubmitting(false);
				setError('Ошибка запроса');
			});
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: onSubmitHandler,
	});

	return (
		<FormikProvider value={formik}>
			<Dialog open={isOpen} onClose={onClose} fullWidth>
				<DialogTitle>Добавление тарифа</DialogTitle>
				<form onSubmit={formik.handleSubmit}>
					<DialogContent>
						<Typography variant={'subtitle1'}>Основные настройки:</Typography>

						<Grid item xs>
							<TextField
								name="name"
								label="Название"
								margin="normal"
								value={formik.values.name}
								onChange={(e) =>
									formik.setFieldValue(
										e.target.name,
										e.target.value,
										!!Object.keys(formik.errors).length,
									)
								}
								error={!!formik.errors.name}
								helperText={formik.errors?.name}
							/>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs>
								<TextField
									name="price"
									type="number"
									label="Стоимость за 1 месяц"
									margin="normal"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">₽</InputAdornment>
										),
									}}
									value={formik.values.price}
									onChange={(e) =>
										formik.setFieldValue(
											e.target.name,
											Number(e.target.value.trim()),
											!!Object.keys(formik.errors).length,
										)
									}
									error={!!formik.errors.price}
									helperText={formik.errors?.price}
								/>
							</Grid>

							<Grid item xs>
								<TextField
									name="price3"
									type="number"
									label="Стоимость за 3 месяца"
									margin="normal"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">₽</InputAdornment>
										),
									}}
									value={formik.values.price3}
									onChange={(e) =>
										formik.setFieldValue(
											e.target.name,
											Number(e.target.value.trim()),
											!!Object.keys(formik.errors).length,
										)
									}
									error={!!formik.errors.price3}
									helperText={formik.errors?.price3}
								/>
							</Grid>
							<Grid item xs>
								<TextField
									name="price6"
									type="number"
									label="Стоимость за 6 месяцев"
									margin="normal"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">₽</InputAdornment>
										),
									}}
									value={formik.values.price6}
									onChange={(e) =>
										formik.setFieldValue(
											e.target.name,
											Number(e.target.value.trim()),
											!!Object.keys(formik.errors).length,
										)
									}
									error={!!formik.errors.price6}
									helperText={formik.errors?.price6}
								/>
							</Grid>
							<Grid item xs>
								<TextField
									name="price12"
									type="number"
									label="Стоимость за 12 месяцев"
									margin="normal"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">₽</InputAdornment>
										),
									}}
									value={formik.values.price12}
									onChange={(e) =>
										formik.setFieldValue(
											e.target.name,
											Number(e.target.value.trim()),
											!!Object.keys(formik.errors).length,
										)
									}
									error={!!formik.errors.price12}
									helperText={formik.errors?.price12}
								/>
							</Grid>
						</Grid>

						<Grid item xs>
							<TextField
								name="description"
								label="Описание"
								margin="normal"
								value={formik.values.description}
								onChange={(e) =>
									formik.setFieldValue(
										e.target.name,
										e.target.value,
										!!Object.keys(formik.errors).length,
									)
								}
								error={!!formik.errors.description}
								helperText={formik.errors?.description}
							/>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs>
								<TextField
									name="duration"
									type="number"
									label="Количество дней"
									margin="normal"
									value={formik.values.duration}
									onChange={(e) =>
										formik.setFieldValue(
											e.target.name,
											Number(e.target.value.trim()),
											!!Object.keys(formik.errors).length,
										)
									}
									error={!!formik.errors.duration}
									helperText={formik.errors?.duration}
								/>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={4}>
								<Checkbox
									name="isActive"
									checked={formik.values.isActive}
									label="Активный"
								/>
							</Grid>

							<Grid item xs={4}>
								<Checkbox
									name="isPublic"
									checked={formik.values.isPublic}
									label="Публичный"
								/>
							</Grid>

							<Grid item xs={4}>
								<Checkbox
									name="isDefault"
									checked={formik.values.isDefault}
									label="По умолчанию"
								/>
							</Grid>
						</Grid>
						<Alert severity={'info'}>
							{' '}
							Добавить разрешения можно только через <b>редактирование тарифа</b>{' '}
						</Alert>
					</DialogContent>

					<DialogActions>
						<Grid container>
							<Grid item sm />

							<Grid item>
								<Button style={{marginRight: 10}} onClick={handleClose}>
									Отменить
								</Button>

								<Button
									variant="contained"
									color="primary"
									type="submit"
									disabled={loading}
								>
									Добавить
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
					<DialogContent style={{padding: '0px 20px'}}>
						{!loading && error && (
							<Typography variant="body2" gutterBottom component="div">
								<FormHelperText error>{error}</FormHelperText>
							</Typography>
						)}
					</DialogContent>
				</form>
			</Dialog>
		</FormikProvider>
	);
};

export default AddTariffModal;
