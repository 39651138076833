import React from 'react';
import {FormikHelpers, useFormik} from 'formik';
import {object, SchemaOf, string} from 'yup';
import {Card, Link, TextField} from '@mui/material';
import {GraphQLError} from 'graphql';
import {useNavigate} from 'react-router';
import {Button} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {loginMutation} from 'api/users';
import routeUrls from 'constants/routeUrls';
import appConsts from 'constants/appConsts';
import css from './LoginPage.pcss';
import {LoginMutationVariables} from '../../gqlApp/graphql';

const initialValues: LoginMutationVariables = {email: '', password: ''};

const validationSchema: SchemaOf<LoginMutationVariables> = object().shape({
	email: string().email().required(),

	password: string().min(appConsts.MIN_PASSWORD_LENGTH).required(),
});

const LoginByEmailPage: React.FC = () => {
	const navigate = useNavigate();

	const onSubmit = (
		values: LoginMutationVariables,
		actions: FormikHelpers<LoginMutationVariables>,
	) =>
		loginMutation({
			email: values.email,
			password: values.password,
		})
			.then(() => {
				navigate(routeUrls.APP_DASHBOARD);
			})
			.catch((err) => {
				err.graphQLErrors.forEach((error: GraphQLError) => {
					if (error.extensions?.code === 'FORBIDDEN') {
						actions.setErrors({email: error.message});

						return;
					}

					actions.setErrors({
						[Object.keys(error.extensions?.exception || {})[0]]: error.message,
					});
				});
			});

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	return (
		<Card className={css.logInPage}>
			<div className={css.title}>Вход</div>

			<div className={css.description}>
				Используйте почту и пароль для входа в аккаунт <br />
				или <Link href={routeUrls.AUTH_LOGIN}>воспользуйтесь</Link> входом по номеру
				телефона
			</div>

			<div className={css.form}>
				<form onSubmit={formik.handleSubmit} className={css.form}>
					<TextField
						name="email"
						label="Email"
						fullWidth
						margin="normal"
						value={formik.values.email}
						onChange={(e) =>
							formik.setFieldValue(
								e.target.name,
								e.target.value.trim(),
								!!Object.keys(formik.errors).length,
							)
						}
						error={!!formik.errors.email}
						helperText={formik.errors?.email}
					/>

					<TextField
						name="password"
						label="Пароль"
						type="password"
						fullWidth
						margin="normal"
						value={formik.values.password}
						onChange={(e) =>
							formik.setFieldValue(
								e.target.name,
								e.target.value.trim(),
								!!Object.keys(formik.errors).length,
							)
						}
						error={!!formik.errors.password}
						helperText={formik.errors?.password}
					/>

					<Button
						loading={formik.isSubmitting}
						type="submit"
						className={css.logIn}
						fullWidth
					>
						Войти
					</Button>
				</form>
			</div>
		</Card>
	);
};

export default LoginByEmailPage;
