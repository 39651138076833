import React, {useState} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	Grid,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';
import {FormikHelpers, FormikProvider, useFormik} from 'formik';
import {bool, number, object, SchemaOf, string} from 'yup';

import Checkbox from 'components/common/ui/Checkbox';
import {useEditTariffMutation} from 'api/tariffs';
import css from './EditTariffModal.pcss';
import {TariffFragment} from '../../../../gqlApp/graphql';

interface IEditTariffModal {
	tariff: TariffFragment;
	isOpen: boolean;
	onClose: () => void;
}

interface IValues {
	name: string;
	description: string;
	price: number;
	price3: number;
	price6: number;
	price12: number;
	duration?: number | null;
	isPublic: boolean;
	isActive: boolean;
	isDefault: boolean;
	marked: boolean | null | undefined;
	color: string | null | undefined;
}

const validationSchema: SchemaOf<IValues> = object().shape({
	name: string().required('Укажите название'),
	description: string().required('Укажите описание'),
	price: number().required('Укажите стоимость за 1 месяц'),
	price3: number().required('Укажите стоимость за 3 месяца'),
	price6: number().required('Укажите стоимость за 6 месяцев'),
	price12: number().required('Укажите стоимость за 12 месяцев'),
	isActive: bool().required(),
	isPublic: bool().required(),
	isDefault: bool().required(),
	duration: number(),
	marked: bool().nullable(),
	color: string().nullable(),
});

const EditTariffModal: React.FC<IEditTariffModal> = ({isOpen, onClose, tariff}) => {
	const [error, setError] = useState('');
	const initialValues: IValues = {
		name: tariff.name,
		description: tariff.description,
		price: tariff.price,
		price3: tariff.price3,
		price6: tariff.price6,
		price12: tariff.price12,
		duration: tariff.duration || undefined,
		isPublic: tariff.isPublic,
		isActive: tariff.isActive,
		isDefault: tariff.isDefault,
		marked: tariff.marked,
		color: tariff.color || '#0054FE',
	};

	const handleClose = () => {
		setError('');
		onClose();
	};

	const [editTariffMutation, {loading}] = useEditTariffMutation();

	const onSubmitHandler = (
		{name, description, price, price3, price6, price12, marked, color}: IValues,
		actions: FormikHelpers<IValues>,
	) => {
		editTariffMutation({
			variables: {
				tariffId: tariff._id,
				editTariffInput: {name, description, price, price3, price6, price12, marked, color},
			},
		})
			.then(() => {
				setError('');
				onClose();
			})
			.catch((err) => {
				console.log(JSON.stringify(err));
				actions.setSubmitting(false);
				setError('Ошибка запроса');
			});
	};

	const handleDelete = (actions: FormikHelpers<IValues>) => () => {
		actions.setSubmitting(true);
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: onSubmitHandler,
	});

	return (
		<FormikProvider value={formik}>
			<Dialog open={isOpen} onClose={onClose} fullWidth>
				<DialogTitle>
					Основные настройки тарифа <b>{tariff.name}</b>
				</DialogTitle>

				<form onSubmit={formik.handleSubmit}>
					<DialogContent>
						<Grid container spacing={2} direction={'column'}>
							<Grid item xs>
								<Grid container>
									<TextField
										name="name"
										label="Название"
										margin="normal"
										value={formik.values.name}
										onChange={(e) =>
											formik.setFieldValue(
												e.target.name,
												e.target.value.trim(),
												!!Object.keys(formik.errors).length,
											)
										}
										error={!!formik.errors.name}
										helperText={formik.errors?.name}
									/>
								</Grid>
							</Grid>
							<Grid item xs>
								<Grid container spacing={2}>
									<Grid item xs>
										<TextField
											name="price"
											type="number"
											label="Стоимость за 1 месяц"
											margin="normal"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														₽
													</InputAdornment>
												),
											}}
											value={formik.values.price}
											onChange={(e) =>
												formik.setFieldValue(
													e.target.name,
													Number(e.target.value.trim()),
													!!Object.keys(formik.errors).length,
												)
											}
											error={!!formik.errors.price}
											helperText={formik.errors?.price}
										/>
									</Grid>

									<Grid item xs>
										<TextField
											name="price3"
											type="number"
											label="Стоимость за 3 месяца"
											margin="normal"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														₽
													</InputAdornment>
												),
											}}
											value={formik.values.price3}
											onChange={(e) =>
												formik.setFieldValue(
													e.target.name,
													Number(e.target.value.trim()),
													!!Object.keys(formik.errors).length,
												)
											}
											error={!!formik.errors.price3}
											helperText={formik.errors?.price3}
										/>
									</Grid>

									<Grid item xs>
										<TextField
											name="price6"
											type="number"
											label="Стоимость за 6 месяцев"
											margin="normal"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														₽
													</InputAdornment>
												),
											}}
											value={formik.values.price6}
											onChange={(e) =>
												formik.setFieldValue(
													e.target.name,
													Number(e.target.value.trim()),
													!!Object.keys(formik.errors).length,
												)
											}
											error={!!formik.errors.price6}
											helperText={formik.errors?.price6}
										/>
									</Grid>

									<Grid item xs>
										<TextField
											name="price12"
											type="number"
											label="Стоимость за 12 месяцев"
											margin="normal"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														₽
													</InputAdornment>
												),
											}}
											value={formik.values.price12}
											onChange={(e) =>
												formik.setFieldValue(
													e.target.name,
													Number(e.target.value.trim()),
													!!Object.keys(formik.errors).length,
												)
											}
											error={!!formik.errors.price12}
											helperText={formik.errors?.price12}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs>
								<TextField
									name="description"
									label="Описание"
									margin="normal"
									value={formik.values.description}
									onChange={(e) =>
										formik.setFieldValue(
											e.target.name,
											e.target.value.trim(),
											!!Object.keys(formik.errors).length,
										)
									}
									error={!!formik.errors.description}
									helperText={formik.errors?.description}
								/>
							</Grid>

							<Grid item xs>
								<TextField
									name="duration"
									disabled
									type="number"
									label="Количество дней"
									margin="normal"
									value={formik.values.duration}
									onChange={(e) =>
										formik.setFieldValue(
											e.target.name,
											e.target.value.trim(),
											!!Object.keys(formik.errors).length,
										)
									}
									error={!!formik.errors.duration}
									helperText={formik.errors?.duration}
								/>
							</Grid>

							<Grid item xs>
								<Grid container>
									<Grid item xs={2}>
										<TextField
											fullWidth
											name="color"
											type="color"
											label="Цвет"
											margin="normal"
											value={formik.values.color}
											onChange={(e) =>
												formik.setFieldValue(
													e.target.name,
													e.target.value.trim(),
													!!Object.keys(formik.errors).length,
												)
											}
											error={!!formik.errors.color}
											helperText={formik.errors?.color}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs>
								<Grid container alignItems={'center'}>
									<Grid item xs>
										Сделать главным на странице тарифов{' '}
									</Grid>
									<Grid item xs={5}>
										<Checkbox
											className={css.checkbox}
											name="marked"
											checked={formik.values.marked ?? false}
											label=""
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs>
							<Grid container spacing={2}>
								<Grid item xs>
									<Checkbox
										name="isActive"
										disabled
										checked={formik.values.isActive}
										label="Активный"
									/>
								</Grid>

								<Grid item xs>
									<Checkbox
										name="isPublic"
										disabled
										checked={formik.values.isPublic}
										label="Публичный"
									/>
								</Grid>

								<Grid item xs>
									<Checkbox
										name="isDefault"
										disabled
										checked={formik.values.isDefault}
										label="По умолчанию"
									/>
								</Grid>
							</Grid>
						</Grid>
					</DialogContent>

					<DialogActions style={{padding: '0px 20px'}}>
						<Grid container>
							<Grid item sm>
								<Button disabled onClick={handleDelete(formik)}>
									Удалить
								</Button>
							</Grid>

							<Grid item>
								<Button style={{marginRight: 10}} onClick={handleClose}>
									Отменить
								</Button>

								<Button
									variant="contained"
									color="primary"
									type="submit"
									disabled={loading}
								>
									Изменить
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
					<DialogContent>
						{!formik.isSubmitting && error && (
							<Typography variant="body2" gutterBottom component="div">
								<FormHelperText error>{error}</FormHelperText>
							</Typography>
						)}
					</DialogContent>
				</form>
			</Dialog>
		</FormikProvider>
	);
};

export default EditTariffModal;
