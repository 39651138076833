import React, {useMemo} from 'react';
import {SwipeableDrawer, Theme, useMediaQuery} from '@mui/material';
import {useNavigate} from 'react-router';
import {Sidebar} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {useLayoutContext} from 'layouts/LayoutContext';
import {logoutMutation} from 'api/users';
import routeUrls from 'constants/routeUrls';
import {ReactComponent as IconList} from './icons/IconList.svg';
import {ReactComponent as IconIncome} from './icons/IconIncome.svg';
import {ReactComponent as IconActiveUser} from './icons/IconActiveUser.svg';
import {ReactComponent as IconPopular} from './icons/IconPopular.svg';
import {ReactComponent as IconKey} from './icons/IconKey.svg';
import {ReactComponent as IconPromoCode} from './icons/IconPromocode.svg';
import {ReactComponent as IconPolls} from './icons/IconPolls.svg';
import {ReactComponent as IconUTM} from './icons/IconUTM.svg';

const AppNavigator: React.FC = () => {
	const navigate = useNavigate();

	const menuItems = [
		{
			head: 'Управление',
			items: [
				{
					title: 'Пользователи',
					link: `${routeUrls.APP_USERS}?filter=all`,
					Icon: IconList,
				},
				{
					title: 'Опросы',
					link: routeUrls.APP_POLLS,
					Icon: IconPolls,
				},
				{
					title: 'Промо-коды',
					link: routeUrls.APP_PROMO_CODES,
					Icon: IconPromoCode,
				},
				{
					title: 'Utm-метки',
					link: routeUrls.APP_UTM_SOURCE,
					Icon: IconUTM,
				},
			],
		},
		{
			head: 'Статистика',
			items: [
				{
					title: 'Платежи',
					link: routeUrls.APP_PAYMENT,
					Icon: IconIncome,
				},
				{
					title: 'Ключи API',
					link: routeUrls.APP_KEY_API,
					Icon: IconKey,
				},
				{
					title: 'Популярные запросы',
					link: routeUrls.API_CALLS_POPULAR_ACTIONS,
					Icon: IconPopular,
				},
				{
					title: 'Активные пользователи',
					link: routeUrls.API_CALLS_ACTIVE_USERS,
					Icon: IconActiveUser,
				},
			],
		},
	];

	const subMenu = useMemo(
		() => [
			{
				title: 'Prod',
				link: routeUrls.SHOPSTAT_PROD,
			},
			{
				title: 'Dev',
				link: routeUrls.SHOPSTAT_DEV,
			},
			{
				title: 'Выйти',
				onClick: () => logoutMutation().then(() => navigate(routeUrls.AUTH_LOGIN)),
			},
		],
		[navigate],
	);

	const {drawerIsOpen, toggleDrawer} = useLayoutContext();
	const isFromLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

	const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

	return isFromLg ? (
		<SwipeableDrawer
			open={drawerIsOpen}
			onClose={() => toggleDrawer(false)}
			onOpen={() => toggleDrawer(true)}
			onClick={() => toggleDrawer(false)}
			disableBackdropTransition={!iOS}
			disableDiscovery={iOS}
		>
			<Sidebar itemsMenu={menuItems} subMenu={subMenu} />
		</SwipeableDrawer>
	) : (
		<Sidebar itemsMenu={menuItems} subMenu={subMenu} />
	);
};

export default AppNavigator;
