import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Grid, IconButton, Pagination} from '@mui/material';
import Icon from '@mdi/react';
import {mdiDeleteCircleOutline, mdiSquareEditOutline} from '@mdi/js';
import {Button, ContentBlock, TableAdaptive} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {
	ITableColumn,
	ITableRow,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';
import {format} from 'date-fns';

import EditPromoCodeModal from 'components/promoCodes/EditPromoCodeModal';
import CreatePromoCodeModal from 'components/promoCodes/CreatePromoCodeModal';
import {usePromoCodesListQuery, useRemovePromoCodeMutation} from 'api/promoCodes';
import theme from '../../muiTheme';

const PromoCodesTable: React.FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [totalCount, _setTotalCount] = useState(0);
	const [pageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const pagesCount = useMemo(() => Math.ceil(totalCount / pageSize), [pageSize, totalCount]);

	const [open, setOpen] = useState(false);

	const {data, loading, refetch, error} = usePromoCodesListQuery({
		input: {
			// limit: pageSize,
			// page: currentPage * pageSize,
		},
	});

	const [removePromoCode] = useRemovePromoCodeMutation();

	// useEffect(() => {
	// 	setTotalCount(data?.promoCodesList.total || 0);
	// }, [data]);

	const variables = useMemo(
		() => ({
			input: {
				// limit: pageSize,
				// page: currentPage * pageSize,
			},
		}),
		[],
	);

	const getItems = useCallback(() => {
		(() => {
			refetch(variables);
		})();
	}, [refetch, variables]);

	useEffect(() => {
		getItems();
	}, [getItems]);

	const columns = useMemo<ITableColumn[]>(
		() => [
			{name: 'manage', width: 120, title: '', sortingEnabled: false},
			{name: 'description', title: 'Описание', sortingEnabled: false},
			{name: 'code', title: 'Код', sortingEnabled: false},
			{name: 'discount', title: 'Скидка', sortingEnabled: false},
			{
				name: 'paymentType',
				title: 'Типы оплат',
				sortingEnabled: false,
				format: (value) => {
					if (value) {
						return (value as string)
							.split(',')
							.map((v) => {
								switch (v.trim()) {
									case 'price':
										return '1';

									case 'price3':
										return '3';

									case 'price6':
										return '6';

									case 'price12':
										return '12';
								}

								return '';
							})
							.join(', ');
					}

					return '';
				},
			},
			{
				name: 'utmSource',
				title: 'Метка',
				sortingEnabled: false,
			},
			{
				name: 'limitCount',
				title: 'Лимит',
				sortingEnabled: false,
			},
			{
				name: 'endDate',
				title: 'Дата окончания',
				format: (value) => value && format(new Date(value), 'dd.MM.yyyy'),
				sortingEnabled: false,
			},
			{name: 'userIds', title: 'Пользователи'},
		],
		[],
	);

	const [editedCode, setEditedCode] = useState<string>();

	const rows = useMemo<ITableRow[]>(() => {
		if (!data) {
			return [];
		}

		return data.promoCodesList.map((item) => ({
			manage: (
				<>
					<EditPromoCodeModal
						open={editedCode === item._id}
						onClose={() => setEditedCode(undefined)}
						codeId={item._id}
						promoCode={item}
					/>

					<IconButton onClick={() => setEditedCode(item._id)}>
						<Icon
							path={mdiSquareEditOutline}
							size={0.8}
							color={theme.palette.primary.main}
						/>
					</IconButton>

					<IconButton
						onClick={async () => {
							if (window.confirm('Точно удалить?')) {
								await removePromoCode({
									variables: {
										code: item.code,
									},
								});
								refetch(variables);
							}
						}}
					>
						<Icon
							path={mdiDeleteCircleOutline}
							size={1}
							color={theme.palette.primary.main}
						/>
					</IconButton>
				</>
			),
			_id: item._id,
			description: item.description,
			code: item.code,
			discount: item.discount,
			paymentType: item.paymentType?.join(', '),
			utmSource: item.utmSource,
			limitCount: item.limitCount,
			endDate: item.endDate,
			userIds: item.userIds?.join(', '),
		}));
	}, [data, editedCode, refetch, removePromoCode, variables]);

	return (
		<>
			<Button style={{marginTop: 20}} onClick={() => setOpen(true)}>
				+ Создать промо-код
			</Button>
			<ContentBlock loading={loading} error={!!error}>
				<CreatePromoCodeModal open={open} onClose={() => setOpen(false)} />

				<TableAdaptive
					rows={rows}
					columns={columns}
					loading={loading}
					noDataMessage={error ? error.message : undefined}
				/>

				{pagesCount > 1 && (
					<>
						<br />

						<Grid container justifyContent="center">
							<Pagination
								page={currentPage + 1}
								count={pagesCount}
								onChange={(_, page) => setCurrentPage(page - 1)}
								siblingCount={1}
								boundaryCount={1}
							/>
						</Grid>

						<br />
					</>
				)}
			</ContentBlock>
		</>
	);
};

export default PromoCodesTable;
