import {GroupByTime} from '../gqlApp/graphql';

export const formatDateGroupByTime = (groupedByTime?: GroupByTime) => {
	switch (groupedByTime) {
		case GroupByTime.Hour:
			return 'HH:mm';

		case GroupByTime.Month:
			return 'MMM.yyyy';

		default:
			return 'dd.MM.yyyy';
	}
};
