import React from 'react';
import {CardContent, DialogContent, DialogProps} from '@mui/material';
import {omit} from 'lodash';
import {omitDeep} from '@apollo/client/utilities';

import PollForm from 'components/polls/PollForm';
import Modal from 'components/common/ui/Modal';
import {useLayoutContext} from 'layouts/LayoutContext';
import {allPollsQuery, useCreatePollMutation} from 'api/polls';

interface ICreatePollModal {}

const CreatePollModal: React.FC<ICreatePollModal & DialogProps> = ({open, onClose}) => {
	const {pollsVariables} = useLayoutContext();
	const [createPoll, {loading, error}] = useCreatePollMutation();

	return (
		<Modal title="Создание опроса" maxWidth={'xl'} open={open} onClose={onClose}>
			<DialogContent>
				<CardContent>
					<PollForm
						loading={loading}
						error={error}
						onSubmit={(values) => {
							createPoll({
								variables: {
									input: omit(
										omitDeep(
											omitDeep(
												{
													...values,
													isPublic: !!values.isPublic,
												},
												'__typename',
											),
											'_id',
										),
										'isActive',
										'answeredCount',
									),
								},
							}).then(() => {
								allPollsQuery(pollsVariables);
								onClose && onClose('', 'backdropClick');
							});
						}}
					/>
				</CardContent>
			</DialogContent>
		</Modal>
	);
};

export default CreatePollModal;
