import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {DialogProps, Theme, useMediaQuery} from '@mui/material';
import classNames from 'classnames';

import {ReactComponent as IconCloseMedium} from '../icons/IconCloseMedium.svg';
import css from './Modal.pcss';

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	size?: ModalSize;
}

export enum ModalSize {
	normal = 'normal',
	medium = 'medium',
}

const BootstrapDialogTitle = (props: DialogTitleProps & Omit<DialogProps, 'open'>) => {
	const {children, onClose, size} = props;

	return (
		<DialogTitle sx={{m: 0, p: 2}} className={css.title}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={() => onClose({}, 'backdropClick')}
					className={css.closeButton}
				>
					{size === ModalSize.medium ? <IconCloseMedium /> : <CloseIcon />}
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

interface IModal extends Omit<DialogProps, 'title'> {
	open: boolean;
	className?: string;
	title?: React.ReactNode;
	subtitle?: React.ReactNode;
	size?: ModalSize;
}

const Modal: React.FC<IModal> = ({
	onClose,
	open,
	title,
	subtitle,
	className,
	size = ModalSize.normal,
	children,
	...props
}) => {
	const isFromMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	const handleClose = (event: MouseEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
		if (reason && reason === 'backdropClick') return;

		onClose && onClose(event, reason);
	};

	return (
		<Dialog
			fullWidth
			maxWidth={props.maxWidth || 'md'}
			onClose={handleClose}
			open={open}
			fullScreen={isFromMd}
			{...props}
			classes={{
				root: classNames(className, css.dialog),
				paper: classNames({[css.medium]: size === ModalSize.medium}),
			}}
			title={undefined}
		>
			<BootstrapDialogTitle id="customized-dialog-title" size={size} onClose={onClose}>
				{title}

				{subtitle && <div className={css.subtitle}>{subtitle}</div>}
			</BootstrapDialogTitle>
			<>{children}</>
		</Dialog>
	);
};

export default Modal;
