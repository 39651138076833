import React, {useMemo} from 'react';
import {subDays} from 'date-fns';
import {useParams} from 'react-router-dom';
import {
	ContentBlock,
	DateRangeTemplate,
	SelectRangeDate,
	TableAdaptive,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {ITableColumn} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';

import {DateRange} from '@mui/lab/DateRangePicker/RangeTypes';
import {useApiCallsUserStats} from 'api/calls';

export interface ICallsUserStatsTableRow {
	action: string;
	count: number;
}

const columns: ITableColumn[] = [
	{name: 'action', title: 'Запрос'},
	{name: 'count', title: 'Количество вызовов'},
];

const CallsUserStatsTable: React.FC = () => {
	const {userId} = useParams<{userId: string}>();

	const [dateRangeValue, setDateRangeValue] = React.useState<DateRange<Date>>([
		subDays(new Date(), 30),
		new Date(),
	]);

	const onDateRangeChange = (newValue: DateRange<Date>) => {
		setDateRangeValue(newValue);
	};

	const {data, loading, error} = useApiCallsUserStats({
		userId: userId ?? '',
		fromDate: dateRangeValue[0]?.getTime(),
		toDate: dateRangeValue[1]?.getTime(),
	});

	const rows: ICallsUserStatsTableRow[] = useMemo(() => {
		if (!data) {
			return [];
		}

		return data.apiCallsUserStats.map(({action, count}) => ({
			action,
			count,
		}));
	}, [data]);

	return (
		<ContentBlock
			loading={loading}
			error={!!error}
			rightHeaderBlock={
				<SelectRangeDate
					onChange={onDateRangeChange}
					dateRangeTemplate={DateRangeTemplate.month}
				/>
			}
		>
			<TableAdaptive rows={rows} columns={columns} />
		</ContentBlock>
	);
};

export default CallsUserStatsTable;
