import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
	FormControl,
	Grid,
	IconButton,
	Link,
	MenuItem,
	Pagination,
	Select,
	SelectChangeEvent,
	Theme,
	useMediaQuery,
} from '@mui/material';
import find from 'lodash/find';
import format from 'date-fns/format';
import queryString from 'query-string';
import Icon from '@mdi/react';
import {mdiAccountArrowRight, mdiDeleteCircleOutline, mdiSquareEditOutline} from '@mdi/js';
import {useNavigate} from 'react-router';
import {
	ContentBlock,
	TableAdaptive,
	SearchField,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {
	ITableColumn,
	ITableRow,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';
import {TableOrder} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/utils';

import {UserFilterDisplayEnum} from 'components/pages/DashboardPage';
import UserEditModal from 'components/users/UserEditModal';
import PaidToTableCell from 'components/users/table/PaidToTableCell';
import AddTariffToUserModal from 'components/tariffs/table/modals/AddTariffToUserModal';
import {useRemoveUserMutation, userTokenQuery, useUsersQuery} from 'api/users';
import {useActiveTariffs} from 'api/tariffs';
import routeUrls from 'constants/routeUrls';
import css from './UserTable.pcss';
import theme from '../../../muiTheme';
import appConsts from '../../../constants/appConsts';
import {SortDirection, UserFilter, UserSort} from '../../../gqlApp/graphql';

const UserTable: React.FC = () => {
	const isFromMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	const navigate = useNavigate();

	const filter = queryString.parse(window.location.search).filter as
		| UserFilter
		| undefined
		| 'all';

	const [totalCount, setTotalCount] = useState(0);
	const [pageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const pagesCount = useMemo(() => Math.ceil(totalCount / pageSize), [pageSize, totalCount]);

	const [search, setSearch] = useState('');

	const [sort, setSort] = useState<UserSort>(UserSort.CreatedAt);
	const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Desc);

	const {
		data: usersData,
		loading: usersLoading,
		refetch: usersQuery,
		error: errorUsers,
	} = useUsersQuery({
		filter: filter === 'all' ? undefined : filter,
		search,
		limit: pageSize,
		skip: currentPage * pageSize,
		sort,
		sortDirection,
	});

	useEffect(() => {
		setTotalCount(usersData?.users.total || 0);
	}, [usersData]);

	const usersVariables = useMemo(
		() => ({
			filter: filter === 'all' ? undefined : filter,
			search,
			limit: pageSize,
			skip: currentPage * pageSize,
		}),
		[currentPage, filter, pageSize, search],
	);

	const getItems = useCallback(() => {
		(() => {
			usersQuery(usersVariables);
		})();
	}, [usersQuery, usersVariables]);

	useEffect(() => {
		getItems();
	}, [getItems]);

	const {loading: tariffLoading, data: tariffData} = useActiveTariffs();

	const handleLogInUser = (userId: string) => {
		userTokenQuery({id: userId})
			.then(({data: dataUserToken}) => {
				if (dataUserToken) {
					const {userToken} = dataUserToken;

					window.open(
						`${appConsts.FRONTEND_DOMAIN}/auth/login?userToken=${userToken}`,
						'_blank',
					);
				}
			})
			.catch(() => {
				alert('Аккаунт не имеет токена');
			});
	};

	const columns: ITableColumn[] = useMemo(
		() => [
			{name: 'manage', width: 120, title: '', sortingEnabled: false},
			{name: 'email', title: 'Email'},
			{name: 'phone', title: 'Телефон', sortingEnabled: false},
			{name: 'role', title: 'Роль'},
			{
				name: 'tariff',
				title: 'Тариф',
				sortingEnabled: false,
				format: (value, row) => (
					<Link onClick={() => setAddTariffToUser(row._id)}>{value}</Link>
				),
			},
			{
				name: 'subscriptionTo',
				title: 'Подписан до',
				format: (value) => <PaidToTableCell value={value as any} />,
				sortingEnabled: false,
			},
			{
				name: 'createdAt',
				title: 'Дата регистрации',
				format: (value) => <>{format(value as any, 'dd.MM.yyyy')}</>,
			},
			{name: 'actions', width: 10, title: '', sortingEnabled: false},
		],
		[],
	);

	const [deleteUser] = useRemoveUserMutation();

	const [editedUser, setEditedUser] = useState<string>();
	const [addTariffToUser, setAddTariffToUser] = useState<string>();

	const rows: ITableRow[] = useMemo(() => {
		if (!usersData || !tariffData) {
			return [];
		}

		return usersData.users.items.map((user, index) => ({
			manage: (
				<div key={index}>
					<AddTariffToUserModal
						user={user}
						open={addTariffToUser === user._id}
						onClose={() => setAddTariffToUser(undefined)}
						updateTableUsers={getItems}
					/>

					<UserEditModal
						user={user}
						open={editedUser === user._id}
						onClose={() => setEditedUser(undefined)}
					/>

					<IconButton onClick={() => setEditedUser(user._id)}>
						<Icon
							path={mdiSquareEditOutline}
							size={0.8}
							color={theme.palette.primary.main}
						/>
					</IconButton>

					<IconButton
						onClick={async () => {
							if (window.confirm('Точно удалить?')) {
								await deleteUser({variables: {userId: user._id}});
								usersQuery(usersVariables);
							}
						}}
					>
						<Icon
							path={mdiDeleteCircleOutline}
							size={1}
							color={theme.palette.primary.main}
						/>
					</IconButton>
				</div>
			),
			_id: user._id,
			createdAt: user.createdAt,
			email: user.email,
			phone: user.phone,
			role: user.role,
			subscriptionTo: user.subscription?.toDate,
			tariff:
				find(tariffData.activeTariffs, {_id: user.activeTariffId})?.name ||
				'Старый платный',
			actions: (
				<Grid key={user._id} container>
					{user.isActive && (
						<Grid item>
							<IconButton onClick={() => handleLogInUser(user._id)}>
								<Icon
									path={mdiAccountArrowRight}
									size={1}
									color={theme.palette.grey['500']}
								/>
							</IconButton>
						</Grid>
					)}
				</Grid>
			),
		}));
	}, [
		addTariffToUser,
		deleteUser,
		editedUser,
		getItems,
		tariffData,
		usersData,
		usersQuery,
		usersVariables,
	]);

	const handleFilterChange = (event: SelectChangeEvent<UserFilter>) => {
		navigate(`${routeUrls.APP_USERS}?filter=${event.target.value}`);
	};

	const handleSorting = useCallback(
		({columnName, direction}: {columnName: string; direction: TableOrder}) => {
			setSort(columnName as UserSort);
			setSortDirection(
				direction.toLowerCase() === 'desc' ? SortDirection.Desc : SortDirection.Asc,
			);
		},
		[],
	);

	return (
		<ContentBlock loading={usersLoading || tariffLoading} error={!!errorUsers}>
			<Grid
				container
				spacing={2}
				direction={isFromMd ? 'column' : undefined}
				style={{padding: '10px 0'}}
			>
				<Grid item xs md={3}>
					<FormControl style={{width: '100%'}}>
						<Select
							// @ts-ignore
							value={filter}
							onChange={handleFilterChange}
						>
							<MenuItem value="all">{UserFilterDisplayEnum.All}</MenuItem>

							{Object.values(UserFilter).map((title, index) => (
								<MenuItem key={index} value={title}>
									{UserFilterDisplayEnum[title]}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs md={5}>
					<SearchField
						value={search}
						placeholder={'Поиск по email или номеру'}
						onSubmitValue={setSearch}
						onResetPage={() => setCurrentPage(0)}
						submitAfterClear
					/>
				</Grid>
			</Grid>

			<TableAdaptive
				rows={rows}
				columns={columns}
				defaultSortColumnName={sort}
				defaultSortDirection={sortDirection === SortDirection.Desc ? 'desc' : 'asc'}
				onSorting={handleSorting}
			/>

			{pagesCount > 1 && (
				<Grid container justifyContent="center" className={css.pagination}>
					<Pagination
						page={currentPage + 1}
						count={pagesCount}
						onChange={(_, page) => setCurrentPage(page - 1)}
						siblingCount={1}
						boundaryCount={1}
					/>
				</Grid>
			)}
		</ContentBlock>
	);
};

export default UserTable;
