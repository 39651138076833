import React from 'react';
import {Grid, TextField} from '@mui/material';
import {Button} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

interface IFormUtmSource {
	formik: any;
	loading?: boolean;
	mode?: 'create' | 'edit';
}
const FormUtmSource: React.FC<IFormUtmSource> = (props) => {
	const {formik, loading, mode} = props;

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container>
				<Grid item container direction={'column'}>
					<Grid item style={{marginBottom: 10, fontWeight: 500}}>
						Название метки {mode === 'edit' && '[Поле для просмотра]'}
					</Grid>

					<Grid item xs>
						<TextField
							disabled={mode === 'edit'}
							fullWidth
							name="source"
							value={formik.values.source}
							onChange={formik.handleChange}
							// classes={{root: css.input}}
							error={formik.touched.source && !!formik.errors.source}
							helperText={formik.touched.source && formik.errors.source}
						/>
					</Grid>
				</Grid>
				<Grid item container direction={'column'} style={{marginTop: 30}}>
					<Grid item style={{marginBottom: 10, fontWeight: 500}}>
						Комментарий
					</Grid>

					<Grid item xs>
						<TextField
							onChange={formik.handleChange}
							fullWidth
							name="comment"
							value={formik.values.comment}
							// classes={{root: css.input}}
						/>
					</Grid>
				</Grid>
				<Grid item container direction={'column'} style={{marginTop: 30, fontWeight: 500}}>
					<Grid item style={{marginBottom: 10}}>
						Стоимость рекламы
					</Grid>

					<Grid item xs>
						<TextField
							type={'number'}
							onChange={formik.handleChange}
							fullWidth
							name="price"
							placeholder="₽"
							value={formik.values.price}
							error={formik.touched.price && !!formik.errors.price}
							helperText={formik.touched.price && formik.errors.price}
							// classes={{root: css.input}}
						/>
					</Grid>
				</Grid>

				{mode !== 'edit' && (
					<Grid container>
						<Grid
							item
							container
							direction={'column'}
							style={{
								marginTop: 40,
								fontWeight: 500,
								borderTop: 'solid 2px grey',
							}}
						>
							<Grid item style={{margin: '30px 0 10px'}}>
								Utm Campaign
							</Grid>

							<Grid item xs>
								<TextField
									onChange={formik.handleChange}
									fullWidth
									name="campaign"
									// placeholder="₽"
									value={formik.values.campaign}
									// classes={{root: css.input}}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							container
							direction={'column'}
							style={{marginTop: 30, fontWeight: 500}}
						>
							<Grid item style={{marginBottom: 10}}>
								Utm Medium
							</Grid>

							<Grid item xs>
								<TextField
									onChange={formik.handleChange}
									fullWidth
									name="medium"
									// placeholder="₽"
									value={formik.values.medium}
									// classes={{root: css.input}}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							container
							direction={'column'}
							style={{marginTop: 30, fontWeight: 500}}
						>
							<Grid item style={{marginBottom: 10}}>
								Utm Term
							</Grid>

							<Grid item xs>
								<TextField
									onChange={formik.handleChange}
									fullWidth
									name="term"
									// placeholder="₽"
									value={formik.values.term}
									// classes={{root: css.input}}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							container
							direction={'column'}
							style={{marginTop: 30, fontWeight: 500}}
						>
							<Grid item style={{marginBottom: 10}}>
								Utm Content
							</Grid>

							<Grid item xs>
								<TextField
									onChange={formik.handleChange}
									fullWidth
									name="content"
									// placeholder="₽"
									value={formik.values.content}
									// classes={{root: css.input}}
								/>
							</Grid>
						</Grid>
					</Grid>
				)}

				<Grid item xs style={{marginTop: 30}}>
					<Button fullWidth type={'submit'} loading={loading}>
						{mode === 'edit' ? 'Сохранить' : 'Создать'}
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default FormUtmSource;
