/* eslint-disable */
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never};
export type Incremental<T> =
	| T
	| {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: {input: string; output: string};
	String: {input: string; output: string};
	Boolean: {input: boolean; output: boolean};
	Int: {input: number; output: number};
	Float: {input: number; output: number};
	/** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
	Timestamp: {input: any; output: any};
	/** The `Upload` scalar type represents a file upload. */
	Upload: {input: any; output: any};
};

export enum ActionType {
	Click = 'CLICK',
	View = 'VIEW',
}

export type ActiveUsersStatsEntity = {
	__typename?: 'ActiveUsersStatsEntity';
	count: Scalars['Float']['output'];
	date: Scalars['Timestamp']['output'];
};

export type ActiveUsersStatsResponse = {
	__typename?: 'ActiveUsersStatsResponse';
	statsDAU: Array<ActiveUsersStatsEntity>;
	statsMAU: Array<ActiveUsersStatsEntity>;
};

export type AddProductInput = {
	marketplace?: InputMaybe<ProductSources>;
	sku: Scalars['Float']['input'];
};

export type AddTariffInput = {
	description: Scalars['String']['input'];
	duration?: InputMaybe<Scalars['Float']['input']>;
	isActive: Scalars['Boolean']['input'];
	isDefault: Scalars['Boolean']['input'];
	isPublic: Scalars['Boolean']['input'];
	name: Scalars['String']['input'];
	price: Scalars['Float']['input'];
	price3: Scalars['Float']['input'];
	price6: Scalars['Float']['input'];
	price12: Scalars['Float']['input'];
};

export type AddTariffToUserResponse = {
	__typename?: 'AddTariffToUserResponse';
	paidTariff: PaidTariffType;
	user: UserType;
};

export type AdminPollType = {
	__typename?: 'AdminPollType';
	_id: Scalars['String']['output'];
	abortPageBody?: Maybe<Scalars['String']['output']>;
	abortPageTitle?: Maybe<Scalars['String']['output']>;
	completePageBody?: Maybe<Scalars['String']['output']>;
	completePageTitle?: Maybe<Scalars['String']['output']>;
	name: Scalars['String']['output'];
	questions: Array<QuestionType>;
	remaining: Scalars['Int']['output'];
	sendStatsToBitrix?: Maybe<Scalars['Boolean']['output']>;
	stats?: Maybe<PollStatsType>;
	urlForBitrixStats?: Maybe<Scalars['String']['output']>;
	views: Scalars['Int']['output'];
};

export type AdvertisementPartnershipLeadInput = {
	emailOrPhone: Scalars['String']['input'];
	name: Scalars['String']['input'];
};

export type AllMethodsAnalyticsSummary = {
	__typename?: 'AllMethodsAnalyticsSummary';
	logistic: LogisticSummary;
	order: SalesOrdersReturnSummary;
	profitAndCommission: ProfitAndCommission;
	returnSum: SalesOrdersReturnSummary;
	sales: SalesOrdersReturnSummary;
	warehouseCapital: WarehouseCapital;
};

export type AllNotificationsInput = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
};

export type AllPollsInput = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
};

export type AnalyticItemImage = {
	__typename?: 'AnalyticItemImage';
	original: Scalars['String']['output'];
	small: Scalars['String']['output'];
};

export type AnalyticsDateRangeInput = {
	dateRange: DateRangeInput;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AnalyticsDatesInput = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type AnalyticsExpensesResponse = {
	__typename?: 'AnalyticsExpensesResponse';
	commissionAmount: Scalars['Float']['output'];
	date: Scalars['Timestamp']['output'];
	deliveryAmount: Scalars['Float']['output'];
	othersAmount: Scalars['Float']['output'];
	returnAmount: Scalars['Float']['output'];
};

export type AnalyticsExpensesSummaryResponse = {
	__typename?: 'AnalyticsExpensesSummaryResponse';
	groupedByTime: GroupByTime;
	items: Array<AnalyticsExpensesResponse>;
};

export type AnalyticsFinanceSummaryResponse = {
	__typename?: 'AnalyticsFinanceSummaryResponse';
	balance: Scalars['Float']['output'];
	expensesAmount: Scalars['Float']['output'];
	ordersAmount: Scalars['Float']['output'];
	salesAmount: Scalars['Float']['output'];
};

export type AnalyticsForPay = {
	__typename?: 'AnalyticsForPay';
	amount: Scalars['Float']['output'];
	date: Scalars['Timestamp']['output'];
};

export type AnalyticsForPayByBrandResponse = {
	__typename?: 'AnalyticsForPayByBrandResponse';
	brand: Scalars['String']['output'];
	forPay: Scalars['Float']['output'];
	sales: Scalars['Float']['output'];
};

export type AnalyticsKeyIdsInput = {
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AnalyticsKeyStatsResponse = {
	__typename?: 'AnalyticsKeyStatsResponse';
	analyticsKeyStats: Array<AnalyticsKeyStatsType>;
	groupedByTime: GroupByTime;
};

export type AnalyticsKeyStatsType = {
	__typename?: 'AnalyticsKeyStatsType';
	date: Scalars['Timestamp']['output'];
	ozonKeyCount: Scalars['Float']['output'];
	wbKeyCount: Scalars['Float']['output'];
};

export type AnalyticsLogisticAmountResponse = {
	__typename?: 'AnalyticsLogisticAmountResponse';
	deliveryCost: Scalars['Float']['output'];
	deliveryCount: Scalars['Float']['output'];
	deliveryPercent: Scalars['Float']['output'];
	returnCost: Scalars['Float']['output'];
	returnCount: Scalars['Float']['output'];
	returnPercent: Scalars['Float']['output'];
	total: Scalars['Float']['output'];
};

export type AnalyticsOrdersPerDayResponse = {
	__typename?: 'AnalyticsOrdersPerDayResponse';
	date: Scalars['Timestamp']['output'];
	orders: Scalars['Float']['output'];
	ordersAmount: Scalars['Float']['output'];
};

export type AnalyticsOrdersPerMonth = {
	__typename?: 'AnalyticsOrdersPerMonth';
	date: Scalars['Timestamp']['output'];
	orders: Scalars['Float']['output'];
	ordersAmount: Scalars['Float']['output'];
	ordersAmountWithoutDiscount: Scalars['Float']['output'];
};

export type AnalyticsOrdersPerMonthResponse = {
	__typename?: 'AnalyticsOrdersPerMonthResponse';
	discountPercent: Scalars['Float']['output'];
	items: Array<AnalyticsOrdersPerMonth>;
	orders: Scalars['Float']['output'];
	ordersAmount: Scalars['Float']['output'];
};

export type AnalyticsOrdersProducsByLocationResponse = {
	__typename?: 'AnalyticsOrdersProducsByLocationResponse';
	category: Scalars['String']['output'];
	name: Scalars['String']['output'];
	orders: Scalars['Float']['output'];
	ordersAmount: Scalars['Float']['output'];
	regionName?: Maybe<Scalars['String']['output']>;
	sku: Scalars['Float']['output'];
};

export enum AnalyticsOrdersSort {
	Brand = 'BRAND',
	Category = 'CATEGORY',
	Date = 'DATE',
	ExternalId = 'EXTERNAL_ID',
	Price = 'PRICE',
	ProductName = 'PRODUCT_NAME',
	Quantity = 'QUANTITY',
	SalesAmount = 'SALES_AMOUNT',
}

export type AnalyticsOrdersStatsArgs = {
	dateRange: DateRangeInput;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sortBy?: InputMaybe<AnalyticsOrdersStatsSort>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type AnalyticsOrdersStatsResponse = {
	__typename?: 'AnalyticsOrdersStatsResponse';
	brand?: Maybe<Scalars['String']['output']>;
	category?: Maybe<Scalars['String']['output']>;
	images?: Maybe<Array<AnalyticItemImage>>;
	nmId: Scalars['Float']['output'];
	refunds: Scalars['Int']['output'];
	sales: Scalars['Int']['output'];
	salesAmount: Scalars['Float']['output'];
	subject: Scalars['String']['output'];
};

export enum AnalyticsOrdersStatsSort {
	Brand = 'BRAND',
	Category = 'CATEGORY',
	Sales = 'SALES',
	SalesAmount = 'SALES_AMOUNT',
}

export type AnalyticsOrdersSummaryResponse = {
	__typename?: 'AnalyticsOrdersSummaryResponse';
	discountPercent?: Maybe<Scalars['Float']['output']>;
	quantity?: Maybe<Scalars['Float']['output']>;
	totalPrice?: Maybe<Scalars['Float']['output']>;
};

export type AnalyticsPaymentHistoryResponse = {
	__typename?: 'AnalyticsPaymentHistoryResponse';
	amount: Scalars['Float']['output'];
	date: Scalars['Timestamp']['output'];
	marketName: MarketName;
	name: Scalars['String']['output'];
	status: PayStatus;
};

export type AnalyticsPaymentsInput = {
	dateFrom?: InputMaybe<Scalars['Timestamp']['input']>;
	dateTo?: InputMaybe<Scalars['Timestamp']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AnalyticsSalesDynamicResponse = {
	__typename?: 'AnalyticsSalesDynamicResponse';
	date: Scalars['Timestamp']['output'];
	forPay: Scalars['Float']['output'];
	orderAmount: Scalars['Float']['output'];
	orderQuantity: Scalars['Float']['output'];
	sales: Scalars['Float']['output'];
	salesAmount: Scalars['Float']['output'];
};

export type AnalyticsSalesDynamicResponseV2 = {
	__typename?: 'AnalyticsSalesDynamicResponseV2';
	groupedByTime: GroupByTime;
	items: Array<AnalyticsSalesDynamicResponse>;
};

export type AnalyticsSalesPerDay = {
	__typename?: 'AnalyticsSalesPerDay';
	date: Scalars['Timestamp']['output'];
	salesAmount: Scalars['Float']['output'];
};

export type AnalyticsSalesPerDayResp = {
	__typename?: 'AnalyticsSalesPerDayResp';
	date: Scalars['Timestamp']['output'];
	sales: Scalars['Float']['output'];
	salesAmount: Scalars['Float']['output'];
};

export type AnalyticsSalesPerDayResponse = {
	__typename?: 'AnalyticsSalesPerDayResponse';
	discountPercent: Scalars['Float']['output'];
	items: Array<AnalyticsSalesPerDay>;
	sales: Scalars['Float']['output'];
	salesAmount: Scalars['Float']['output'];
};

export type AnalyticsSalesProducsByLocationResponse = {
	__typename?: 'AnalyticsSalesProducsByLocationResponse';
	category: Scalars['String']['output'];
	city?: Maybe<Scalars['String']['output']>;
	name: Scalars['String']['output'];
	oblastOkrugName?: Maybe<Scalars['String']['output']>;
	orders: Scalars['Float']['output'];
	ordersAmount: Scalars['Float']['output'];
	regionName?: Maybe<Scalars['String']['output']>;
	sku: Scalars['Float']['output'];
};

export type AnalyticsStockTotalAmountResponse = {
	__typename?: 'AnalyticsStockTotalAmountResponse';
	inWay: Scalars['Float']['output'];
	inWayFromClient: Scalars['Float']['output'];
	inWayToClient: Scalars['Float']['output'];
	quantity: Scalars['Float']['output'];
	stockCost: Scalars['Float']['output'];
};

export enum AnalyticsStocksAmountSortBy {
	Brand = 'BRAND',
	Category = 'CATEGORY',
	InWay = 'IN_WAY',
	InWayFromClient = 'IN_WAY_FROM_CLIENT',
	InWayToClient = 'IN_WAY_TO_CLIENT',
	NmId = 'NM_ID',
	Productname = 'PRODUCTNAME',
	Quantity = 'QUANTITY',
	Subject = 'SUBJECT',
	Warehousename = 'WAREHOUSENAME',
}

export enum AnalyticsStocksPlanSortBy {
	Brand = 'BRAND',
	Category = 'CATEGORY',
	NmId = 'NM_ID',
	Productname = 'PRODUCTNAME',
	Quantity = 'QUANTITY',
	Restdays = 'RESTDAYS',
	Sales = 'SALES',
	Subject = 'SUBJECT',
	Toorder = 'TOORDER',
}

export type AnalyticsSummaryByPeriodResponse = {
	__typename?: 'AnalyticsSummaryByPeriodResponse';
	comission: Scalars['Float']['output'];
	fromClient?: Maybe<Scalars['Float']['output']>;
	order: Scalars['Float']['output'];
	orderSum: Scalars['Float']['output'];
	profit: Scalars['Float']['output'];
	reset: Scalars['Float']['output'];
	resetSum: Scalars['Float']['output'];
	retailPrice?: Maybe<Scalars['Float']['output']>;
	revenue: Scalars['Float']['output'];
	sales: Scalars['Float']['output'];
	toClient?: Maybe<Scalars['Float']['output']>;
};

export type AnalyticsSummaryResponse = {
	__typename?: 'AnalyticsSummaryResponse';
	month: AllMethodsAnalyticsSummary;
	today: AllMethodsAnalyticsSummary;
	week: AllMethodsAnalyticsSummary;
	yesterday: AllMethodsAnalyticsSummary;
};

export type AnalyticsTopSalesStatsResponse = {
	__typename?: 'AnalyticsTopSalesStatsResponse';
	brand?: Maybe<Scalars['String']['output']>;
	category?: Maybe<Scalars['String']['output']>;
	nmId: Scalars['Float']['output'];
	sales: Scalars['Float']['output'];
	salesAmount: Scalars['Float']['output'];
	subject: Scalars['String']['output'];
};

/** The answer is yes or no */
export enum Answer {
	No = 'NO',
	Yes = 'YES',
}

export type AnswerInput = {
	questionId: Scalars['String']['input'];
	value: Scalars['String']['input'];
};

export type ApiCallsActionStatsType = {
	__typename?: 'ApiCallsActionStatsType';
	action: Scalars['String']['output'];
	count: Scalars['Float']['output'];
};

export type ApiCallsUsersStatsType = {
	__typename?: 'ApiCallsUsersStatsType';
	count: Scalars['Float']['output'];
	user?: Maybe<UserType>;
	userId: Scalars['String']['output'];
};

export type ApiCallsUsersType = {
	__typename?: 'ApiCallsUsersType';
	items: Array<ApiCallsUsersStatsType>;
	totalItems: Scalars['Float']['output'];
};

export type ApplicationFeedbackAnswerResponse = {
	__typename?: 'ApplicationFeedbackAnswerResponse';
	answer: Scalars['String']['output'];
};

export type ApplyCodeInput = {
	code: Scalars['String']['input'];
};

export type ApplyPromoCodeInput = {
	code: Scalars['String']['input'];
};

export type ApplyPromoCodeType = {
	__typename?: 'ApplyPromoCodeType';
	discount: Array<Scalars['Int']['output']>;
	tariffs: Array<TariffType>;
};

export type BannerButtonProps = {
	__typename?: 'BannerButtonProps';
	label: Scalars['String']['output'];
	url: Scalars['String']['output'];
};

export type BannerButtonPropsInputType = {
	label: Scalars['String']['input'];
	url: Scalars['String']['input'];
};

export type BannerInputType = {
	background?: InputMaybe<Scalars['String']['input']>;
	backgroundMobile?: InputMaybe<Scalars['String']['input']>;
	buttonProps: BannerButtonPropsInputType;
	dark?: InputMaybe<Scalars['Boolean']['input']>;
	expireDate: Scalars['Timestamp']['input'];
	isSystem?: InputMaybe<Scalars['Boolean']['input']>;
	maxViewCount: Scalars['Int']['input'];
	paidStatus: Scalars['Boolean']['input'];
	position: Scalars['Int']['input'];
	subtitle?: InputMaybe<Scalars['String']['input']>;
	text?: InputMaybe<Scalars['String']['input']>;
	title: Scalars['String']['input'];
	titleType?: InputMaybe<BannerTitleType>;
	type?: InputMaybe<BannerTitleType>;
};

export type BannerMetricResponse = {
	__typename?: 'BannerMetricResponse';
	actionType: ActionType;
	bannerId: Scalars['String']['output'];
	catalog: CatalogType;
	device: DeviceType;
	marketName?: Maybe<MarketName>;
	position: PositionType;
	url: Scalars['String']['output'];
	userId: Scalars['ID']['output'];
};

export type BannerResponse = {
	__typename?: 'BannerResponse';
	_id: Scalars['String']['output'];
	background?: Maybe<Scalars['String']['output']>;
	backgroundMobile?: Maybe<Scalars['String']['output']>;
	buttonProps: BannerButtonProps;
	dark?: Maybe<Scalars['Boolean']['output']>;
	isSystem?: Maybe<Scalars['Boolean']['output']>;
	position: Scalars['Int']['output'];
	subtitle?: Maybe<Scalars['String']['output']>;
	text?: Maybe<Scalars['String']['output']>;
	title: Scalars['String']['output'];
	titleType?: Maybe<BannerTitleType>;
	type?: Maybe<BannerTitleType>;
};

export enum BannerTitleType {
	Default = 'DEFAULT',
	Second = 'SECOND',
	Third = 'THIRD',
}

export type BaseUserType = {
	__typename?: 'BaseUserType';
	_id: Scalars['String']['output'];
	activeTariffId: Scalars['String']['output'];
	botFeedbackToken?: Maybe<Scalars['String']['output']>;
	createdAt: Scalars['Timestamp']['output'];
	email: Scalars['String']['output'];
	havePassword?: Maybe<Scalars['Boolean']['output']>;
	isActive: Scalars['Boolean']['output'];
	isDeleted: Scalars['Boolean']['output'];
	isEmailVerified: Scalars['Boolean']['output'];
	isPhoneVerified: Scalars['Boolean']['output'];
	isSubscribed?: Maybe<Scalars['Boolean']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	ozonApiKeys: Array<OzonApiKeys>;
	phone?: Maybe<Scalars['String']['output']>;
	role: Scalars['String']['output'];
	telegram?: Maybe<Scalars['String']['output']>;
	uncompletedPoll?: Maybe<Scalars['String']['output']>;
	wbApiKeys: Array<WbApiKeys>;
};

export enum BrandsSortBy {
	Name = 'NAME',
	ProductCount = 'PRODUCT_COUNT',
	Revenue = 'REVENUE',
	Sales = 'SALES',
	SellerCount = 'SELLER_COUNT',
}

export type CancelSubscriptionResp = {
	__typename?: 'CancelSubscriptionResp';
	subscriptionStatus: SubscriptionStatus;
	tariff: TariffType;
};

export type CardAuthInfo = {
	__typename?: 'CardAuthInfo';
	pan: Scalars['String']['output'];
	paymentSystem?: Maybe<Scalars['String']['output']>;
};

export enum CatalogType {
	Brand = 'BRAND',
	Brands = 'BRANDS',
	Category = 'CATEGORY',
	Favorites = 'FAVORITES',
	Home = 'HOME',
	Market = 'MARKET',
	Markets = 'MARKETS',
	MySales = 'MY_SALES',
	Other = 'OTHER',
	Personal = 'PERSONAL',
	ProductCard = 'PRODUCT_CARD',
	Search = 'SEARCH',
	Seller = 'SELLER',
	Sellers = 'SELLERS',
	Seo = 'SEO',
	TelegramBotShopstat = 'TELEGRAM_BOT_SHOPSTAT',
}

export type CategoryBrandRevenue = {
	__typename?: 'CategoryBrandRevenue';
	revenue: Scalars['Float']['output'];
	title: Scalars['String']['output'];
};

export type CategoryBrandSales = {
	__typename?: 'CategoryBrandSales';
	sold: Scalars['Float']['output'];
	title: Scalars['String']['output'];
};

export type CategoryBrandsRevenueResp = {
	__typename?: 'CategoryBrandsRevenueResp';
	revenue: Array<CategoryBrandRevenue>;
	total: Scalars['Float']['output'];
};

export type CategoryBrandsSalesResp = {
	__typename?: 'CategoryBrandsSalesResp';
	sales: Array<CategoryBrandSales>;
	total: Scalars['Float']['output'];
};

export type ChangeFormActiveInput = {
	formId: Scalars['String']['input'];
	isActive: Scalars['Boolean']['input'];
};

export type ChangePasswordInput = {
	newPassword: Scalars['String']['input'];
	newPasswordRe: Scalars['String']['input'];
	password: Scalars['String']['input'];
};

export type CheckStoreStatusInput = {
	storeId: Scalars['String']['input'];
};

export type CheckUserResponse = {
	__typename?: 'CheckUserResponse';
	status: Scalars['Boolean']['output'];
};

export type ConfirmPhoneByCodeResponse = {
	__typename?: 'ConfirmPhoneByCodeResponse';
	token: Scalars['String']['output'];
	user: UserType;
};

export type ConfirmUserEmailType = {
	__typename?: 'ConfirmUserEmailType';
	token: Scalars['String']['output'];
	user: UserType;
};

export type ConnectToTariffInput = {
	autopayment?: InputMaybe<Scalars['Boolean']['input']>;
	failUrl?: InputMaybe<Scalars['String']['input']>;
	paymentType: PaymentTypeEnum;
	promoCode?: InputMaybe<Scalars['String']['input']>;
	source?: InputMaybe<PaymentSource>;
	successUrl?: InputMaybe<Scalars['String']['input']>;
	tariffId: Scalars['String']['input'];
	utmSource?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFormInput = {
	emails?: InputMaybe<Array<Scalars['String']['input']>>;
	haveOzonKey?: InputMaybe<Scalars['Boolean']['input']>;
	haveWbKey?: InputMaybe<Scalars['Boolean']['input']>;
	isPublic?: InputMaybe<Scalars['Boolean']['input']>;
	limit?: InputMaybe<Scalars['Float']['input']>;
	name: Scalars['String']['input'];
	questions: Array<FormQuestionInput>;
	subTitle?: InputMaybe<Scalars['String']['input']>;
	tariffIds?: InputMaybe<Array<Scalars['String']['input']>>;
	title: Scalars['String']['input'];
	userCreatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
	userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateInvoiceInput = {
	email?: InputMaybe<Scalars['String']['input']>;
	inn: Scalars['String']['input'];
	paymentType: PaymentTypeEnum;
	promoCode?: InputMaybe<Scalars['String']['input']>;
	tariffId: Scalars['String']['input'];
	utmSource?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNotificationInput = {
	description?: InputMaybe<Scalars['String']['input']>;
	remaining?: InputMaybe<Scalars['Float']['input']>;
	title?: InputMaybe<Scalars['String']['input']>;
	url?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePollInput = {
	abortPageBody?: InputMaybe<Scalars['String']['input']>;
	abortPageTitle?: InputMaybe<Scalars['String']['input']>;
	completePageBody?: InputMaybe<Scalars['String']['input']>;
	completePageTitle?: InputMaybe<Scalars['String']['input']>;
	name: Scalars['String']['input'];
	questions: Array<QuestionInput>;
	remaining: Scalars['Int']['input'];
	sendStatsToBitrix?: InputMaybe<Scalars['Boolean']['input']>;
	urlForBitrixStats?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePromoCodeInput = {
	code: Scalars['String']['input'];
	description: Scalars['String']['input'];
	discount: Scalars['Int']['input'];
	endDate?: InputMaybe<Scalars['Timestamp']['input']>;
	limitCount?: InputMaybe<Scalars['Float']['input']>;
	paymentType?: InputMaybe<Array<PaymentTypeEnum>>;
	userIds?: InputMaybe<Array<Scalars['String']['input']>>;
	utmSource?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSearchPositionRequestsInput = {
	externalId: Scalars['Float']['input'];
	marketplace: ProductSources;
	searchQueries: Array<Scalars['String']['input']>;
};

export type CreateUtmSourceInput = {
	campaign?: InputMaybe<Scalars['String']['input']>;
	comment?: InputMaybe<Scalars['String']['input']>;
	content?: InputMaybe<Scalars['String']['input']>;
	medium?: InputMaybe<Scalars['String']['input']>;
	price: Scalars['Int']['input'];
	source: Scalars['String']['input'];
	term?: InputMaybe<Scalars['String']['input']>;
};

export type DateRangeInput = {
	fromDate: Scalars['Timestamp']['input'];
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type DefaultRangeInput = {
	from?: InputMaybe<Scalars['Float']['input']>;
	to?: InputMaybe<Scalars['Float']['input']>;
};

export enum DeviceFilter {
	All = 'ALL',
	Browser = 'BROWSER',
	Extension = 'EXTENSION',
	MobileApp = 'MOBILE_APP',
	MobileBrowser = 'MOBILE_BROWSER',
}

export enum DeviceType {
	Android = 'ANDROID',
	Desktop = 'DESKTOP',
	Mobile = 'MOBILE',
}

export type EditFormInput = {
	_id: Scalars['String']['input'];
	emails?: InputMaybe<Array<Scalars['String']['input']>>;
	haveOzonKey?: InputMaybe<Scalars['Boolean']['input']>;
	haveWbKey?: InputMaybe<Scalars['Boolean']['input']>;
	isActive?: InputMaybe<Scalars['Boolean']['input']>;
	isPublic: Scalars['Boolean']['input'];
	limit?: InputMaybe<Scalars['Float']['input']>;
	name: Scalars['String']['input'];
	questions: Array<EditFormQuestionInput>;
	subTitle?: InputMaybe<Scalars['String']['input']>;
	tariffIds?: InputMaybe<Array<Scalars['String']['input']>>;
	title: Scalars['String']['input'];
	userCreatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
	userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EditFormQuestionInput = {
	_id?: InputMaybe<Scalars['String']['input']>;
	haveAnotherVariant?: InputMaybe<Scalars['Boolean']['input']>;
	isRequired?: InputMaybe<Scalars['Boolean']['input']>;
	questionType: QuestionTypeEnum;
	subTitle?: InputMaybe<Scalars['String']['input']>;
	title: Scalars['String']['input'];
	variants?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EditNotificationInput = {
	_id: Scalars['String']['input'];
	description?: InputMaybe<Scalars['String']['input']>;
	remaining?: InputMaybe<Scalars['Float']['input']>;
	title?: InputMaybe<Scalars['String']['input']>;
	url?: InputMaybe<Scalars['String']['input']>;
};

export type EditPollInput = {
	_id: Scalars['String']['input'];
	abortPageBody?: InputMaybe<Scalars['String']['input']>;
	abortPageTitle?: InputMaybe<Scalars['String']['input']>;
	completePageBody?: InputMaybe<Scalars['String']['input']>;
	completePageTitle?: InputMaybe<Scalars['String']['input']>;
	name: Scalars['String']['input'];
	questions: Array<QuestionInput>;
	remaining: Scalars['Int']['input'];
	sendStatsToBitrix?: InputMaybe<Scalars['Boolean']['input']>;
	urlForBitrixStats?: InputMaybe<Scalars['String']['input']>;
};

export type EditPromoCodeInput = {
	code?: InputMaybe<Scalars['String']['input']>;
	codeId: Scalars['String']['input'];
	description?: InputMaybe<Scalars['String']['input']>;
	discount?: InputMaybe<Scalars['Int']['input']>;
	endDate?: InputMaybe<Scalars['Timestamp']['input']>;
	isActive?: InputMaybe<Scalars['Boolean']['input']>;
	isPublic?: InputMaybe<Scalars['Boolean']['input']>;
	limitCount?: InputMaybe<Scalars['Float']['input']>;
	paymentType?: InputMaybe<Array<PaymentTypeEnum>>;
	userIds?: InputMaybe<Array<Scalars['String']['input']>>;
	utmSource?: InputMaybe<Scalars['String']['input']>;
};

export type EditTariffInput = {
	color?: InputMaybe<Scalars['String']['input']>;
	description: Scalars['String']['input'];
	marked?: InputMaybe<Scalars['Boolean']['input']>;
	name: Scalars['String']['input'];
	price: Scalars['Float']['input'];
	price3: Scalars['Float']['input'];
	price6: Scalars['Float']['input'];
	price12: Scalars['Float']['input'];
};

export type ExtStatsResponse = {
	__typename?: 'ExtStatsResponse';
	clickedFromExtNotificationCount: Scalars['Float']['output'];
};

export type Favorite = {
	__typename?: 'Favorite';
	brandId?: Maybe<Scalars['String']['output']>;
	brandName?: Maybe<Scalars['String']['output']>;
	image?: Maybe<Scalars['String']['output']>;
	marketplace: ProductSources;
	monthRevenue: Scalars['Int']['output'];
	monthSales: Scalars['Int']['output'];
	name: Scalars['String']['output'];
	price: Scalars['Float']['output'];
	productId: Scalars['Int']['output'];
	quantity: Scalars['Int']['output'];
	rating: Scalars['Float']['output'];
	reviewCount: Scalars['Int']['output'];
	sellerId?: Maybe<Scalars['String']['output']>;
	sellerName?: Maybe<Scalars['String']['output']>;
	soldMonth: Scalars['Int']['output'];
	soldToday: Scalars['Int']['output'];
	soldWeek: Scalars['Int']['output'];
	soldYesterday: Scalars['Int']['output'];
};

export type FavoriteProductType = {
	__typename?: 'FavoriteProductType';
	marketplace: ProductSources;
	productId: Scalars['Float']['output'];
};

export enum FavoriteSortBy {
	Name = 'NAME',
	Quantity = 'QUANTITY',
	SoldMonth = 'SOLD_MONTH',
	SoldToday = 'SOLD_TODAY',
	SoldWeek = 'SOLD_WEEK',
	SoldYesterday = 'SOLD_YESTERDAY',
}

export type FavoritesGroupType = {
	__typename?: 'FavoritesGroupType';
	_id: Scalars['String']['output'];
	isDefault: Scalars['Boolean']['output'];
	name: Scalars['String']['output'];
	products: Array<FavoriteProductType>;
};

export type FavoritesResp = {
	__typename?: 'FavoritesResp';
	products: Array<Favorite>;
	total: Scalars['Int']['output'];
	totalData?: Maybe<TotalData>;
};

export enum FixedDateRange {
	Month = 'MONTH',
	Week = 'WEEK',
	Year = 'YEAR',
}

export type FormAnswersInput = {
	answers: Array<AnswerInput>;
	formId: Scalars['String']['input'];
};

export type FormListTypeResponse = {
	__typename?: 'FormListTypeResponse';
	items: Array<FormTypeResponse>;
	total: Scalars['Float']['output'];
};

export type FormQuestionInput = {
	haveAnotherVariant?: InputMaybe<Scalars['Boolean']['input']>;
	isRequired?: InputMaybe<Scalars['Boolean']['input']>;
	questionType: QuestionTypeEnum;
	subTitle?: InputMaybe<Scalars['String']['input']>;
	title: Scalars['String']['input'];
	variants?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FormQuestionResponse = {
	__typename?: 'FormQuestionResponse';
	_id: Scalars['String']['output'];
	haveAnotherVariant?: Maybe<Scalars['Boolean']['output']>;
	isRequired?: Maybe<Scalars['Boolean']['output']>;
	questionType: QuestionTypeEnum;
	subTitle?: Maybe<Scalars['String']['output']>;
	title: Scalars['String']['output'];
	variants?: Maybe<Array<Scalars['String']['output']>>;
};

export type FormTypeResponse = {
	__typename?: 'FormTypeResponse';
	_id: Scalars['String']['output'];
	answeredCount?: Maybe<Scalars['Float']['output']>;
	emails?: Maybe<Array<Scalars['String']['output']>>;
	haveOzonKey?: Maybe<Scalars['Boolean']['output']>;
	haveWbKey?: Maybe<Scalars['Boolean']['output']>;
	isActive?: Maybe<Scalars['Boolean']['output']>;
	isPublic?: Maybe<Scalars['Boolean']['output']>;
	limit?: Maybe<Scalars['Float']['output']>;
	name: Scalars['String']['output'];
	questions: Array<FormQuestionResponse>;
	subTitle?: Maybe<Scalars['String']['output']>;
	tariffIds?: Maybe<Array<Scalars['String']['output']>>;
	title: Scalars['String']['output'];
	userCreatedAt?: Maybe<Scalars['Timestamp']['output']>;
	userIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type FreeDaysInput = {
	count: Scalars['Int']['input'];
	userId: Scalars['String']['input'];
};

export type GetBotFeedbackToken = {
	telegramId: Scalars['Int']['input'];
	token: Scalars['String']['input'];
};

export type GetFeedbackAnswerInput = {
	hash?: InputMaybe<Scalars['String']['input']>;
	productName: Scalars['String']['input'];
	rating: Scalars['Float']['input'];
	storeId: Scalars['String']['input'];
	text: Scalars['String']['input'];
	username?: InputMaybe<Scalars['String']['input']>;
};

export type GetProductTagsInput = {
	/** Max 10 items */
	externalIds: Array<Scalars['Float']['input']>;
};

export type GetProductsByTagsInput = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search: Scalars['String']['input'];
};

export type GetQuestionAnswerInput = {
	hash?: InputMaybe<Scalars['String']['input']>;
	productId: Scalars['Float']['input'];
	productName: Scalars['String']['input'];
	storeId: Scalars['String']['input'];
	text: Scalars['String']['input'];
};

export enum GroupByTime {
	Day = 'DAY',
	Hour = 'HOUR',
	Month = 'MONTH',
	Week = 'WEEK',
}

export type InnInfoInput = {
	inn: Scalars['String']['input'];
};

export type InnItemResponse = {
	__typename?: 'InnItemResponse';
	email?: Maybe<Scalars['String']['output']>;
	inn: Scalars['String']['output'];
	name?: Maybe<Scalars['String']['output']>;
};

export type InvoiceResponse = {
	__typename?: 'InvoiceResponse';
	pdfUrl: Scalars['String']['output'];
};

export type LastOrdersInput = {
	dateRange: DateRangeInput;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	needGroup?: InputMaybe<Scalars['Boolean']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sortBy?: InputMaybe<AnalyticsOrdersSort>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type LeadResponse = {
	__typename?: 'LeadResponse';
	email?: Maybe<Scalars['String']['output']>;
	name: Scalars['String']['output'];
	phone?: Maybe<Scalars['String']['output']>;
	title?: Maybe<Scalars['String']['output']>;
	userId?: Maybe<Scalars['String']['output']>;
};

export type LoginByEmailType = {
	__typename?: 'LoginByEmailType';
	token: Scalars['String']['output'];
	user: UserType;
};

export type LoginByLogPassInput = {
	asAdmin?: InputMaybe<Scalars['Boolean']['input']>;
	login: Scalars['String']['input'];
	password: Scalars['String']['input'];
};

export type LoginByYandexInput = {
	token: Scalars['String']['input'];
	utmCampaign?: InputMaybe<Scalars['String']['input']>;
	utmContent?: InputMaybe<Scalars['String']['input']>;
	utmMedium?: InputMaybe<Scalars['String']['input']>;
	utmSource?: InputMaybe<Scalars['String']['input']>;
	utmTerm?: InputMaybe<Scalars['String']['input']>;
};

export type LoginInOtvetoReponse = {
	__typename?: 'LoginInOtvetoReponse';
	token: Scalars['String']['output'];
};

export type LogisticSummary = {
	__typename?: 'LogisticSummary';
	currentFromClient?: Maybe<Scalars['Float']['output']>;
	currentToClient?: Maybe<Scalars['Float']['output']>;
	sumPastAndCurFromClient?: Maybe<Scalars['Float']['output']>;
	sumPastAndCurToClient?: Maybe<Scalars['Float']['output']>;
};

export enum MarketName {
	Ozon = 'OZON',
	Wildberries = 'WILDBERRIES',
}

export type MarketplaceStats = {
	__typename?: 'MarketplaceStats';
	name: Scalars['String']['output'];
	stats: MarketplaceStatsItem;
};

export type MarketplaceStatsChart = {
	__typename?: 'MarketplaceStatsChart';
	groupedByTime: GroupByTime;
	name: Scalars['String']['output'];
	statsChart: Array<MarketplaceStatsChartData>;
};

export type MarketplaceStatsChartData = {
	__typename?: 'MarketplaceStatsChartData';
	date: Scalars['Timestamp']['output'];
	revenue: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type MarketplaceStatsItem = {
	__typename?: 'MarketplaceStatsItem';
	brandCount: Scalars['Float']['output'];
	productCount: Scalars['Float']['output'];
	quantity: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	sellerCount: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export enum MetricAction {
	ClickExtensionMenu = 'CLICK_EXTENSION_MENU',
	ClickPeriodMenu = 'CLICK_PERIOD_MENU',
	GoToPay = 'GO_TO_PAY',
	PaidFailure = 'PAID_FAILURE',
	PaidSuccess = 'PAID_SUCCESS',
	PaymentCreated = 'PAYMENT_CREATED',
}

export type Mutation = {
	__typename?: 'Mutation';
	acceptInviteToTeam: Scalars['Boolean']['output'];
	addAdvertisementPartners: LeadResponse;
	addBannerLead: LeadResponse;
	addBannerMetricsItemPre: BannerMetricResponse;
	addBannerMetricsItemPreV2: BannerMetricResponse;
	addBotFeedbackToken: Scalars['Boolean']['output'];
	addFreeDaysToTariff: Scalars['Boolean']['output'];
	addOzonApiKey: UserType;
	addPartnersLead: LeadResponse;
	addPollAnswer: PollAnswerType;
	addProductToParserJob: Scalars['Boolean']['output'];
	addRedirectMetricsItem: Scalars['Boolean']['output'];
	addTariff: TariffType;
	addTariffToUser: AddTariffToUserResponse;
	addToFavorites: Favorite;
	addWbApiKey: UserType;
	answerToForm: Scalars['Boolean']['output'];
	applyCoupon: Scalars['String']['output'];
	attachPhoneToUser: Scalars['String']['output'];
	autoSubscription: CancelSubscriptionResp;
	cancelSubscription: CancelSubscriptionResp;
	changePhoneByUser: Scalars['String']['output'];
	changeUserPassword: UserType;
	changeUserPhone: UserType;
	clickFromExtNotification: UserType;
	clickFromUserNotification: UserType;
	confirmPhoneByCode: ConfirmPhoneByCodeResponse;
	confirmUserEmailByCode: ConfirmUserEmailType;
	connectToTariff: SubscribeToTariffResp;
	createBanner: BannerResponse;
	createFavoritesGroup: FavoritesGroupType;
	createForm: FormTypeResponse;
	createInvoice: InvoiceResponse;
	createNotification: NotificationType;
	createPoll: AdminPollType;
	createPromoCode: PromoCodeBase;
	createSearchPositionRequests: Array<SearchPositionRequestEntity>;
	createUtmSource: UtmSourceResponse;
	deleteFavoritesGroup: FavoritesGroupType;
	deleteUser: Scalars['Boolean']['output'];
	editForm: FormTypeResponse;
	editNotification: NotificationType;
	editPoll: AdminPollType;
	editPromoCode: PromoCodeBase;
	editTariff: TariffType;
	editTariffPermission: TariffType;
	editTeamCommetn: TeamMateResp;
	editUser: UserType;
	enterFromAds: Scalars['Boolean']['output'];
	getTokenForBot: UserTokenForBotResp;
	inviteToTeam: Scalars['String']['output'];
	leaveFromTeam: Scalars['Boolean']['output'];
	loginByEmail: LoginByEmailType;
	loginByLogPass: LoginByEmailType;
	loginByPhone: Scalars['String']['output'];
	loginByYandex: LoginByEmailType;
	logout: Scalars['String']['output'];
	logoutBotFeedback: UserType;
	partnerRequest: Scalars['Boolean']['output'];
	recoverPassword: BaseUserType;
	registerByYandex: LoginByEmailType;
	registration: UserType;
	removeForm: Scalars['Boolean']['output'];
	removeFromFavorites: Favorite;
	removeFromTeam: Scalars['Boolean']['output'];
	removeNotification: NotificationType;
	removeOzonApiKey: UserType;
	removePoll: AdminPollType;
	removePromoCode: Scalars['Boolean']['output'];
	removeSearchPositionRequest: SearchPositionRequestEntity;
	removeUser: UserType;
	removeWbApiKey: UserType;
	resendEmailVerificationCode: Scalars['String']['output'];
	retryPhoneVerificationCall: Scalars['String']['output'];
	sendPassRecoveryEMail: Scalars['String']['output'];
	sendPhoneVerificationCodeBySms: Scalars['String']['output'];
	sendPhoneVerificationCodeBySmsService: Scalars['String']['output'];
	setSubscribed: Scalars['Boolean']['output'];
	setUserPassword: UserType;
	updateOzonApiKey: UserType;
	updateUtmSource: UtmSourceResponse;
	updateWbApiKey: UserType;
	wbAnalyticsCostPriceUpload: WbAnalyticsUploadResponse;
	wbAnalyticsSetCostPrice: WbAnalyticsFileParseResp;
};

export type MutationAcceptInviteToTeamArgs = {
	isAccept: Scalars['Boolean']['input'];
	token: Scalars['String']['input'];
};

export type MutationAddAdvertisementPartnersArgs = {
	input: AdvertisementPartnershipLeadInput;
};

export type MutationAddBannerMetricsItemPreArgs = {
	actionType: ActionType;
	bannerId: Scalars['Int']['input'];
	catalog: CatalogType;
	device: DeviceType;
	marketName?: InputMaybe<MarketName>;
	position: PositionType;
	url: Scalars['String']['input'];
};

export type MutationAddBannerMetricsItemPreV2Args = {
	actionType: ActionType;
	bannerId: Scalars['String']['input'];
	catalog: CatalogType;
	device: DeviceType;
	marketName?: InputMaybe<MarketName>;
	position: PositionType;
	url: Scalars['String']['input'];
};

export type MutationAddBotFeedbackTokenArgs = {
	input: GetBotFeedbackToken;
};

export type MutationAddFreeDaysToTariffArgs = {
	input: FreeDaysInput;
};

export type MutationAddOzonApiKeyArgs = {
	apiKey: Scalars['String']['input'];
	clientId: Scalars['Float']['input'];
	name: Scalars['String']['input'];
};

export type MutationAddPartnersLeadArgs = {
	input: PartnershipLeadInput;
};

export type MutationAddPollAnswerArgs = {
	answer: PollAnswerInput;
};

export type MutationAddProductToParserJobArgs = {
	input: AddProductInput;
};

export type MutationAddRedirectMetricsItemArgs = {
	ip?: InputMaybe<Scalars['String']['input']>;
	url?: InputMaybe<Scalars['String']['input']>;
	utmSource: Scalars['String']['input'];
};

export type MutationAddTariffArgs = {
	newTariffInput: AddTariffInput;
};

export type MutationAddTariffToUserArgs = {
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	paymentType?: InputMaybe<PaymentTypeEnum>;
	tariffId: Scalars['String']['input'];
	totalPaid?: InputMaybe<Scalars['Float']['input']>;
	userId: Scalars['String']['input'];
	withPayment?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationAddToFavoritesArgs = {
	favoritesGroupId: Scalars['ID']['input'];
	marketplace: ProductSources;
	productId: Scalars['Int']['input'];
};

export type MutationAddWbApiKeyArgs = {
	key: Scalars['String']['input'];
	name: Scalars['String']['input'];
};

export type MutationAnswerToFormArgs = {
	input: FormAnswersInput;
};

export type MutationApplyCouponArgs = {
	input: ApplyCodeInput;
};

export type MutationAttachPhoneToUserArgs = {
	name: Scalars['String']['input'];
	phone: Scalars['String']['input'];
};

export type MutationChangePhoneByUserArgs = {
	phone: Scalars['String']['input'];
};

export type MutationChangeUserPasswordArgs = {
	input: ChangePasswordInput;
};

export type MutationChangeUserPhoneArgs = {
	email: Scalars['String']['input'];
	newPhone: Scalars['String']['input'];
	phone: Scalars['String']['input'];
};

export type MutationClickFromUserNotificationArgs = {
	name: Scalars['String']['input'];
};

export type MutationConfirmPhoneByCodeArgs = {
	code: Scalars['String']['input'];
	phone: Scalars['String']['input'];
};

export type MutationConfirmUserEmailByCodeArgs = {
	verificationCode: Scalars['String']['input'];
};

export type MutationConnectToTariffArgs = {
	input: ConnectToTariffInput;
};

export type MutationCreateBannerArgs = {
	desktopFile: Scalars['Upload']['input'];
	input: BannerInputType;
	mobileFile: Scalars['Upload']['input'];
};

export type MutationCreateFavoritesGroupArgs = {
	groupName: Scalars['String']['input'];
};

export type MutationCreateFormArgs = {
	input: CreateFormInput;
};

export type MutationCreateInvoiceArgs = {
	input: CreateInvoiceInput;
};

export type MutationCreateNotificationArgs = {
	notification: CreateNotificationInput;
};

export type MutationCreatePollArgs = {
	poll: CreatePollInput;
};

export type MutationCreatePromoCodeArgs = {
	input: CreatePromoCodeInput;
};

export type MutationCreateSearchPositionRequestsArgs = {
	input: CreateSearchPositionRequestsInput;
};

export type MutationCreateUtmSourceArgs = {
	input: CreateUtmSourceInput;
};

export type MutationDeleteFavoritesGroupArgs = {
	id: Scalars['ID']['input'];
};

export type MutationEditFormArgs = {
	input: EditFormInput;
};

export type MutationEditNotificationArgs = {
	notification: EditNotificationInput;
};

export type MutationEditPollArgs = {
	poll: EditPollInput;
};

export type MutationEditPromoCodeArgs = {
	input: EditPromoCodeInput;
};

export type MutationEditTariffArgs = {
	editTariffInput: EditTariffInput;
	tariffId: Scalars['String']['input'];
};

export type MutationEditTariffPermissionArgs = {
	analyticsAccounts?: InputMaybe<Scalars['Int']['input']>;
	analyticsSOHistoryDays?: InputMaybe<Scalars['Int']['input']>;
	analyticsSalesHistoryDays?: InputMaybe<Scalars['Int']['input']>;
	exportProducts?: InputMaybe<Scalars['Int']['input']>;
	favoritesGroupsLimit?: InputMaybe<Scalars['Int']['input']>;
	favoritesLimit?: InputMaybe<Scalars['Int']['input']>;
	favoritesSummary?: InputMaybe<Scalars['Boolean']['input']>;
	hideAnalyticsBlock?: InputMaybe<Scalars['Boolean']['input']>;
	hideBrands?: InputMaybe<Scalars['Boolean']['input']>;
	hideExtProductHistory?: InputMaybe<Scalars['Boolean']['input']>;
	hideOzon?: InputMaybe<Scalars['Boolean']['input']>;
	hideProductsSummary?: InputMaybe<Scalars['Boolean']['input']>;
	hideSellers?: InputMaybe<Scalars['Boolean']['input']>;
	hideSeo?: InputMaybe<Scalars['Boolean']['input']>;
	hideTopCategories?: InputMaybe<Scalars['Boolean']['input']>;
	hideTopProducts?: InputMaybe<Scalars['Boolean']['input']>;
	historyDays?: InputMaybe<Scalars['Int']['input']>;
	productsGroupLimit?: InputMaybe<Scalars['Int']['input']>;
	requestsLimit?: InputMaybe<Scalars['Int']['input']>;
	sbAllProducts?: InputMaybe<Scalars['Boolean']['input']>;
	sbNewItems?: InputMaybe<Scalars['Boolean']['input']>;
	sbPresence?: InputMaybe<Scalars['Boolean']['input']>;
	sbSummary?: InputMaybe<Scalars['Boolean']['input']>;
	sbTopProducts?: InputMaybe<Scalars['Boolean']['input']>;
	searchQueriesLimit?: InputMaybe<Scalars['Int']['input']>;
	tariffId: Scalars['String']['input'];
	usersCount?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationEditTeamCommetnArgs = {
	comment: Scalars['String']['input'];
	userId: Scalars['String']['input'];
};

export type MutationEditUserArgs = {
	email: Scalars['String']['input'];
	id: Scalars['ID']['input'];
	isActive: Scalars['Boolean']['input'];
	isEmailVerified: Scalars['Boolean']['input'];
	isPhoneVerified: Scalars['Boolean']['input'];
	name: Scalars['String']['input'];
	phone: Scalars['String']['input'];
};

export type MutationEnterFromAdsArgs = {
	utmSource: Scalars['String']['input'];
};

export type MutationInviteToTeamArgs = {
	comment?: InputMaybe<Scalars['String']['input']>;
	email: Scalars['String']['input'];
};

export type MutationLoginByEmailArgs = {
	asAdmin?: InputMaybe<Scalars['Boolean']['input']>;
	email: Scalars['String']['input'];
	password: Scalars['String']['input'];
};

export type MutationLoginByLogPassArgs = {
	input: LoginByLogPassInput;
};

export type MutationLoginByPhoneArgs = {
	asAdmin?: InputMaybe<Scalars['Boolean']['input']>;
	phone: Scalars['String']['input'];
	smsService?: InputMaybe<Scalars['String']['input']>;
};

export type MutationLoginByYandexArgs = {
	input: LoginByYandexInput;
};

export type MutationPartnerRequestArgs = {
	input: UserPartnerRequestInput;
};

export type MutationRecoverPasswordArgs = {
	newPassword: Scalars['String']['input'];
	recoveryCode: Scalars['String']['input'];
};

export type MutationRegisterByYandexArgs = {
	input: LoginByYandexInput;
};

export type MutationRegistrationArgs = {
	email: Scalars['String']['input'];
	name: Scalars['String']['input'];
	password?: InputMaybe<Scalars['String']['input']>;
	passwordRe?: InputMaybe<Scalars['String']['input']>;
	phone: Scalars['String']['input'];
	utmCampaign?: InputMaybe<Scalars['String']['input']>;
	utmContent?: InputMaybe<Scalars['String']['input']>;
	utmMedium?: InputMaybe<Scalars['String']['input']>;
	utmSource?: InputMaybe<Scalars['String']['input']>;
	utmTerm?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRemoveFormArgs = {
	formId: Scalars['String']['input'];
};

export type MutationRemoveFromFavoritesArgs = {
	favoritesGroupId: Scalars['ID']['input'];
	marketplace: ProductSources;
	productId: Scalars['Int']['input'];
};

export type MutationRemoveFromTeamArgs = {
	userId: Scalars['String']['input'];
};

export type MutationRemoveNotificationArgs = {
	notificationId: Scalars['String']['input'];
};

export type MutationRemoveOzonApiKeyArgs = {
	keyId: Scalars['String']['input'];
};

export type MutationRemovePollArgs = {
	pollId: Scalars['String']['input'];
};

export type MutationRemovePromoCodeArgs = {
	code: Scalars['String']['input'];
};

export type MutationRemoveSearchPositionRequestArgs = {
	id: Scalars['String']['input'];
};

export type MutationRemoveUserArgs = {
	userId: Scalars['ID']['input'];
};

export type MutationRemoveWbApiKeyArgs = {
	keyId: Scalars['String']['input'];
};

export type MutationResendEmailVerificationCodeArgs = {
	email: Scalars['String']['input'];
};

export type MutationRetryPhoneVerificationCallArgs = {
	phone: Scalars['String']['input'];
};

export type MutationSendPassRecoveryEMailArgs = {
	email: Scalars['String']['input'];
};

export type MutationSendPhoneVerificationCodeBySmsArgs = {
	phone: Scalars['String']['input'];
	smsService?: InputMaybe<SmsServices>;
};

export type MutationSendPhoneVerificationCodeBySmsServiceArgs = {
	phone: Scalars['String']['input'];
	smsService?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSetSubscribedArgs = {
	email: Scalars['String']['input'];
};

export type MutationSetUserPasswordArgs = {
	input: SetPasswordInput;
};

export type MutationUpdateOzonApiKeyArgs = {
	apiKey: Scalars['String']['input'];
	clientId: Scalars['Float']['input'];
	keyId: Scalars['String']['input'];
};

export type MutationUpdateUtmSourceArgs = {
	input: UpdateUtmSourceInput;
};

export type MutationUpdateWbApiKeyArgs = {
	key: Scalars['String']['input'];
	keyId: Scalars['String']['input'];
};

export type MutationWbAnalyticsCostPriceUploadArgs = {
	file: Scalars['Upload']['input'];
};

export type MutationWbAnalyticsSetCostPriceArgs = {
	costPriceCellNumber: Scalars['Int']['input'];
	fileName: Scalars['String']['input'];
	keyId: Scalars['String']['input'];
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	prdIdCellNumber: Scalars['Int']['input'];
};

export type MySearchPositionRequestsInput = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
};

export type MySearchPositionRequestsResponse = {
	__typename?: 'MySearchPositionRequestsResponse';
	items: Array<SearchPositionRequestEntity>;
	total: Scalars['Float']['output'];
};

export type NotificationType = {
	__typename?: 'NotificationType';
	_id: Scalars['String']['output'];
	description?: Maybe<Scalars['String']['output']>;
	remaining?: Maybe<Scalars['Float']['output']>;
	title?: Maybe<Scalars['String']['output']>;
	url?: Maybe<Scalars['String']['output']>;
	view?: Maybe<Scalars['Float']['output']>;
};

export type NotificationsListType = {
	__typename?: 'NotificationsListType';
	items: Array<NotificationType>;
	total: Scalars['Int']['output'];
};

export type OzonApiKeys = {
	__typename?: 'OZONApiKeys';
	createdAt: Scalars['Timestamp']['output'];
	isActive?: Maybe<Scalars['Boolean']['output']>;
	isDisabled?: Maybe<Scalars['Boolean']['output']>;
	isHistoryLoaded?: Maybe<Scalars['Boolean']['output']>;
	keyId: Scalars['String']['output'];
	name: Scalars['String']['output'];
};

export type OptionalDateRangeInput = {
	fromDate: Scalars['Timestamp']['input'];
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type OrderSummaryInput = {
	dateRange: DateRangeInput;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
};

export type OzonAnalyticsBrandsResponse = {
	__typename?: 'OzonAnalyticsBrandsResponse';
	name: Scalars['String']['output'];
};

export type OzonAnalyticsLastOrdersList = {
	__typename?: 'OzonAnalyticsLastOrdersList';
	brand?: Maybe<Scalars['String']['output']>;
	category?: Maybe<Scalars['String']['output']>;
	date: Scalars['Timestamp']['output'];
	discountPercent: Scalars['Float']['output'];
	images?: Maybe<Array<AnalyticItemImage>>;
	nmId: Scalars['Float']['output'];
	quantity: Scalars['Float']['output'];
	salesAmount?: Maybe<Scalars['Float']['output']>;
	subject: Scalars['String']['output'];
	totalPrice: Scalars['Float']['output'];
};

export type OzonAnalyticsOrderPlanItem = {
	__typename?: 'OzonAnalyticsOrderPlanItem';
	brand: Scalars['String']['output'];
	category: Scalars['String']['output'];
	nmId: Scalars['Float']['output'];
	quantity: Scalars['Float']['output'];
	restDays?: Maybe<Scalars['Float']['output']>;
	salesPerDay: Scalars['Float']['output'];
	subject: Scalars['String']['output'];
	toOrder?: Maybe<Scalars['Float']['output']>;
	warehouseName?: Maybe<Scalars['String']['output']>;
};

export type OzonAnalyticsOrderPlanResponse = {
	__typename?: 'OzonAnalyticsOrderPlanResponse';
	items: Array<OzonAnalyticsOrderPlanItem>;
	total: Scalars['Int']['output'];
};

export type OzonAnalyticsOrdersStatsResponse = {
	__typename?: 'OzonAnalyticsOrdersStatsResponse';
	items: Array<AnalyticsOrdersStatsResponse>;
	total: Scalars['Int']['output'];
	totalSales: Scalars['Int']['output'];
	totalSalesAmount: Scalars['Int']['output'];
};

export type OzonAnalyticsProductsResp = {
	__typename?: 'OzonAnalyticsProductsResp';
	externalId: Scalars['Float']['output'];
	name: Scalars['String']['output'];
	subject?: Maybe<Scalars['String']['output']>;
};

export type OzonAnalyticsReportStatsResponse = {
	__typename?: 'OzonAnalyticsReportStatsResponse';
	brand?: Maybe<Scalars['String']['output']>;
	deliveryAmount: Scalars['Float']['output'];
	deliveryRub: Scalars['Float']['output'];
	nmId: Scalars['Float']['output'];
	returnAmount: Scalars['Float']['output'];
	subject: Scalars['String']['output'];
	warehouseName: Scalars['String']['output'];
};

export type OzonAnalyticsSalesByRegionResponse = {
	__typename?: 'OzonAnalyticsSalesByRegionResponse';
	city: Scalars['String']['output'];
	regionName: Scalars['String']['output'];
	sales: Scalars['Float']['output'];
	salesAmount: Scalars['Float']['output'];
};

export type OzonAnalyticsSalesStats = {
	__typename?: 'OzonAnalyticsSalesStats';
	discountPercent: Scalars['Float']['output'];
	discountSum: Scalars['Float']['output'];
	finishPrice: Scalars['Float']['output'];
	forPay: Scalars['Float']['output'];
	nmId: Scalars['Float']['output'];
	retailPrice: Scalars['Float']['output'];
	salesAmount: Scalars['Float']['output'];
	salesSum: Scalars['Float']['output'];
	subject: Scalars['String']['output'];
};

export type OzonAnalyticsStock = {
	__typename?: 'OzonAnalyticsStock';
	barcode: Scalars['String']['output'];
	betweenWarehouses: Scalars['Float']['output'];
	category: Scalars['String']['output'];
	createdAt: Scalars['Timestamp']['output'];
	date: Scalars['Timestamp']['output'];
	discounted: Scalars['String']['output'];
	height: Scalars['Float']['output'];
	inWayFromClient: Scalars['Float']['output'];
	inWayToClient: Scalars['Float']['output'];
	keyId: Scalars['String']['output'];
	length: Scalars['Float']['output'];
	loss: Scalars['Float']['output'];
	name: Scalars['String']['output'];
	nmId: Scalars['Float']['output'];
	notForSale: Scalars['Float']['output'];
	offerId: Scalars['String']['output'];
	quantity: Scalars['Float']['output'];
	volume: Scalars['Float']['output'];
	warehouseId: Scalars['String']['output'];
	warehouseName: Scalars['String']['output'];
	weight: Scalars['Float']['output'];
	width: Scalars['Float']['output'];
};

export type OzonBrandCategoryType = {
	__typename?: 'OzonBrandCategoryType';
	categoryId: Scalars['Int']['output'];
	categoryName: Scalars['String']['output'];
	productCount: Scalars['Int']['output'];
	subcategoryId: Scalars['Int']['output'];
	subcategoryName: Scalars['String']['output'];
};

export type OzonBrandType = {
	__typename?: 'OzonBrandType';
	avgPrice?: Maybe<Scalars['Float']['output']>;
	brandId?: Maybe<Scalars['Int']['output']>;
	categories: Array<OzonBrandCategoryType>;
	inMarketplaceSince?: Maybe<Scalars['Timestamp']['output']>;
	maxPrice?: Maybe<Scalars['Float']['output']>;
	minPrice?: Maybe<Scalars['Float']['output']>;
	monthlyRevenue?: Maybe<Scalars['Float']['output']>;
	monthlySales?: Maybe<Scalars['Float']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	productCount?: Maybe<Scalars['Int']['output']>;
	quantity?: Maybe<Scalars['Int']['output']>;
	sellerCount?: Maybe<Scalars['Int']['output']>;
	stockCost?: Maybe<Scalars['Float']['output']>;
	url: Scalars['String']['output'];
};

export type OzonBrandsResponce = {
	__typename?: 'OzonBrandsResponce';
	items: Array<OzonBrandType>;
	total: Scalars['Int']['output'];
};

export type OzonCategoryBrand = {
	__typename?: 'OzonCategoryBrand';
	brandId: Scalars['Float']['output'];
	productCount: Scalars['Float']['output'];
	rating: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	reviewCount: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
	title: Scalars['String']['output'];
};

export type OzonCategoryDailyStats = {
	__typename?: 'OzonCategoryDailyStats';
	avgPrice: Scalars['Float']['output'];
	avgSoldPrice: Scalars['Float']['output'];
	date: Scalars['Timestamp']['output'];
	productCount: Scalars['Float']['output'];
	rating?: Maybe<Scalars['Float']['output']>;
	revenue: Scalars['Float']['output'];
	reviewCount: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type OzonCategoryPosition = {
	__typename?: 'OzonCategoryPosition';
	date: Scalars['Timestamp']['output'];
	position: Scalars['Int']['output'];
};

export type OzonCategoryStats = {
	__typename?: 'OzonCategoryStats';
	brandCount: Scalars['Float']['output'];
	monthRevenue: Scalars['Float']['output'];
	monthSold: Scalars['Float']['output'];
	newProductCount: Scalars['Float']['output'];
	sellerCount: Scalars['Float']['output'];
};

export type OzonCategoryStatsChart = {
	__typename?: 'OzonCategoryStatsChart';
	date: Scalars['Timestamp']['output'];
	revenue?: Maybe<Scalars['Float']['output']>;
};

export type OzonCategoryStatsChartResp = {
	__typename?: 'OzonCategoryStatsChartResp';
	currentChart: Array<OzonCategoryStatsChart>;
	prevChart: Array<OzonCategoryStatsChart>;
};

export type OzonCategoryType = {
	__typename?: 'OzonCategoryType';
	categoryId: Scalars['Int']['output'];
	fullName: Scalars['String']['output'];
	imageUrl?: Maybe<Scalars['String']['output']>;
	isDeleted: Scalars['Boolean']['output'];
	name: Scalars['String']['output'];
	parentCategory?: Maybe<OzonCategoryType>;
	productsCount: Scalars['Int']['output'];
	stats: OzonCategoryStats;
	subCategories: Array<OzonCategoryType>;
};

export type OzonCategoryTypeStatsArgs = {
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OzonExtProductType = {
	__typename?: 'OzonExtProductType';
	_id: Scalars['String']['output'];
	brand?: Maybe<OzonProductBrandType>;
	categoryId: Scalars['Float']['output'];
	color?: Maybe<Scalars['String']['output']>;
	description?: Maybe<Scalars['String']['output']>;
	discount: Scalars['Float']['output'];
	externalId: Scalars['Float']['output'];
	groupId: Scalars['String']['output'];
	images: Array<AnalyticItemImage>;
	inShopSince?: Maybe<Scalars['Timestamp']['output']>;
	isAdult: Scalars['Boolean']['output'];
	isAvailableOnOzon: Scalars['Boolean']['output'];
	isCrossDock: Scalars['Boolean']['output'];
	isCrossborder: Scalars['Boolean']['output'];
	isDeliveryFromSeller?: Maybe<Scalars['Boolean']['output']>;
	isDigital: Scalars['Boolean']['output'];
	isFresh: Scalars['Boolean']['output'];
	isUsed: Scalars['Boolean']['output'];
	isWithVideo: Scalars['Boolean']['output'];
	lastDeliveryChanged?: Maybe<Scalars['Timestamp']['output']>;
	monthRevenue: Scalars['Float']['output'];
	monthSales: Scalars['Float']['output'];
	name?: Maybe<Scalars['String']['output']>;
	options?: Maybe<Array<OzonOptionType>>;
	price: Scalars['Float']['output'];
	quantity: Scalars['Int']['output'];
	rating: Scalars['Float']['output'];
	reviewCount: Scalars['Int']['output'];
	seller?: Maybe<OzonSeller>;
	sellerId?: Maybe<Scalars['String']['output']>;
	size?: Maybe<Scalars['String']['output']>;
	summary?: Maybe<OzonProductSummary>;
	updatedAt: Scalars['Timestamp']['output'];
	warehouseId?: Maybe<Scalars['Float']['output']>;
};

export type OzonHistoryType = {
	__typename?: 'OzonHistoryType';
	date: Scalars['Timestamp']['output'];
	discount: Scalars['Float']['output'];
	price: Scalars['Float']['output'];
	productId: Scalars['Int']['output'];
	quantity: Scalars['Int']['output'];
	rating: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	reviewCount: Scalars['Int']['output'];
	sold: Scalars['Float']['output'];
};

export type OzonLastOrdersResponse = {
	__typename?: 'OzonLastOrdersResponse';
	items: Array<OzonAnalyticsLastOrdersList>;
	total: Scalars['Float']['output'];
	totalSales: Scalars['Int']['output'];
	totalSalesAmount: Scalars['Int']['output'];
};

export type OzonOptionType = {
	__typename?: 'OzonOptionType';
	name: Scalars['String']['output'];
	value: Scalars['String']['output'];
};

export type OzonProductBrandType = {
	__typename?: 'OzonProductBrandType';
	id?: Maybe<Scalars['Int']['output']>;
	name?: Maybe<Scalars['String']['output']>;
};

export type OzonProductDailyHistoryResponseType = {
	__typename?: 'OzonProductDailyHistoryResponseType';
	data: Array<OzonProductDailyHistoryType>;
	groupedByTime: GroupByTime;
};

export type OzonProductDailyHistoryType = {
	__typename?: 'OzonProductDailyHistoryType';
	date: Scalars['Timestamp']['output'];
	discount: Scalars['Float']['output'];
	price: Scalars['Float']['output'];
	quantity: Scalars['Int']['output'];
	rating: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	reviewCount: Scalars['Int']['output'];
	sold: Scalars['Float']['output'];
};

export type OzonProductHistory = {
	__typename?: 'OzonProductHistory';
	date: Scalars['Timestamp']['output'];
	price: Scalars['Int']['output'];
	quantity: Scalars['Int']['output'];
	revenue: Scalars['Float']['output'];
	sold: Scalars['Int']['output'];
};

export type OzonProductHistoryDailyChart = {
	__typename?: 'OzonProductHistoryDailyChart';
	hour: Scalars['Int']['output'];
	revenue: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type OzonProductPositionHistory = {
	__typename?: 'OzonProductPositionHistory';
	category?: Maybe<OzonCategoryType>;
	positions: Array<OzonCategoryPosition>;
};

export enum OzonProductSortByEnum {
	Brand = 'BRAND',
	ExternalId = 'EXTERNAL_ID',
	InShopSince = 'IN_SHOP_SINCE',
	Name = 'NAME',
	Price = 'PRICE',
	Quantity = 'QUANTITY',
	Rating = 'RATING',
	Revenue = 'REVENUE',
	Review = 'REVIEW',
	Seller = 'SELLER',
	Sold = 'SOLD',
}

export type OzonProductSummary = {
	__typename?: 'OzonProductSummary';
	averageSales: Scalars['Float']['output'];
	daysInStock: Scalars['Float']['output'];
	monthRevenue: Scalars['Float']['output'];
	monthSold: Scalars['Float']['output'];
	newReviewCount: Scalars['Float']['output'];
	todayRevenue: Scalars['Float']['output'];
	todaySold: Scalars['Float']['output'];
	weekRevenue: Scalars['Float']['output'];
	weekSold: Scalars['Float']['output'];
	yesterdayRevenue: Scalars['Float']['output'];
	yesterdaySold: Scalars['Float']['output'];
};

export type OzonProductType = {
	__typename?: 'OzonProductType';
	_id: Scalars['String']['output'];
	brand?: Maybe<OzonProductBrandType>;
	categoryId: Scalars['Float']['output'];
	color?: Maybe<Scalars['String']['output']>;
	description?: Maybe<Scalars['String']['output']>;
	discount: Scalars['Float']['output'];
	externalId: Scalars['Float']['output'];
	groupId: Scalars['String']['output'];
	history: Array<OzonHistoryType>;
	images: Array<AnalyticItemImage>;
	inShopSince?: Maybe<Scalars['Timestamp']['output']>;
	isAdult: Scalars['Boolean']['output'];
	isAvailableOnOzon: Scalars['Boolean']['output'];
	isCrossDock: Scalars['Boolean']['output'];
	isCrossborder: Scalars['Boolean']['output'];
	isDeliveryFromSeller?: Maybe<Scalars['Boolean']['output']>;
	isDigital: Scalars['Boolean']['output'];
	isFresh: Scalars['Boolean']['output'];
	isUsed: Scalars['Boolean']['output'];
	isWithVideo: Scalars['Boolean']['output'];
	lastDeliveryChanged?: Maybe<Scalars['Timestamp']['output']>;
	monthRevenue: Scalars['Float']['output'];
	monthSales: Scalars['Float']['output'];
	name?: Maybe<Scalars['String']['output']>;
	options?: Maybe<Array<OzonOptionType>>;
	price: Scalars['Float']['output'];
	quantity: Scalars['Int']['output'];
	rating: Scalars['Float']['output'];
	reviewCount: Scalars['Int']['output'];
	searchQueries?: Maybe<Array<SearchPositionRequestEntity>>;
	seller?: Maybe<OzonSeller>;
	sellerId?: Maybe<Scalars['String']['output']>;
	size?: Maybe<Scalars['String']['output']>;
	summary: OzonProductSummary;
	updatedAt: Scalars['Timestamp']['output'];
	warehouseId?: Maybe<Scalars['Float']['output']>;
	warehouseQuantity?: Maybe<Array<OzonQuantityPerWarehouseType>>;
};

export type OzonProductTypeHistoryArgs = {
	date: DateRangeInput;
};

export type OzonProductTypeSummaryArgs = {
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OzonProductVariantType = {
	__typename?: 'OzonProductVariantType';
	color?: Maybe<Scalars['String']['output']>;
	monthRevenue: Scalars['Float']['output'];
	monthSold: Scalars['Float']['output'];
	name: Scalars['String']['output'];
	price: Scalars['Float']['output'];
	size: Scalars['String']['output'];
};

export type OzonProductsType = {
	__typename?: 'OzonProductsType';
	products: Array<OzonProductType>;
	total: Scalars['Float']['output'];
};

export type OzonQuantityPerWarehouseType = {
	__typename?: 'OzonQuantityPerWarehouseType';
	name: Scalars['String']['output'];
	quantity: Scalars['Float']['output'];
};

export type OzonSectionsStatsType = {
	__typename?: 'OzonSectionsStatsType';
	brandCount: Scalars['Int']['output'];
	categoryCount: Scalars['Int']['output'];
	promotionCount: Scalars['Int']['output'];
	sellerCount: Scalars['Int']['output'];
};

export type OzonSeller = {
	__typename?: 'OzonSeller';
	id?: Maybe<Scalars['Float']['output']>;
	name?: Maybe<Scalars['String']['output']>;
};

export type OzonSellerCategoryType = {
	__typename?: 'OzonSellerCategoryType';
	categoryId: Scalars['Int']['output'];
	categoryName: Scalars['String']['output'];
	productCount: Scalars['Int']['output'];
	subcategoryId: Scalars['Int']['output'];
	subcategoryName: Scalars['String']['output'];
};

export type OzonSellerType = {
	__typename?: 'OzonSellerType';
	_id: Scalars['String']['output'];
	avgPrice?: Maybe<Scalars['Float']['output']>;
	brandCount?: Maybe<Scalars['Int']['output']>;
	categories: Array<OzonSellerCategoryType>;
	externalId?: Maybe<Scalars['Int']['output']>;
	inMarketplaceSince?: Maybe<Scalars['Timestamp']['output']>;
	maxPrice?: Maybe<Scalars['Float']['output']>;
	minPrice?: Maybe<Scalars['Float']['output']>;
	monthlyRevenue?: Maybe<Scalars['Float']['output']>;
	monthlySales?: Maybe<Scalars['Float']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	productCount?: Maybe<Scalars['Int']['output']>;
	quantity?: Maybe<Scalars['Float']['output']>;
	stockCost?: Maybe<Scalars['Float']['output']>;
};

export type OzonSellersResponce = {
	__typename?: 'OzonSellersResponce';
	items: Array<OzonSellerType>;
	total: Scalars['Int']['output'];
};

export type OzonStats = {
	__typename?: 'OzonStats';
	brandsCount: Scalars['Int']['output'];
	productsCount: Scalars['Int']['output'];
	quantity: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	sellersCount: Scalars['Int']['output'];
	sold: Scalars['Float']['output'];
};

export type OzonStatsChart = {
	__typename?: 'OzonStatsChart';
	date: Scalars['Timestamp']['output'];
	revenue: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type OzonStatsChartResponse = {
	__typename?: 'OzonStatsChartResponse';
	data: Array<OzonStatsChart>;
	groupedByTime: GroupByTime;
};

export type OzonSubcategoryRevenue = {
	__typename?: 'OzonSubcategoryRevenue';
	revenue: Scalars['Float']['output'];
	title: Scalars['String']['output'];
};

export type OzonSubcategorySales = {
	__typename?: 'OzonSubcategorySales';
	sold: Scalars['Float']['output'];
	title: Scalars['String']['output'];
};

export type OzonSubcategoryStats = {
	__typename?: 'OzonSubcategoryStats';
	categoryId: Scalars['Float']['output'];
	productCount: Scalars['Float']['output'];
	rating: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	reviewCount: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
	title: Scalars['String']['output'];
};

export type OzonTopCategory = {
	__typename?: 'OzonTopCategory';
	category: OzonCategoryType;
	productCount: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type OzonTopProduct = {
	__typename?: 'OzonTopProduct';
	product: OzonProductType;
	revenue: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type OzonTrend = {
	__typename?: 'OzonTrend';
	brandCount: Scalars['Float']['output'];
	productCount: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	revenuePerProduct: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
	week: Week;
};

export type OzonTrendChart = {
	__typename?: 'OzonTrendChart';
	brandCount: Scalars['Float']['output'];
	productCount: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	revenuePerProduct: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
	sundayDate: Scalars['Timestamp']['output'];
};

export type PagesInput = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
};

export type PaidTariffType = {
	__typename?: 'PaidTariffType';
	_id: Scalars['String']['output'];
	autoRenew: Scalars['Boolean']['output'];
	datePaid: Scalars['Timestamp']['output'];
	fromDate: Scalars['Timestamp']['output'];
	tariffId: Scalars['String']['output'];
	toDate?: Maybe<Scalars['Timestamp']['output']>;
	totalPaid: Scalars['Float']['output'];
	userId: Scalars['String']['output'];
};

export type PartnershipLeadInput = {
	title: Scalars['String']['input'];
};

export enum PayStatus {
	Paid = 'PAID',
	Wait = 'WAIT',
}

export type PaymentData = {
	__typename?: 'PaymentData';
	cardAuthInfo?: Maybe<CardAuthInfo>;
};

export enum PaymentSource {
	Cloud = 'CLOUD',
	Invoice = 'INVOICE',
	Sberbank = 'SBERBANK',
	Tinkoff = 'TINKOFF',
}

export type PaymentStatusResponse = {
	__typename?: 'PaymentStatusResponse';
	description?: Maybe<Scalars['String']['output']>;
	status?: Maybe<Scalars['Int']['output']>;
};

export type PaymentType = {
	__typename?: 'PaymentType';
	amount: Scalars['Float']['output'];
	autopayment: Scalars['Boolean']['output'];
	orderId?: Maybe<Scalars['String']['output']>;
	payDate: Scalars['Timestamp']['output'];
	paymentData?: Maybe<PaymentData>;
	paymentType: PaymentTypeEnum;
	status: ServerPaymentStatus;
	tariff: TariffType;
};

export enum PaymentTypeEnum {
	Halfyear = 'HALFYEAR',
	One = 'ONE',
	Quarter = 'QUARTER',
	Year = 'YEAR',
}

export type PaymentsStatsResp = {
	__typename?: 'PaymentsStatsResp';
	date: Scalars['Timestamp']['output'];
	priceAmount: Scalars['Float']['output'];
	priceHalfYearAmount: Scalars['Float']['output'];
	priceHalfYearSum: Scalars['Float']['output'];
	priceQuartersAmount: Scalars['Float']['output'];
	priceQuartersSum: Scalars['Float']['output'];
	priceSum: Scalars['Float']['output'];
	priceYearAmount: Scalars['Float']['output'];
	priceYearSum: Scalars['Float']['output'];
};

export type PollAnswerInput = {
	answer: Answer;
	pollId: Scalars['String']['input'];
	questionId: Scalars['String']['input'];
};

export type PollAnswerType = {
	__typename?: 'PollAnswerType';
	_id: Scalars['String']['output'];
	answer: Answer;
	pollId: Scalars['String']['output'];
	questionId: Scalars['String']['output'];
	userId: Scalars['String']['output'];
};

export type PollStatsType = {
	__typename?: 'PollStatsType';
	questionStats: QuestionStatsType;
	respondentCount: Scalars['Float']['output'];
};

export type PollType = {
	__typename?: 'PollType';
	_id: Scalars['String']['output'];
	abortPageBody?: Maybe<Scalars['String']['output']>;
	abortPageTitle?: Maybe<Scalars['String']['output']>;
	completePageBody?: Maybe<Scalars['String']['output']>;
	completePageTitle?: Maybe<Scalars['String']['output']>;
	name: Scalars['String']['output'];
	questions: Array<QuestionType>;
	stats?: Maybe<PollStatsType>;
};

export type PollsListType = {
	__typename?: 'PollsListType';
	items: Array<AdminPollType>;
	total: Scalars['Int']['output'];
};

export enum PositionType {
	Bottom = 'BOTTOM',
	Center = 'CENTER',
	ExtensionCategory = 'EXTENSION_CATEGORY',
	ExtensionProductCard = 'EXTENSION_PRODUCT_CARD',
	LandingPartner = 'LANDING_PARTNER',
	Left = 'LEFT',
	Modal = 'MODAL',
	Right = 'RIGHT',
	Top = 'TOP',
}

export type ProductSearchQueriesInput = {
	externalId: Scalars['Float']['input'];
	marketplace: ProductSources;
};

export enum ProductSortByEnum {
	Brand = 'BRAND',
	ExternalId = 'EXTERNAL_ID',
	InShopSince = 'IN_SHOP_SINCE',
	Name = 'NAME',
	Price = 'PRICE',
	Quantity = 'QUANTITY',
	Rating = 'RATING',
	Revenue = 'REVENUE',
	Review = 'REVIEW',
	Seller = 'SELLER',
	Sold = 'SOLD',
}

export enum ProductSources {
	Ozon = 'OZON',
	Wildberries = 'WILDBERRIES',
}

export type ProfitAndCommission = {
	__typename?: 'ProfitAndCommission';
	currentCommission: Scalars['Float']['output'];
	currentProfit: Scalars['Float']['output'];
	sumPastAndCurCommission?: Maybe<Scalars['Float']['output']>;
	sumPastAndCurProfit?: Maybe<Scalars['Float']['output']>;
};

export type PromoCodeBase = {
	__typename?: 'PromoCodeBase';
	applyCount?: Maybe<Scalars['Float']['output']>;
	code: Scalars['String']['output'];
	description: Scalars['String']['output'];
	discount: Scalars['Int']['output'];
	endDate?: Maybe<Scalars['Timestamp']['output']>;
	isActive?: Maybe<Scalars['Boolean']['output']>;
	limitCount?: Maybe<Scalars['Float']['output']>;
	paymentType?: Maybe<Array<Scalars['String']['output']>>;
	userIds?: Maybe<Array<Scalars['String']['output']>>;
	utmSource?: Maybe<Scalars['String']['output']>;
};

export type PromoCodeListInput = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
};

export type PromoCodeType = {
	__typename?: 'PromoCodeType';
	_id: Scalars['String']['output'];
	applyCount?: Maybe<Scalars['Float']['output']>;
	code: Scalars['String']['output'];
	description: Scalars['String']['output'];
	discount: Scalars['Int']['output'];
	endDate?: Maybe<Scalars['Timestamp']['output']>;
	isActive?: Maybe<Scalars['Boolean']['output']>;
	limitCount?: Maybe<Scalars['Float']['output']>;
	paymentType?: Maybe<Array<Scalars['String']['output']>>;
	userIds?: Maybe<Array<Scalars['String']['output']>>;
	utmSource?: Maybe<Scalars['String']['output']>;
};

export type Query = {
	__typename?: 'Query';
	activeNotifications?: Maybe<NotificationType>;
	activePoll?: Maybe<PollType>;
	activeTariffs: Array<TariffType>;
	activeUsersStat: Array<ActiveUsersStatsEntity>;
	activeUsersStats: ActiveUsersStatsResponse;
	addSubMetrics: Scalars['Boolean']['output'];
	allNotifications: NotificationsListType;
	allPolls: PollsListType;
	analyticsKeyStats: AnalyticsKeyStatsResponse;
	apiCallsActiveUsers: ApiCallsUsersType;
	apiCallsPopularActions: Array<ApiCallsActionStatsType>;
	apiCallsUserStats: Array<ApiCallsActionStatsType>;
	applyPromoCode: ApplyPromoCodeType;
	availableTariffs: Array<TariffType>;
	banners: Array<BannerResponse>;
	changeFormActive: FormTypeResponse;
	checkOzonStoreStatus: Scalars['Boolean']['output'];
	checkSubStatus: Scalars['Boolean']['output'];
	checkUserExistInOtveto: CheckUserResponse;
	checkWbStoreStatus: Scalars['Boolean']['output'];
	connectToTariffStatus: PaymentStatusResponse;
	createAppOzonFeedbackAnswer: ApplicationFeedbackAnswerResponse;
	createAppOzonQuestionAnswer: ApplicationFeedbackAnswerResponse;
	createAppWbFeedbackAnswer: ApplicationFeedbackAnswerResponse;
	createAppWbQuestionAnswer: ApplicationFeedbackAnswerResponse;
	extStats: ExtStatsResponse;
	favorites: FavoritesResp;
	favoritesGroups: Array<FavoritesGroupType>;
	formList: FormListTypeResponse;
	getActiveForm?: Maybe<FormTypeResponse>;
	getActiveFormById: FormTypeResponse;
	getAuthTokenForOtveto: LoginInOtvetoReponse;
	getTeamUsers: Array<TeamMateResp>;
	getUtmSourceList: UtmSourceListResponse;
	getUtmSourceStats: Array<UtmSourceStatsResponse>;
	innInfoHint: InnItemResponse;
	marketplacesStats: Array<MarketplaceStats>;
	marketplacesStatsChart: Array<MarketplaceStatsChart>;
	me: UserType;
	mySearchPositionsRequests: MySearchPositionRequestsResponse;
	ozonAnalyticsBrands: Array<OzonAnalyticsBrandsResponse>;
	ozonAnalyticsComingPayments: AnalyticsForPay;
	ozonAnalyticsExpensesSummaryResponse: AnalyticsExpensesSummaryResponse;
	ozonAnalyticsFinanceSummary: AnalyticsFinanceSummaryResponse;
	ozonAnalyticsLastOrders: Array<OzonAnalyticsLastOrdersList>;
	ozonAnalyticsLastOrdersV2: OzonLastOrdersResponse;
	ozonAnalyticsOrderPlan: OzonAnalyticsOrderPlanResponse;
	ozonAnalyticsOrdersPerDay: Array<AnalyticsOrdersPerDayResponse>;
	ozonAnalyticsOrdersPerMonth: AnalyticsOrdersPerMonthResponse;
	ozonAnalyticsOrdersStats: Array<AnalyticsOrdersStatsResponse>;
	ozonAnalyticsOrdersStatsV2: OzonAnalyticsOrdersStatsResponse;
	ozonAnalyticsOrdersSummary: AnalyticsOrdersSummaryResponse;
	ozonAnalyticsPaymentsHistory: Array<AnalyticsPaymentHistoryResponse>;
	ozonAnalyticsProducts: Array<OzonAnalyticsProductsResp>;
	ozonAnalyticsProductsSalesByRegion: Array<AnalyticsSalesProducsByLocationResponse>;
	ozonAnalyticsSalesByRegion: Array<OzonAnalyticsSalesByRegionResponse>;
	ozonAnalyticsSalesDynamic: Array<AnalyticsSalesDynamicResponse>;
	ozonAnalyticsSalesDynamicV2: AnalyticsSalesDynamicResponseV2;
	ozonAnalyticsSalesForPayByBrands: Array<AnalyticsForPayByBrandResponse>;
	ozonAnalyticsSalesLogisticAmount: AnalyticsLogisticAmountResponse;
	ozonAnalyticsSalesPerDay: Array<AnalyticsSalesPerDayResp>;
	ozonAnalyticsSalesPerMonth: AnalyticsSalesPerDayResponse;
	ozonAnalyticsSalesReport: Array<OzonAnalyticsReportStatsResponse>;
	ozonAnalyticsSalesStats: Array<OzonAnalyticsSalesStats>;
	ozonAnalyticsStocks: Array<OzonAnalyticsStock>;
	ozonAnalyticsStocksAmount: AnalyticsStockTotalAmountResponse;
	ozonAnalyticsStocksByProduct: StocksResponse;
	ozonAnalyticsStocksByWarehouse: StocksResponse;
	ozonAnalyticsSummary: AnalyticsSummaryResponse;
	ozonAnalyticsSummaryByPeriod: AnalyticsSummaryByPeriodResponse;
	ozonAnalyticsSummaryV2: AnalyticsSummaryResponse;
	ozonAnalyticsTopSalesStats: Array<AnalyticsTopSalesStatsResponse>;
	ozonBrand: OzonBrandType;
	ozonBrands: OzonBrandsResponce;
	ozonCategories: Array<OzonCategoryType>;
	ozonCategory: OzonCategoryType;
	ozonCategoryBrands: Array<OzonCategoryBrand>;
	ozonCategoryBrandsRevenue: CategoryBrandsRevenueResp;
	ozonCategoryBrandsSales: CategoryBrandsSalesResp;
	ozonCategoryDailyStats: Array<OzonCategoryDailyStats>;
	ozonCategoryProducts: OzonProductsType;
	ozonCategoryStatsChart: OzonCategoryStatsChartResp;
	ozonEnter: UserType;
	ozonExtensionProductHistory: Array<OzonProductHistory>;
	ozonProduct: OzonProductType;
	ozonProductDailyHistory: OzonProductDailyHistoryResponseType;
	ozonProductHistory: Array<OzonProductHistoryType>;
	ozonProductPositionHistory: Array<OzonProductPositionHistory>;
	ozonProductSummary: OzonProductSummary;
	ozonProductVariants: Array<OzonProductVariantType>;
	ozonProducts: OzonProductsType;
	ozonProductsSummary: Array<OzonExtProductType>;
	ozonSalesHistoryPerDay: Array<OzonProductHistoryDailyChart>;
	ozonSectionsStats: OzonSectionsStatsType;
	ozonSeller: OzonSellerType;
	ozonSellers: OzonSellersResponce;
	ozonStats: OzonStats;
	ozonStatsChart: OzonStatsChartResponse;
	ozonSubcategoriesRevenue: Array<OzonSubcategoryRevenue>;
	ozonSubcategoriesSales: Array<OzonSubcategorySales>;
	ozonSubcategoriesStats: Array<OzonSubcategoryStats>;
	ozonTopCategories: Array<OzonTopCategory>;
	ozonTopProducts: Array<OzonTopProduct>;
	ozonTrends: Array<OzonTrend>;
	ozonTrendsChart: Array<OzonTrendChart>;
	payments: Array<PaymentType>;
	paymentsStats: Array<PaymentsStatsResp>;
	productSearchQueries: Array<SearchPositionRequestEntity>;
	promoCodesList: Array<PromoCodeType>;
	registrationStats: RegistrationStatsResponse;
	saveEmailForBlogBroadcast: Scalars['Boolean']['output'];
	showProductsStatusInExt: Scalars['Boolean']['output'];
	subscriptionStat: SubscriptionMetricsResp;
	tariffEnabled: Scalars['Boolean']['output'];
	tariffs: Array<TariffType>;
	user: UserType;
	userInnList: Array<InnItemResponse>;
	userToken: Scalars['String']['output'];
	users: UsersResponse;
	usersPublicStat: UsersPublicStatResp;
	usersStat: UsersStatResp;
	viewedNotifications: Array<NotificationType>;
	wbAnalyticsBrands: Array<WbAnalyticsBrandsType>;
	wbAnalyticsComingPayments: AnalyticsForPay;
	wbAnalyticsExpensesResponse: Array<WbAnalyticsExpensesResponse>;
	wbAnalyticsExpensesSummaryResponse: AnalyticsExpensesSummaryResponse;
	wbAnalyticsFinanceSummary: AnalyticsFinanceSummaryResponse;
	wbAnalyticsLastOrders: Array<WbAnalyticsLastOrdersList>;
	wbAnalyticsLastOrdersV2: WbLastOrdersResponse;
	wbAnalyticsOrderPlan: WbAnalyticsOrderPlanResponse;
	wbAnalyticsOrdersByRegion: Array<AnalyticsOrdersProducsByLocationResponse>;
	wbAnalyticsOrdersPerDay: Array<AnalyticsOrdersPerDayResponse>;
	wbAnalyticsOrdersPerMonth: AnalyticsOrdersPerMonthResponse;
	wbAnalyticsOrdersStats: Array<WbAnalyticsOrdersStatsResponse>;
	wbAnalyticsOrdersStatsV2: WbAnalyticsOrdersStatsV2Response;
	wbAnalyticsOrdersSummary: AnalyticsOrdersSummaryResponse;
	wbAnalyticsPaymentsHistory: Array<AnalyticsPaymentHistoryResponse>;
	wbAnalyticsProducts: Array<WbAnalyticsProductsResp>;
	wbAnalyticsSales: Array<WbAnalyticsSalesType>;
	wbAnalyticsSalesByOblast: Array<WbAnalyticsSalesByLocationResponse>;
	wbAnalyticsSalesByRegion: Array<WbAnalyticsSalesByLocationResponse>;
	wbAnalyticsSalesDynamic: Array<AnalyticsSalesDynamicResponse>;
	wbAnalyticsSalesDynamicV2: AnalyticsSalesDynamicResponseV2;
	wbAnalyticsSalesForPayByBrands: Array<AnalyticsForPayByBrandResponse>;
	wbAnalyticsSalesLogisticAmount: AnalyticsLogisticAmountResponse;
	wbAnalyticsSalesPerDay: Array<AnalyticsSalesPerDayResp>;
	wbAnalyticsSalesPerMonth: AnalyticsSalesPerDayResponse;
	wbAnalyticsSalesReport: Array<WbAnalyticsReportStatsResponse>;
	wbAnalyticsSalesStats: Array<WbAnalyticsSalesStats>;
	wbAnalyticsStocksAmount: AnalyticsStockTotalAmountResponse;
	wbAnalyticsStocksByProduct: WbAnalyticsStockResponse;
	wbAnalyticsStocksByWarehouse: WbAnalyticsStockResponse;
	wbAnalyticsSummary: AnalyticsSummaryResponse;
	wbAnalyticsSummaryByPeriod: AnalyticsSummaryByPeriodResponse;
	wbAnalyticsSummaryV2: AnalyticsSummaryResponse;
	wbAnalyticsTopSalesStats: Array<WbAnalyticsTopSalesStats>;
	wbBrand: WbBrandType;
	wbBrands: WbBrandsResponce;
	wbCategories: Array<WbCategoryType>;
	wbCategory: WbCategoryType;
	wbCategoryBrands: Array<WbCategoryBrand>;
	wbCategoryBrandsRevenue: CategoryBrandsRevenueResp;
	wbCategoryBrandsSales: CategoryBrandsSalesResp;
	wbCategoryDailyStats: Array<WbCategoryDailyStats>;
	wbCategoryProducts: WbProductsType;
	wbCategoryStatsChart: WbCategoryStatsChartResp;
	wbEnter: UserType;
	wbExtensionProductHistory: Array<WbProductHistory>;
	wbNewTopProducts: OzonProductsType;
	wbProduct: WbProductType;
	wbProductDailyHistory: WbProductDailyHistoryResponseType;
	wbProductHistory: Array<WbProductHistoryType>;
	wbProductPositionHistory: Array<WbProductPositionHistoryType>;
	wbProductSearchByTag: WbProductsByTagResponse;
	wbProductSummary: WbProductSummary;
	wbProductTagPositionHistory: Array<WbProductTagHistoryResponse>;
	wbProductTagStat: Array<WbSearchStatisticsResponse>;
	wbProductVariants: Array<WbProductVariantType>;
	wbProducts: WbProductsType;
	wbProductsSummary: Array<WbExtProductType>;
	wbQuantityPerWarehouse: Array<WbWarehouseType>;
	wbSalesHistoryPerDay: Array<WbProductHistoryDailyChart>;
	wbSectionsStats: WbSectionsStatsType;
	wbSeller: WbSellerType;
	wbSellerProducts: OzonProductsType;
	wbSellerTopProducts: OzonProductsType;
	wbSellers: WbSellersResponce;
	wbStats: WbStats;
	wbStatsChart: WbStatsChartResponse;
	wbStatsSummary: WbStatsSummaryResponse;
	wbSubcategoriesRevenue: Array<WbSubcategoryRevenue>;
	wbSubcategoriesSales: Array<WbSubcategorySales>;
	wbSubcategoriesStats: Array<WbSubcategoryStats>;
	wbTopCategories: Array<WbTopCategory>;
	wbTopProducts: Array<WbTopProduct>;
	wbTrends: Array<WbTrend>;
	wbTrendsChart: Array<WbTrendChart>;
};

export type QueryActiveUsersStatArgs = {
	dateRange: DateRangeInput;
	device?: InputMaybe<DeviceFilter>;
	monthly?: InputMaybe<Scalars['Boolean']['input']>;
	userStatType?: InputMaybe<UserStatTypeFilter>;
};

export type QueryActiveUsersStatsArgs = {
	dateRange: DateRangeInput;
	device?: InputMaybe<DeviceFilter>;
	userStatType?: InputMaybe<UserStatTypeFilter>;
};

export type QueryAddSubMetricsArgs = {
	action: MetricAction;
};

export type QueryAllNotificationsArgs = {
	filter: AllNotificationsInput;
};

export type QueryAllPollsArgs = {
	filter: AllPollsInput;
};

export type QueryAnalyticsKeyStatsArgs = {
	dateRange: DateRangeInput;
};

export type QueryApiCallsActiveUsersArgs = {
	fromDate: Scalars['Timestamp']['input'];
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	toDate: Scalars['Timestamp']['input'];
};

export type QueryApiCallsPopularActionsArgs = {
	fromDate: Scalars['Timestamp']['input'];
	toDate: Scalars['Timestamp']['input'];
};

export type QueryApiCallsUserStatsArgs = {
	fromDate: Scalars['Timestamp']['input'];
	toDate: Scalars['Timestamp']['input'];
	userId: Scalars['String']['input'];
};

export type QueryApplyPromoCodeArgs = {
	input: ApplyPromoCodeInput;
};

export type QueryChangeFormActiveArgs = {
	input: ChangeFormActiveInput;
};

export type QueryCheckOzonStoreStatusArgs = {
	input: CheckStoreStatusInput;
};

export type QueryCheckWbStoreStatusArgs = {
	input: CheckStoreStatusInput;
};

export type QueryConnectToTariffStatusArgs = {
	orderId: Scalars['String']['input'];
};

export type QueryCreateAppOzonFeedbackAnswerArgs = {
	input: GetFeedbackAnswerInput;
};

export type QueryCreateAppOzonQuestionAnswerArgs = {
	input: GetQuestionAnswerInput;
};

export type QueryCreateAppWbFeedbackAnswerArgs = {
	input: GetFeedbackAnswerInput;
};

export type QueryCreateAppWbQuestionAnswerArgs = {
	input: GetQuestionAnswerInput;
};

export type QueryFavoritesArgs = {
	favoritesGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	marketplaces?: InputMaybe<Array<ProductSources>>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sortBy?: InputMaybe<FavoriteSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryFormListArgs = {
	input: PagesInput;
};

export type QueryGetActiveFormByIdArgs = {
	formId: Scalars['String']['input'];
};

export type QueryGetAuthTokenForOtvetoArgs = {
	input: UtmSourceInput;
};

export type QueryGetUtmSourceListArgs = {
	input: UtmListOptionsInput;
};

export type QueryGetUtmSourceStatsArgs = {
	input: UtmSourceStatsInput;
};

export type QueryInnInfoHintArgs = {
	input: InnInfoInput;
};

export type QueryMarketplacesStatsArgs = {
	dateRange: DateRangeInput;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMarketplacesStatsChartArgs = {
	dateRange: DateRangeInput;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMySearchPositionsRequestsArgs = {
	input: MySearchPositionRequestsInput;
};

export type QueryOzonAnalyticsBrandsArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryOzonAnalyticsComingPaymentsArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryOzonAnalyticsExpensesSummaryResponseArgs = {
	input: AnalyticsDateRangeInput;
};

export type QueryOzonAnalyticsFinanceSummaryArgs = {
	input: AnalyticsDateRangeInput;
};

export type QueryOzonAnalyticsLastOrdersArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryOzonAnalyticsLastOrdersV2Args = {
	input: LastOrdersInput;
};

export type QueryOzonAnalyticsOrderPlanArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	orderDays?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sortBy?: InputMaybe<AnalyticsStocksPlanSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryOzonAnalyticsOrdersPerDayArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonAnalyticsOrdersPerMonthArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	date?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryOzonAnalyticsOrdersStatsArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonAnalyticsOrdersStatsV2Args = {
	input: AnalyticsOrdersStatsArgs;
};

export type QueryOzonAnalyticsOrdersSummaryArgs = {
	input: OrderSummaryInput;
};

export type QueryOzonAnalyticsPaymentsHistoryArgs = {
	input: AnalyticsPaymentsInput;
};

export type QueryOzonAnalyticsProductsArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOzonAnalyticsProductsSalesByRegionArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonAnalyticsSalesByRegionArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonAnalyticsSalesDynamicArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonAnalyticsSalesDynamicV2Args = {
	input: AnalyticsDatesInput;
};

export type QueryOzonAnalyticsSalesForPayByBrandsArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonAnalyticsSalesLogisticAmountArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonAnalyticsSalesPerDayArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonAnalyticsSalesPerMonthArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	date?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryOzonAnalyticsSalesReportArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonAnalyticsSalesStatsArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonAnalyticsStocksArgs = {
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonAnalyticsStocksAmountArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryOzonAnalyticsStocksByProductArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sortBy?: InputMaybe<StocksSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonAnalyticsStocksByWarehouseArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sortBy?: InputMaybe<StocksSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonAnalyticsSummaryArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryOzonAnalyticsSummaryByPeriodArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryOzonAnalyticsSummaryV2Args = {
	input: AnalyticsKeyIdsInput;
};

export type QueryOzonAnalyticsTopSalesStatsArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonBrandArgs = {
	brandId: Scalars['Float']['input'];
};

export type QueryOzonBrandsArgs = {
	categoryId?: InputMaybe<Scalars['Int']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	productCount?: InputMaybe<DefaultRangeInput>;
	revenue?: InputMaybe<DefaultRangeInput>;
	search?: InputMaybe<Scalars['String']['input']>;
	sold?: InputMaybe<DefaultRangeInput>;
	sortBy?: InputMaybe<BrandsSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryOzonCategoriesArgs = {
	parentCategoryId?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOzonCategoryArgs = {
	categoryId?: InputMaybe<Scalars['Float']['input']>;
	fullName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOzonCategoryBrandsArgs = {
	categoryId: Scalars['Int']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonCategoryBrandsRevenueArgs = {
	categoryId: Scalars['Int']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonCategoryBrandsSalesArgs = {
	categoryId: Scalars['Int']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonCategoryDailyStatsArgs = {
	categoryId: Scalars['Float']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonCategoryProductsArgs = {
	categoryId: Scalars['Float']['input'];
	dateRange?: InputMaybe<DateRangeInput>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sortBy?: InputMaybe<OzonProductSortByEnum>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryOzonCategoryStatsChartArgs = {
	categoryId: Scalars['Float']['input'];
	fixedDateRange: FixedDateRange;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOzonExtensionProductHistoryArgs = {
	externalId: Scalars['Int']['input'];
};

export type QueryOzonProductArgs = {
	id: Scalars['Float']['input'];
};

export type QueryOzonProductDailyHistoryArgs = {
	dateRange: DateRangeInput;
	id: Scalars['Int']['input'];
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOzonProductHistoryArgs = {
	dateRange: DateRangeInput;
	externalId: Scalars['Float']['input'];
};

export type QueryOzonProductPositionHistoryArgs = {
	dateRange: DateRangeInput;
	id: Scalars['Int']['input'];
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOzonProductSummaryArgs = {
	externalId: Scalars['Float']['input'];
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOzonProductVariantsArgs = {
	id: Scalars['Float']['input'];
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOzonProductsArgs = {
	brandIds?: InputMaybe<Array<Scalars['Int']['input']>>;
	externalIds?: InputMaybe<Array<Scalars['Float']['input']>>;
	groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
	ids?: InputMaybe<Array<Scalars['String']['input']>>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sellerId?: InputMaybe<Array<Scalars['String']['input']>>;
	sortBy?: InputMaybe<OzonProductSortByEnum>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryOzonProductsSummaryArgs = {
	externalIds?: InputMaybe<Array<Scalars['Float']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryOzonSalesHistoryPerDayArgs = {
	date: Scalars['Timestamp']['input'];
	id: Scalars['Float']['input'];
};

export type QueryOzonSellerArgs = {
	id: Scalars['String']['input'];
};

export type QueryOzonSellersArgs = {
	categoryId?: InputMaybe<Scalars['Int']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	productCount?: InputMaybe<DefaultRangeInput>;
	revenue?: InputMaybe<DefaultRangeInput>;
	search?: InputMaybe<Scalars['String']['input']>;
	sold?: InputMaybe<DefaultRangeInput>;
	sortBy?: InputMaybe<SellersSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryOzonStatsArgs = {
	fromDate: Scalars['Timestamp']['input'];
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate: Scalars['Timestamp']['input'];
};

export type QueryOzonStatsChartArgs = {
	fromDate: Scalars['Timestamp']['input'];
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate: Scalars['Timestamp']['input'];
};

export type QueryOzonSubcategoriesRevenueArgs = {
	categoryId: Scalars['Float']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonSubcategoriesSalesArgs = {
	categoryId: Scalars['Float']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonSubcategoriesStatsArgs = {
	categoryId: Scalars['Float']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonTopCategoriesArgs = {
	date?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOzonTopProductsArgs = {
	date?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOzonTrendsArgs = {
	categoryId: Scalars['Float']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryOzonTrendsChartArgs = {
	categoryId?: InputMaybe<Scalars['Float']['input']>;
	dateRange?: InputMaybe<DateRangeInput>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPaymentsStatsArgs = {
	dateRange: DateRangeInput;
};

export type QueryProductSearchQueriesArgs = {
	input: ProductSearchQueriesInput;
};

export type QueryPromoCodesListArgs = {
	input: PromoCodeListInput;
};

export type QueryRegistrationStatsArgs = {
	dateRange: DateRangeInput;
	type?: InputMaybe<UserTypeFilter>;
};

export type QuerySaveEmailForBlogBroadcastArgs = {
	email: Scalars['String']['input'];
};

export type QueryUserArgs = {
	id: Scalars['ID']['input'];
};

export type QueryUserTokenArgs = {
	id: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
	filter?: InputMaybe<UserFilter>;
	limit?: InputMaybe<Scalars['Float']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	skip?: InputMaybe<Scalars['Float']['input']>;
	sort?: InputMaybe<UserSort>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryViewedNotificationsArgs = {
	filter: AllNotificationsInput;
};

export type QueryWbAnalyticsBrandsArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryWbAnalyticsComingPaymentsArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryWbAnalyticsExpensesResponseArgs = {
	input: AnalyticsDateRangeInput;
};

export type QueryWbAnalyticsExpensesSummaryResponseArgs = {
	input: AnalyticsDateRangeInput;
};

export type QueryWbAnalyticsFinanceSummaryArgs = {
	input: AnalyticsDateRangeInput;
};

export type QueryWbAnalyticsLastOrdersArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryWbAnalyticsLastOrdersV2Args = {
	input: LastOrdersInput;
};

export type QueryWbAnalyticsOrderPlanArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	orderDays?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sortBy?: InputMaybe<AnalyticsStocksPlanSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryWbAnalyticsOrdersByRegionArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsOrdersPerDayArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsOrdersPerMonthArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	date?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryWbAnalyticsOrdersStatsArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsOrdersStatsV2Args = {
	input: AnalyticsOrdersStatsArgs;
};

export type QueryWbAnalyticsOrdersSummaryArgs = {
	input: OrderSummaryInput;
};

export type QueryWbAnalyticsPaymentsHistoryArgs = {
	input: AnalyticsPaymentsInput;
};

export type QueryWbAnalyticsProductsArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWbAnalyticsSalesArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsSalesByOblastArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsSalesByRegionArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsSalesDynamicArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsSalesDynamicV2Args = {
	input: AnalyticsDatesInput;
};

export type QueryWbAnalyticsSalesForPayByBrandsArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsSalesLogisticAmountArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsSalesPerDayArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsSalesPerMonthArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	date?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryWbAnalyticsSalesReportArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsSalesStatsArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsStocksAmountArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryWbAnalyticsStocksByProductArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sortBy?: InputMaybe<AnalyticsStocksAmountSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsStocksByWarehouseArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sortBy?: InputMaybe<AnalyticsStocksAmountSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbAnalyticsSummaryArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryWbAnalyticsSummaryByPeriodArgs = {
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryWbAnalyticsSummaryV2Args = {
	input: AnalyticsKeyIdsInput;
};

export type QueryWbAnalyticsTopSalesStatsArgs = {
	brand?: InputMaybe<Scalars['String']['input']>;
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	keyId?: InputMaybe<Scalars['String']['input']>;
	keyIds?: InputMaybe<Array<Scalars['String']['input']>>;
	search?: InputMaybe<Scalars['String']['input']>;
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbBrandArgs = {
	brandId: Scalars['String']['input'];
};

export type QueryWbBrandsArgs = {
	categoryId?: InputMaybe<Scalars['Int']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	productCount?: InputMaybe<DefaultRangeInput>;
	revenue?: InputMaybe<DefaultRangeInput>;
	search?: InputMaybe<Scalars['String']['input']>;
	sold?: InputMaybe<DefaultRangeInput>;
	sortBy?: InputMaybe<BrandsSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryWbCategoriesArgs = {
	parentCategoryId?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWbCategoryArgs = {
	categoryId?: InputMaybe<Scalars['Float']['input']>;
	fullName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWbCategoryBrandsArgs = {
	categoryId: Scalars['Int']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbCategoryBrandsRevenueArgs = {
	categoryId: Scalars['Int']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbCategoryBrandsSalesArgs = {
	categoryId: Scalars['Int']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbCategoryDailyStatsArgs = {
	categoryId: Scalars['Float']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbCategoryProductsArgs = {
	categoryId: Scalars['Float']['input'];
	dateRange?: InputMaybe<DateRangeInput>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sortBy?: InputMaybe<ProductSortByEnum>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryWbCategoryStatsChartArgs = {
	categoryId: Scalars['Float']['input'];
	fixedDateRange: FixedDateRange;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWbExtensionProductHistoryArgs = {
	externalId: Scalars['Int']['input'];
};

export type QueryWbNewTopProductsArgs = {
	brandIds?: InputMaybe<Array<Scalars['Int']['input']>>;
	externalIds?: InputMaybe<Array<Scalars['Float']['input']>>;
	groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
	ids?: InputMaybe<Array<Scalars['String']['input']>>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sellerId?: InputMaybe<Array<Scalars['String']['input']>>;
	sortBy?: InputMaybe<OzonProductSortByEnum>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryWbProductArgs = {
	id: Scalars['Float']['input'];
};

export type QueryWbProductDailyHistoryArgs = {
	dateRange: DateRangeInput;
	id: Scalars['Int']['input'];
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWbProductHistoryArgs = {
	dateRange: DateRangeInput;
	externalId: Scalars['Float']['input'];
};

export type QueryWbProductPositionHistoryArgs = {
	dateRange: DateRangeInput;
	id: Scalars['Int']['input'];
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWbProductSearchByTagArgs = {
	input: GetProductsByTagsInput;
};

export type QueryWbProductSummaryArgs = {
	externalId: Scalars['Float']['input'];
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWbProductTagPositionHistoryArgs = {
	input: WbProductTagHistoryInput;
};

export type QueryWbProductTagStatArgs = {
	input: GetProductTagsInput;
};

export type QueryWbProductVariantsArgs = {
	id: Scalars['Float']['input'];
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWbProductsArgs = {
	brandIds?: InputMaybe<Array<Scalars['String']['input']>>;
	externalIds?: InputMaybe<Array<Scalars['Float']['input']>>;
	groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
	ids?: InputMaybe<Array<Scalars['String']['input']>>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sellerId?: InputMaybe<Scalars['String']['input']>;
	sortBy?: InputMaybe<ProductSortByEnum>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryWbProductsSummaryArgs = {
	externalIds?: InputMaybe<Array<Scalars['Float']['input']>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryWbQuantityPerWarehouseArgs = {
	from?: InputMaybe<Scalars['Timestamp']['input']>;
	id: Scalars['Int']['input'];
	to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbSalesHistoryPerDayArgs = {
	date: Scalars['Timestamp']['input'];
	id: Scalars['Float']['input'];
};

export type QueryWbSellerArgs = {
	id: Scalars['String']['input'];
};

export type QueryWbSellerProductsArgs = {
	brandIds?: InputMaybe<Array<Scalars['Int']['input']>>;
	externalIds?: InputMaybe<Array<Scalars['Float']['input']>>;
	groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
	ids?: InputMaybe<Array<Scalars['String']['input']>>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sellerId?: InputMaybe<Array<Scalars['String']['input']>>;
	sortBy?: InputMaybe<OzonProductSortByEnum>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryWbSellerTopProductsArgs = {
	brandIds?: InputMaybe<Array<Scalars['Int']['input']>>;
	externalIds?: InputMaybe<Array<Scalars['Float']['input']>>;
	groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
	ids?: InputMaybe<Array<Scalars['String']['input']>>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sellerId?: InputMaybe<Array<Scalars['String']['input']>>;
	sortBy?: InputMaybe<OzonProductSortByEnum>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryWbSellersArgs = {
	categoryId?: InputMaybe<Scalars['Int']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	productCount?: InputMaybe<DefaultRangeInput>;
	revenue?: InputMaybe<DefaultRangeInput>;
	search?: InputMaybe<Scalars['String']['input']>;
	sold?: InputMaybe<DefaultRangeInput>;
	sortBy?: InputMaybe<SellersSortBy>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryWbStatsArgs = {
	dateRange: DateRangeInput;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWbStatsChartArgs = {
	dateRange: DateRangeInput;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWbStatsSummaryArgs = {
	dateRange: DateRangeInput;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWbSubcategoriesRevenueArgs = {
	categoryId: Scalars['Float']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbSubcategoriesSalesArgs = {
	categoryId: Scalars['Float']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbSubcategoriesStatsArgs = {
	categoryId: Scalars['Float']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbTopCategoriesArgs = {
	date?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWbTopProductsArgs = {
	date?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWbTrendsArgs = {
	categoryId: Scalars['Float']['input'];
	fromDate?: InputMaybe<Scalars['Timestamp']['input']>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type QueryWbTrendsChartArgs = {
	categoryId?: InputMaybe<Scalars['Float']['input']>;
	dateRange?: InputMaybe<DateRangeInput>;
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuestionInput = {
	_id?: InputMaybe<Scalars['String']['input']>;
	action?: InputMaybe<Scalars['String']['input']>;
	answerToAbort: Scalars['String']['input'];
	answerToAbortLink?: InputMaybe<Scalars['String']['input']>;
	answerToAbortStopPoll?: InputMaybe<Scalars['Boolean']['input']>;
	answerToContinue: Scalars['String']['input'];
	answerToContinueLink?: InputMaybe<Scalars['String']['input']>;
	answerToContinueStopPoll?: InputMaybe<Scalars['Boolean']['input']>;
	text: Scalars['String']['input'];
	title: Scalars['String']['input'];
};

export type QuestionStatsType = {
	__typename?: 'QuestionStatsType';
	noAnswerCount: Array<Scalars['Float']['output']>;
	yesAnswerCount: Array<Scalars['Float']['output']>;
};

export type QuestionType = {
	__typename?: 'QuestionType';
	_id: Scalars['String']['output'];
	action?: Maybe<Scalars['String']['output']>;
	answerToAbort: Scalars['String']['output'];
	answerToAbortLink?: Maybe<Scalars['String']['output']>;
	answerToAbortStopPoll?: Maybe<Scalars['Boolean']['output']>;
	answerToContinue: Scalars['String']['output'];
	answerToContinueLink?: Maybe<Scalars['String']['output']>;
	answerToContinueStopPoll?: Maybe<Scalars['Boolean']['output']>;
	text: Scalars['String']['output'];
	title: Scalars['String']['output'];
};

export enum QuestionTypeEnum {
	Checkbox = 'CHECKBOX',
	Radio = 'RADIO',
	Text = 'TEXT',
}

export type RegistrationStatsResponse = {
	__typename?: 'RegistrationStatsResponse';
	groupedByTime: GroupByTime;
	registrationStats: Array<RegistrationStatsType>;
};

export type RegistrationStatsType = {
	__typename?: 'RegistrationStatsType';
	date: Scalars['Timestamp']['output'];
	emailsConfirmed: Scalars['Float']['output'];
	newRegistrations: Scalars['Float']['output'];
	phonesConfirmed: Scalars['Float']['output'];
};

export type SalesOrdersReturnSummary = {
	__typename?: 'SalesOrdersReturnSummary';
	currentCount: Scalars['Float']['output'];
	currentRevenue: Scalars['Float']['output'];
	sumPastAndCurCount?: Maybe<Scalars['Float']['output']>;
	sumPastAndCurRevenue?: Maybe<Scalars['Float']['output']>;
};

export type SearchPositionRequestEntity = {
	__typename?: 'SearchPositionRequestEntity';
	_id: Scalars['String']['output'];
	marketplace: ProductSources;
	positionHistory?: Maybe<Array<SearchPositionRequestProductHistory>>;
	product?: Maybe<SearchPositionRequestProduct>;
	productExternalId: Scalars['Float']['output'];
	searchQuery: Scalars['String']['output'];
	userId: Scalars['String']['output'];
};

export type SearchPositionRequestEntityPositionHistoryArgs = {
	dates?: InputMaybe<OptionalDateRangeInput>;
};

export type SearchPositionRequestProduct = {
	__typename?: 'SearchPositionRequestProduct';
	brandId?: Maybe<Scalars['String']['output']>;
	brandName?: Maybe<Scalars['String']['output']>;
	externalId: Scalars['Float']['output'];
	image?: Maybe<Scalars['String']['output']>;
	marketplace: ProductSources;
	monthRevenue: Scalars['Int']['output'];
	monthSales: Scalars['Int']['output'];
	name: Scalars['String']['output'];
	price: Scalars['Float']['output'];
	quantity: Scalars['Int']['output'];
	rating: Scalars['Float']['output'];
	reviewCount: Scalars['Int']['output'];
	sellerId?: Maybe<Scalars['String']['output']>;
	sellerName?: Maybe<Scalars['String']['output']>;
};

export type SearchPositionRequestProductHistory = {
	__typename?: 'SearchPositionRequestProductHistory';
	date: Scalars['Timestamp']['output'];
	position?: Maybe<Scalars['Int']['output']>;
};

export enum SellersSortBy {
	BrandCount = 'BRAND_COUNT',
	Name = 'NAME',
	ProductCount = 'PRODUCT_COUNT',
	Revenue = 'REVENUE',
	Sales = 'SALES',
}

export enum ServerPaymentStatus {
	Authorized = 'AUTHORIZED',
	Authorizing = 'AUTHORIZING',
	AuthFail = 'AUTH_FAIL',
	Canceled = 'CANCELED',
	Checked = 'CHECKED',
	Checking = 'CHECKING',
	Completed = 'COMPLETED',
	Completing = 'COMPLETING',
	Confirmed = 'CONFIRMED',
	Confirming = 'CONFIRMING',
	DeadlineExpired = 'DEADLINE_EXPIRED',
	Error = 'ERROR',
	Formshowed = 'FORMSHOWED',
	InProgress = 'IN_PROGRESS',
	New = 'NEW',
	PartialRefunded = 'PARTIAL_REFUNDED',
	PartialReversed = 'PARTIAL_REVERSED',
	Preauthorizing = 'PREAUTHORIZING',
	Processing = 'PROCESSING',
	Refunded = 'REFUNDED',
	Refunding = 'REFUNDING',
	Rejected = 'REJECTED',
	Reversed = 'REVERSED',
	Reversing = 'REVERSING',
	Success = 'SUCCESS',
	Unknown = 'UNKNOWN',
	WaitPayment = 'WAIT_PAYMENT',
	'3DsChecked' = '_3DS_CHECKED',
	'3DsChecking' = '_3DS_CHECKING',
}

export type SetPasswordInput = {
	password: Scalars['String']['input'];
	passwordRe: Scalars['String']['input'];
};

export enum SmsServices {
	RedSms = 'RedSms',
	SmsAero = 'SmsAero',
	SmsRu = 'SmsRu',
}

export enum SortDirection {
	Asc = 'ASC',
	Desc = 'DESC',
}

export type StockItem = {
	__typename?: 'StockItem';
	brand?: Maybe<Scalars['String']['output']>;
	category?: Maybe<Scalars['String']['output']>;
	inWay: Scalars['Float']['output'];
	inWayFromClient: Scalars['Float']['output'];
	inWayToClient: Scalars['Float']['output'];
	nmId: Scalars['Float']['output'];
	quantity: Scalars['Float']['output'];
	subject: Scalars['String']['output'];
	warehouseName?: Maybe<Scalars['String']['output']>;
};

export type StocksResponse = {
	__typename?: 'StocksResponse';
	items: Array<StockItem>;
	total: Scalars['Float']['output'];
};

export enum StocksSortBy {
	Brand = 'BRAND',
	Category = 'CATEGORY',
	InWay = 'IN_WAY',
	InWayFromClient = 'IN_WAY_FROM_CLIENT',
	InWayToClient = 'IN_WAY_TO_CLIENT',
	NmId = 'NM_ID',
	Quantity = 'QUANTITY',
	Subject = 'SUBJECT',
	Warehousename = 'WAREHOUSENAME',
}

export type SubscribeToTariffResp = {
	__typename?: 'SubscribeToTariffResp';
	error?: Maybe<Scalars['String']['output']>;
	errorMessage?: Maybe<Scalars['String']['output']>;
	orderId?: Maybe<Scalars['String']['output']>;
	paymentURL?: Maybe<Scalars['String']['output']>;
	status?: Maybe<ServerPaymentStatus>;
	success: Scalars['Boolean']['output'];
};

export type Subscription = {
	__typename?: 'Subscription';
	subMe: UserType;
	subTariffEnabled: Scalars['Boolean']['output'];
};

export type SubscriptionMetricsResp = {
	__typename?: 'SubscriptionMetricsResp';
	defaultCount: Scalars['Float']['output'];
	paidCount: Scalars['Float']['output'];
	trialCount: Scalars['Float']['output'];
};

export enum SubscriptionStatus {
	Active = 'ACTIVE',
	Cancelled = 'CANCELLED',
}

export type TariffPermissionsType = {
	__typename?: 'TariffPermissionsType';
	analyticsAccounts?: Maybe<Scalars['Int']['output']>;
	analyticsSOHistoryDays?: Maybe<Scalars['Int']['output']>;
	analyticsSalesHistoryDays?: Maybe<Scalars['Int']['output']>;
	exportProducts?: Maybe<Scalars['Int']['output']>;
	favoritesGroupsLimit?: Maybe<Scalars['Int']['output']>;
	favoritesLimit?: Maybe<Scalars['Int']['output']>;
	favoritesSummary?: Maybe<Scalars['Boolean']['output']>;
	hideAnalyticsBlock?: Maybe<Scalars['Boolean']['output']>;
	hideBrands?: Maybe<Scalars['Boolean']['output']>;
	hideExtProductHistory?: Maybe<Scalars['Boolean']['output']>;
	hideOzon?: Maybe<Scalars['Boolean']['output']>;
	hideProductsSummary?: Maybe<Scalars['Boolean']['output']>;
	hideSellers?: Maybe<Scalars['Boolean']['output']>;
	hideSeo?: Maybe<Scalars['Boolean']['output']>;
	hideTopCategories?: Maybe<Scalars['Boolean']['output']>;
	hideTopProducts?: Maybe<Scalars['Boolean']['output']>;
	historyDays?: Maybe<Scalars['Int']['output']>;
	productsGroupLimit?: Maybe<Scalars['Int']['output']>;
	requestsLimit?: Maybe<Scalars['Int']['output']>;
	sbAllProducts?: Maybe<Scalars['Boolean']['output']>;
	sbNewItems?: Maybe<Scalars['Boolean']['output']>;
	sbPresence?: Maybe<Scalars['Boolean']['output']>;
	sbSummary?: Maybe<Scalars['Boolean']['output']>;
	sbTopProducts?: Maybe<Scalars['Boolean']['output']>;
	searchQueriesLimit?: Maybe<Scalars['Int']['output']>;
	usersCount?: Maybe<Scalars['Int']['output']>;
};

export type TariffType = {
	__typename?: 'TariffType';
	_id: Scalars['String']['output'];
	color?: Maybe<Scalars['String']['output']>;
	description: Scalars['String']['output'];
	duration?: Maybe<Scalars['Float']['output']>;
	isActive: Scalars['Boolean']['output'];
	isDefault: Scalars['Boolean']['output'];
	isPublic: Scalars['Boolean']['output'];
	isTrial: Scalars['Boolean']['output'];
	marked?: Maybe<Scalars['Boolean']['output']>;
	name: Scalars['String']['output'];
	permissions?: Maybe<TariffPermissionsType>;
	price: Scalars['Float']['output'];
	price3: Scalars['Float']['output'];
	price6: Scalars['Float']['output'];
	price12: Scalars['Float']['output'];
};

export type TeamMateResp = {
	__typename?: 'TeamMateResp';
	comment?: Maybe<Scalars['String']['output']>;
	createdAt: Scalars['Timestamp']['output'];
	memberId: Scalars['String']['output'];
	updatedAt: Scalars['Timestamp']['output'];
};

export type TotalData = {
	__typename?: 'TotalData';
	totalQuantity: Scalars['Int']['output'];
	totalSoldMonth: Scalars['Int']['output'];
	totalSoldToday: Scalars['Int']['output'];
	totalSoldWeek: Scalars['Int']['output'];
	totalSoldYesterday: Scalars['Int']['output'];
};

export type UpdateUtmSourceInput = {
	_id: Scalars['String']['input'];
	comment?: InputMaybe<Scalars['String']['input']>;
	price: Scalars['Int']['input'];
};

export enum UserFilter {
	NotActivated = 'NotActivated',
	WithEmailVerified = 'WithEmailVerified',
	WithPhoneVerified = 'WithPhoneVerified',
}

export type UserPartnerRequestInput = {
	birthday?: InputMaybe<Scalars['Timestamp']['input']>;
	email: Scalars['String']['input'];
	firstName?: InputMaybe<Scalars['String']['input']>;
	incomeAvg6m?: InputMaybe<Scalars['Float']['input']>;
	inn: Scalars['Float']['input'];
	lastName?: InputMaybe<Scalars['String']['input']>;
	maxLimit?: InputMaybe<Scalars['Float']['input']>;
	orderId: Scalars['Float']['input'];
	patronymic?: InputMaybe<Scalars['String']['input']>;
	phone: Scalars['String']['input'];
	success?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserSort {
	CreatedAt = 'createdAt',
	Email = 'email',
	IsActive = 'isActive',
	Phone = 'phone',
	Role = 'role',
}

export enum UserStatTypeFilter {
	All = 'ALL',
	Authorized = 'AUTHORIZED',
	Unauthorized = 'UNAUTHORIZED',
}

export type UserTokenForBotResp = {
	__typename?: 'UserTokenForBotResp';
	token: Scalars['String']['output'];
};

export type UserType = {
	__typename?: 'UserType';
	_id: Scalars['String']['output'];
	activeTariff: TariffType;
	activeTariffId: Scalars['String']['output'];
	botFeedbackToken?: Maybe<Scalars['String']['output']>;
	carrotAuth?: Maybe<Scalars['String']['output']>;
	clickedFromExtNotification?: Maybe<Scalars['Boolean']['output']>;
	createdAt: Scalars['Timestamp']['output'];
	email: Scalars['String']['output'];
	favoritesGroups: Array<FavoritesGroupType>;
	havePassword?: Maybe<Scalars['Boolean']['output']>;
	isActive: Scalars['Boolean']['output'];
	isDeleted: Scalars['Boolean']['output'];
	isEmailVerified: Scalars['Boolean']['output'];
	isPhoneVerified: Scalars['Boolean']['output'];
	isSubscribed?: Maybe<Scalars['Boolean']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	ozonApiKeys: Array<OzonApiKeys>;
	paidTariffs: Array<PaidTariffType>;
	phone?: Maybe<Scalars['String']['output']>;
	role: Scalars['String']['output'];
	subscription?: Maybe<PaidTariffType>;
	telegram?: Maybe<Scalars['String']['output']>;
	test?: Maybe<Scalars['String']['output']>;
	uncompletedPoll?: Maybe<Scalars['String']['output']>;
	wbApiKeys: Array<WbApiKeys>;
};

export enum UserTypeFilter {
	All = 'ALL',
	Default = 'DEFAULT',
	Yandex = 'YANDEX',
}

export type UsersPublicStatResp = {
	__typename?: 'UsersPublicStatResp';
	registered: Scalars['Float']['output'];
};

export type UsersResponse = {
	__typename?: 'UsersResponse';
	items: Array<UserType>;
	total: Scalars['Float']['output'];
};

export type UsersStatResp = {
	__typename?: 'UsersStatResp';
	emailVerified: Scalars['Float']['output'];
	notActivated: Scalars['Float']['output'];
	ozonKeys: Scalars['Float']['output'];
	phoneVerified: Scalars['Float']['output'];
	registered: Scalars['Float']['output'];
	userWithAnalyticsKey: Scalars['Float']['output'];
	wbKeys: Scalars['Float']['output'];
};

export type UtmCampaign = {
	__typename?: 'UtmCampaign';
	_id: Scalars['String']['output'];
	campaign?: Maybe<Scalars['String']['output']>;
	comment?: Maybe<Scalars['String']['output']>;
	content?: Maybe<Scalars['String']['output']>;
	medium?: Maybe<Scalars['String']['output']>;
	mediumStats?: Maybe<Array<UtmMedium>>;
	payersCount: Scalars['Float']['output'];
	paymentAmount: Scalars['Float']['output'];
	paymentCount: Scalars['Float']['output'];
	price: Scalars['Float']['output'];
	root?: Maybe<Scalars['String']['output']>;
	source: Scalars['String']['output'];
	term?: Maybe<Scalars['String']['output']>;
	usersCount: Scalars['Float']['output'];
};

export type UtmContent = {
	__typename?: 'UtmContent';
	_id: Scalars['String']['output'];
	campaign?: Maybe<Scalars['String']['output']>;
	comment?: Maybe<Scalars['String']['output']>;
	content?: Maybe<Scalars['String']['output']>;
	medium?: Maybe<Scalars['String']['output']>;
	payersCount: Scalars['Float']['output'];
	paymentAmount: Scalars['Float']['output'];
	paymentCount: Scalars['Float']['output'];
	price: Scalars['Float']['output'];
	root?: Maybe<Scalars['String']['output']>;
	source: Scalars['String']['output'];
	term?: Maybe<Scalars['String']['output']>;
	termStats?: Maybe<Array<UtmTerm>>;
	usersCount: Scalars['Float']['output'];
};

export type UtmListOptionsInput = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	sort?: InputMaybe<UtmSort>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type UtmMedium = {
	__typename?: 'UtmMedium';
	_id: Scalars['String']['output'];
	campaign?: Maybe<Scalars['String']['output']>;
	comment?: Maybe<Scalars['String']['output']>;
	content?: Maybe<Scalars['String']['output']>;
	contentStats?: Maybe<Array<UtmContent>>;
	medium?: Maybe<Scalars['String']['output']>;
	payersCount: Scalars['Float']['output'];
	paymentAmount: Scalars['Float']['output'];
	paymentCount: Scalars['Float']['output'];
	price: Scalars['Float']['output'];
	root?: Maybe<Scalars['String']['output']>;
	source: Scalars['String']['output'];
	term?: Maybe<Scalars['String']['output']>;
	usersCount: Scalars['Float']['output'];
};

export enum UtmSort {
	CreatedAt = 'createdAt',
	PayersCount = 'payersCount',
	PaymentAmount = 'paymentAmount',
	PaymentCount = 'paymentCount',
	Price = 'price',
	UsersCount = 'usersCount',
}

export type UtmSourceInput = {
	utmSource?: InputMaybe<Scalars['String']['input']>;
};

export type UtmSourceListResponse = {
	__typename?: 'UtmSourceListResponse';
	items?: Maybe<Array<UtmSourceTotalStatsResponse>>;
	total: Scalars['Int']['output'];
};

export type UtmSourceResponse = {
	__typename?: 'UtmSourceResponse';
	_id: Scalars['String']['output'];
	campaign?: Maybe<Scalars['String']['output']>;
	comment?: Maybe<Scalars['String']['output']>;
	content?: Maybe<Scalars['String']['output']>;
	medium?: Maybe<Scalars['String']['output']>;
	payersCount: Scalars['Float']['output'];
	paymentAmount: Scalars['Float']['output'];
	paymentCount: Scalars['Float']['output'];
	price: Scalars['Int']['output'];
	source: Scalars['String']['output'];
	term?: Maybe<Scalars['String']['output']>;
	usersCount: Scalars['Float']['output'];
};

export type UtmSourceStatsInput = {
	fromDate: Scalars['Timestamp']['input'];
	source?: InputMaybe<Scalars['String']['input']>;
	toDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type UtmSourceStatsResponse = {
	__typename?: 'UtmSourceStatsResponse';
	dateToShow: Scalars['Timestamp']['output'];
	paymentAmount: Scalars['Float']['output'];
	paymentCount: Scalars['Float']['output'];
	registrationCount: Scalars['Float']['output'];
	source: Scalars['String']['output'];
};

export type UtmSourceTotalStatsResponse = {
	__typename?: 'UtmSourceTotalStatsResponse';
	_id: Scalars['String']['output'];
	campaign?: Maybe<Scalars['String']['output']>;
	campaignStats?: Maybe<Array<UtmCampaign>>;
	comment?: Maybe<Scalars['String']['output']>;
	content?: Maybe<Scalars['String']['output']>;
	medium?: Maybe<Scalars['String']['output']>;
	payersCount: Scalars['Float']['output'];
	paymentAmount: Scalars['Float']['output'];
	paymentCount: Scalars['Float']['output'];
	price: Scalars['Float']['output'];
	root?: Maybe<Scalars['String']['output']>;
	source: Scalars['String']['output'];
	term?: Maybe<Scalars['String']['output']>;
	usersCount: Scalars['Float']['output'];
};

export type UtmTerm = {
	__typename?: 'UtmTerm';
	_id: Scalars['String']['output'];
	campaign?: Maybe<Scalars['String']['output']>;
	comment?: Maybe<Scalars['String']['output']>;
	content?: Maybe<Scalars['String']['output']>;
	medium?: Maybe<Scalars['String']['output']>;
	payersCount: Scalars['Float']['output'];
	paymentAmount: Scalars['Float']['output'];
	paymentCount: Scalars['Float']['output'];
	price: Scalars['Float']['output'];
	root?: Maybe<Scalars['String']['output']>;
	source: Scalars['String']['output'];
	term?: Maybe<Scalars['String']['output']>;
	usersCount: Scalars['Float']['output'];
};

export type WbAnalyticsExpensesResponse = {
	__typename?: 'WBAnalyticsExpensesResponse';
	acquiringFee: Scalars['Int']['output'];
	date: Scalars['Timestamp']['output'];
	deliveryAmount: Scalars['Int']['output'];
	penalty: Scalars['Int']['output'];
	ppvzVw: Scalars['Int']['output'];
	returnAmount: Scalars['Int']['output'];
};

export type WbAnalyticsProductsResp = {
	__typename?: 'WBAnalyticsProductsResp';
	externalId: Scalars['Float']['output'];
	name: Scalars['String']['output'];
	subject?: Maybe<Scalars['String']['output']>;
};

export type WbApiKeys = {
	__typename?: 'WBApiKeys';
	createdAt: Scalars['Timestamp']['output'];
	isActive?: Maybe<Scalars['Boolean']['output']>;
	isDisabled?: Maybe<Scalars['Boolean']['output']>;
	isExpiration?: Maybe<Scalars['Boolean']['output']>;
	isHistoryLoaded?: Maybe<Scalars['Boolean']['output']>;
	isNewApi: Scalars['Boolean']['output'];
	key?: Maybe<Scalars['String']['output']>;
	keyId: Scalars['String']['output'];
	name: Scalars['String']['output'];
};

export type WbBrandCategoryType = {
	__typename?: 'WBBrandCategoryType';
	categoryId: Scalars['Int']['output'];
	categoryName: Scalars['String']['output'];
	productCount: Scalars['Int']['output'];
	subcategoryId: Scalars['Int']['output'];
	subcategoryName: Scalars['String']['output'];
};

export type WbBrandType = {
	__typename?: 'WBBrandType';
	avgPrice?: Maybe<Scalars['Float']['output']>;
	brandId?: Maybe<Scalars['String']['output']>;
	categories: Array<WbBrandCategoryType>;
	inMarketplaceSince?: Maybe<Scalars['Timestamp']['output']>;
	maxPrice?: Maybe<Scalars['Float']['output']>;
	minPrice?: Maybe<Scalars['Float']['output']>;
	monthlyRevenue?: Maybe<Scalars['Float']['output']>;
	monthlySales?: Maybe<Scalars['Float']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	productCount?: Maybe<Scalars['Int']['output']>;
	quantity?: Maybe<Scalars['Int']['output']>;
	sellerCount?: Maybe<Scalars['Int']['output']>;
	stockCost?: Maybe<Scalars['Float']['output']>;
	url: Scalars['String']['output'];
};

export type WbBrandsResponce = {
	__typename?: 'WBBrandsResponce';
	items: Array<WbBrandType>;
	total: Scalars['Int']['output'];
};

export type WbCategoryBrand = {
	__typename?: 'WBCategoryBrand';
	brandId: Scalars['String']['output'];
	productCount: Scalars['Float']['output'];
	rating: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	reviewCount: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
	title: Scalars['String']['output'];
};

export type WbCategoryDailyStats = {
	__typename?: 'WBCategoryDailyStats';
	avgPrice: Scalars['Float']['output'];
	avgSoldPrice: Scalars['Float']['output'];
	date: Scalars['Timestamp']['output'];
	productCount: Scalars['Float']['output'];
	rating?: Maybe<Scalars['Float']['output']>;
	revenue: Scalars['Float']['output'];
	reviewCount: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type WbCategoryPositionType = {
	__typename?: 'WBCategoryPositionType';
	cpm?: Maybe<Scalars['Int']['output']>;
	date: Scalars['Timestamp']['output'];
	position: Scalars['Int']['output'];
	realPosition?: Maybe<Scalars['Int']['output']>;
};

export type WbCategoryStatsChart = {
	__typename?: 'WBCategoryStatsChart';
	date: Scalars['Timestamp']['output'];
	index: Scalars['Float']['output'];
	revenue?: Maybe<Scalars['Float']['output']>;
};

export type WbCategoryType = {
	__typename?: 'WBCategoryType';
	categoryId: Scalars['Int']['output'];
	fullName: Scalars['String']['output'];
	isDeleted: Scalars['Boolean']['output'];
	name: Scalars['String']['output'];
	parentCategory?: Maybe<WbCategoryType>;
	productsCount: Scalars['Int']['output'];
	stats: WbCategoryStats;
	subCategories: Array<WbCategoryType>;
};

export type WbCategoryTypeStatsArgs = {
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WbDiscountType = {
	__typename?: 'WBDiscountType';
	basic?: Maybe<Scalars['Float']['output']>;
	client?: Maybe<Scalars['Float']['output']>;
	promo?: Maybe<Scalars['Float']['output']>;
};

export type WbExtProductType = {
	__typename?: 'WBExtProductType';
	_id: Scalars['String']['output'];
	brand?: Maybe<WbProductBrandType>;
	color?: Maybe<Scalars['String']['output']>;
	cpm?: Maybe<WbProductCpmType>;
	description?: Maybe<Scalars['String']['output']>;
	discount: WbDiscountType;
	externalId: Scalars['Float']['output'];
	groupId: Scalars['String']['output'];
	images: Array<AnalyticItemImage>;
	inShopSince?: Maybe<Scalars['Timestamp']['output']>;
	isAvailableOnWildberries: Scalars['Boolean']['output'];
	isDeliveryFromSeller?: Maybe<Scalars['Boolean']['output']>;
	isWithVideo: Scalars['Boolean']['output'];
	lastDeliveryChanged?: Maybe<Scalars['Timestamp']['output']>;
	monthRevenue: Scalars['Float']['output'];
	monthSales: Scalars['Float']['output'];
	name: Scalars['String']['output'];
	price: Scalars['Float']['output'];
	quantity: Scalars['Int']['output'];
	quantityPerWarehouse: Array<WbQuantityPerWarehouseType>;
	questionCount: Scalars['Int']['output'];
	rating: Scalars['Float']['output'];
	reviewCount?: Maybe<Scalars['Int']['output']>;
	sales: Scalars['Int']['output'];
	seller?: Maybe<Scalars['String']['output']>;
	sellerId?: Maybe<Scalars['String']['output']>;
	sizes?: Maybe<Array<WbSizesType>>;
	summary?: Maybe<WbProductSummary>;
	updatedAt: Scalars['Timestamp']['output'];
};

export type WbHistoryType = {
	__typename?: 'WBHistoryType';
	date: Scalars['Timestamp']['output'];
	discountBasic: Scalars['Float']['output'];
	discountClient: Scalars['Float']['output'];
	discountPromo: Scalars['Float']['output'];
	price: Scalars['Float']['output'];
	productId: Scalars['Int']['output'];
	quantity: Scalars['Int']['output'];
	rating: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	reviewCount: Scalars['Int']['output'];
	sizes: Array<WbProductHistorySizes>;
	sold: Scalars['Float']['output'];
};

export type WbImagesType = {
	__typename?: 'WBImagesType';
	original?: Maybe<Scalars['String']['output']>;
	small?: Maybe<Scalars['String']['output']>;
};

export type WbProductBaseType = {
	__typename?: 'WBProductBaseType';
	_id: Scalars['String']['output'];
	brand?: Maybe<WbProductBrandType>;
	color?: Maybe<Scalars['String']['output']>;
	cpm?: Maybe<WbProductCpmType>;
	description?: Maybe<Scalars['String']['output']>;
	discount: WbDiscountType;
	externalId: Scalars['Float']['output'];
	groupId: Scalars['String']['output'];
	images: Array<AnalyticItemImage>;
	inShopSince?: Maybe<Scalars['Timestamp']['output']>;
	isAvailableOnWildberries: Scalars['Boolean']['output'];
	isDeliveryFromSeller?: Maybe<Scalars['Boolean']['output']>;
	isWithVideo: Scalars['Boolean']['output'];
	lastDeliveryChanged?: Maybe<Scalars['Timestamp']['output']>;
	monthRevenue: Scalars['Float']['output'];
	monthSales: Scalars['Float']['output'];
	name: Scalars['String']['output'];
	price: Scalars['Float']['output'];
	quantity: Scalars['Int']['output'];
	quantityPerWarehouse: Array<WbQuantityPerWarehouseType>;
	questionCount: Scalars['Int']['output'];
	rating: Scalars['Float']['output'];
	reviewCount?: Maybe<Scalars['Int']['output']>;
	sales: Scalars['Int']['output'];
	seller?: Maybe<Scalars['String']['output']>;
	sellerId?: Maybe<Scalars['String']['output']>;
	sizes?: Maybe<Array<WbSizesType>>;
	updatedAt: Scalars['Timestamp']['output'];
};

export type WbProductBrandType = {
	__typename?: 'WBProductBrandType';
	id: Scalars['String']['output'];
	name: Scalars['String']['output'];
};

export type WbProductCpmType = {
	__typename?: 'WBProductCpmType';
	category?: Maybe<Scalars['Float']['output']>;
	searchQuery?: Maybe<Scalars['Float']['output']>;
};

export type WbProductDailyHistoryResponseType = {
	__typename?: 'WBProductDailyHistoryResponseType';
	data: Array<WbProductDailyHistoryType>;
	groupedByTime: GroupByTime;
};

export type WbProductDailyHistoryType = {
	__typename?: 'WBProductDailyHistoryType';
	date: Scalars['Timestamp']['output'];
	discountBasic: Scalars['Float']['output'];
	discountClient: Scalars['Float']['output'];
	discountPromo: Scalars['Float']['output'];
	price: Scalars['Float']['output'];
	quantity: Scalars['Int']['output'];
	rating: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	reviewCount: Scalars['Int']['output'];
	sold: Scalars['Float']['output'];
};

export type WbProductHistoryDailyChart = {
	__typename?: 'WBProductHistoryDailyChart';
	hour: Scalars['Int']['output'];
	revenue: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type WbProductHistorySizes = {
	__typename?: 'WBProductHistorySizes';
	id: Scalars['Int']['output'];
	quantity: Scalars['Int']['output'];
	warehouses: Array<WbWarehouse>;
};

export type WbProductHistoryType = {
	__typename?: 'WBProductHistoryType';
	createdAt: Scalars['Timestamp']['output'];
	externalId: Scalars['Float']['output'];
	newDescription?: Maybe<Scalars['String']['output']>;
	newImages?: Maybe<Array<WbImagesType>>;
	newName?: Maybe<Scalars['String']['output']>;
	oldDescription?: Maybe<Scalars['String']['output']>;
	oldImages?: Maybe<Array<WbImagesType>>;
	oldName?: Maybe<Scalars['String']['output']>;
};

export type WbProductPositionHistoryType = {
	__typename?: 'WBProductPositionHistoryType';
	category?: Maybe<WbCategoryType>;
	positions: Array<WbCategoryPositionType>;
};

export type WbProductSummary = {
	__typename?: 'WBProductSummary';
	averageSales: Scalars['Float']['output'];
	daysInStock: Scalars['Float']['output'];
	monthRevenue: Scalars['Float']['output'];
	monthSold: Scalars['Float']['output'];
	newReviewCount: Scalars['Float']['output'];
	todayRevenue: Scalars['Float']['output'];
	todaySold: Scalars['Float']['output'];
	weekRevenue: Scalars['Float']['output'];
	weekSold: Scalars['Float']['output'];
	yesterdayRevenue: Scalars['Float']['output'];
	yesterdaySold: Scalars['Float']['output'];
};

export type WbProductTagHistoryResponse = {
	__typename?: 'WBProductTagHistoryResponse';
	positions: Array<WbProductTagStatType>;
	tag: Scalars['String']['output'];
};

export type WbProductTagStatType = {
	__typename?: 'WBProductTagStatType';
	cpm?: Maybe<Scalars['Float']['output']>;
	date: Scalars['Timestamp']['output'];
	position: Scalars['Int']['output'];
	realPosition?: Maybe<Scalars['Float']['output']>;
};

export type WbProductType = {
	__typename?: 'WBProductType';
	_id: Scalars['String']['output'];
	brand?: Maybe<WbProductBrandType>;
	color?: Maybe<Scalars['String']['output']>;
	cpm?: Maybe<WbProductCpmType>;
	description?: Maybe<Scalars['String']['output']>;
	discount: WbDiscountType;
	externalId: Scalars['Float']['output'];
	groupId: Scalars['String']['output'];
	history: Array<WbHistoryType>;
	images: Array<AnalyticItemImage>;
	inShopSince?: Maybe<Scalars['Timestamp']['output']>;
	isAvailableOnWildberries: Scalars['Boolean']['output'];
	isDeliveryFromSeller?: Maybe<Scalars['Boolean']['output']>;
	isWithVideo: Scalars['Boolean']['output'];
	lastDeliveryChanged?: Maybe<Scalars['Timestamp']['output']>;
	monthRevenue: Scalars['Float']['output'];
	monthSales: Scalars['Float']['output'];
	name: Scalars['String']['output'];
	price: Scalars['Float']['output'];
	quantity: Scalars['Int']['output'];
	quantityPerWarehouse: Array<WbQuantityPerWarehouseType>;
	questionCount: Scalars['Int']['output'];
	rating: Scalars['Float']['output'];
	reviewCount?: Maybe<Scalars['Int']['output']>;
	sales: Scalars['Int']['output'];
	searchQueries?: Maybe<Array<SearchPositionRequestEntity>>;
	seller?: Maybe<Scalars['String']['output']>;
	sellerId?: Maybe<Scalars['String']['output']>;
	sizes?: Maybe<Array<WbSizesType>>;
	summary: WbProductSummary;
	updatedAt: Scalars['Timestamp']['output'];
	warehouseQuantity: Array<WbWarehouseStocks>;
};

export type WbProductTypeSummaryArgs = {
	includeFbs?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WbProductVariantType = {
	__typename?: 'WBProductVariantType';
	color?: Maybe<Scalars['String']['output']>;
	monthRevenue: Scalars['Float']['output'];
	monthSold: Scalars['Float']['output'];
	name: Scalars['String']['output'];
	price: Scalars['Float']['output'];
	size: Scalars['String']['output'];
};

export type WbProductsByTagResponse = {
	__typename?: 'WBProductsByTagResponse';
	haveNextPage: Scalars['Boolean']['output'];
	products: Array<WbProductBaseType>;
	total: Scalars['Float']['output'];
};

export type WbProductsType = {
	__typename?: 'WBProductsType';
	products: Array<WbProductType>;
	total: Scalars['Float']['output'];
};

export type WbQuantityPerWarehouseType = {
	__typename?: 'WBQuantityPerWarehouseType';
	quantity: Scalars['Int']['output'];
	sizeId: Scalars['Int']['output'];
	warehouses: Array<WbWarehouse>;
};

export type WbSearchStatisticsResponse = {
	__typename?: 'WBSearchStatisticsResponse';
	product: WbProductBaseType;
	tags: Array<WbTagsType>;
};

export type WbSellerCategoryType = {
	__typename?: 'WBSellerCategoryType';
	categoryId: Scalars['Int']['output'];
	categoryName: Scalars['String']['output'];
	productCount: Scalars['Int']['output'];
	subcategoryId: Scalars['Int']['output'];
	subcategoryName: Scalars['String']['output'];
};

export type WbSellerType = {
	__typename?: 'WBSellerType';
	_id: Scalars['String']['output'];
	avgPrice?: Maybe<Scalars['Float']['output']>;
	brandCount?: Maybe<Scalars['Int']['output']>;
	categories: Array<WbSellerCategoryType>;
	externalId?: Maybe<Scalars['Int']['output']>;
	inMarketplaceSince?: Maybe<Scalars['Timestamp']['output']>;
	maxPrice?: Maybe<Scalars['Float']['output']>;
	minPrice?: Maybe<Scalars['Float']['output']>;
	monthlyRevenue?: Maybe<Scalars['Float']['output']>;
	monthlySales?: Maybe<Scalars['Float']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	ogrn: Scalars['String']['output'];
	productCount?: Maybe<Scalars['Int']['output']>;
	quantity?: Maybe<Scalars['Float']['output']>;
	stockCost?: Maybe<Scalars['Float']['output']>;
};

export type WbSellersResponce = {
	__typename?: 'WBSellersResponce';
	items: Array<WbSellerType>;
	total: Scalars['Int']['output'];
};

export type WbSizesType = {
	__typename?: 'WBSizesType';
	id: Scalars['Int']['output'];
	name: Scalars['String']['output'];
};

export type WbStats = {
	__typename?: 'WBStats';
	brandsCount: Scalars['Float']['output'];
	productsCount: Scalars['Float']['output'];
	quantity: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	sellersCount: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type WbStatsChart = {
	__typename?: 'WBStatsChart';
	date: Scalars['Timestamp']['output'];
	revenue: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type WbStatsChartResponse = {
	__typename?: 'WBStatsChartResponse';
	data: Array<WbStatsChart>;
	groupedByTime: GroupByTime;
};

export type WbStatsSummaryResponse = {
	__typename?: 'WBStatsSummaryResponse';
	brandCount: Scalars['Float']['output'];
	productCount: Scalars['Float']['output'];
	quantity: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	sellerCount: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type WbSubcategoryRevenue = {
	__typename?: 'WBSubcategoryRevenue';
	revenue: Scalars['Float']['output'];
	title: Scalars['String']['output'];
};

export type WbSubcategorySales = {
	__typename?: 'WBSubcategorySales';
	sold: Scalars['Float']['output'];
	title: Scalars['String']['output'];
};

export type WbSubcategoryStats = {
	__typename?: 'WBSubcategoryStats';
	categoryId: Scalars['Float']['output'];
	productCount: Scalars['Float']['output'];
	rating: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	reviewCount: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
	title: Scalars['String']['output'];
};

export type WbTagsType = {
	__typename?: 'WBTagsType';
	frequency: Scalars['Float']['output'];
	productsCount?: Maybe<Scalars['Float']['output']>;
	tag: Scalars['String']['output'];
};

export type WbTopCategory = {
	__typename?: 'WBTopCategory';
	category: WbCategoryType;
	productCount: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type WbTopProduct = {
	__typename?: 'WBTopProduct';
	product: WbProductType;
	revenue: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type WbTrend = {
	__typename?: 'WBTrend';
	brandCount: Scalars['Float']['output'];
	productCount: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	revenuePerProduct: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
	week: Week;
};

export type WbTrendChart = {
	__typename?: 'WBTrendChart';
	brandCount: Scalars['Float']['output'];
	productCount: Scalars['Float']['output'];
	revenue: Scalars['Float']['output'];
	revenuePerProduct: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
	sundayDate: Scalars['Timestamp']['output'];
};

export type WbWarehouse = {
	__typename?: 'WBWarehouse';
	id: Scalars['Int']['output'];
	name?: Maybe<Scalars['String']['output']>;
	quantity: Scalars['Int']['output'];
};

export type WbWarehouseStocks = {
	__typename?: 'WBWarehouseStocks';
	name: Scalars['String']['output'];
	quantity: Scalars['Float']['output'];
};

export type WbWarehouseType = {
	__typename?: 'WBWarehouseType';
	date?: Maybe<Scalars['Timestamp']['output']>;
	id: Scalars['Float']['output'];
	lat?: Maybe<Scalars['Float']['output']>;
	long?: Maybe<Scalars['Float']['output']>;
	name?: Maybe<Scalars['String']['output']>;
	quantity: Scalars['Float']['output'];
	sold: Scalars['Float']['output'];
};

export type WarehouseCapital = {
	__typename?: 'WarehouseCapital';
	currentRetailPrice?: Maybe<Scalars['Float']['output']>;
	sumPastAndCurRetailPrice?: Maybe<Scalars['Float']['output']>;
};

export type WbAnalyticsBrandsType = {
	__typename?: 'WbAnalyticsBrandsType';
	name: Scalars['String']['output'];
};

export type WbAnalyticsFileParseResp = {
	__typename?: 'WbAnalyticsFileParseResp';
	success: Scalars['Boolean']['output'];
};

export type WbAnalyticsLastOrdersList = {
	__typename?: 'WbAnalyticsLastOrdersList';
	brand: Scalars['String']['output'];
	category: Scalars['String']['output'];
	date: Scalars['Timestamp']['output'];
	discountPercent: Scalars['Float']['output'];
	images?: Maybe<Array<AnalyticItemImage>>;
	nmId: Scalars['Float']['output'];
	orderSum?: Maybe<Scalars['Float']['output']>;
	productName?: Maybe<Scalars['String']['output']>;
	quantity: Scalars['Float']['output'];
	salesAmount?: Maybe<Scalars['Float']['output']>;
	subject: Scalars['String']['output'];
	totalPrice: Scalars['Float']['output'];
};

export type WbAnalyticsOrderPlanItem = {
	__typename?: 'WbAnalyticsOrderPlanItem';
	brand: Scalars['String']['output'];
	category: Scalars['String']['output'];
	nmId: Scalars['Float']['output'];
	productName?: Maybe<Scalars['String']['output']>;
	quantity: Scalars['Float']['output'];
	restDays?: Maybe<Scalars['Float']['output']>;
	salesPerDay: Scalars['Float']['output'];
	subject: Scalars['String']['output'];
	toOrder?: Maybe<Scalars['Float']['output']>;
	warehouseName?: Maybe<Scalars['String']['output']>;
};

export type WbAnalyticsOrderPlanResponse = {
	__typename?: 'WbAnalyticsOrderPlanResponse';
	items: Array<WbAnalyticsOrderPlanItem>;
	total: Scalars['Int']['output'];
};

export type WbAnalyticsOrdersStatsResponse = {
	__typename?: 'WbAnalyticsOrdersStatsResponse';
	brand: Scalars['String']['output'];
	category: Scalars['String']['output'];
	images?: Maybe<Array<AnalyticItemImage>>;
	nmId: Scalars['Float']['output'];
	productName?: Maybe<Scalars['String']['output']>;
	refunds: Scalars['Int']['output'];
	sales: Scalars['Int']['output'];
	salesAmount: Scalars['Float']['output'];
	subject: Scalars['String']['output'];
};

export type WbAnalyticsOrdersStatsV2Response = {
	__typename?: 'WbAnalyticsOrdersStatsV2Response';
	items: Array<WbAnalyticsOrdersStatsResponse>;
	total: Scalars['Int']['output'];
	totalSales: Scalars['Int']['output'];
	totalSalesAmount: Scalars['Int']['output'];
};

export type WbAnalyticsReportStatsResponse = {
	__typename?: 'WbAnalyticsReportStatsResponse';
	brand: Scalars['String']['output'];
	deliveryAmount: Scalars['Float']['output'];
	deliveryRub: Scalars['Float']['output'];
	nmId: Scalars['Float']['output'];
	productName?: Maybe<Scalars['String']['output']>;
	returnAmount: Scalars['Float']['output'];
	subject: Scalars['String']['output'];
	warehouseName?: Maybe<Scalars['String']['output']>;
};

export type WbAnalyticsSalesByLocationResponse = {
	__typename?: 'WbAnalyticsSalesByLocationResponse';
	countryName: Scalars['String']['output'];
	oblastOkrugName: Scalars['String']['output'];
	regionName: Scalars['String']['output'];
	sales: Scalars['Float']['output'];
	salesAmount: Scalars['Float']['output'];
};

export type WbAnalyticsSalesStats = {
	__typename?: 'WbAnalyticsSalesStats';
	brand: Scalars['String']['output'];
	discountPercent: Scalars['Float']['output'];
	discountSum: Scalars['Float']['output'];
	finishPrice: Scalars['Float']['output'];
	forPay: Scalars['Float']['output'];
	nmId: Scalars['Float']['output'];
	productName?: Maybe<Scalars['String']['output']>;
	retailPrice: Scalars['Float']['output'];
	salesAmount: Scalars['Float']['output'];
	salesSum: Scalars['Float']['output'];
	subject: Scalars['String']['output'];
};

export type WbAnalyticsSalesType = {
	__typename?: 'WbAnalyticsSalesType';
	barcode: Scalars['String']['output'];
	brand: Scalars['String']['output'];
	category: Scalars['String']['output'];
	countryName: Scalars['String']['output'];
	date: Scalars['Timestamp']['output'];
	discountPercent: Scalars['Float']['output'];
	finishedPrice: Scalars['Float']['output'];
	forPay: Scalars['Float']['output'];
	incomeID: Scalars['Float']['output'];
	isRealization: Scalars['Boolean']['output'];
	isStorno: Scalars['Float']['output'];
	isSupply: Scalars['Boolean']['output'];
	keyId: Scalars['String']['output'];
	lastChangeDate: Scalars['Timestamp']['output'];
	nmId: Scalars['Float']['output'];
	number?: Maybe<Scalars['String']['output']>;
	oblastOkrugName: Scalars['String']['output'];
	odid: Scalars['Float']['output'];
	orderId: Scalars['Float']['output'];
	priceWithDisc: Scalars['Float']['output'];
	productName?: Maybe<Scalars['String']['output']>;
	promoCodeDiscount: Scalars['Float']['output'];
	quantity: Scalars['Float']['output'];
	regionName: Scalars['String']['output'];
	saleID: Scalars['String']['output'];
	spp: Scalars['Float']['output'];
	subject: Scalars['String']['output'];
	supplierArticle: Scalars['String']['output'];
	techSize: Scalars['String']['output'];
	totalPrice: Scalars['Float']['output'];
	warehouseName: Scalars['String']['output'];
};

export type WbAnalyticsStockCurrentAmount = {
	__typename?: 'WbAnalyticsStockCurrentAmount';
	brand: Scalars['String']['output'];
	category: Scalars['String']['output'];
	inWay: Scalars['Float']['output'];
	inWayFromClient: Scalars['Float']['output'];
	inWayToClient: Scalars['Float']['output'];
	nmId: Scalars['Float']['output'];
	productName?: Maybe<Scalars['String']['output']>;
	quantity: Scalars['Float']['output'];
	subject: Scalars['String']['output'];
	warehouseName: Scalars['String']['output'];
};

export type WbAnalyticsStockResponse = {
	__typename?: 'WbAnalyticsStockResponse';
	items: Array<WbAnalyticsStockCurrentAmount>;
	total: Scalars['Int']['output'];
};

export type WbAnalyticsTopSalesStats = {
	__typename?: 'WbAnalyticsTopSalesStats';
	barcode: Scalars['String']['output'];
	brand: Scalars['String']['output'];
	category: Scalars['String']['output'];
	nmId: Scalars['Float']['output'];
	productName?: Maybe<Scalars['String']['output']>;
	sales: Scalars['Float']['output'];
	salesAmount: Scalars['Float']['output'];
	subject: Scalars['String']['output'];
};

export type WbAnalyticsUploadResponse = {
	__typename?: 'WbAnalyticsUploadResponse';
	data: Array<Array<Scalars['String']['output']>>;
	fileName: Scalars['String']['output'];
	headers: Array<Scalars['String']['output']>;
};

export type WbCategoryStats = {
	__typename?: 'WbCategoryStats';
	brandCount: Scalars['Float']['output'];
	monthRevenue: Scalars['Float']['output'];
	monthSold: Scalars['Float']['output'];
	newProductCount: Scalars['Float']['output'];
	sellerCount: Scalars['Float']['output'];
};

export type WbCategoryStatsChartResp = {
	__typename?: 'WbCategoryStatsChartResp';
	currentChart: Array<WbCategoryStatsChart>;
	prevChart: Array<WbCategoryStatsChart>;
};

export type WbLastOrdersResponse = {
	__typename?: 'WbLastOrdersResponse';
	items: Array<WbAnalyticsLastOrdersList>;
	total: Scalars['Float']['output'];
	totalSales: Scalars['Int']['output'];
	totalSalesAmount: Scalars['Int']['output'];
};

export type WbProductHistory = {
	__typename?: 'WbProductHistory';
	date: Scalars['Timestamp']['output'];
	price: Scalars['Int']['output'];
	quantity: Scalars['Int']['output'];
	revenue: Scalars['Float']['output'];
	sold: Scalars['Int']['output'];
};

export type WbProductTagHistoryInput = {
	dateRange: DateRangeInput;
	externalId: Scalars['Float']['input'];
};

export type WbSectionsStatsType = {
	__typename?: 'WbSectionsStatsType';
	brandCount: Scalars['Int']['output'];
	categoryCount: Scalars['Int']['output'];
	promotionCount: Scalars['Int']['output'];
	sellerCount: Scalars['Int']['output'];
};

export type Week = {
	__typename?: 'Week';
	from: Scalars['Timestamp']['output'];
	to: Scalars['Timestamp']['output'];
};

export type OzonImagesType = {
	__typename?: 'ozonImagesType';
	original?: Maybe<Scalars['String']['output']>;
	small?: Maybe<Scalars['String']['output']>;
};

export type OzonProductHistoryType = {
	__typename?: 'ozonProductHistoryType';
	createdAt: Scalars['Timestamp']['output'];
	externalId: Scalars['Float']['output'];
	newDescription?: Maybe<Scalars['String']['output']>;
	newImages?: Maybe<Array<OzonImagesType>>;
	newName?: Maybe<Scalars['String']['output']>;
	oldDescription?: Maybe<Scalars['String']['output']>;
	oldImages?: Maybe<Array<OzonImagesType>>;
	oldName?: Maybe<Scalars['String']['output']>;
};

export type ApiCallsPopularActionsQueryVariables = Exact<{
	fromDate: Scalars['Timestamp']['input'];
	toDate: Scalars['Timestamp']['input'];
}>;

export type ApiCallsPopularActionsQuery = {
	__typename?: 'Query';
	apiCallsPopularActions: Array<{
		__typename?: 'ApiCallsActionStatsType';
		action: string;
		count: number;
	}>;
};

export type ApiCallsActiveUsersQueryVariables = Exact<{
	fromDate: Scalars['Timestamp']['input'];
	toDate: Scalars['Timestamp']['input'];
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ApiCallsActiveUsersQuery = {
	__typename?: 'Query';
	apiCallsActiveUsers: {
		__typename?: 'ApiCallsUsersType';
		totalItems: number;
		items: Array<{
			__typename?: 'ApiCallsUsersStatsType';
			userId: string;
			count: number;
			user?: {
				__typename?: 'UserType';
				email: string;
				createdAt: any;
				isActive: boolean;
			} | null;
		}>;
	};
};

export type ApiCallsUserStatsQueryVariables = Exact<{
	userId: Scalars['String']['input'];
	fromDate: Scalars['Timestamp']['input'];
	toDate: Scalars['Timestamp']['input'];
}>;

export type ApiCallsUserStatsQuery = {
	__typename?: 'Query';
	apiCallsUserStats: Array<{
		__typename?: 'ApiCallsActionStatsType';
		action: string;
		count: number;
	}>;
};

export type NotificationBaseFragment = {
	__typename?: 'NotificationType';
	_id: string;
	title?: string | null;
	description?: string | null;
	view?: number | null;
	remaining?: number | null;
};

export type AllNotificationsQueryVariables = Exact<{
	filter: AllNotificationsInput;
}>;

export type AllNotificationsQuery = {
	__typename?: 'Query';
	allNotifications: {
		__typename?: 'NotificationsListType';
		total: number;
		items: Array<{__typename?: 'NotificationType'} & NotificationBaseFragment>;
	};
};

export type CreateNotificationMutationVariables = Exact<{
	notification: CreateNotificationInput;
}>;

export type CreateNotificationMutation = {
	__typename?: 'Mutation';
	createNotification: {__typename?: 'NotificationType'} & NotificationBaseFragment;
};

export type EditNotificationMutationVariables = Exact<{
	notification: EditNotificationInput;
}>;

export type EditNotificationMutation = {
	__typename?: 'Mutation';
	editNotification: {__typename?: 'NotificationType'} & NotificationBaseFragment;
};

export type RemoveNotificationMutationVariables = Exact<{
	notificationId: Scalars['String']['input'];
}>;

export type RemoveNotificationMutation = {
	__typename?: 'Mutation';
	removeNotification: {__typename?: 'NotificationType'; _id: string};
};

export type SubscriptionStatQueryVariables = Exact<{[key: string]: never}>;

export type SubscriptionStatQuery = {
	__typename?: 'Query';
	subscriptionStat: {
		__typename?: 'SubscriptionMetricsResp';
		trialCount: number;
		defaultCount: number;
		paidCount: number;
	};
};

export type PaymentsStatsQueryVariables = Exact<{
	dateRange: DateRangeInput;
}>;

export type PaymentsStatsQuery = {
	__typename?: 'Query';
	paymentsStats: Array<{
		__typename?: 'PaymentsStatsResp';
		date: any;
		priceSum: number;
		priceAmount: number;
		priceQuartersSum: number;
		priceQuartersAmount: number;
		priceHalfYearSum: number;
		priceHalfYearAmount: number;
		priceYearSum: number;
		priceYearAmount: number;
	}>;
};

export type QuestionBaseFragment = {
	__typename?: 'FormQuestionResponse';
	_id: string;
	title: string;
	subTitle?: string | null;
	questionType: QuestionTypeEnum;
	variants?: Array<string> | null;
	haveAnotherVariant?: boolean | null;
	isRequired?: boolean | null;
};

export type PollBaseFragment = {
	__typename?: 'FormTypeResponse';
	_id: string;
	name: string;
	title: string;
	subTitle?: string | null;
	isPublic?: boolean | null;
	isActive?: boolean | null;
	emails?: Array<string> | null;
	userIds?: Array<string> | null;
	tariffIds?: Array<string> | null;
	userCreatedAt?: any | null;
	haveOzonKey?: boolean | null;
	haveWbKey?: boolean | null;
	limit?: number | null;
	answeredCount?: number | null;
	questions: Array<{__typename?: 'FormQuestionResponse'} & QuestionBaseFragment>;
};

export type AllPollsQueryVariables = Exact<{
	input: PagesInput;
}>;

export type AllPollsQuery = {
	__typename?: 'Query';
	formList: {
		__typename?: 'FormListTypeResponse';
		total: number;
		items: Array<{__typename?: 'FormTypeResponse'} & PollBaseFragment>;
	};
};

export type CreatePollMutationVariables = Exact<{
	input: CreateFormInput;
}>;

export type CreatePollMutation = {
	__typename?: 'Mutation';
	createForm: {__typename?: 'FormTypeResponse'} & PollBaseFragment;
};

export type EditPollMutationVariables = Exact<{
	input: EditFormInput;
}>;

export type EditPollMutation = {
	__typename?: 'Mutation';
	editForm: {__typename?: 'FormTypeResponse'} & PollBaseFragment;
};

export type RemovePollMutationVariables = Exact<{
	formId: Scalars['String']['input'];
}>;

export type RemovePollMutation = {__typename?: 'Mutation'; removeForm: boolean};

export type ChangeFormActiveQueryVariables = Exact<{
	input: ChangeFormActiveInput;
}>;

export type ChangeFormActiveQuery = {
	__typename?: 'Query';
	changeFormActive: {__typename?: 'FormTypeResponse'} & PollBaseFragment;
};

export type PromoCodesListQueryVariables = Exact<{
	input: PromoCodeListInput;
}>;

export type PromoCodesListQuery = {
	__typename?: 'Query';
	promoCodesList: Array<{
		__typename?: 'PromoCodeType';
		description: string;
		code: string;
		discount: number;
		paymentType?: Array<string> | null;
		utmSource?: string | null;
		limitCount?: number | null;
		endDate?: any | null;
		userIds?: Array<string> | null;
		applyCount?: number | null;
		isActive?: boolean | null;
		_id: string;
	}>;
};

export type CreatePromoCodeMutationVariables = Exact<{
	input: CreatePromoCodeInput;
}>;

export type CreatePromoCodeMutation = {
	__typename?: 'Mutation';
	createPromoCode: {__typename?: 'PromoCodeBase'; code: string};
};

export type RemovePromoCodeMutationVariables = Exact<{
	code: Scalars['String']['input'];
}>;

export type RemovePromoCodeMutation = {__typename?: 'Mutation'; removePromoCode: boolean};

export type EditPromoCodeMutationVariables = Exact<{
	input: EditPromoCodeInput;
}>;

export type EditPromoCodeMutation = {
	__typename?: 'Mutation';
	editPromoCode: {__typename?: 'PromoCodeBase'; code: string};
};

export type PermissionsFragment = {
	__typename?: 'TariffPermissionsType';
	historyDays?: number | null;
	hideBrands?: boolean | null;
	hideSellers?: boolean | null;
	favoritesGroupsLimit?: number | null;
	productsGroupLimit?: number | null;
	hideTopProducts?: boolean | null;
	hideTopCategories?: boolean | null;
	favoritesSummary?: boolean | null;
	sbSummary?: boolean | null;
	sbTopProducts?: boolean | null;
	sbNewItems?: boolean | null;
	sbAllProducts?: boolean | null;
	sbPresence?: boolean | null;
	analyticsAccounts?: number | null;
	searchQueriesLimit?: number | null;
	hideOzon?: boolean | null;
	analyticsSalesHistoryDays?: number | null;
	analyticsSOHistoryDays?: number | null;
	exportProducts?: number | null;
	hideExtProductHistory?: boolean | null;
	hideAnalyticsBlock?: boolean | null;
	hideProductsSummary?: boolean | null;
	hideSeo?: boolean | null;
	favoritesLimit?: number | null;
};

export type TariffFragment = {
	__typename?: 'TariffType';
	_id: string;
	name: string;
	description: string;
	price: number;
	price3: number;
	price6: number;
	price12: number;
	isTrial: boolean;
	isActive: boolean;
	isPublic: boolean;
	isDefault: boolean;
	duration?: number | null;
	marked?: boolean | null;
	color?: string | null;
	permissions?: ({__typename?: 'TariffPermissionsType'} & PermissionsFragment) | null;
};

export type TariffsQueryVariables = Exact<{[key: string]: never}>;

export type TariffsQuery = {
	__typename?: 'Query';
	tariffs: Array<{__typename?: 'TariffType'} & TariffFragment>;
};

export type ActiveTariffsQueryVariables = Exact<{[key: string]: never}>;

export type ActiveTariffsQuery = {
	__typename?: 'Query';
	activeTariffs: Array<{__typename?: 'TariffType'} & TariffFragment>;
};

export type AddTariffToUserMutationVariables = Exact<{
	userId: Scalars['String']['input'];
	tariffId: Scalars['String']['input'];
	paymentType?: InputMaybe<PaymentTypeEnum>;
	withPayment?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AddTariffToUserMutation = {
	__typename?: 'Mutation';
	addTariffToUser: {
		__typename?: 'AddTariffToUserResponse';
		paidTariff: {
			__typename?: 'PaidTariffType';
			userId: string;
			tariffId: string;
			fromDate: any;
			toDate?: any | null;
			totalPaid: number;
			datePaid: any;
		};
		user: {
			__typename?: 'UserType';
			_id: string;
			email: string;
			role: string;
			createdAt: any;
			telegram?: string | null;
			isActive: boolean;
			activeTariffId: string;
		};
	};
};

export type AddTariffMutationVariables = Exact<{
	newTariffInput: AddTariffInput;
}>;

export type AddTariffMutation = {
	__typename?: 'Mutation';
	addTariff: {__typename?: 'TariffType'} & TariffFragment;
};

export type EditTariffMutationVariables = Exact<{
	tariffId: Scalars['String']['input'];
	editTariffInput: EditTariffInput;
}>;

export type EditTariffMutation = {
	__typename?: 'Mutation';
	editTariff: {__typename?: 'TariffType'} & TariffFragment;
};

export type EditTariffPermissionMutationVariables = Exact<{
	historyDays?: InputMaybe<Scalars['Int']['input']>;
	hideBrands?: InputMaybe<Scalars['Boolean']['input']>;
	hideSellers?: InputMaybe<Scalars['Boolean']['input']>;
	favoritesGroupsLimit?: InputMaybe<Scalars['Int']['input']>;
	productsGroupLimit?: InputMaybe<Scalars['Int']['input']>;
	hideTopProducts?: InputMaybe<Scalars['Boolean']['input']>;
	hideTopCategories?: InputMaybe<Scalars['Boolean']['input']>;
	favoritesSummary?: InputMaybe<Scalars['Boolean']['input']>;
	sbSummary?: InputMaybe<Scalars['Boolean']['input']>;
	sbTopProducts?: InputMaybe<Scalars['Boolean']['input']>;
	sbNewItems?: InputMaybe<Scalars['Boolean']['input']>;
	sbAllProducts?: InputMaybe<Scalars['Boolean']['input']>;
	sbPresence?: InputMaybe<Scalars['Boolean']['input']>;
	analyticsAccounts?: InputMaybe<Scalars['Int']['input']>;
	searchQueriesLimit?: InputMaybe<Scalars['Int']['input']>;
	hideOzon?: InputMaybe<Scalars['Boolean']['input']>;
	analyticsSalesHistoryDays?: InputMaybe<Scalars['Int']['input']>;
	analyticsSOHistoryDays?: InputMaybe<Scalars['Int']['input']>;
	exportProducts?: InputMaybe<Scalars['Int']['input']>;
	hideExtProductHistory?: InputMaybe<Scalars['Boolean']['input']>;
	tariffId: Scalars['String']['input'];
	hideAnalyticsBlock?: InputMaybe<Scalars['Boolean']['input']>;
	hideProductsSummary?: InputMaybe<Scalars['Boolean']['input']>;
	hideSeo?: InputMaybe<Scalars['Boolean']['input']>;
	favoritesLimit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type EditTariffPermissionMutation = {
	__typename?: 'Mutation';
	editTariffPermission: {__typename?: 'TariffType'} & TariffFragment;
};

export type AddFreeDaysToTariffMutationVariables = Exact<{
	input: FreeDaysInput;
}>;

export type AddFreeDaysToTariffMutation = {__typename?: 'Mutation'; addFreeDaysToTariff: boolean};

export type UserBaseFragment = {
	__typename?: 'UserType';
	_id: string;
	email: string;
	name?: string | null;
	role: string;
	createdAt: any;
	phone?: string | null;
	isActive: boolean;
	activeTariffId: string;
	isEmailVerified: boolean;
	isPhoneVerified: boolean;
	wbApiKeys: Array<{__typename?: 'WBApiKeys'; key?: string | null; keyId: string; name: string}>;
	paidTariffs: Array<{
		__typename?: 'PaidTariffType';
		userId: string;
		tariffId: string;
		fromDate: any;
		toDate?: any | null;
		totalPaid: number;
		datePaid: any;
	}>;
	subscription?: {__typename?: 'PaidTariffType'; toDate?: any | null} | null;
};

export type LoginMutationVariables = Exact<{
	email: Scalars['String']['input'];
	password: Scalars['String']['input'];
}>;

export type LoginMutation = {
	__typename?: 'Mutation';
	loginByEmail: {
		__typename?: 'LoginByEmailType';
		token: string;
		user: {__typename?: 'UserType'; role: string};
	};
};

export type LogoutMutationVariables = Exact<{[key: string]: never}>;

export type LogoutMutation = {__typename?: 'Mutation'; logout: string};

export type MeQueryVariables = Exact<{[key: string]: never}>;

export type MeQuery = {
	__typename?: 'Query';
	me: {__typename?: 'UserType'; _id: string; email: string; role: string};
};

export type UsersQueryVariables = Exact<{
	filter?: InputMaybe<UserFilter>;
	skip?: InputMaybe<Scalars['Float']['input']>;
	limit?: InputMaybe<Scalars['Float']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	sort?: InputMaybe<UserSort>;
	sortDirection?: InputMaybe<SortDirection>;
}>;

export type UsersQuery = {
	__typename?: 'Query';
	users: {
		__typename?: 'UsersResponse';
		total: number;
		items: Array<{__typename?: 'UserType'} & UserBaseFragment>;
	};
};

export type EditUserMutationVariables = Exact<{
	id: Scalars['ID']['input'];
	isActive: Scalars['Boolean']['input'];
	email: Scalars['String']['input'];
	isEmailVerified: Scalars['Boolean']['input'];
	phone: Scalars['String']['input'];
	isPhoneVerified: Scalars['Boolean']['input'];
	name: Scalars['String']['input'];
}>;

export type EditUserMutation = {
	__typename?: 'Mutation';
	editUser: {__typename?: 'UserType'} & UserBaseFragment;
};

export type RemoveUserMutationVariables = Exact<{
	userId: Scalars['ID']['input'];
}>;

export type RemoveUserMutation = {
	__typename?: 'Mutation';
	removeUser: {__typename?: 'UserType'; _id: string};
};

export type UsersStatRespQueryVariables = Exact<{[key: string]: never}>;

export type UsersStatRespQuery = {
	__typename?: 'Query';
	usersStat: {
		__typename?: 'UsersStatResp';
		registered: number;
		phoneVerified: number;
		emailVerified: number;
		notActivated: number;
		ozonKeys: number;
		wbKeys: number;
		userWithAnalyticsKey: number;
	};
	extStats: {__typename?: 'ExtStatsResponse'; clickedFromExtNotificationCount: number};
};

export type UserTokenQueryVariables = Exact<{
	id: Scalars['ID']['input'];
}>;

export type UserTokenQuery = {__typename?: 'Query'; userToken: string};

export type RegistrationStatsQueryVariables = Exact<{
	dateRange: DateRangeInput;
	type?: InputMaybe<UserTypeFilter>;
}>;

export type RegistrationStatsQuery = {
	__typename?: 'Query';
	registrationStats: {
		__typename?: 'RegistrationStatsResponse';
		groupedByTime: GroupByTime;
		registrationStats: Array<{
			__typename?: 'RegistrationStatsType';
			date: any;
			newRegistrations: number;
			emailsConfirmed: number;
			phonesConfirmed: number;
		}>;
	};
};

export type ConfirmPhoneByCodeMutationVariables = Exact<{
	code: Scalars['String']['input'];
	phone: Scalars['String']['input'];
}>;

export type ConfirmPhoneByCodeMutation = {
	__typename?: 'Mutation';
	confirmPhoneByCode: {__typename?: 'ConfirmPhoneByCodeResponse'; token: string};
};

export type SendPhoneVerificationCodeBySmsMutationVariables = Exact<{
	phone: Scalars['String']['input'];
}>;

export type SendPhoneVerificationCodeBySmsMutation = {
	__typename?: 'Mutation';
	sendPhoneVerificationCodeBySms: string;
};

export type LoginByPhoneMutationVariables = Exact<{
	phone: Scalars['String']['input'];
}>;

export type LoginByPhoneMutation = {__typename?: 'Mutation'; loginByPhone: string};

export type AnalyticsKeyStatsQueryVariables = Exact<{
	dateRange: DateRangeInput;
}>;

export type AnalyticsKeyStatsQuery = {
	__typename?: 'Query';
	analyticsKeyStats: {
		__typename?: 'AnalyticsKeyStatsResponse';
		groupedByTime: GroupByTime;
		analyticsKeyStats: Array<{
			__typename?: 'AnalyticsKeyStatsType';
			date: any;
			wbKeyCount: number;
			ozonKeyCount: number;
		}>;
	};
};

export type ActiveUsersStatsDauQueryVariables = Exact<{
	dateRange: DateRangeInput;
	device?: InputMaybe<DeviceFilter>;
}>;

export type ActiveUsersStatsDauQuery = {
	__typename?: 'Query';
	activeUsersStatsDAU: Array<{__typename?: 'ActiveUsersStatsEntity'; date: any; count: number}>;
};

export type ActiveUsersStatsMauQueryVariables = Exact<{
	dateRange: DateRangeInput;
	device?: InputMaybe<DeviceFilter>;
}>;

export type ActiveUsersStatsMauQuery = {
	__typename?: 'Query';
	activeUsersStatsMAU: Array<{__typename?: 'ActiveUsersStatsEntity'; date: any; count: number}>;
};

export type GetUtmSourceStatsQueryVariables = Exact<{
	input: UtmSourceStatsInput;
}>;

export type GetUtmSourceStatsQuery = {
	__typename?: 'Query';
	getUtmSourceStats: Array<{
		__typename?: 'UtmSourceStatsResponse';
		source: string;
		dateToShow: any;
		paymentAmount: number;
		paymentCount: number;
		registrationCount: number;
	}>;
};

export type UpdateUtmSourceMutationVariables = Exact<{
	input: UpdateUtmSourceInput;
}>;

export type UpdateUtmSourceMutation = {
	__typename?: 'Mutation';
	updateUtmSource: {
		__typename?: 'UtmSourceResponse';
		_id: string;
		source: string;
		medium?: string | null;
		campaign?: string | null;
		term?: string | null;
		content?: string | null;
		price: number;
		comment?: string | null;
		usersCount: number;
		paymentAmount: number;
		paymentCount: number;
		payersCount: number;
	};
};

export type CreateUtmSourceMutationVariables = Exact<{
	input: CreateUtmSourceInput;
}>;

export type CreateUtmSourceMutation = {
	__typename?: 'Mutation';
	createUtmSource: {
		__typename?: 'UtmSourceResponse';
		_id: string;
		source: string;
		medium?: string | null;
		campaign?: string | null;
		term?: string | null;
		content?: string | null;
		price: number;
		comment?: string | null;
		usersCount: number;
		paymentAmount: number;
		paymentCount: number;
		payersCount: number;
	};
};

export type GetUtmSourceListQueryVariables = Exact<{
	input: UtmListOptionsInput;
}>;

export type GetUtmSourceListQuery = {
	__typename?: 'Query';
	getUtmSourceList: {
		__typename?: 'UtmSourceListResponse';
		total: number;
		items?: Array<{
			__typename?: 'UtmSourceTotalStatsResponse';
			_id: string;
			price: number;
			comment?: string | null;
			root?: string | null;
			source: string;
			campaign?: string | null;
			medium?: string | null;
			term?: string | null;
			content?: string | null;
			usersCount: number;
			paymentAmount: number;
			paymentCount: number;
			payersCount: number;
			campaignStats?: Array<{
				__typename?: 'UtmCampaign';
				_id: string;
				price: number;
				comment?: string | null;
				root?: string | null;
				source: string;
				campaign?: string | null;
				medium?: string | null;
				term?: string | null;
				content?: string | null;
				usersCount: number;
				paymentAmount: number;
				paymentCount: number;
				payersCount: number;
				mediumStats?: Array<{
					__typename?: 'UtmMedium';
					_id: string;
					price: number;
					comment?: string | null;
					root?: string | null;
					source: string;
					campaign?: string | null;
					medium?: string | null;
					term?: string | null;
					content?: string | null;
					usersCount: number;
					paymentAmount: number;
					paymentCount: number;
					payersCount: number;
					contentStats?: Array<{
						__typename?: 'UtmContent';
						_id: string;
						price: number;
						comment?: string | null;
						root?: string | null;
						source: string;
						campaign?: string | null;
						medium?: string | null;
						term?: string | null;
						content?: string | null;
						usersCount: number;
						paymentAmount: number;
						paymentCount: number;
						payersCount: number;
						termStats?: Array<{
							__typename?: 'UtmTerm';
							_id: string;
							price: number;
							comment?: string | null;
							root?: string | null;
							source: string;
							campaign?: string | null;
							medium?: string | null;
							term?: string | null;
							content?: string | null;
							usersCount: number;
							paymentAmount: number;
							paymentCount: number;
							payersCount: number;
						}> | null;
					}> | null;
				}> | null;
			}> | null;
		}> | null;
	};
};

export const NotificationBaseFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'NotificationBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'NotificationType'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'view'}},
					{kind: 'Field', name: {kind: 'Name', value: 'remaining'}},
				],
			},
		},
	],
} as unknown as DocumentNode<NotificationBaseFragment, unknown>;
export const QuestionBaseFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'QuestionBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'FormQuestionResponse'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'subTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'questionType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'variants'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveAnotherVariant'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isRequired'}},
				],
			},
		},
	],
} as unknown as DocumentNode<QuestionBaseFragment, unknown>;
export const PollBaseFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'PollBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'FormTypeResponse'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'subTitle'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'questions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'QuestionBase'},
								},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isPublic'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'emails'}},
					{kind: 'Field', name: {kind: 'Name', value: 'userIds'}},
					{kind: 'Field', name: {kind: 'Name', value: 'tariffIds'}},
					{kind: 'Field', name: {kind: 'Name', value: 'userCreatedAt'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveOzonKey'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveWbKey'}},
					{kind: 'Field', name: {kind: 'Name', value: 'limit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'answeredCount'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'QuestionBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'FormQuestionResponse'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'subTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'questionType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'variants'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveAnotherVariant'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isRequired'}},
				],
			},
		},
	],
} as unknown as DocumentNode<PollBaseFragment, unknown>;
export const PermissionsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Permissions'},
			typeCondition: {
				kind: 'NamedType',
				name: {kind: 'Name', value: 'TariffPermissionsType'},
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'historyDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideBrands'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSellers'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesGroupsLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'productsGroupLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopCategories'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbNewItems'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbAllProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbPresence'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsAccounts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'searchQueriesLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideOzon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSalesHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSOHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'exportProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideExtProductHistory'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideAnalyticsBlock'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideProductsSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSeo'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesLimit'}},
				],
			},
		},
	],
} as unknown as DocumentNode<PermissionsFragment, unknown>;
export const TariffFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Tariff'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'TariffType'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price3'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price6'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price12'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isTrial'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isPublic'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
					{kind: 'Field', name: {kind: 'Name', value: 'duration'}},
					{kind: 'Field', name: {kind: 'Name', value: 'marked'}},
					{kind: 'Field', name: {kind: 'Name', value: 'color'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'permissions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'Permissions'},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Permissions'},
			typeCondition: {
				kind: 'NamedType',
				name: {kind: 'Name', value: 'TariffPermissionsType'},
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'historyDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideBrands'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSellers'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesGroupsLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'productsGroupLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopCategories'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbNewItems'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbAllProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbPresence'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsAccounts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'searchQueriesLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideOzon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSalesHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSOHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'exportProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideExtProductHistory'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideAnalyticsBlock'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideProductsSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSeo'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesLimit'}},
				],
			},
		},
	],
} as unknown as DocumentNode<TariffFragment, unknown>;
export const UserBaseFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'UserBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'UserType'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'email'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'role'}},
					{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
					{kind: 'Field', name: {kind: 'Name', value: 'phone'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'activeTariffId'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'wbApiKeys'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{kind: 'Field', name: {kind: 'Name', value: 'keyId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'name'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'paidTariffs'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'userId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'tariffId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'fromDate'}},
								{kind: 'Field', name: {kind: 'Name', value: 'toDate'}},
								{kind: 'Field', name: {kind: 'Name', value: 'totalPaid'}},
								{kind: 'Field', name: {kind: 'Name', value: 'datePaid'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isEmailVerified'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isPhoneVerified'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'subscription'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'Field', name: {kind: 'Name', value: 'toDate'}}],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UserBaseFragment, unknown>;
export const ApiCallsPopularActionsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ApiCallsPopularActions'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'fromDate'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timestamp'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'toDate'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timestamp'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'apiCallsPopularActions'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'fromDate'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'fromDate'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'toDate'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'toDate'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'action'}},
								{kind: 'Field', name: {kind: 'Name', value: 'count'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ApiCallsPopularActionsQuery, ApiCallsPopularActionsQueryVariables>;
export const ApiCallsActiveUsersDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ApiCallsActiveUsers'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'fromDate'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timestamp'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'toDate'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timestamp'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'limit'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'offset'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'apiCallsActiveUsers'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'fromDate'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'fromDate'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'toDate'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'toDate'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'limit'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'limit'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'offset'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'offset'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'totalItems'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'userId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'count'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'user'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'email'},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'createdAt',
															},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'isActive'},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ApiCallsActiveUsersQuery, ApiCallsActiveUsersQueryVariables>;
export const ApiCallsUserStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ApiCallsUserStats'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'fromDate'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timestamp'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'toDate'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timestamp'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'apiCallsUserStats'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'userId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'fromDate'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'fromDate'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'toDate'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'toDate'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'action'}},
								{kind: 'Field', name: {kind: 'Name', value: 'count'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ApiCallsUserStatsQuery, ApiCallsUserStatsQueryVariables>;
export const AllNotificationsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AllNotifications'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'AllNotificationsInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'allNotifications'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'filter'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: {kind: 'Name', value: 'NotificationBase'},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'NotificationBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'NotificationType'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'view'}},
					{kind: 'Field', name: {kind: 'Name', value: 'remaining'}},
				],
			},
		},
	],
} as unknown as DocumentNode<AllNotificationsQuery, AllNotificationsQueryVariables>;
export const CreateNotificationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CreateNotification'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'notification'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'CreateNotificationInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'createNotification'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'notification'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'notification'},
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'NotificationBase'},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'NotificationBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'NotificationType'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'view'}},
					{kind: 'Field', name: {kind: 'Name', value: 'remaining'}},
				],
			},
		},
	],
} as unknown as DocumentNode<CreateNotificationMutation, CreateNotificationMutationVariables>;
export const EditNotificationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'EditNotification'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'notification'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'EditNotificationInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'editNotification'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'notification'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'notification'},
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'NotificationBase'},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'NotificationBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'NotificationType'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'view'}},
					{kind: 'Field', name: {kind: 'Name', value: 'remaining'}},
				],
			},
		},
	],
} as unknown as DocumentNode<EditNotificationMutation, EditNotificationMutationVariables>;
export const RemoveNotificationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'RemoveNotification'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'notificationId'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'removeNotification'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'notificationId'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'notificationId'},
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'Field', name: {kind: 'Name', value: '_id'}}],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<RemoveNotificationMutation, RemoveNotificationMutationVariables>;
export const SubscriptionStatDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'SubscriptionStat'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'subscriptionStat'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'trialCount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'defaultCount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'paidCount'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SubscriptionStatQuery, SubscriptionStatQueryVariables>;
export const PaymentsStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'PaymentsStats'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dateRange'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateRangeInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'paymentsStats'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dateRange'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dateRange'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'date'}},
								{kind: 'Field', name: {kind: 'Name', value: 'priceSum'}},
								{kind: 'Field', name: {kind: 'Name', value: 'priceAmount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'priceQuartersSum'}},
								{kind: 'Field', name: {kind: 'Name', value: 'priceQuartersAmount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'priceHalfYearSum'}},
								{kind: 'Field', name: {kind: 'Name', value: 'priceHalfYearAmount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'priceYearSum'}},
								{kind: 'Field', name: {kind: 'Name', value: 'priceYearAmount'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<PaymentsStatsQuery, PaymentsStatsQueryVariables>;
export const AllPollsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AllPolls'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'PagesInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'formList'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: {kind: 'Name', value: 'PollBase'},
											},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'QuestionBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'FormQuestionResponse'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'subTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'questionType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'variants'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveAnotherVariant'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isRequired'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'PollBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'FormTypeResponse'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'subTitle'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'questions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'QuestionBase'},
								},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isPublic'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'emails'}},
					{kind: 'Field', name: {kind: 'Name', value: 'userIds'}},
					{kind: 'Field', name: {kind: 'Name', value: 'tariffIds'}},
					{kind: 'Field', name: {kind: 'Name', value: 'userCreatedAt'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveOzonKey'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveWbKey'}},
					{kind: 'Field', name: {kind: 'Name', value: 'limit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'answeredCount'}},
				],
			},
		},
	],
} as unknown as DocumentNode<AllPollsQuery, AllPollsQueryVariables>;
export const CreatePollDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CreatePoll'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'CreateFormInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'createForm'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'FragmentSpread', name: {kind: 'Name', value: 'PollBase'}},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'QuestionBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'FormQuestionResponse'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'subTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'questionType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'variants'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveAnotherVariant'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isRequired'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'PollBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'FormTypeResponse'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'subTitle'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'questions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'QuestionBase'},
								},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isPublic'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'emails'}},
					{kind: 'Field', name: {kind: 'Name', value: 'userIds'}},
					{kind: 'Field', name: {kind: 'Name', value: 'tariffIds'}},
					{kind: 'Field', name: {kind: 'Name', value: 'userCreatedAt'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveOzonKey'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveWbKey'}},
					{kind: 'Field', name: {kind: 'Name', value: 'limit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'answeredCount'}},
				],
			},
		},
	],
} as unknown as DocumentNode<CreatePollMutation, CreatePollMutationVariables>;
export const EditPollDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'EditPoll'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'EditFormInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'editForm'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'FragmentSpread', name: {kind: 'Name', value: 'PollBase'}},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'QuestionBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'FormQuestionResponse'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'subTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'questionType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'variants'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveAnotherVariant'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isRequired'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'PollBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'FormTypeResponse'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'subTitle'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'questions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'QuestionBase'},
								},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isPublic'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'emails'}},
					{kind: 'Field', name: {kind: 'Name', value: 'userIds'}},
					{kind: 'Field', name: {kind: 'Name', value: 'tariffIds'}},
					{kind: 'Field', name: {kind: 'Name', value: 'userCreatedAt'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveOzonKey'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveWbKey'}},
					{kind: 'Field', name: {kind: 'Name', value: 'limit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'answeredCount'}},
				],
			},
		},
	],
} as unknown as DocumentNode<EditPollMutation, EditPollMutationVariables>;
export const RemovePollDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'RemovePoll'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'formId'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'removeForm'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'formId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'formId'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<RemovePollMutation, RemovePollMutationVariables>;
export const ChangeFormActiveDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ChangeFormActive'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'ChangeFormActiveInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'changeFormActive'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'FragmentSpread', name: {kind: 'Name', value: 'PollBase'}},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'QuestionBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'FormQuestionResponse'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'subTitle'}},
					{kind: 'Field', name: {kind: 'Name', value: 'questionType'}},
					{kind: 'Field', name: {kind: 'Name', value: 'variants'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveAnotherVariant'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isRequired'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'PollBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'FormTypeResponse'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'title'}},
					{kind: 'Field', name: {kind: 'Name', value: 'subTitle'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'questions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'QuestionBase'},
								},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isPublic'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'emails'}},
					{kind: 'Field', name: {kind: 'Name', value: 'userIds'}},
					{kind: 'Field', name: {kind: 'Name', value: 'tariffIds'}},
					{kind: 'Field', name: {kind: 'Name', value: 'userCreatedAt'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveOzonKey'}},
					{kind: 'Field', name: {kind: 'Name', value: 'haveWbKey'}},
					{kind: 'Field', name: {kind: 'Name', value: 'limit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'answeredCount'}},
				],
			},
		},
	],
} as unknown as DocumentNode<ChangeFormActiveQuery, ChangeFormActiveQueryVariables>;
export const PromoCodesListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'PromoCodesList'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'PromoCodeListInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'promoCodesList'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'description'}},
								{kind: 'Field', name: {kind: 'Name', value: 'code'}},
								{kind: 'Field', name: {kind: 'Name', value: 'discount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'paymentType'}},
								{kind: 'Field', name: {kind: 'Name', value: 'utmSource'}},
								{kind: 'Field', name: {kind: 'Name', value: 'limitCount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'endDate'}},
								{kind: 'Field', name: {kind: 'Name', value: 'userIds'}},
								{kind: 'Field', name: {kind: 'Name', value: 'applyCount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
								{kind: 'Field', name: {kind: 'Name', value: '_id'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<PromoCodesListQuery, PromoCodesListQueryVariables>;
export const CreatePromoCodeDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CreatePromoCode'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'CreatePromoCodeInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'createPromoCode'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'Field', name: {kind: 'Name', value: 'code'}}],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>;
export const RemovePromoCodeDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'RemovePromoCode'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'removePromoCode'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'code'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<RemovePromoCodeMutation, RemovePromoCodeMutationVariables>;
export const EditPromoCodeDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'EditPromoCode'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'EditPromoCodeInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'editPromoCode'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'Field', name: {kind: 'Name', value: 'code'}}],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<EditPromoCodeMutation, EditPromoCodeMutationVariables>;
export const TariffsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Tariffs'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'tariffs'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Tariff'}},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Permissions'},
			typeCondition: {
				kind: 'NamedType',
				name: {kind: 'Name', value: 'TariffPermissionsType'},
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'historyDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideBrands'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSellers'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesGroupsLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'productsGroupLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopCategories'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbNewItems'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbAllProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbPresence'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsAccounts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'searchQueriesLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideOzon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSalesHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSOHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'exportProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideExtProductHistory'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideAnalyticsBlock'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideProductsSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSeo'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesLimit'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Tariff'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'TariffType'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price3'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price6'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price12'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isTrial'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isPublic'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
					{kind: 'Field', name: {kind: 'Name', value: 'duration'}},
					{kind: 'Field', name: {kind: 'Name', value: 'marked'}},
					{kind: 'Field', name: {kind: 'Name', value: 'color'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'permissions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'Permissions'},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<TariffsQuery, TariffsQueryVariables>;
export const ActiveTariffsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ActiveTariffs'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'activeTariffs'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Tariff'}},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Permissions'},
			typeCondition: {
				kind: 'NamedType',
				name: {kind: 'Name', value: 'TariffPermissionsType'},
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'historyDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideBrands'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSellers'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesGroupsLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'productsGroupLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopCategories'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbNewItems'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbAllProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbPresence'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsAccounts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'searchQueriesLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideOzon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSalesHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSOHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'exportProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideExtProductHistory'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideAnalyticsBlock'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideProductsSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSeo'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesLimit'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Tariff'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'TariffType'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price3'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price6'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price12'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isTrial'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isPublic'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
					{kind: 'Field', name: {kind: 'Name', value: 'duration'}},
					{kind: 'Field', name: {kind: 'Name', value: 'marked'}},
					{kind: 'Field', name: {kind: 'Name', value: 'color'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'permissions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'Permissions'},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ActiveTariffsQuery, ActiveTariffsQueryVariables>;
export const AddTariffToUserDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'AddTariffToUser'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'tariffId'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'paymentType'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'PaymentTypeEnum'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'withPayment'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'addTariffToUser'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'userId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'tariffId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'tariffId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'paymentType'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'paymentType'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'withPayment'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'withPayment'},
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'paidTariff'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'userId'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'tariffId'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'fromDate'},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'toDate'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'totalPaid'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'datePaid'},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'user'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: '_id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'email'}},
											{kind: 'Field', name: {kind: 'Name', value: 'role'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'createdAt'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'telegram'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'isActive'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'activeTariffId'},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AddTariffToUserMutation, AddTariffToUserMutationVariables>;
export const AddTariffDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'AddTariff'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'newTariffInput'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'AddTariffInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'addTariff'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'newTariffInput'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'newTariffInput'},
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Tariff'}},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Permissions'},
			typeCondition: {
				kind: 'NamedType',
				name: {kind: 'Name', value: 'TariffPermissionsType'},
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'historyDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideBrands'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSellers'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesGroupsLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'productsGroupLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopCategories'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbNewItems'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbAllProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbPresence'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsAccounts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'searchQueriesLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideOzon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSalesHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSOHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'exportProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideExtProductHistory'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideAnalyticsBlock'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideProductsSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSeo'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesLimit'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Tariff'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'TariffType'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price3'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price6'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price12'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isTrial'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isPublic'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
					{kind: 'Field', name: {kind: 'Name', value: 'duration'}},
					{kind: 'Field', name: {kind: 'Name', value: 'marked'}},
					{kind: 'Field', name: {kind: 'Name', value: 'color'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'permissions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'Permissions'},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AddTariffMutation, AddTariffMutationVariables>;
export const EditTariffDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'EditTariff'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'tariffId'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'editTariffInput'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'EditTariffInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'editTariff'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'tariffId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'tariffId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'editTariffInput'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'editTariffInput'},
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Tariff'}},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Permissions'},
			typeCondition: {
				kind: 'NamedType',
				name: {kind: 'Name', value: 'TariffPermissionsType'},
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'historyDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideBrands'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSellers'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesGroupsLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'productsGroupLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopCategories'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbNewItems'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbAllProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbPresence'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsAccounts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'searchQueriesLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideOzon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSalesHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSOHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'exportProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideExtProductHistory'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideAnalyticsBlock'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideProductsSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSeo'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesLimit'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Tariff'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'TariffType'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price3'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price6'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price12'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isTrial'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isPublic'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
					{kind: 'Field', name: {kind: 'Name', value: 'duration'}},
					{kind: 'Field', name: {kind: 'Name', value: 'marked'}},
					{kind: 'Field', name: {kind: 'Name', value: 'color'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'permissions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'Permissions'},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<EditTariffMutation, EditTariffMutationVariables>;
export const EditTariffPermissionDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'EditTariffPermission'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'historyDays'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'hideBrands'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'hideSellers'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {
						kind: 'Variable',
						name: {kind: 'Name', value: 'favoritesGroupsLimit'},
					},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'productsGroupLimit'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'hideTopProducts'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'hideTopCategories'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'favoritesSummary'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'sbSummary'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'sbTopProducts'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'sbNewItems'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'sbAllProducts'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'sbPresence'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'analyticsAccounts'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'searchQueriesLimit'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'hideOzon'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {
						kind: 'Variable',
						name: {kind: 'Name', value: 'analyticsSalesHistoryDays'},
					},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {
						kind: 'Variable',
						name: {kind: 'Name', value: 'analyticsSOHistoryDays'},
					},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'exportProducts'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {
						kind: 'Variable',
						name: {kind: 'Name', value: 'hideExtProductHistory'},
					},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'tariffId'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'hideAnalyticsBlock'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {
						kind: 'Variable',
						name: {kind: 'Name', value: 'hideProductsSummary'},
					},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'hideSeo'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'favoritesLimit'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'editTariffPermission'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'historyDays'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'historyDays'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'hideBrands'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'hideBrands'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'hideSellers'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'hideSellers'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'favoritesGroupsLimit'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'favoritesGroupsLimit'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'productsGroupLimit'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'productsGroupLimit'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'hideTopProducts'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'hideTopProducts'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'hideTopCategories'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'hideTopCategories'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'favoritesSummary'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'favoritesSummary'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'sbSummary'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'sbSummary'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'sbTopProducts'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'sbTopProducts'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'sbNewItems'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'sbNewItems'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'sbAllProducts'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'sbAllProducts'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'sbPresence'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'sbPresence'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'analyticsAccounts'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'analyticsAccounts'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'searchQueriesLimit'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'searchQueriesLimit'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'hideOzon'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'hideOzon'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'analyticsSalesHistoryDays'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'analyticsSalesHistoryDays'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'analyticsSOHistoryDays'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'analyticsSOHistoryDays'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'exportProducts'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'exportProducts'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'hideExtProductHistory'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'hideExtProductHistory'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'tariffId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'tariffId'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'hideAnalyticsBlock'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'hideAnalyticsBlock'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'hideProductsSummary'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'hideProductsSummary'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'hideSeo'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'hideSeo'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'favoritesLimit'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'favoritesLimit'},
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'FragmentSpread', name: {kind: 'Name', value: 'Tariff'}},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Permissions'},
			typeCondition: {
				kind: 'NamedType',
				name: {kind: 'Name', value: 'TariffPermissionsType'},
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'historyDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideBrands'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSellers'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesGroupsLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'productsGroupLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideTopCategories'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbTopProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbNewItems'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbAllProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'sbPresence'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsAccounts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'searchQueriesLimit'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideOzon'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSalesHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'analyticsSOHistoryDays'}},
					{kind: 'Field', name: {kind: 'Name', value: 'exportProducts'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideExtProductHistory'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideAnalyticsBlock'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideProductsSummary'}},
					{kind: 'Field', name: {kind: 'Name', value: 'hideSeo'}},
					{kind: 'Field', name: {kind: 'Name', value: 'favoritesLimit'}},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'Tariff'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'TariffType'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'description'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price3'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price6'}},
					{kind: 'Field', name: {kind: 'Name', value: 'price12'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isTrial'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isPublic'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
					{kind: 'Field', name: {kind: 'Name', value: 'duration'}},
					{kind: 'Field', name: {kind: 'Name', value: 'marked'}},
					{kind: 'Field', name: {kind: 'Name', value: 'color'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'permissions'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'Permissions'},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<EditTariffPermissionMutation, EditTariffPermissionMutationVariables>;
export const AddFreeDaysToTariffDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'AddFreeDaysToTariff'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'FreeDaysInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'addFreeDaysToTariff'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AddFreeDaysToTariffMutation, AddFreeDaysToTariffMutationVariables>;
export const LoginDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'Login'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'loginByEmail'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'email'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'password'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'asAdmin'},
								value: {kind: 'BooleanValue', value: true},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'user'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'role'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'token'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'Logout'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [{kind: 'Field', name: {kind: 'Name', value: 'logout'}}],
			},
		},
	],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Me'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'me'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: '_id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'email'}},
								{kind: 'Field', name: {kind: 'Name', value: 'role'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
export const UsersDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Users'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserFilter'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'skip'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'limit'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'search'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'sort'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserSort'}},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'sortDirection'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'SortDirection'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'users'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'filter'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'skip'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'skip'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'limit'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'limit'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'search'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'search'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'sort'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'sort'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'sortDirection'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'sortDirection'},
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: {kind: 'Name', value: 'UserBase'},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'UserBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'UserType'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'email'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'role'}},
					{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
					{kind: 'Field', name: {kind: 'Name', value: 'phone'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'activeTariffId'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'wbApiKeys'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{kind: 'Field', name: {kind: 'Name', value: 'keyId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'name'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'paidTariffs'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'userId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'tariffId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'fromDate'}},
								{kind: 'Field', name: {kind: 'Name', value: 'toDate'}},
								{kind: 'Field', name: {kind: 'Name', value: 'totalPaid'}},
								{kind: 'Field', name: {kind: 'Name', value: 'datePaid'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isEmailVerified'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isPhoneVerified'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'subscription'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'Field', name: {kind: 'Name', value: 'toDate'}}],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const EditUserDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'EditUser'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'isActive'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'isEmailVerified'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'isPhoneVerified'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'editUser'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'isActive'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'isActive'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'email'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'isEmailVerified'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'isEmailVerified'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'phone'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'isPhoneVerified'},
								value: {
									kind: 'Variable',
									name: {kind: 'Name', value: 'isPhoneVerified'},
								},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'name'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'FragmentSpread', name: {kind: 'Name', value: 'UserBase'}},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'UserBase'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'UserType'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: '_id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'email'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'role'}},
					{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
					{kind: 'Field', name: {kind: 'Name', value: 'phone'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
					{kind: 'Field', name: {kind: 'Name', value: 'activeTariffId'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'wbApiKeys'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'key'}},
								{kind: 'Field', name: {kind: 'Name', value: 'keyId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'name'}},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'paidTariffs'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'userId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'tariffId'}},
								{kind: 'Field', name: {kind: 'Name', value: 'fromDate'}},
								{kind: 'Field', name: {kind: 'Name', value: 'toDate'}},
								{kind: 'Field', name: {kind: 'Name', value: 'totalPaid'}},
								{kind: 'Field', name: {kind: 'Name', value: 'datePaid'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'isEmailVerified'}},
					{kind: 'Field', name: {kind: 'Name', value: 'isPhoneVerified'}},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'subscription'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'Field', name: {kind: 'Name', value: 'toDate'}}],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<EditUserMutation, EditUserMutationVariables>;
export const RemoveUserDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'RemoveUser'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'removeUser'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'userId'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'Field', name: {kind: 'Name', value: '_id'}}],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<RemoveUserMutation, RemoveUserMutationVariables>;
export const UsersStatRespDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'UsersStatResp'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'usersStat'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'registered'}},
								{kind: 'Field', name: {kind: 'Name', value: 'phoneVerified'}},
								{kind: 'Field', name: {kind: 'Name', value: 'emailVerified'}},
								{kind: 'Field', name: {kind: 'Name', value: 'notActivated'}},
								{kind: 'Field', name: {kind: 'Name', value: 'ozonKeys'}},
								{kind: 'Field', name: {kind: 'Name', value: 'wbKeys'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'userWithAnalyticsKey'},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'extStats'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'clickedFromExtNotificationCount'},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UsersStatRespQuery, UsersStatRespQueryVariables>;
export const UserTokenDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'UserToken'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'userToken'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UserTokenQuery, UserTokenQueryVariables>;
export const RegistrationStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'RegistrationStats'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dateRange'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateRangeInput'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserTypeFilter'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'registrationStats'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dateRange'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dateRange'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'type'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'groupedByTime'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'registrationStats'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'date'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'newRegistrations'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'emailsConfirmed'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'phonesConfirmed'},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<RegistrationStatsQuery, RegistrationStatsQueryVariables>;
export const ConfirmPhoneByCodeDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'ConfirmPhoneByCode'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'confirmPhoneByCode'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'code'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'phone'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{kind: 'Field', name: {kind: 'Name', value: 'token'}}],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ConfirmPhoneByCodeMutation, ConfirmPhoneByCodeMutationVariables>;
export const SendPhoneVerificationCodeBySmsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'SendPhoneVerificationCodeBySms'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'sendPhoneVerificationCodeBySms'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'phone'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	SendPhoneVerificationCodeBySmsMutation,
	SendPhoneVerificationCodeBySmsMutationVariables
>;
export const LoginByPhoneDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'LoginByPhone'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'loginByPhone'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'phone'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'asAdmin'},
								value: {kind: 'BooleanValue', value: true},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<LoginByPhoneMutation, LoginByPhoneMutationVariables>;
export const AnalyticsKeyStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'AnalyticsKeyStats'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dateRange'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateRangeInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'analyticsKeyStats'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dateRange'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dateRange'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'groupedByTime'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'analyticsKeyStats'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'date'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'wbKeyCount'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'ozonKeyCount'},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AnalyticsKeyStatsQuery, AnalyticsKeyStatsQueryVariables>;
export const ActiveUsersStatsDauDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ActiveUsersStatsDAU'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dateRange'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateRangeInput'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'device'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'DeviceFilter'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: {kind: 'Name', value: 'activeUsersStatsDAU'},
						name: {kind: 'Name', value: 'activeUsersStat'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dateRange'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dateRange'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'device'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'device'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'date'}},
								{kind: 'Field', name: {kind: 'Name', value: 'count'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ActiveUsersStatsDauQuery, ActiveUsersStatsDauQueryVariables>;
export const ActiveUsersStatsMauDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'ActiveUsersStatsMAU'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'dateRange'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateRangeInput'}},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'device'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'DeviceFilter'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: {kind: 'Name', value: 'activeUsersStatsMAU'},
						name: {kind: 'Name', value: 'activeUsersStat'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'dateRange'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'dateRange'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'monthly'},
								value: {kind: 'BooleanValue', value: true},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'device'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'device'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'date'}},
								{kind: 'Field', name: {kind: 'Name', value: 'count'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ActiveUsersStatsMauQuery, ActiveUsersStatsMauQueryVariables>;
export const GetUtmSourceStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GetUtmSourceStats'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'UtmSourceStatsInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'getUtmSourceStats'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'source'}},
								{kind: 'Field', name: {kind: 'Name', value: 'dateToShow'}},
								{kind: 'Field', name: {kind: 'Name', value: 'paymentAmount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'paymentCount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'registrationCount'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetUtmSourceStatsQuery, GetUtmSourceStatsQueryVariables>;
export const UpdateUtmSourceDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'UpdateUtmSource'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'UpdateUtmSourceInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'updateUtmSource'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: '_id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'source'}},
								{kind: 'Field', name: {kind: 'Name', value: 'medium'}},
								{kind: 'Field', name: {kind: 'Name', value: 'campaign'}},
								{kind: 'Field', name: {kind: 'Name', value: 'term'}},
								{kind: 'Field', name: {kind: 'Name', value: 'content'}},
								{kind: 'Field', name: {kind: 'Name', value: 'price'}},
								{kind: 'Field', name: {kind: 'Name', value: 'comment'}},
								{kind: 'Field', name: {kind: 'Name', value: 'usersCount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'paymentAmount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'paymentCount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'payersCount'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UpdateUtmSourceMutation, UpdateUtmSourceMutationVariables>;
export const CreateUtmSourceDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CreateUtmSource'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'CreateUtmSourceInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'createUtmSource'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: '_id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'source'}},
								{kind: 'Field', name: {kind: 'Name', value: 'medium'}},
								{kind: 'Field', name: {kind: 'Name', value: 'campaign'}},
								{kind: 'Field', name: {kind: 'Name', value: 'term'}},
								{kind: 'Field', name: {kind: 'Name', value: 'content'}},
								{kind: 'Field', name: {kind: 'Name', value: 'price'}},
								{kind: 'Field', name: {kind: 'Name', value: 'comment'}},
								{kind: 'Field', name: {kind: 'Name', value: 'usersCount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'paymentAmount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'paymentCount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'payersCount'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CreateUtmSourceMutation, CreateUtmSourceMutationVariables>;
export const GetUtmSourceListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GetUtmSourceList'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'UtmListOptionsInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'getUtmSourceList'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: '_id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'price'}},
											{kind: 'Field', name: {kind: 'Name', value: 'comment'}},
											{kind: 'Field', name: {kind: 'Name', value: 'root'}},
											{kind: 'Field', name: {kind: 'Name', value: 'source'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'campaign'},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'medium'}},
											{kind: 'Field', name: {kind: 'Name', value: 'term'}},
											{kind: 'Field', name: {kind: 'Name', value: 'content'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'usersCount'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'paymentAmount'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'paymentCount'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'payersCount'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'campaignStats'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: {kind: 'Name', value: '_id'},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'price'},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'comment'},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'root'},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'source'},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'campaign'},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'medium'},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'term'},
														},
														{
															kind: 'Field',
															name: {kind: 'Name', value: 'content'},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'usersCount',
															},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'paymentAmount',
															},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'paymentCount',
															},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'payersCount',
															},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'mediumStats',
															},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: '_id',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'price',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'comment',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'root',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'source',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'campaign',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'medium',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'term',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'content',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'usersCount',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'paymentAmount',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'paymentCount',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'payersCount',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'contentStats',
																		},
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: '_id',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'price',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'comment',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'root',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'source',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'campaign',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'medium',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'term',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'content',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'usersCount',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'paymentAmount',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'paymentCount',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'payersCount',
																					},
																				},
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'termStats',
																					},
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections:
																							[
																								{
																									kind: 'Field',
																									name: {
																										kind: 'Name',
																										value: '_id',
																									},
																								},
																								{
																									kind: 'Field',
																									name: {
																										kind: 'Name',
																										value: 'price',
																									},
																								},
																								{
																									kind: 'Field',
																									name: {
																										kind: 'Name',
																										value: 'comment',
																									},
																								},
																								{
																									kind: 'Field',
																									name: {
																										kind: 'Name',
																										value: 'root',
																									},
																								},
																								{
																									kind: 'Field',
																									name: {
																										kind: 'Name',
																										value: 'source',
																									},
																								},
																								{
																									kind: 'Field',
																									name: {
																										kind: 'Name',
																										value: 'campaign',
																									},
																								},
																								{
																									kind: 'Field',
																									name: {
																										kind: 'Name',
																										value: 'medium',
																									},
																								},
																								{
																									kind: 'Field',
																									name: {
																										kind: 'Name',
																										value: 'term',
																									},
																								},
																								{
																									kind: 'Field',
																									name: {
																										kind: 'Name',
																										value: 'content',
																									},
																								},
																								{
																									kind: 'Field',
																									name: {
																										kind: 'Name',
																										value: 'usersCount',
																									},
																								},
																								{
																									kind: 'Field',
																									name: {
																										kind: 'Name',
																										value: 'paymentAmount',
																									},
																								},
																								{
																									kind: 'Field',
																									name: {
																										kind: 'Name',
																										value: 'paymentCount',
																									},
																								},
																								{
																									kind: 'Field',
																									name: {
																										kind: 'Name',
																										value: 'payersCount',
																									},
																								},
																							],
																					},
																				},
																			],
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetUtmSourceListQuery, GetUtmSourceListQueryVariables>;
