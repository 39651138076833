import React from 'react';
import {
	Autocomplete as AutocompleteMui,
	AutocompleteProps,
	CircularProgress,
	TextField,
	TextFieldProps,
} from '@mui/material';

interface IAutocomplete extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> {
	TextFieldProps?: TextFieldProps;
}

const Autocomplete: React.FC<IAutocomplete> = ({loading, TextFieldProps, ...props}) => (
	<AutocompleteMui
		{...props}
		multiple
		loading={loading}
		renderInput={(params) => (
			<TextField
				{...params}
				{...TextFieldProps}
				InputProps={{
					...params.InputProps,
					...TextFieldProps?.InputProps,
					endAdornment: (
						<React.Fragment>
							{loading ? <CircularProgress color="inherit" size={20} /> : null}
							{params.InputProps.endAdornment}
							{TextFieldProps?.InputProps?.endAdornment}
						</React.Fragment>
					),
				}}
			/>
		)}
	/>
);

export default Autocomplete;
