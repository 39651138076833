import React, {useState} from 'react';
import {
	ContentBlock,
	DateRangeTemplate,
	SelectRangeDate,
	VisualChart,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {DateRange} from '@mui/lab/DateRangePicker';
import {subDays} from 'date-fns';

import {usePaymentsStats} from 'api/payments';

const PaymentBlock: React.FC = () => {
	const [dateRangeValue, setDateRangeValue] = useState<DateRange<Date>>([
		subDays(new Date(), 30),
		new Date(),
	]);

	const onDateRangeChange = (newValue: DateRange<Date>) => {
		setDateRangeValue(newValue);
	};

	const {data, loading, error} = usePaymentsStats({
		dateRange: {
			fromDate: dateRangeValue[0]?.getTime(),
			toDate: dateRangeValue[1]?.getTime(),
		},
	});

	return (
		<ContentBlock
			label={'Платный тариф'}
			rightHeaderBlock={
				<SelectRangeDate
					onChange={onDateRangeChange}
					dateRangeTemplate={DateRangeTemplate.month}
				/>
			}
			loading={loading}
			error={!!error}
		>
			<VisualChart
				labels={data.labels}
				datasets={data.datasets}
				loading={loading}
				moveTooltip={100}
			/>
		</ContentBlock>
	);
};

export default PaymentBlock;
