import React from 'react';
import {CardContent, DialogContent, DialogProps} from '@mui/material';
import {omit} from 'lodash';

import Modal from 'components/common/ui/Modal';
import {useLayoutContext} from 'layouts/LayoutContext';
import NotificationForm from 'components/notifs/NotificationForm';
import {useAllNotificationsLazyQuery, useCreateNotificationMutation} from 'api/notifs';

const CreateNotifModal: React.FC<DialogProps> = ({open, onClose}) => {
	const {notifsVariables} = useLayoutContext();
	const [createNotif, {loading, error}] = useCreateNotificationMutation();
	const [allPollsQuery] = useAllNotificationsLazyQuery();

	return (
		<Modal title="Создание оповещения" maxWidth={'xl'} open={open} onClose={onClose}>
			<DialogContent>
				<CardContent>
					<NotificationForm
						loading={loading}
						error={error}
						onSubmit={(values) => {
							createNotif({
								variables: {
									notification: {
										...omit(values, '_id', 'views'),
									},
								},
							}).then(() => {
								allPollsQuery({variables: notifsVariables});
								onClose && onClose('', 'backdropClick');
							});
						}}
					/>
				</CardContent>
			</DialogContent>
		</Modal>
	);
};

export default CreateNotifModal;
