import React from 'react';
import classNames from 'classnames';
import {Box, BoxProps} from '@mui/material';

import css from './TextError.pcss';

interface ITextError extends BoxProps {
	small?: boolean;
}

const TextError: React.FC<ITextError> = ({children, small, ...props}) => (
	<Box {...props} className={classNames(props.className, css.error, {[css.small]: small})}>
		{children}
	</Box>
);

export default TextError;
