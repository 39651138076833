import {useQuery} from '@apollo/client';

import apolloClient from 'utils/apolloClient';
import {
	ApiCallsActiveUsersQuery,
	ApiCallsActiveUsersQueryVariables,
	ApiCallsPopularActionsQueryVariables,
	ApiCallsUserStatsQueryVariables,
} from '../gqlApp/graphql';
import {graphql} from '../gqlApp';

const API_CALLS_POPULAR_ACTIONS_QUERY = graphql(/* GraphQL */ `
	query ApiCallsPopularActions($fromDate: Timestamp!, $toDate: Timestamp!) {
		apiCallsPopularActions(fromDate: $fromDate, toDate: $toDate) {
			action
			count
		}
	}
`);

export const useApiCallsPopularActions = (variables: ApiCallsPopularActionsQueryVariables) =>
	useQuery(API_CALLS_POPULAR_ACTIONS_QUERY, {variables});

const API_CALLS_ACTIVE_USERS_QUERY = graphql(`
	query ApiCallsActiveUsers(
		$fromDate: Timestamp!
		$toDate: Timestamp!
		$limit: Int
		$offset: Int
	) {
		apiCallsActiveUsers(fromDate: $fromDate, toDate: $toDate, limit: $limit, offset: $offset) {
			totalItems
			items {
				userId
				count
				user {
					email
					createdAt
					isActive
				}
			}
		}
	}
`);

export const apiCallsActiveUsers = (variables: ApiCallsActiveUsersQueryVariables) =>
	apolloClient.query<ApiCallsActiveUsersQuery, ApiCallsActiveUsersQueryVariables>({
		query: API_CALLS_ACTIVE_USERS_QUERY,
		variables,
	});

const API_CALLS_USER_STATS_QUERY = graphql(`
	query ApiCallsUserStats($userId: String!, $fromDate: Timestamp!, $toDate: Timestamp!) {
		apiCallsUserStats(userId: $userId, fromDate: $fromDate, toDate: $toDate) {
			action
			count
		}
	}
`);

export const useApiCallsUserStats = (variables: ApiCallsUserStatsQueryVariables) =>
	useQuery(API_CALLS_USER_STATS_QUERY, {
		variables,
	});
