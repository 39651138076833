import React, {ChangeEvent, useEffect} from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {values} from 'lodash';
import {Close} from '@mui/icons-material';

import TextError from 'components/common/ui/TextError';
import {EditFormQuestionInput, QuestionTypeEnum} from '../../gqlApp/graphql';

interface IQuestionForm {
	onChange: (Question: EditFormQuestionInput) => void;
	question: IInitialValues;
	variantsError: boolean;
}

export interface IQuestion extends Omit<EditFormQuestionInput, '__typename'> {}

interface IInitialValues extends IQuestion {}

const QuestionForm: React.FC<IQuestionForm> = ({onChange, variantsError, question}) => {
	const formik = useFormik({
		initialValues: question,
		onSubmit: () => {},
	});

	useEffect(() => {
		onChange(formik.values);
		// eslint-disable-next-line
	}, [formik.values]);

	const handleFieldChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		formik.handleChange(event);
	};

	const labelByType = (type: QuestionTypeEnum) => {
		switch (type) {
			case QuestionTypeEnum.Radio:
				return 'Одиночный';

			case QuestionTypeEnum.Checkbox:
				return 'Множественный';

			default:
				return 'Текстовым полем';
		}
	};

	return (
		<Card variant={'outlined'}>
			<CardContent>
				<Grid container spacing={2} direction={'column'}>
					<Grid item>
						<TextField
							name="title"
							label="Название вопроса"
							multiline
							variant={'outlined'}
							fullWidth
							onChange={handleFieldChange}
							defaultValue={formik.values.title}
						/>
					</Grid>

					{/*<Grid item>
						<TextField
							name="subTitle"
							label="Подзаголовок вопроса"
							variant={'outlined'}
							fullWidth
							multiline
							onChange={handleFieldChange}
							defaultValue={formik.values.subTitle}
						/>
					</Grid>*/}

					<Grid item>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Тип вариантов</InputLabel>
							<Select
								name="questionType"
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={formik.values.questionType}
								label="Тип вариантов"
								onChange={formik.handleChange}
							>
								{values(QuestionTypeEnum).map((value) => (
									<MenuItem value={value}>{labelByType(value)}</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item>
						<FormControlLabel
							control={
								<Checkbox
									checked={!!formik.values.isRequired}
									onChange={(e) =>
										formik.setFieldValue(e.target.name, e.target.checked)
									}
									name="isRequired"
								/>
							}
							label="Обязательный ответ"
						/>

						<FormControlLabel
							control={
								<Checkbox
									checked={!!formik.values.haveAnotherVariant}
									onChange={(e) =>
										formik.setFieldValue(e.target.name, e.target.checked)
									}
									name="haveAnotherVariant"
								/>
							}
							label="Можно ответить самому"
						/>
					</Grid>

					{formik.values.questionType !== QuestionTypeEnum.Text && (
						<Grid item container spacing={2}>
							<Grid item xs={12}>
								{(() => {
									if (variantsError && !formik.values.variants?.length) {
										return (
											<TextError
												small
												textAlign={'center'}
												margin={'0 0 10px'}
											>
												Добавьте варианты
											</TextError>
										);
									}

									return formik.values.variants?.map((variant, i) => (
										<React.Fragment key={i}>
											<TextField
												variant={'standard'}
												fullWidth
												placeholder="Введите вариант"
												error={
													variantsError &&
													!formik.values.variants![i].trim()
												}
												helperText={
													variantsError &&
													!formik.values.variants![i].trim() &&
													'Введите вариант'
												}
												onChange={(e) => {
													const newVariants = formik.values.variants
														? [...formik.values.variants]
														: [];
													newVariants[i] = e.target.value;
													formik.setFieldValue('variants', newVariants);
												}}
												multiline
												defaultValue={variant}
												InputProps={{
													endAdornment: (
														<IconButton
															onClick={() => {
																const newVariants = formik.values
																	.variants
																	? [
																			...formik.values
																				.variants,
																	  ].filter((_, ii) => ii !== i)
																	: [];

																formik.setFieldValue(
																	'variants',
																	[],
																);

																setTimeout(() => {
																	formik.setFieldValue(
																		'variants',
																		newVariants,
																	);
																});
															}}
														>
															<Close fontSize={'small'} />
														</IconButton>
													),
												}}
											/>

											<Box style={{height: 10}} />
										</React.Fragment>
									));
								})()}

								<Box textAlign={'center'}>
									<Button
										onClick={() =>
											formik.setFieldValue('variants', [
												...(formik.values.variants || []),
												'',
											])
										}
									>
										Добавить ответ
									</Button>
								</Box>
							</Grid>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

export default QuestionForm;
