import React from 'react';
import {Alert, AlertProps, Snackbar as SnackbarMui, SnackbarProps} from '@mui/material';

interface ISnackbar {
	message: React.ReactNode;
	alertProps: AlertProps;
}

const Snackbar: React.FC<ISnackbar & SnackbarProps> = ({alertProps, ...props}) => (
	<SnackbarMui
		autoHideDuration={5000}
		anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
		{...props}
	>
		<Alert severity="error" {...alertProps}>
			{props.message}
		</Alert>
	</SnackbarMui>
);

export default Snackbar;
