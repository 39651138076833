import appConsts from 'constants/appConsts';

export const getToken = () =>
	localStorage.getItem(appConsts.AUTH_TOKEN) || sessionStorage.getItem(appConsts.AUTH_TOKEN);

export const removeToken = () => {
	localStorage.removeItem(appConsts.AUTH_TOKEN);
	sessionStorage.removeItem(appConsts.AUTH_TOKEN);
};

export const setToken = (token: string, remember: boolean = true) => {
	removeToken();

	const storage = remember ? localStorage : sessionStorage;
	storage.setItem(appConsts.AUTH_TOKEN, token);
};

export function validateEmail(email: string) {
	const pattern =
		// eslint-disable-next-line max-len
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return pattern.test(String(email).toLowerCase());
}

export function validatePhone(phone: string) {
	return /^\d{5,}$/.test(phone);
}
