import React from 'react';
import {CardContent, DialogContent, DialogProps} from '@mui/material';
import {omitDeep} from '@apollo/client/utilities';
import {omit} from 'lodash';

import PollForm from 'components/polls/PollForm';
import Modal from 'components/common/ui/Modal';
import {useEditPollMutation} from 'api/polls';
import {FormTypeResponse} from '../../gqlApp/graphql';

interface IEditPollModal {
	poll: FormTypeResponse;
}

const EditPollModal: React.FC<IEditPollModal & DialogProps> = ({poll, ...props}) => {
	const [editPoll, {loading, error}] = useEditPollMutation();

	return (
		<Modal
			title="Редактирование опроса"
			maxWidth={'xl'}
			open={props.open}
			onClose={props.onClose}
		>
			<DialogContent>
				<CardContent>
					<PollForm
						poll={poll}
						loading={loading}
						error={error}
						onSubmit={(values) => {
							editPoll({
								variables: {
									input: omit(
										omitDeep(
											{
												...values,
												isPublic: !!values.isPublic,
											},
											'__typename',
										),
										'answeredCount',
									),
								},
							}).then(() => props.onClose && props.onClose({}, 'backdropClick'));
						}}
					/>
				</CardContent>
			</DialogContent>
		</Modal>
	);
};

export default EditPollModal;
