import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {format, subDays} from 'date-fns';
import {Link} from 'react-router-dom';
import {ApolloError} from '@apollo/client';
import {DateRange} from '@mui/lab/DateRangePicker';
import {
	ContentBlock,
	DateRangeTemplate,
	Paginate,
	SelectRangeDate,
	TableAdaptive,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {ITableColumn} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';

import routeUrls from 'constants/routeUrls';
import {apiCallsActiveUsers} from 'api/calls';
import {ApiCallsActiveUsersQuery} from '../../../gqlApp/graphql';

export interface ICallsActiveUsersTableRow {
	email: React.ReactNode;
	createdAt: number;
	isActive: string;
	count: number;
}

const columns: ITableColumn[] = [
	{name: 'email', title: 'E-mail'},
	{
		name: 'createdAt',
		title: 'Зарегистрирован',
		format: (value) => <>{value ? format(value as number, 'dd.MM.yyyy') : ''}</>,
	},
	{name: 'isActive', title: 'Активен'},
	{name: 'count', title: 'Кол-во вызовов'},
];

const CallsActiveUsersTable: React.FC = () => {
	const [value, setValue] = useState<DateRange<Date>>([subDays(new Date(), 30), new Date()]);

	const onChangeDate = (newValue: DateRange<Date>) => {
		setValue(newValue);
	};

	const [totalCount, setTotalCount] = useState(0);
	const [pageSize] = useState(30);
	const [currentPage, setCurrentPage] = useState(0);
	const pagesCount = useMemo(() => Math.ceil(totalCount / pageSize), [pageSize, totalCount]);

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<ApiCallsActiveUsersQuery | undefined>(undefined);

	const [error, setError] = useState<ApolloError>();
	useEffect(() => {
		setTotalCount(data?.apiCallsActiveUsers.totalItems || 0);
	}, [data]);

	const getItems = useCallback(() => {
		setLoading(true);

		(async () => {
			await apiCallsActiveUsers({
				fromDate: value[0]?.getTime(),
				toDate: value[1]?.getTime(),
				limit: pageSize,
				offset: currentPage * pageSize,
			})
				.then(({data: innerData}) => {
					setData(innerData);
					setLoading(false);
				})
				.catch((e) => {
					setError(e);
					setLoading(false);
				});
		})();
	}, [currentPage, pageSize, value]);

	useEffect(() => {
		getItems();
	}, [getItems]);

	const rows: ICallsActiveUsersTableRow[] = useMemo(() => {
		if (!data) {
			return [];
		}

		return data.apiCallsActiveUsers.items.map(({count, userId, user}) => ({
			id: userId,
			email: (
				<p>
					<Link to={routeUrls.API_CALLS_USER_STATS.replace(':userId', userId)}>
						{user ? user.email : 'Неавторизованный'}
					</Link>
				</p>
			),
			createdAt: user && user.createdAt,
			isActive: user ? (user.isActive ? 'Да' : 'Нет') : '',
			count,
		}));
	}, [data]);

	return (
		<ContentBlock
			loading={loading}
			error={!!error}
			rightHeaderBlock={
				<SelectRangeDate
					onChange={onChangeDate}
					dateRangeTemplate={DateRangeTemplate.month}
				/>
			}
		>
			<TableAdaptive rows={rows} columns={columns} />

			{pagesCount > 1 && (
				<>
					<br />

					<Paginate
						page={currentPage + 1}
						count={pagesCount}
						onChange={(_, page) => setCurrentPage(page - 1)}
					/>

					<br />
				</>
			)}
		</ContentBlock>
	);
};

export default CallsActiveUsersTable;
