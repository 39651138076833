import React, {useMemo} from 'react';
import {GeneralStatisticsBlockProps} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import RegistrationChartBlock from 'components/dashboard/RegistrationChartBlock';
import DauChartBlock from 'components/dashboard/DauChartBlock';
import MauChartBlock from 'components/dashboard/MauChartBlock';
import {useUsersStatQuery} from 'api/users';
import AppWrapper from 'components/common/AppWrapper';
import {UserFilter} from '../../gqlApp/graphql';

export enum UserFilterDisplayEnum {
	All = 'Всего пользователей',
	WithPhoneVerified = 'Подтвердили телефон',
	WithEmailVerified = 'Подтвердили email',
}

const DashboardPage: React.FC = () => {
	const {data: usersStatData, loading} = useUsersStatQuery();

	const blocks: GeneralStatisticsBlockProps[] = useMemo(
		() => [
			{
				label: UserFilterDisplayEnum.All,
				value: usersStatData?.usersStat.registered,
				loading,
			},
			{
				label: UserFilterDisplayEnum[UserFilter.WithPhoneVerified],
				value: usersStatData?.usersStat.phoneVerified,
				loading,
			},
			{
				label: UserFilterDisplayEnum[UserFilter.WithEmailVerified],
				value: usersStatData?.usersStat.emailVerified,
				loading,
			},
			{
				label: 'Кликов в уведомлении о расширении',
				value: usersStatData?.extStats.clickedFromExtNotificationCount,
				loading,
			},
		],
		[loading, usersStatData],
	);

	return (
		<AppWrapper title={'Главная'} blocksStat={blocks}>
			<RegistrationChartBlock />

			<DauChartBlock />

			<MauChartBlock />
		</AppWrapper>
	);
};

export default DashboardPage;
