import {useQuery, useMutation, useLazyQuery} from '@apollo/client';
import format from 'date-fns/format';
import sortBy from 'lodash/sortBy';
import {useMemo} from 'react';
import ru from 'date-fns/locale/ru';
import {TooltipItem} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {formatDateGroupByTime} from 'utils/charts';
import {removeToken, setToken} from 'utils/auth';
import apolloClient from 'utils/apolloClient';
import {graphql} from '../gqlApp';
import {
	ActiveUsersStatsDauQuery,
	ActiveUsersStatsMauQuery,
	ActiveUsersStatsMauQueryVariables,
	AnalyticsKeyStatsQuery,
	AnalyticsKeyStatsQueryVariables,
	LoginMutation,
	LoginMutationVariables,
	LogoutMutation,
	RegistrationStatsQuery,
	RegistrationStatsQueryVariables,
	UsersQueryVariables,
	UserTokenQuery,
	UserTokenQueryVariables,
} from '../gqlApp/graphql';

graphql(`
	fragment UserBase on UserType {
		_id
		email
		name
		role
		createdAt
		phone
		isActive
		activeTariffId
		wbApiKeys {
			key
			keyId
			name
		}
		paidTariffs {
			userId
			tariffId
			fromDate
			toDate
			totalPaid
			datePaid
		}
		isEmailVerified
		isPhoneVerified
		subscription {
			toDate
		}
	}
`);

const LOGIN_MUTATION = graphql(`
	mutation Login($email: String!, $password: String!) {
		loginByEmail(email: $email, password: $password, asAdmin: true) {
			user {
				role
			}
			token
		}
	}
`);

export const loginMutation = (variables: LoginMutationVariables) =>
	apolloClient.mutate<LoginMutation, LoginMutationVariables>({
		variables,
		mutation: LOGIN_MUTATION,
		update(_, {data}) {
			if (
				data &&
				data.loginByEmail &&
				data.loginByEmail.token &&
				data.loginByEmail.user.role === 'Admin'
			) {
				setToken(data.loginByEmail.token);
			}
		},
	});

const LOGOUT_MUTATION = graphql(`
	mutation Logout {
		logout
	}
`);

export const logoutMutation = () =>
	apolloClient.mutate<LogoutMutation, {}>({
		mutation: LOGOUT_MUTATION,
		update() {
			removeToken();
		},
	});

export const ME_QUERY = graphql(`
	query Me {
		me {
			_id
			email
			role
		}
	}
`);

export const useMeQuery = () => useQuery(ME_QUERY, {fetchPolicy: 'cache-first'});

const USERS_QUERY = graphql(`
	query Users(
		$filter: UserFilter
		$skip: Float
		$limit: Float
		$search: String
		$sort: UserSort
		$sortDirection: SortDirection
	) {
		users(
			filter: $filter
			skip: $skip
			limit: $limit
			search: $search
			sort: $sort
			sortDirection: $sortDirection
		) {
			total
			items {
				...UserBase
			}
		}
	}
`);

export const useUsersQuery = (variables?: UsersQueryVariables) =>
	useQuery(USERS_QUERY, {variables});

export const useUsersLazyQuery = () => useLazyQuery(USERS_QUERY);

const EDIT_USER_MUTATION = graphql(`
	mutation EditUser(
		$id: ID!
		$isActive: Boolean!
		$email: String!
		$isEmailVerified: Boolean!
		$phone: String!
		$isPhoneVerified: Boolean!
		$name: String!
	) {
		editUser(
			id: $id
			isActive: $isActive
			email: $email
			isEmailVerified: $isEmailVerified
			phone: $phone
			isPhoneVerified: $isPhoneVerified
			name: $name
		) {
			...UserBase
		}
	}
`);

export const useEditUserMutation = () =>
	useMutation(EDIT_USER_MUTATION, {
		update: (cache, {data}) => {
			if (!data) {
				return;
			}

			const {editUser} = data;
			const users = cache.readQuery({
				query: USERS_QUERY,
			});

			if (!users) {
				return;
			}

			cache.writeQuery({
				query: USERS_QUERY,
				data: {
					users: {
						...users.users,
						items: users.users.items.map((item) =>
							item._id === editUser._id ? editUser : item,
						),
					},
				},
			});
		},
	});

const REMOVE_USER_MUTATION = graphql(`
	mutation RemoveUser($userId: ID!) {
		removeUser(userId: $userId) {
			_id
		}
	}
`);

export const useRemoveUserMutation = () =>
	useMutation(REMOVE_USER_MUTATION, {
		// update: (cache, {data}) => {
		// 	if (!data) {
		// 		return;
		// 	}
		//
		// 	const {removeUser} = data;
		// 	const users = cache.readQuery<Users>({
		// 		query: USERS_QUERY,
		// 	});
		//
		// 	cache.writeQuery({
		// 		query: USERS_QUERY,
		// 		data: {
		// 			users: {
		// 				...users?.users,
		// 				items: users?.users.items.filter(
		// 					(item) => item._id === removeUser._id,
		// 				)
		// 			},
		// 		},
		// 	});
		// },
	});

const USERS_STAT_QUERY = graphql(`
	query UsersStatResp {
		usersStat {
			registered
			phoneVerified
			emailVerified
			notActivated
			ozonKeys
			wbKeys
			userWithAnalyticsKey
		}

		extStats {
			clickedFromExtNotificationCount
		}
	}
`);

export const useUsersStatQuery = () => useQuery(USERS_STAT_QUERY);

const USER_TOKEN_QUERY = graphql(`
	query UserToken($id: ID!) {
		userToken(id: $id)
	}
`);

export const userTokenQuery = (variables: UserTokenQueryVariables) =>
	apolloClient.query<UserTokenQuery>({query: USER_TOKEN_QUERY, variables});

// Chart Registration
const REGISTRATION_STATS_QUERY = graphql(`
	query RegistrationStats($dateRange: DateRangeInput!, $type: UserTypeFilter) {
		registrationStats(dateRange: $dateRange, type: $type) {
			groupedByTime
			registrationStats {
				date
				newRegistrations
				emailsConfirmed
				phonesConfirmed
			}
		}
	}
`);

export const useRegistrationStatsQuery = (variables: RegistrationStatsQueryVariables) => {
	const {data, error, loading} = useQuery(REGISTRATION_STATS_QUERY, {variables});

	return useMemo(() => {
		const makeData = (data?: RegistrationStatsQuery['registrationStats']) => {
			const sortedDataByDate = sortBy(data?.registrationStats, 'date');

			const formatDate = formatDateGroupByTime(data?.groupedByTime);

			return {
				labels: sortedDataByDate?.map(({date}) => format(date, formatDate, {locale: ru})),
				datasets: [
					{
						label: 'Регистрации',
						unit: ' шт',
						color: 'rgb(244, 67, 54)',
						data: sortedDataByDate.map(({newRegistrations}) =>
							Math.round(newRegistrations),
						),
						tooltipReplace:
							sortedDataByDate?.map(({newRegistrations, date}) => (
								<TooltipItem
									label={'Регистрации'}
									sum={null}
									count={newRegistrations}
									date={date}
									groupBy={data?.groupedByTime}
								/>
							)) || [],
					},
					{
						label: 'Подтвержденные номера',
						unit: ' шт',
						color: 'rgb(60, 197, 60)',
						data:
							sortedDataByDate.map(({phonesConfirmed}) =>
								Math.round(phonesConfirmed),
							) || [],
						tooltipReplace:
							sortedDataByDate?.map(({phonesConfirmed}) => (
								<TooltipItem
									label={'Подтвержденные номера'}
									sum={null}
									count={phonesConfirmed}
								/>
							)) || [],
					},
					{
						label: 'Подтвержденные emails',
						unit: ' шт',
						color: 'rgb(33, 150, 243)',
						data:
							sortedDataByDate.map(({emailsConfirmed}) =>
								Math.round(emailsConfirmed),
							) || [],
						tooltipReplace:
							sortedDataByDate?.map(({emailsConfirmed}) => (
								<TooltipItem
									label={'Подтвержденные emails'}
									sum={null}
									count={emailsConfirmed}
								/>
							)) || [],
					},
				],
			};
		};

		return {
			data: makeData(data?.registrationStats),
			loading,
			error,
		};
	}, [data?.registrationStats, error, loading]);
};

const CONFIRM_BY_PHONE_MUTATION = graphql(`
	mutation ConfirmPhoneByCode($code: String!, $phone: String!) {
		confirmPhoneByCode(code: $code, phone: $phone) {
			token
		}
	}
`);

export const useConfirmPhoneByCodeMutation = () =>
	useMutation(CONFIRM_BY_PHONE_MUTATION, {
		update(_, {data}) {
			if (data && data.confirmPhoneByCode.token) {
				setToken(data.confirmPhoneByCode.token);

				window.postMessage({token: data.confirmPhoneByCode.token}, window.location.origin);
			}
		},
	});

const SEND_PHONE_VERIFICATION_CODE_BY_SMS_MUTATION = graphql(`
	mutation SendPhoneVerificationCodeBySms($phone: String!) {
		sendPhoneVerificationCodeBySms(phone: $phone)
	}
`);

export const useSendPhoneVerificationCodeBySmsMutation = () =>
	useMutation(SEND_PHONE_VERIFICATION_CODE_BY_SMS_MUTATION);

const LOGIN_BY_PHONE_MUTATION = graphql(`
	mutation LoginByPhone($phone: String!) {
		loginByPhone(phone: $phone, asAdmin: true)
	}
`);

export const useLoginByPhoneMutation = () => useMutation(LOGIN_BY_PHONE_MUTATION);

const ANALYTICS_KEY_STATS = graphql(`
	query AnalyticsKeyStats($dateRange: DateRangeInput!) {
		analyticsKeyStats(dateRange: $dateRange) {
			groupedByTime
			analyticsKeyStats {
				date
				wbKeyCount
				ozonKeyCount
			}
		}
	}
`);

export const useAnalyticsKeyStats = (variables: AnalyticsKeyStatsQueryVariables) => {
	const {data, error, loading} = useQuery(ANALYTICS_KEY_STATS, {variables});

	return useMemo(() => {
		const makeData = (data?: AnalyticsKeyStatsQuery['analyticsKeyStats']) => {
			const sortedDataByDate = sortBy(data?.analyticsKeyStats, 'date');

			const formatDate = formatDateGroupByTime(data?.groupedByTime);

			return {
				labels: sortedDataByDate?.map(({date}) => format(date, formatDate, {locale: ru})),
				datasets: [
					{
						label: 'Wildberries',
						unit: ' шт',
						color: '#fa0d87',
						data: sortedDataByDate.map(({wbKeyCount}) => Math.round(wbKeyCount)),
						tooltipReplace:
							sortedDataByDate?.map(({wbKeyCount, date}) => (
								<TooltipItem
									label={'Wb '}
									sum={null}
									count={wbKeyCount}
									date={date}
									groupBy={data?.groupedByTime}
								/>
							)) || [],
					},
					{
						label: 'Ozon',
						unit: ' шт',
						color: '#0069ff',
						data:
							sortedDataByDate.map(({ozonKeyCount}) => Math.round(ozonKeyCount)) ||
							[],
						tooltipReplace:
							sortedDataByDate?.map(({ozonKeyCount, wbKeyCount}) => (
								<>
									<TooltipItem label={'Ozon '} sum={null} count={ozonKeyCount} />
									<TooltipItem
										label={'Итого: '}
										sum={null}
										count={ozonKeyCount + wbKeyCount}
										isTotal
									/>
								</>
							)) || [],
					},
				],
			};
		};

		return {
			data: makeData(data?.analyticsKeyStats),
			loading,
			error,
		};
	}, [data?.analyticsKeyStats, error, loading]);
};

const ACTIVE_USERS_STATS_DAU = graphql(`
	query ActiveUsersStatsDAU($dateRange: DateRangeInput!, $device: DeviceFilter) {
		activeUsersStatsDAU: activeUsersStat(dateRange: $dateRange, device: $device) {
			date
			count
		}
	}
`);
export const useActiveUsersStatsDau = (variables: ActiveUsersStatsMauQueryVariables) => {
	const {data, error, loading} = useQuery(ACTIVE_USERS_STATS_DAU, {variables});

	return useMemo(() => {
		const makeData = (data?: ActiveUsersStatsDauQuery['activeUsersStatsDAU']) => {
			const sortedDataByDate = sortBy(data, 'date');

			return {
				labels: sortedDataByDate?.map(({date}) => format(date, 'dd.MM.yyyy', {locale: ru})),
				datasets: [
					{
						label: 'Уникальные пользователи',
						unit: ' шт',
						color: '#39dbe0',
						data: sortedDataByDate.map(({count}) => Math.round(count)),
						tooltipReplace:
							sortedDataByDate?.map(({count, date}) => (
								<TooltipItem
									label={'За день'}
									sum={null}
									count={count}
									date={date}
								/>
							)) || [],
					},
				],
			};
		};

		return {
			data: makeData(data?.activeUsersStatsDAU),
			loading,
			error,
		};
	}, [data?.activeUsersStatsDAU, error, loading]);
};

const ACTIVE_USERS_STATS_MAU = graphql(`
	query ActiveUsersStatsMAU($dateRange: DateRangeInput!, $device: DeviceFilter) {
		activeUsersStatsMAU: activeUsersStat(
			dateRange: $dateRange
			monthly: true
			device: $device
		) {
			date
			count
		}
	}
`);

export const useActiveUsersStatsMau = (variables: ActiveUsersStatsMauQueryVariables) => {
	const {data, error, loading} = useQuery(ACTIVE_USERS_STATS_MAU, {variables});

	return useMemo(() => {
		const makeData = (data?: ActiveUsersStatsMauQuery['activeUsersStatsMAU']) => {
			const sortedDataByDate = sortBy(data, 'date');

			return {
				labels: sortedDataByDate?.map(({date}) => format(date, 'LLLL yyyy', {locale: ru})),
				datasets: [
					{
						label: 'Уникальные пользователи',
						unit: ' шт',
						color: '#09a3a1',
						data: sortedDataByDate.map(({count}) => Math.round(count)),
						tooltipReplace:
							sortedDataByDate?.map(({count, date}) => (
								<TooltipItem
									label={'За месяц'}
									sum={null}
									count={count}
									date={date}
								/>
							)) || [],
					},
				],
			};
		};

		return {
			data: makeData(data?.activeUsersStatsMAU),
			loading,
			error,
		};
	}, [data?.activeUsersStatsMAU, error, loading]);
};
