import React, {useMemo} from 'react';
import {GeneralStatisticsBlockProps} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import PaymentBlock from 'components/payment/PaymentBlock';
import {useSubscriptionStatQuery} from 'api/payments';
import AppWrapper from 'components/common/AppWrapper';

const PaymentPage: React.FC = () => {
	const {data, loading} = useSubscriptionStatQuery();

	const blocks: GeneralStatisticsBlockProps[] = useMemo(
		() => [
			{
				label: 'Бесплатный',
				value: data?.subscriptionStat?.defaultCount,
				loading,
			},
			{
				label: 'Триал',
				value: data?.subscriptionStat?.trialCount,
				loading,
			},
			{
				label: 'Платный',
				value: data?.subscriptionStat?.paidCount,
				loading,
			},
		],
		[
			data?.subscriptionStat?.defaultCount,
			data?.subscriptionStat?.paidCount,
			data?.subscriptionStat?.trialCount,
			loading,
		],
	);

	return (
		<AppWrapper title={'Платежи'} blocksStat={blocks}>
			<PaymentBlock />
		</AppWrapper>
	);
};

export default PaymentPage;
