import React, {useEffect, useMemo, useState} from 'react';
import {Card, CardContent, colors, Grid, IconButton, Typography} from '@mui/material';
import Icon from '@mdi/react';
import {mdiCheck, mdiDeleteCircleOutline, mdiPlay, mdiSquareEditOutline, mdiStop} from '@mdi/js';
import {useConfirm} from 'material-ui-confirm';
import {
	Button,
	ContentBlock,
	Paginate,
	TableAdaptive,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {
	ITableColumn,
	ITableRow,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';

import EditPollModal from 'components/polls/EditPollModal';
import {useLayoutContext} from 'layouts/LayoutContext';
import CreatePollModal from 'components/polls/CreatePollModal';
import {useAllPollsQuery, useChangeFormActiveQuery, useRemovePollMutation} from 'api/polls';
import theme from '../../muiTheme';
import {AllPollsQueryVariables, QuestionBaseFragment} from '../../gqlApp/graphql';

export default function PollsTable() {
	const [currentPage, setCurrentPage] = useState(1);

	const [open, setOpen] = useState(false);

	const confirm = useConfirm();

	const pollsVariables = useMemo<AllPollsQueryVariables>(
		() => ({
			input: {page: currentPage, limit: 10},
		}),
		[currentPage],
	);

	const {data, refetch: refetchPolls, loading, error} = useAllPollsQuery(pollsVariables);

	const {setPollsVariables} = useLayoutContext();

	useEffect(() => {
		setPollsVariables(pollsVariables);
	}, [pollsVariables, setPollsVariables]);

	const pagesCount = useMemo(
		() => Math.ceil((data?.formList.total || 0) / 10),
		[data?.formList.total],
	);

	const [editedPoll, setEditedPoll] = useState<string>();

	const [removePoll] = useRemovePollMutation();

	const [changeFormActive] = useChangeFormActiveQuery();

	const columns = useMemo<ITableColumn[]>(
		() => [
			{
				name: 'manage',
				width: 120,
				title: '',
				sortingEnabled: false,
				format: (_value, row) => {
					const fondPoll = data?.formList.items.find(({_id}) => _id === row._id);

					return (
						<>
							{fondPoll && (
								<EditPollModal
									poll={fondPoll}
									open={editedPoll === row._id}
									onClose={() => setEditedPoll(undefined)}
								/>
							)}

							<IconButton onClick={() => setEditedPoll(row._id)}>
								<Icon
									path={mdiSquareEditOutline}
									size={0.8}
									color={theme.palette.primary.main}
								/>
							</IconButton>

							<IconButton
								onClick={() => {
									confirm({title: 'Точно удалить?'})
										.then(async () => {
											await removePoll({variables: {formId: row._id}});
											refetchPolls(pollsVariables);
										})
										.catch(() => {});
								}}
							>
								<Icon
									path={mdiDeleteCircleOutline}
									size={0.8}
									color={theme.palette.primary.main}
								/>
							</IconButton>

							{row?.limit !== 0 && (
								<IconButton
									onClick={() =>
										confirm({
											title: `Точно ${
												row?.isActive ? 'остановить' : 'запустить'
											} "${row?.name}"?`,
										})
											.then(() =>
												changeFormActive({
													variables: {
														input: {
															formId: row._id,
															isActive: !row.isActive,
														},
													},
												}),
											)
											.catch(() => {})
									}
								>
									<Icon
										path={row?.isActive ? mdiStop : mdiPlay}
										size={0.8}
										color={
											row?.isActive
												? colors.green['400']
												: theme.palette.primary.main
										}
									/>
								</IconButton>
							)}
						</>
					);
				},
			},
			{
				name: 'name',
				title: 'Название',
				sortingEnabled: false,
			},
			{
				name: 'limit',
				align: 'right',
				title: 'Неотвечено',
			},
			{
				name: 'answeredCount',
				align: 'right',
				title: 'Отвечено',
			},
			{
				name: 'emails',
				title: 'Емейлы',
				format: (value) => (value ? <Icon path={mdiCheck} size={1} color="green" /> : ''),
			},
			{
				name: 'tariffIds',
				title: 'Тарифы',
				format: (value) => (value ? <Icon path={mdiCheck} size={1} color="green" /> : ''),
			},
			{
				name: 'userIds',
				title: 'Пользователи',
				format: (value) => (value ? <Icon path={mdiCheck} size={1} color="green" /> : ''),
			},
			{
				name: 'haveOzonKey',
				title: 'Ozon ключи',
				format: (value) => (value ? <Icon path={mdiCheck} size={1} color="green" /> : ''),
			},
			{
				name: 'haveWbKey',
				title: 'Wb ключи',
				format: (value) => (value ? <Icon path={mdiCheck} size={1} color="green" /> : ''),
			},
			{
				name: 'collapse',
				align: 'right',
				title: 'Вопросы',
				sortingEnabled: false,
				format: (_value, row) => {
					const fondPoll = data?.formList.items.find(({_id}) => _id === row._id);

					if (!fondPoll) {
						return null;
					}

					return (
						<Grid container spacing={3}>
							{(fondPoll.questions as QuestionBaseFragment[]).map(
								({_id, title, subTitle, variants}) => (
									<Grid key={_id} item xs={3}>
										<Card variant={'outlined'}>
											<CardContent>
												<Typography
													variant="body1"
													color="textSecondary"
													component="p"
													gutterBottom
												>
													{title}
												</Typography>

												<Typography
													variant="body2"
													color="textSecondary"
													component="p"
												>
													{subTitle}
												</Typography>

												<br />

												<Grid container spacing={2}>
													<Grid item xs={6}>
														{variants?.join(', ')}
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
								),
							)}
						</Grid>
					);
				},
			},
		],
		[
			changeFormActive,
			confirm,
			data?.formList.items,
			editedPoll,
			pollsVariables,
			refetchPolls,
			removePoll,
		],
	);

	const rows = useMemo(
		() =>
			(data?.formList.items.map(({userIds, emails, tariffIds, ...form}) => ({
				manage: '',
				...form,
				userIds: userIds?.length,
				emails: emails?.length,
				tariffIds: tariffIds?.length,
			})) || []) as unknown as ITableRow[],
		[data?.formList.items],
	);

	return (
		<>
			<Button style={{marginTop: 20}} onClick={() => setOpen(true)}>
				+ Создать опрос
			</Button>

			<ContentBlock loading={loading} error={!!error}>
				<CreatePollModal open={open} onClose={() => setOpen(false)} />

				<TableAdaptive
					columns={columns}
					rows={rows}
					defaultSortDirection="asc"
					defaultSortColumnName="remaining"
				/>

				<br />

				{pagesCount > 1 && (
					<>
						<Paginate
							page={currentPage}
							count={pagesCount}
							onChange={(_, page) => setCurrentPage(page)}
							siblingCount={1}
							boundaryCount={1}
						/>

						<br />
					</>
				)}
			</ContentBlock>
		</>
	);
}
