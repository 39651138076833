import React from 'react';
import {CardContent, DialogProps} from '@mui/material';
import {omit} from 'lodash';

import Modal from 'components/common/ui/Modal';
import PromoCodeForm from 'components/promoCodes/PromoCodeForm';
import {useEditPromoCode, usePromoCodesListQuery} from 'api/promoCodes';
import {CreatePromoCodeInput, PromoCodeType} from '../../gqlApp/graphql';

interface IEditPromoCodeModal {
	codeId: string;
	promoCode: PromoCodeType;
}

const EditPromoCodeModal: React.FC<IEditPromoCodeModal & DialogProps> = (props) => {
	const [editPromoCode, {error, loading}] = useEditPromoCode();

	const {refetch: refetchPromoCodesList} = usePromoCodesListQuery({
		input: {},
	});

	return (
		<Modal title={'Редактирование промо-кода'} open={props.open} onClose={props.onClose}>
			<CardContent>
				<PromoCodeForm
					loading={loading}
					error={error}
					editPromoCode={
						omit(
							props.promoCode,
							'_id',
							'__typename',
							'isActive',
							'applyCount',
						) as CreatePromoCodeInput
					}
					onSubmit={(input) => {
						editPromoCode({
							variables: {
								input: {
									codeId: props.codeId,
									...input,
								},
							},
						}).then(() => {
							props.onClose && props.onClose('', 'backdropClick');
							refetchPromoCodesList();
						});
					}}
				/>
			</CardContent>
		</Modal>
	);
};

export default EditPromoCodeModal;
