import React from 'react';
import {Tooltip as MuiTooltip, TooltipProps} from '@mui/material';
import classNames from 'classnames';

import css from './Tooltip.pcss';

interface ITooltip {
	title?: string;
	content: React.ReactNode;
	children: TooltipProps['children'];
	small?: boolean;
	defaultPlacement?: boolean;
}

const Tooltip: React.FC<ITooltip & Omit<TooltipProps, 'title' | 'content'>> = ({
	title,
	content,
	children,
	small,
	defaultPlacement,
	...props
}) => (
	<MuiTooltip
		classes={{
			popper: classNames(css.popper, {[css.small]: small}),
			tooltip: css.tooltip,
			arrow: css.arrow,
		}}
		arrow
		placement={defaultPlacement ? undefined : 'right-start'}
		title={
			<>
				{title && <div className={css.title}>{title}</div>}
				<div className={css.content}>{content}</div>
			</>
		}
		children={children}
		{...props}
	/>
);

export default Tooltip;
