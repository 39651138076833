import React from 'react';
import {ApolloProvider} from '@apollo/client';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {ThemeProvider} from '@mui/material/styles';
import ru from 'date-fns/locale/ru';

// eslint-disable-next-line import/no-unassigned-import
import '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/style.css';

import apolloClient from 'utils/apolloClient';
import theme from './muiTheme';
import Routes from './Routes';

const Root: React.FC = () => (
	<ThemeProvider theme={theme}>
		<LocalizationProvider dateAdapter={DateFnsAdapter} locale={ru}>
			<ApolloProvider client={apolloClient}>
				<Routes />
			</ApolloProvider>
		</LocalizationProvider>
	</ThemeProvider>
);

export default Root;
