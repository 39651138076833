import React, {useCallback, useMemo, useState} from 'react';
import {
	Divider,
	Fade,
	ListItemIcon,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	TextFieldProps,
} from '@mui/material';
// import ruIcon from 'country-flag-icons/3x2/RU.svg';
// import byIcon from 'country-flag-icons/3x2/BY.svg';
// import uaIcon from 'country-flag-icons/3x2/UA.svg';
// import kzIcon from 'country-flag-icons/3x2/KZ.svg';
// import kgIcon from 'country-flag-icons/3x2/KG.svg';
// import amIcon from 'country-flag-icons/3x2/AM.svg';
import InputMask from 'react-input-mask';
import {KeyboardArrowDown} from '@mui/icons-material';

import css from './PhoneField.pcss';

interface IPhoneField {
	onChange?: (event: {target: {name: string | undefined; value: string}}) => void;
}

const PhoneField: React.FC<IPhoneField & Omit<TextFieldProps, 'onChange'>> = ({
	onChange,
	...props
}) => {
	const [region, setRegion] = useState('ru');
	const [number, setNumber] = useState('');

	const regionItems = useMemo(
		() => [
			{
				key: 'ru',
				code: '7',
				label: '+7',
				mask: '(999) 999 99 99',
				// icon: ruIcon,
			},
			{
				key: 'by',
				code: '375',
				label: '+375',
				mask: '(99) 999 99 99',
				// icon: byIcon,
			},
			{
				key: 'ua',
				code: '380',
				label: '+380',
				mask: '(99) 999 99 99',
				// icon: uaIcon,
			},
			{
				key: 'kz',
				code: '7',
				label: '+7',
				mask: '(999) 999 9 999',
				// icon: kzIcon,
			},
			{
				key: 'kg',
				code: '996',
				label: '+996',
				mask: '(999) 999 999',
				// icon: kgIcon,
			},
			{
				key: 'am',
				code: '374',
				label: '+374',
				mask: '(999) 999 999',
				// icon: amIcon,
			},
		],
		[],
	);

	const handleSelectChange = useCallback(
		(event: SelectChangeEvent<string>) => {
			setRegion(event.target.value);
			setNumber('');

			const formattedNumber = number.replace(/\D/g, '');

			if (!onChange || !formattedNumber) {
				return;
			}

			onChange({
				target: {
					name: event.target.name,
					value:
						regionItems.find(({key}) => key === event.target.value)!.code +
						formattedNumber,
				},
			});
		},
		[number, onChange, regionItems],
	);

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			setNumber(event.target.value);

			if (!onChange) {
				return;
			}

			const formattedNumber = event.target.value.replace(/\D/g, '');

			onChange({
				target: {
					name: event.target.name,
					value: formattedNumber
						? regionItems.find(({key}) => key === region)!.code + formattedNumber
						: '',
				},
			});
		},
		[onChange, region, regionItems],
	);

	const inputComponent = useCallback(
		(props: any) => (
			<InputMask
				{...props}
				mask={regionItems.find(({key}) => key === region)!.mask}
				maskChar={null}
			/>
		),
		[region, regionItems],
	);

	return (
		<>
			<TextField
				fullWidth
				placeholder="Ваш номер телефона"
				defaultValue={number}
				{...props}
				InputProps={{
					...props.InputProps,
					inputComponent,
					classes: {
						root: css.root,
						input: css.input,
					},
					startAdornment: (
						<>
							<Select
								value={region}
								onChange={handleSelectChange}
								name={props.name}
								className={css.select}
								inputProps={{
									className: css.selectInput,
								}}
								IconComponent={KeyboardArrowDown}
								classes={{icon: css.selectIcon}}
								MenuProps={{
									MenuListProps: {disablePadding: true},
									PopoverClasses: {paper: css.paper, root: css.popover},
									keepMounted: true,
									elevation: 0,
									TransitionComponent: Fade,
								}}
							>
								{regionItems.map(({key, label}) => (
									<MenuItem key={key} value={key}>
										<ListItemIcon className={css.flag}>
											<img
												// src={icon}
												width={20}
												alt=""
											/>
										</ListItemIcon>
										{label}
									</MenuItem>
								))}
							</Select>

							<Divider
								orientation="vertical"
								flexItem
								variant="middle"
								className={css.divider}
							/>
						</>
					),
				}}
				onChange={handleInputChange}
			/>
		</>
	);
};

export default PhoneField;
