import React, {useState} from 'react';
import {Button} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import CreateNotifModal from 'components/notifs/CreateNotifModal';
import NotifsTable from 'components/notifs/NotifsTable';
import AppWrapper from 'components/common/AppWrapper';

const NotifsPage: React.FC = () => {
	const [open, setOpen] = useState(false);

	return (
		<AppWrapper title="Оповещения">
			<Button onClick={() => setOpen(true)}>Создать оповещение</Button>

			<CreateNotifModal open={open} onClose={() => setOpen(false)} />

			<NotifsTable />
		</AppWrapper>
	);
};

export default NotifsPage;
