import {useMutation, useQuery} from '@apollo/client';

import {graphql} from '../gqlApp';
import {PromoCodesListQueryVariables} from '../gqlApp/graphql';

const PROMO_CODES_LIST_QUERY = graphql(`
	query PromoCodesList($input: PromoCodeListInput!) {
		promoCodesList(input: $input) {
			description
			code
			discount
			paymentType
			utmSource
			limitCount
			endDate
			userIds
			applyCount
			isActive
			_id
		}
	}
`);

export const usePromoCodesListQuery = (variables: PromoCodesListQueryVariables) =>
	useQuery(PROMO_CODES_LIST_QUERY, {variables});

const CREATE_PROMO_CODE_MUTATION = graphql(`
	mutation CreatePromoCode($input: CreatePromoCodeInput!) {
		createPromoCode(input: $input) {
			code
		}
	}
`);

export const useCreatePromoCodeMutation = () => useMutation(CREATE_PROMO_CODE_MUTATION);

const REMOVE_PROMO_CODE = graphql(`
	mutation RemovePromoCode($code: String!) {
		removePromoCode(code: $code)
	}
`);

export const useRemovePromoCodeMutation = () => useMutation(REMOVE_PROMO_CODE);

const EDIT_PROMO_CODE = graphql(`
	mutation EditPromoCode($input: EditPromoCodeInput!) {
		editPromoCode(input: $input) {
			code
		}
	}
`);

export const useEditPromoCode = () => useMutation(EDIT_PROMO_CODE);
