/* eslint-disable */
import * as types from './graphql';
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
	'\n\tquery ApiCallsPopularActions($fromDate: Timestamp!, $toDate: Timestamp!) {\n\t\tapiCallsPopularActions(fromDate: $fromDate, toDate: $toDate) {\n\t\t\taction\n\t\t\tcount\n\t\t}\n\t}\n':
		types.ApiCallsPopularActionsDocument,
	'\n\tquery ApiCallsActiveUsers(\n\t\t$fromDate: Timestamp!\n\t\t$toDate: Timestamp!\n\t\t$limit: Int\n\t\t$offset: Int\n\t) {\n\t\tapiCallsActiveUsers(fromDate: $fromDate, toDate: $toDate, limit: $limit, offset: $offset) {\n\t\t\ttotalItems\n\t\t\titems {\n\t\t\t\tuserId\n\t\t\t\tcount\n\t\t\t\tuser {\n\t\t\t\t\temail\n\t\t\t\t\tcreatedAt\n\t\t\t\t\tisActive\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n':
		types.ApiCallsActiveUsersDocument,
	'\n\tquery ApiCallsUserStats($userId: String!, $fromDate: Timestamp!, $toDate: Timestamp!) {\n\t\tapiCallsUserStats(userId: $userId, fromDate: $fromDate, toDate: $toDate) {\n\t\t\taction\n\t\t\tcount\n\t\t}\n\t}\n':
		types.ApiCallsUserStatsDocument,
	'\n\tfragment NotificationBase on NotificationType {\n\t\t_id\n\t\ttitle\n\t\tdescription\n\t\tview\n\t\tremaining\n\t}\n':
		types.NotificationBaseFragmentDoc,
	'\n\tquery AllNotifications($filter: AllNotificationsInput!) {\n\t\tallNotifications(filter: $filter) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\t...NotificationBase\n\t\t\t}\n\t\t}\n\t}\n':
		types.AllNotificationsDocument,
	'\n\tmutation CreateNotification($notification: CreateNotificationInput!) {\n\t\tcreateNotification(notification: $notification) {\n\t\t\t...NotificationBase\n\t\t}\n\t}\n':
		types.CreateNotificationDocument,
	'\n\tmutation EditNotification($notification: EditNotificationInput!) {\n\t\teditNotification(notification: $notification) {\n\t\t\t...NotificationBase\n\t\t}\n\t}\n':
		types.EditNotificationDocument,
	'\n\tmutation RemoveNotification($notificationId: String!) {\n\t\tremoveNotification(notificationId: $notificationId) {\n\t\t\t_id\n\t\t}\n\t}\n':
		types.RemoveNotificationDocument,
	'\n\tquery SubscriptionStat {\n\t\tsubscriptionStat {\n\t\t\ttrialCount\n\t\t\tdefaultCount\n\t\t\tpaidCount\n\t\t}\n\t}\n':
		types.SubscriptionStatDocument,
	'\n\tquery PaymentsStats($dateRange: DateRangeInput!) {\n\t\tpaymentsStats(dateRange: $dateRange) {\n\t\t\tdate\n\t\t\tpriceSum\n\t\t\tpriceAmount\n\t\t\tpriceQuartersSum\n\t\t\tpriceQuartersAmount\n\t\t\tpriceHalfYearSum\n\t\t\tpriceHalfYearAmount\n\t\t\tpriceYearSum\n\t\t\tpriceYearAmount\n\t\t}\n\t}\n':
		types.PaymentsStatsDocument,
	'\n\tfragment QuestionBase on FormQuestionResponse {\n\t\t_id\n\t\ttitle\n\t\tsubTitle\n\t\tquestionType\n\t\tvariants\n\t\thaveAnotherVariant\n\t\tisRequired\n\t}\n':
		types.QuestionBaseFragmentDoc,
	'\n\tfragment PollBase on FormTypeResponse {\n\t\t_id\n\t\tname\n\t\ttitle\n\t\tsubTitle\n\t\tquestions {\n\t\t\t...QuestionBase\n\t\t}\n\t\tisPublic\n\t\tisActive\n\t\temails\n\t\tuserIds\n\t\ttariffIds\n\t\tuserCreatedAt\n\t\thaveOzonKey\n\t\thaveWbKey\n\t\tlimit\n\t\tansweredCount\n\t}\n':
		types.PollBaseFragmentDoc,
	'\n\tquery AllPolls($input: PagesInput!) {\n\t\tformList(input: $input) {\n\t\t\titems {\n\t\t\t\t...PollBase\n\t\t\t}\n\t\t\ttotal\n\t\t}\n\t}\n':
		types.AllPollsDocument,
	'\n\tmutation CreatePoll($input: CreateFormInput!) {\n\t\tcreateForm(input: $input) {\n\t\t\t...PollBase\n\t\t}\n\t}\n':
		types.CreatePollDocument,
	'\n\tmutation EditPoll($input: EditFormInput!) {\n\t\teditForm(input: $input) {\n\t\t\t...PollBase\n\t\t}\n\t}\n':
		types.EditPollDocument,
	'\n\tmutation RemovePoll($formId: String!) {\n\t\tremoveForm(formId: $formId)\n\t}\n':
		types.RemovePollDocument,
	'\n\tquery ChangeFormActive($input: ChangeFormActiveInput!) {\n\t\tchangeFormActive(input: $input) {\n\t\t\t...PollBase\n\t\t}\n\t}\n':
		types.ChangeFormActiveDocument,
	'\n\tquery PromoCodesList($input: PromoCodeListInput!) {\n\t\tpromoCodesList(input: $input) {\n\t\t\tdescription\n\t\t\tcode\n\t\t\tdiscount\n\t\t\tpaymentType\n\t\t\tutmSource\n\t\t\tlimitCount\n\t\t\tendDate\n\t\t\tuserIds\n\t\t\tapplyCount\n\t\t\tisActive\n\t\t\t_id\n\t\t}\n\t}\n':
		types.PromoCodesListDocument,
	'\n\tmutation CreatePromoCode($input: CreatePromoCodeInput!) {\n\t\tcreatePromoCode(input: $input) {\n\t\t\tcode\n\t\t}\n\t}\n':
		types.CreatePromoCodeDocument,
	'\n\tmutation RemovePromoCode($code: String!) {\n\t\tremovePromoCode(code: $code)\n\t}\n':
		types.RemovePromoCodeDocument,
	'\n\tmutation EditPromoCode($input: EditPromoCodeInput!) {\n\t\teditPromoCode(input: $input) {\n\t\t\tcode\n\t\t}\n\t}\n':
		types.EditPromoCodeDocument,
	'\n\tfragment Permissions on TariffPermissionsType {\n\t\thistoryDays\n\t\thideBrands\n\t\thideSellers\n\t\tfavoritesGroupsLimit\n\t\tproductsGroupLimit\n\t\thideTopProducts\n\t\thideTopCategories\n\t\tfavoritesSummary\n\t\tsbSummary\n\t\tsbTopProducts\n\t\tsbNewItems\n\t\tsbAllProducts\n\t\tsbPresence\n\t\tanalyticsAccounts\n\t\tsearchQueriesLimit\n\t\thideOzon\n\t\tanalyticsSalesHistoryDays\n\t\tanalyticsSOHistoryDays\n\t\texportProducts\n\t\thideExtProductHistory\n\t\thideAnalyticsBlock\n\t\thideProductsSummary\n\t\thideSeo\n\t\tfavoritesLimit\n\t}\n':
		types.PermissionsFragmentDoc,
	'\n\tfragment Tariff on TariffType {\n\t\t_id\n\t\tname\n\t\tdescription\n\t\tprice\n\t\tprice3\n\t\tprice6\n\t\tprice12\n\t\tisTrial\n\t\tisActive\n\t\tisPublic\n\t\tisDefault\n\t\tduration\n\t\tmarked\n\t\tcolor\n\t\tpermissions {\n\t\t\t...Permissions\n\t\t}\n\t}\n':
		types.TariffFragmentDoc,
	'\n\tquery Tariffs {\n\t\ttariffs {\n\t\t\t...Tariff\n\t\t}\n\t}\n': types.TariffsDocument,
	'\n\tquery ActiveTariffs {\n\t\tactiveTariffs {\n\t\t\t...Tariff\n\t\t}\n\t}\n':
		types.ActiveTariffsDocument,
	'\n\tmutation AddTariffToUser(\n\t\t$userId: String!\n\t\t$tariffId: String!\n\t\t$paymentType: PaymentTypeEnum\n\t\t$withPayment: Boolean\n\t) {\n\t\taddTariffToUser(\n\t\t\tuserId: $userId\n\t\t\ttariffId: $tariffId\n\t\t\tpaymentType: $paymentType\n\t\t\twithPayment: $withPayment\n\t\t) {\n\t\t\tpaidTariff {\n\t\t\t\tuserId\n\t\t\t\ttariffId\n\t\t\t\tfromDate\n\t\t\t\ttoDate\n\t\t\t\ttotalPaid\n\t\t\t\tdatePaid\n\t\t\t}\n\n\t\t\tuser {\n\t\t\t\t_id\n\t\t\t\temail\n\t\t\t\trole\n\t\t\t\tcreatedAt\n\t\t\t\ttelegram\n\t\t\t\tisActive\n\t\t\t\tactiveTariffId\n\t\t\t}\n\t\t}\n\t}\n':
		types.AddTariffToUserDocument,
	'\n\tmutation AddTariff($newTariffInput: AddTariffInput!) {\n\t\taddTariff(newTariffInput: $newTariffInput) {\n\t\t\t...Tariff\n\t\t}\n\t}\n':
		types.AddTariffDocument,
	'\n\tmutation EditTariff($tariffId: String!, $editTariffInput: EditTariffInput!) {\n\t\teditTariff(tariffId: $tariffId, editTariffInput: $editTariffInput) {\n\t\t\t...Tariff\n\t\t}\n\t}\n':
		types.EditTariffDocument,
	'\n\tmutation EditTariffPermission(\n\t\t$historyDays: Int\n\t\t$hideBrands: Boolean\n\t\t$hideSellers: Boolean\n\t\t$favoritesGroupsLimit: Int\n\t\t$productsGroupLimit: Int\n\t\t$hideTopProducts: Boolean\n\t\t$hideTopCategories: Boolean\n\t\t$favoritesSummary: Boolean\n\t\t$sbSummary: Boolean\n\t\t$sbTopProducts: Boolean\n\t\t$sbNewItems: Boolean\n\t\t$sbAllProducts: Boolean\n\t\t$sbPresence: Boolean\n\t\t$analyticsAccounts: Int\n\t\t$searchQueriesLimit: Int\n\t\t$hideOzon: Boolean\n\t\t$analyticsSalesHistoryDays: Int\n\t\t$analyticsSOHistoryDays: Int\n\t\t$exportProducts: Int\n\t\t$hideExtProductHistory: Boolean\n\t\t$tariffId: String!\n\t\t$hideAnalyticsBlock: Boolean\n\t\t$hideProductsSummary: Boolean\n\t\t$hideSeo: Boolean\n\t\t$favoritesLimit: Int\n\t) {\n\t\teditTariffPermission(\n\t\t\thistoryDays: $historyDays\n\t\t\thideBrands: $hideBrands\n\t\t\thideSellers: $hideSellers\n\t\t\tfavoritesGroupsLimit: $favoritesGroupsLimit\n\t\t\tproductsGroupLimit: $productsGroupLimit\n\t\t\thideTopProducts: $hideTopProducts\n\t\t\thideTopCategories: $hideTopCategories\n\t\t\tfavoritesSummary: $favoritesSummary\n\t\t\tsbSummary: $sbSummary\n\t\t\tsbTopProducts: $sbTopProducts\n\t\t\tsbNewItems: $sbNewItems\n\t\t\tsbAllProducts: $sbAllProducts\n\t\t\tsbPresence: $sbPresence\n\t\t\tanalyticsAccounts: $analyticsAccounts\n\t\t\tsearchQueriesLimit: $searchQueriesLimit\n\t\t\thideOzon: $hideOzon\n\t\t\tanalyticsSalesHistoryDays: $analyticsSalesHistoryDays\n\t\t\tanalyticsSOHistoryDays: $analyticsSOHistoryDays\n\t\t\texportProducts: $exportProducts\n\t\t\thideExtProductHistory: $hideExtProductHistory\n\t\t\ttariffId: $tariffId\n\t\t\thideAnalyticsBlock: $hideAnalyticsBlock\n\t\t\thideProductsSummary: $hideProductsSummary\n\t\t\thideSeo: $hideSeo\n\t\t\tfavoritesLimit: $favoritesLimit\n\t\t) {\n\t\t\t...Tariff\n\t\t}\n\t}\n':
		types.EditTariffPermissionDocument,
	'\n\tmutation AddFreeDaysToTariff($input: FreeDaysInput!) {\n\t\taddFreeDaysToTariff(input: $input)\n\t}\n':
		types.AddFreeDaysToTariffDocument,
	'\n\tfragment UserBase on UserType {\n\t\t_id\n\t\temail\n\t\tname\n\t\trole\n\t\tcreatedAt\n\t\tphone\n\t\tisActive\n\t\tactiveTariffId\n\t\twbApiKeys {\n\t\t\tkey\n\t\t\tkeyId\n\t\t\tname\n\t\t}\n\t\tpaidTariffs {\n\t\t\tuserId\n\t\t\ttariffId\n\t\t\tfromDate\n\t\t\ttoDate\n\t\t\ttotalPaid\n\t\t\tdatePaid\n\t\t}\n\t\tisEmailVerified\n\t\tisPhoneVerified\n\t\tsubscription {\n\t\t\ttoDate\n\t\t}\n\t}\n':
		types.UserBaseFragmentDoc,
	'\n\tmutation Login($email: String!, $password: String!) {\n\t\tloginByEmail(email: $email, password: $password, asAdmin: true) {\n\t\t\tuser {\n\t\t\t\trole\n\t\t\t}\n\t\t\ttoken\n\t\t}\n\t}\n':
		types.LoginDocument,
	'\n\tmutation Logout {\n\t\tlogout\n\t}\n': types.LogoutDocument,
	'\n\tquery Me {\n\t\tme {\n\t\t\t_id\n\t\t\temail\n\t\t\trole\n\t\t}\n\t}\n': types.MeDocument,
	'\n\tquery Users(\n\t\t$filter: UserFilter\n\t\t$skip: Float\n\t\t$limit: Float\n\t\t$search: String\n\t\t$sort: UserSort\n\t\t$sortDirection: SortDirection\n\t) {\n\t\tusers(\n\t\t\tfilter: $filter\n\t\t\tskip: $skip\n\t\t\tlimit: $limit\n\t\t\tsearch: $search\n\t\t\tsort: $sort\n\t\t\tsortDirection: $sortDirection\n\t\t) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\t...UserBase\n\t\t\t}\n\t\t}\n\t}\n':
		types.UsersDocument,
	'\n\tmutation EditUser(\n\t\t$id: ID!\n\t\t$isActive: Boolean!\n\t\t$email: String!\n\t\t$isEmailVerified: Boolean!\n\t\t$phone: String!\n\t\t$isPhoneVerified: Boolean!\n\t\t$name: String!\n\t) {\n\t\teditUser(\n\t\t\tid: $id\n\t\t\tisActive: $isActive\n\t\t\temail: $email\n\t\t\tisEmailVerified: $isEmailVerified\n\t\t\tphone: $phone\n\t\t\tisPhoneVerified: $isPhoneVerified\n\t\t\tname: $name\n\t\t) {\n\t\t\t...UserBase\n\t\t}\n\t}\n':
		types.EditUserDocument,
	'\n\tmutation RemoveUser($userId: ID!) {\n\t\tremoveUser(userId: $userId) {\n\t\t\t_id\n\t\t}\n\t}\n':
		types.RemoveUserDocument,
	'\n\tquery UsersStatResp {\n\t\tusersStat {\n\t\t\tregistered\n\t\t\tphoneVerified\n\t\t\temailVerified\n\t\t\tnotActivated\n\t\t\tozonKeys\n\t\t\twbKeys\n\t\t\tuserWithAnalyticsKey\n\t\t}\n\n\t\textStats {\n\t\t\tclickedFromExtNotificationCount\n\t\t}\n\t}\n':
		types.UsersStatRespDocument,
	'\n\tquery UserToken($id: ID!) {\n\t\tuserToken(id: $id)\n\t}\n': types.UserTokenDocument,
	'\n\tquery RegistrationStats($dateRange: DateRangeInput!, $type: UserTypeFilter) {\n\t\tregistrationStats(dateRange: $dateRange, type: $type) {\n\t\t\tgroupedByTime\n\t\t\tregistrationStats {\n\t\t\t\tdate\n\t\t\t\tnewRegistrations\n\t\t\t\temailsConfirmed\n\t\t\t\tphonesConfirmed\n\t\t\t}\n\t\t}\n\t}\n':
		types.RegistrationStatsDocument,
	'\n\tmutation ConfirmPhoneByCode($code: String!, $phone: String!) {\n\t\tconfirmPhoneByCode(code: $code, phone: $phone) {\n\t\t\ttoken\n\t\t}\n\t}\n':
		types.ConfirmPhoneByCodeDocument,
	'\n\tmutation SendPhoneVerificationCodeBySms($phone: String!) {\n\t\tsendPhoneVerificationCodeBySms(phone: $phone)\n\t}\n':
		types.SendPhoneVerificationCodeBySmsDocument,
	'\n\tmutation LoginByPhone($phone: String!) {\n\t\tloginByPhone(phone: $phone, asAdmin: true)\n\t}\n':
		types.LoginByPhoneDocument,
	'\n\tquery AnalyticsKeyStats($dateRange: DateRangeInput!) {\n\t\tanalyticsKeyStats(dateRange: $dateRange) {\n\t\t\tgroupedByTime\n\t\t\tanalyticsKeyStats {\n\t\t\t\tdate\n\t\t\t\twbKeyCount\n\t\t\t\tozonKeyCount\n\t\t\t}\n\t\t}\n\t}\n':
		types.AnalyticsKeyStatsDocument,
	'\n\tquery ActiveUsersStatsDAU($dateRange: DateRangeInput!, $device: DeviceFilter) {\n\t\tactiveUsersStatsDAU: activeUsersStat(dateRange: $dateRange, device: $device) {\n\t\t\tdate\n\t\t\tcount\n\t\t}\n\t}\n':
		types.ActiveUsersStatsDauDocument,
	'\n\tquery ActiveUsersStatsMAU($dateRange: DateRangeInput!, $device: DeviceFilter) {\n\t\tactiveUsersStatsMAU: activeUsersStat(\n\t\t\tdateRange: $dateRange\n\t\t\tmonthly: true\n\t\t\tdevice: $device\n\t\t) {\n\t\t\tdate\n\t\t\tcount\n\t\t}\n\t}\n':
		types.ActiveUsersStatsMauDocument,
	'\n\tquery GetUtmSourceStats($input: UtmSourceStatsInput!) {\n\t\tgetUtmSourceStats(input: $input) {\n\t\t\tsource\n\t\t\tdateToShow\n\t\t\tpaymentAmount\n\t\t\tpaymentCount\n\t\t\tregistrationCount\n\t\t}\n\t}\n':
		types.GetUtmSourceStatsDocument,
	'\n\tmutation UpdateUtmSource($input: UpdateUtmSourceInput!) {\n\t\tupdateUtmSource(input: $input) {\n\t\t\t_id\n\t\t\tsource\n\t\t\tmedium\n\t\t\tcampaign\n\t\t\tterm\n\t\t\tcontent\n\t\t\tprice\n\t\t\tcomment\n\t\t\tusersCount\n\t\t\tpaymentAmount\n\t\t\tpaymentCount\n\t\t\tpayersCount\n\t\t}\n\t}\n':
		types.UpdateUtmSourceDocument,
	'\n\tmutation CreateUtmSource($input: CreateUtmSourceInput!) {\n\t\tcreateUtmSource(input: $input) {\n\t\t\t_id\n\t\t\tsource\n\t\t\tmedium\n\t\t\tcampaign\n\t\t\tterm\n\t\t\tcontent\n\t\t\tprice\n\t\t\tcomment\n\t\t\tusersCount\n\t\t\tpaymentAmount\n\t\t\tpaymentCount\n\t\t\tpayersCount\n\t\t}\n\t}\n':
		types.CreateUtmSourceDocument,
	'\n\tquery GetUtmSourceList($input: UtmListOptionsInput!) {\n\t\tgetUtmSourceList(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\t_id\n\t\t\t\tprice\n\t\t\t\tcomment\n\t\t\t\troot\n\t\t\t\tsource\n\t\t\t\tcampaign\n\t\t\t\tmedium\n\t\t\t\tterm\n\t\t\t\tcontent\n\t\t\t\tusersCount\n\t\t\t\tpaymentAmount\n\t\t\t\tpaymentCount\n\t\t\t\tpayersCount\n\t\t\t\tcampaignStats {\n\t\t\t\t\t_id\n\t\t\t\t\tprice\n\t\t\t\t\tcomment\n\t\t\t\t\troot\n\t\t\t\t\tsource\n\t\t\t\t\tcampaign\n\t\t\t\t\tmedium\n\t\t\t\t\tterm\n\t\t\t\t\tcontent\n\t\t\t\t\tusersCount\n\t\t\t\t\tpaymentAmount\n\t\t\t\t\tpaymentCount\n\t\t\t\t\tpayersCount\n\t\t\t\t\tmediumStats {\n\t\t\t\t\t\t_id\n\t\t\t\t\t\tprice\n\t\t\t\t\t\tcomment\n\t\t\t\t\t\troot\n\t\t\t\t\t\tsource\n\t\t\t\t\t\tcampaign\n\t\t\t\t\t\tmedium\n\t\t\t\t\t\tterm\n\t\t\t\t\t\tcontent\n\t\t\t\t\t\tusersCount\n\t\t\t\t\t\tpaymentAmount\n\t\t\t\t\t\tpaymentCount\n\t\t\t\t\t\tpayersCount\n\t\t\t\t\t\tcontentStats {\n\t\t\t\t\t\t\t_id\n\t\t\t\t\t\t\tprice\n\t\t\t\t\t\t\tcomment\n\t\t\t\t\t\t\troot\n\t\t\t\t\t\t\tsource\n\t\t\t\t\t\t\tcampaign\n\t\t\t\t\t\t\tmedium\n\t\t\t\t\t\t\tterm\n\t\t\t\t\t\t\tcontent\n\t\t\t\t\t\t\tusersCount\n\t\t\t\t\t\t\tpaymentAmount\n\t\t\t\t\t\t\tpaymentCount\n\t\t\t\t\t\t\tpayersCount\n\t\t\t\t\t\t\ttermStats {\n\t\t\t\t\t\t\t\t_id\n\t\t\t\t\t\t\t\tprice\n\t\t\t\t\t\t\t\tcomment\n\t\t\t\t\t\t\t\troot\n\t\t\t\t\t\t\t\tsource\n\t\t\t\t\t\t\t\tcampaign\n\t\t\t\t\t\t\t\tmedium\n\t\t\t\t\t\t\t\tterm\n\t\t\t\t\t\t\t\tcontent\n\t\t\t\t\t\t\t\tusersCount\n\t\t\t\t\t\t\t\tpaymentAmount\n\t\t\t\t\t\t\t\tpaymentCount\n\t\t\t\t\t\t\t\tpayersCount\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n':
		types.GetUtmSourceListDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery ApiCallsPopularActions($fromDate: Timestamp!, $toDate: Timestamp!) {\n\t\tapiCallsPopularActions(fromDate: $fromDate, toDate: $toDate) {\n\t\t\taction\n\t\t\tcount\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery ApiCallsPopularActions($fromDate: Timestamp!, $toDate: Timestamp!) {\n\t\tapiCallsPopularActions(fromDate: $fromDate, toDate: $toDate) {\n\t\t\taction\n\t\t\tcount\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery ApiCallsActiveUsers(\n\t\t$fromDate: Timestamp!\n\t\t$toDate: Timestamp!\n\t\t$limit: Int\n\t\t$offset: Int\n\t) {\n\t\tapiCallsActiveUsers(fromDate: $fromDate, toDate: $toDate, limit: $limit, offset: $offset) {\n\t\t\ttotalItems\n\t\t\titems {\n\t\t\t\tuserId\n\t\t\t\tcount\n\t\t\t\tuser {\n\t\t\t\t\temail\n\t\t\t\t\tcreatedAt\n\t\t\t\t\tisActive\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery ApiCallsActiveUsers(\n\t\t$fromDate: Timestamp!\n\t\t$toDate: Timestamp!\n\t\t$limit: Int\n\t\t$offset: Int\n\t) {\n\t\tapiCallsActiveUsers(fromDate: $fromDate, toDate: $toDate, limit: $limit, offset: $offset) {\n\t\t\ttotalItems\n\t\t\titems {\n\t\t\t\tuserId\n\t\t\t\tcount\n\t\t\t\tuser {\n\t\t\t\t\temail\n\t\t\t\t\tcreatedAt\n\t\t\t\t\tisActive\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery ApiCallsUserStats($userId: String!, $fromDate: Timestamp!, $toDate: Timestamp!) {\n\t\tapiCallsUserStats(userId: $userId, fromDate: $fromDate, toDate: $toDate) {\n\t\t\taction\n\t\t\tcount\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery ApiCallsUserStats($userId: String!, $fromDate: Timestamp!, $toDate: Timestamp!) {\n\t\tapiCallsUserStats(userId: $userId, fromDate: $fromDate, toDate: $toDate) {\n\t\t\taction\n\t\t\tcount\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment NotificationBase on NotificationType {\n\t\t_id\n\t\ttitle\n\t\tdescription\n\t\tview\n\t\tremaining\n\t}\n',
): (typeof documents)['\n\tfragment NotificationBase on NotificationType {\n\t\t_id\n\t\ttitle\n\t\tdescription\n\t\tview\n\t\tremaining\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery AllNotifications($filter: AllNotificationsInput!) {\n\t\tallNotifications(filter: $filter) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\t...NotificationBase\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery AllNotifications($filter: AllNotificationsInput!) {\n\t\tallNotifications(filter: $filter) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\t...NotificationBase\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation CreateNotification($notification: CreateNotificationInput!) {\n\t\tcreateNotification(notification: $notification) {\n\t\t\t...NotificationBase\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation CreateNotification($notification: CreateNotificationInput!) {\n\t\tcreateNotification(notification: $notification) {\n\t\t\t...NotificationBase\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation EditNotification($notification: EditNotificationInput!) {\n\t\teditNotification(notification: $notification) {\n\t\t\t...NotificationBase\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation EditNotification($notification: EditNotificationInput!) {\n\t\teditNotification(notification: $notification) {\n\t\t\t...NotificationBase\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation RemoveNotification($notificationId: String!) {\n\t\tremoveNotification(notificationId: $notificationId) {\n\t\t\t_id\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation RemoveNotification($notificationId: String!) {\n\t\tremoveNotification(notificationId: $notificationId) {\n\t\t\t_id\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery SubscriptionStat {\n\t\tsubscriptionStat {\n\t\t\ttrialCount\n\t\t\tdefaultCount\n\t\t\tpaidCount\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery SubscriptionStat {\n\t\tsubscriptionStat {\n\t\t\ttrialCount\n\t\t\tdefaultCount\n\t\t\tpaidCount\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery PaymentsStats($dateRange: DateRangeInput!) {\n\t\tpaymentsStats(dateRange: $dateRange) {\n\t\t\tdate\n\t\t\tpriceSum\n\t\t\tpriceAmount\n\t\t\tpriceQuartersSum\n\t\t\tpriceQuartersAmount\n\t\t\tpriceHalfYearSum\n\t\t\tpriceHalfYearAmount\n\t\t\tpriceYearSum\n\t\t\tpriceYearAmount\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery PaymentsStats($dateRange: DateRangeInput!) {\n\t\tpaymentsStats(dateRange: $dateRange) {\n\t\t\tdate\n\t\t\tpriceSum\n\t\t\tpriceAmount\n\t\t\tpriceQuartersSum\n\t\t\tpriceQuartersAmount\n\t\t\tpriceHalfYearSum\n\t\t\tpriceHalfYearAmount\n\t\t\tpriceYearSum\n\t\t\tpriceYearAmount\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment QuestionBase on FormQuestionResponse {\n\t\t_id\n\t\ttitle\n\t\tsubTitle\n\t\tquestionType\n\t\tvariants\n\t\thaveAnotherVariant\n\t\tisRequired\n\t}\n',
): (typeof documents)['\n\tfragment QuestionBase on FormQuestionResponse {\n\t\t_id\n\t\ttitle\n\t\tsubTitle\n\t\tquestionType\n\t\tvariants\n\t\thaveAnotherVariant\n\t\tisRequired\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment PollBase on FormTypeResponse {\n\t\t_id\n\t\tname\n\t\ttitle\n\t\tsubTitle\n\t\tquestions {\n\t\t\t...QuestionBase\n\t\t}\n\t\tisPublic\n\t\tisActive\n\t\temails\n\t\tuserIds\n\t\ttariffIds\n\t\tuserCreatedAt\n\t\thaveOzonKey\n\t\thaveWbKey\n\t\tlimit\n\t\tansweredCount\n\t}\n',
): (typeof documents)['\n\tfragment PollBase on FormTypeResponse {\n\t\t_id\n\t\tname\n\t\ttitle\n\t\tsubTitle\n\t\tquestions {\n\t\t\t...QuestionBase\n\t\t}\n\t\tisPublic\n\t\tisActive\n\t\temails\n\t\tuserIds\n\t\ttariffIds\n\t\tuserCreatedAt\n\t\thaveOzonKey\n\t\thaveWbKey\n\t\tlimit\n\t\tansweredCount\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery AllPolls($input: PagesInput!) {\n\t\tformList(input: $input) {\n\t\t\titems {\n\t\t\t\t...PollBase\n\t\t\t}\n\t\t\ttotal\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery AllPolls($input: PagesInput!) {\n\t\tformList(input: $input) {\n\t\t\titems {\n\t\t\t\t...PollBase\n\t\t\t}\n\t\t\ttotal\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation CreatePoll($input: CreateFormInput!) {\n\t\tcreateForm(input: $input) {\n\t\t\t...PollBase\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation CreatePoll($input: CreateFormInput!) {\n\t\tcreateForm(input: $input) {\n\t\t\t...PollBase\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation EditPoll($input: EditFormInput!) {\n\t\teditForm(input: $input) {\n\t\t\t...PollBase\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation EditPoll($input: EditFormInput!) {\n\t\teditForm(input: $input) {\n\t\t\t...PollBase\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation RemovePoll($formId: String!) {\n\t\tremoveForm(formId: $formId)\n\t}\n',
): (typeof documents)['\n\tmutation RemovePoll($formId: String!) {\n\t\tremoveForm(formId: $formId)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery ChangeFormActive($input: ChangeFormActiveInput!) {\n\t\tchangeFormActive(input: $input) {\n\t\t\t...PollBase\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery ChangeFormActive($input: ChangeFormActiveInput!) {\n\t\tchangeFormActive(input: $input) {\n\t\t\t...PollBase\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery PromoCodesList($input: PromoCodeListInput!) {\n\t\tpromoCodesList(input: $input) {\n\t\t\tdescription\n\t\t\tcode\n\t\t\tdiscount\n\t\t\tpaymentType\n\t\t\tutmSource\n\t\t\tlimitCount\n\t\t\tendDate\n\t\t\tuserIds\n\t\t\tapplyCount\n\t\t\tisActive\n\t\t\t_id\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery PromoCodesList($input: PromoCodeListInput!) {\n\t\tpromoCodesList(input: $input) {\n\t\t\tdescription\n\t\t\tcode\n\t\t\tdiscount\n\t\t\tpaymentType\n\t\t\tutmSource\n\t\t\tlimitCount\n\t\t\tendDate\n\t\t\tuserIds\n\t\t\tapplyCount\n\t\t\tisActive\n\t\t\t_id\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation CreatePromoCode($input: CreatePromoCodeInput!) {\n\t\tcreatePromoCode(input: $input) {\n\t\t\tcode\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation CreatePromoCode($input: CreatePromoCodeInput!) {\n\t\tcreatePromoCode(input: $input) {\n\t\t\tcode\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation RemovePromoCode($code: String!) {\n\t\tremovePromoCode(code: $code)\n\t}\n',
): (typeof documents)['\n\tmutation RemovePromoCode($code: String!) {\n\t\tremovePromoCode(code: $code)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation EditPromoCode($input: EditPromoCodeInput!) {\n\t\teditPromoCode(input: $input) {\n\t\t\tcode\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation EditPromoCode($input: EditPromoCodeInput!) {\n\t\teditPromoCode(input: $input) {\n\t\t\tcode\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment Permissions on TariffPermissionsType {\n\t\thistoryDays\n\t\thideBrands\n\t\thideSellers\n\t\tfavoritesGroupsLimit\n\t\tproductsGroupLimit\n\t\thideTopProducts\n\t\thideTopCategories\n\t\tfavoritesSummary\n\t\tsbSummary\n\t\tsbTopProducts\n\t\tsbNewItems\n\t\tsbAllProducts\n\t\tsbPresence\n\t\tanalyticsAccounts\n\t\tsearchQueriesLimit\n\t\thideOzon\n\t\tanalyticsSalesHistoryDays\n\t\tanalyticsSOHistoryDays\n\t\texportProducts\n\t\thideExtProductHistory\n\t\thideAnalyticsBlock\n\t\thideProductsSummary\n\t\thideSeo\n\t\tfavoritesLimit\n\t}\n',
): (typeof documents)['\n\tfragment Permissions on TariffPermissionsType {\n\t\thistoryDays\n\t\thideBrands\n\t\thideSellers\n\t\tfavoritesGroupsLimit\n\t\tproductsGroupLimit\n\t\thideTopProducts\n\t\thideTopCategories\n\t\tfavoritesSummary\n\t\tsbSummary\n\t\tsbTopProducts\n\t\tsbNewItems\n\t\tsbAllProducts\n\t\tsbPresence\n\t\tanalyticsAccounts\n\t\tsearchQueriesLimit\n\t\thideOzon\n\t\tanalyticsSalesHistoryDays\n\t\tanalyticsSOHistoryDays\n\t\texportProducts\n\t\thideExtProductHistory\n\t\thideAnalyticsBlock\n\t\thideProductsSummary\n\t\thideSeo\n\t\tfavoritesLimit\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment Tariff on TariffType {\n\t\t_id\n\t\tname\n\t\tdescription\n\t\tprice\n\t\tprice3\n\t\tprice6\n\t\tprice12\n\t\tisTrial\n\t\tisActive\n\t\tisPublic\n\t\tisDefault\n\t\tduration\n\t\tmarked\n\t\tcolor\n\t\tpermissions {\n\t\t\t...Permissions\n\t\t}\n\t}\n',
): (typeof documents)['\n\tfragment Tariff on TariffType {\n\t\t_id\n\t\tname\n\t\tdescription\n\t\tprice\n\t\tprice3\n\t\tprice6\n\t\tprice12\n\t\tisTrial\n\t\tisActive\n\t\tisPublic\n\t\tisDefault\n\t\tduration\n\t\tmarked\n\t\tcolor\n\t\tpermissions {\n\t\t\t...Permissions\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery Tariffs {\n\t\ttariffs {\n\t\t\t...Tariff\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery Tariffs {\n\t\ttariffs {\n\t\t\t...Tariff\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery ActiveTariffs {\n\t\tactiveTariffs {\n\t\t\t...Tariff\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery ActiveTariffs {\n\t\tactiveTariffs {\n\t\t\t...Tariff\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation AddTariffToUser(\n\t\t$userId: String!\n\t\t$tariffId: String!\n\t\t$paymentType: PaymentTypeEnum\n\t\t$withPayment: Boolean\n\t) {\n\t\taddTariffToUser(\n\t\t\tuserId: $userId\n\t\t\ttariffId: $tariffId\n\t\t\tpaymentType: $paymentType\n\t\t\twithPayment: $withPayment\n\t\t) {\n\t\t\tpaidTariff {\n\t\t\t\tuserId\n\t\t\t\ttariffId\n\t\t\t\tfromDate\n\t\t\t\ttoDate\n\t\t\t\ttotalPaid\n\t\t\t\tdatePaid\n\t\t\t}\n\n\t\t\tuser {\n\t\t\t\t_id\n\t\t\t\temail\n\t\t\t\trole\n\t\t\t\tcreatedAt\n\t\t\t\ttelegram\n\t\t\t\tisActive\n\t\t\t\tactiveTariffId\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation AddTariffToUser(\n\t\t$userId: String!\n\t\t$tariffId: String!\n\t\t$paymentType: PaymentTypeEnum\n\t\t$withPayment: Boolean\n\t) {\n\t\taddTariffToUser(\n\t\t\tuserId: $userId\n\t\t\ttariffId: $tariffId\n\t\t\tpaymentType: $paymentType\n\t\t\twithPayment: $withPayment\n\t\t) {\n\t\t\tpaidTariff {\n\t\t\t\tuserId\n\t\t\t\ttariffId\n\t\t\t\tfromDate\n\t\t\t\ttoDate\n\t\t\t\ttotalPaid\n\t\t\t\tdatePaid\n\t\t\t}\n\n\t\t\tuser {\n\t\t\t\t_id\n\t\t\t\temail\n\t\t\t\trole\n\t\t\t\tcreatedAt\n\t\t\t\ttelegram\n\t\t\t\tisActive\n\t\t\t\tactiveTariffId\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation AddTariff($newTariffInput: AddTariffInput!) {\n\t\taddTariff(newTariffInput: $newTariffInput) {\n\t\t\t...Tariff\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation AddTariff($newTariffInput: AddTariffInput!) {\n\t\taddTariff(newTariffInput: $newTariffInput) {\n\t\t\t...Tariff\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation EditTariff($tariffId: String!, $editTariffInput: EditTariffInput!) {\n\t\teditTariff(tariffId: $tariffId, editTariffInput: $editTariffInput) {\n\t\t\t...Tariff\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation EditTariff($tariffId: String!, $editTariffInput: EditTariffInput!) {\n\t\teditTariff(tariffId: $tariffId, editTariffInput: $editTariffInput) {\n\t\t\t...Tariff\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation EditTariffPermission(\n\t\t$historyDays: Int\n\t\t$hideBrands: Boolean\n\t\t$hideSellers: Boolean\n\t\t$favoritesGroupsLimit: Int\n\t\t$productsGroupLimit: Int\n\t\t$hideTopProducts: Boolean\n\t\t$hideTopCategories: Boolean\n\t\t$favoritesSummary: Boolean\n\t\t$sbSummary: Boolean\n\t\t$sbTopProducts: Boolean\n\t\t$sbNewItems: Boolean\n\t\t$sbAllProducts: Boolean\n\t\t$sbPresence: Boolean\n\t\t$analyticsAccounts: Int\n\t\t$searchQueriesLimit: Int\n\t\t$hideOzon: Boolean\n\t\t$analyticsSalesHistoryDays: Int\n\t\t$analyticsSOHistoryDays: Int\n\t\t$exportProducts: Int\n\t\t$hideExtProductHistory: Boolean\n\t\t$tariffId: String!\n\t\t$hideAnalyticsBlock: Boolean\n\t\t$hideProductsSummary: Boolean\n\t\t$hideSeo: Boolean\n\t\t$favoritesLimit: Int\n\t) {\n\t\teditTariffPermission(\n\t\t\thistoryDays: $historyDays\n\t\t\thideBrands: $hideBrands\n\t\t\thideSellers: $hideSellers\n\t\t\tfavoritesGroupsLimit: $favoritesGroupsLimit\n\t\t\tproductsGroupLimit: $productsGroupLimit\n\t\t\thideTopProducts: $hideTopProducts\n\t\t\thideTopCategories: $hideTopCategories\n\t\t\tfavoritesSummary: $favoritesSummary\n\t\t\tsbSummary: $sbSummary\n\t\t\tsbTopProducts: $sbTopProducts\n\t\t\tsbNewItems: $sbNewItems\n\t\t\tsbAllProducts: $sbAllProducts\n\t\t\tsbPresence: $sbPresence\n\t\t\tanalyticsAccounts: $analyticsAccounts\n\t\t\tsearchQueriesLimit: $searchQueriesLimit\n\t\t\thideOzon: $hideOzon\n\t\t\tanalyticsSalesHistoryDays: $analyticsSalesHistoryDays\n\t\t\tanalyticsSOHistoryDays: $analyticsSOHistoryDays\n\t\t\texportProducts: $exportProducts\n\t\t\thideExtProductHistory: $hideExtProductHistory\n\t\t\ttariffId: $tariffId\n\t\t\thideAnalyticsBlock: $hideAnalyticsBlock\n\t\t\thideProductsSummary: $hideProductsSummary\n\t\t\thideSeo: $hideSeo\n\t\t\tfavoritesLimit: $favoritesLimit\n\t\t) {\n\t\t\t...Tariff\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation EditTariffPermission(\n\t\t$historyDays: Int\n\t\t$hideBrands: Boolean\n\t\t$hideSellers: Boolean\n\t\t$favoritesGroupsLimit: Int\n\t\t$productsGroupLimit: Int\n\t\t$hideTopProducts: Boolean\n\t\t$hideTopCategories: Boolean\n\t\t$favoritesSummary: Boolean\n\t\t$sbSummary: Boolean\n\t\t$sbTopProducts: Boolean\n\t\t$sbNewItems: Boolean\n\t\t$sbAllProducts: Boolean\n\t\t$sbPresence: Boolean\n\t\t$analyticsAccounts: Int\n\t\t$searchQueriesLimit: Int\n\t\t$hideOzon: Boolean\n\t\t$analyticsSalesHistoryDays: Int\n\t\t$analyticsSOHistoryDays: Int\n\t\t$exportProducts: Int\n\t\t$hideExtProductHistory: Boolean\n\t\t$tariffId: String!\n\t\t$hideAnalyticsBlock: Boolean\n\t\t$hideProductsSummary: Boolean\n\t\t$hideSeo: Boolean\n\t\t$favoritesLimit: Int\n\t) {\n\t\teditTariffPermission(\n\t\t\thistoryDays: $historyDays\n\t\t\thideBrands: $hideBrands\n\t\t\thideSellers: $hideSellers\n\t\t\tfavoritesGroupsLimit: $favoritesGroupsLimit\n\t\t\tproductsGroupLimit: $productsGroupLimit\n\t\t\thideTopProducts: $hideTopProducts\n\t\t\thideTopCategories: $hideTopCategories\n\t\t\tfavoritesSummary: $favoritesSummary\n\t\t\tsbSummary: $sbSummary\n\t\t\tsbTopProducts: $sbTopProducts\n\t\t\tsbNewItems: $sbNewItems\n\t\t\tsbAllProducts: $sbAllProducts\n\t\t\tsbPresence: $sbPresence\n\t\t\tanalyticsAccounts: $analyticsAccounts\n\t\t\tsearchQueriesLimit: $searchQueriesLimit\n\t\t\thideOzon: $hideOzon\n\t\t\tanalyticsSalesHistoryDays: $analyticsSalesHistoryDays\n\t\t\tanalyticsSOHistoryDays: $analyticsSOHistoryDays\n\t\t\texportProducts: $exportProducts\n\t\t\thideExtProductHistory: $hideExtProductHistory\n\t\t\ttariffId: $tariffId\n\t\t\thideAnalyticsBlock: $hideAnalyticsBlock\n\t\t\thideProductsSummary: $hideProductsSummary\n\t\t\thideSeo: $hideSeo\n\t\t\tfavoritesLimit: $favoritesLimit\n\t\t) {\n\t\t\t...Tariff\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation AddFreeDaysToTariff($input: FreeDaysInput!) {\n\t\taddFreeDaysToTariff(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation AddFreeDaysToTariff($input: FreeDaysInput!) {\n\t\taddFreeDaysToTariff(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment UserBase on UserType {\n\t\t_id\n\t\temail\n\t\tname\n\t\trole\n\t\tcreatedAt\n\t\tphone\n\t\tisActive\n\t\tactiveTariffId\n\t\twbApiKeys {\n\t\t\tkey\n\t\t\tkeyId\n\t\t\tname\n\t\t}\n\t\tpaidTariffs {\n\t\t\tuserId\n\t\t\ttariffId\n\t\t\tfromDate\n\t\t\ttoDate\n\t\t\ttotalPaid\n\t\t\tdatePaid\n\t\t}\n\t\tisEmailVerified\n\t\tisPhoneVerified\n\t\tsubscription {\n\t\t\ttoDate\n\t\t}\n\t}\n',
): (typeof documents)['\n\tfragment UserBase on UserType {\n\t\t_id\n\t\temail\n\t\tname\n\t\trole\n\t\tcreatedAt\n\t\tphone\n\t\tisActive\n\t\tactiveTariffId\n\t\twbApiKeys {\n\t\t\tkey\n\t\t\tkeyId\n\t\t\tname\n\t\t}\n\t\tpaidTariffs {\n\t\t\tuserId\n\t\t\ttariffId\n\t\t\tfromDate\n\t\t\ttoDate\n\t\t\ttotalPaid\n\t\t\tdatePaid\n\t\t}\n\t\tisEmailVerified\n\t\tisPhoneVerified\n\t\tsubscription {\n\t\t\ttoDate\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation Login($email: String!, $password: String!) {\n\t\tloginByEmail(email: $email, password: $password, asAdmin: true) {\n\t\t\tuser {\n\t\t\t\trole\n\t\t\t}\n\t\t\ttoken\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation Login($email: String!, $password: String!) {\n\t\tloginByEmail(email: $email, password: $password, asAdmin: true) {\n\t\t\tuser {\n\t\t\t\trole\n\t\t\t}\n\t\t\ttoken\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation Logout {\n\t\tlogout\n\t}\n',
): (typeof documents)['\n\tmutation Logout {\n\t\tlogout\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery Me {\n\t\tme {\n\t\t\t_id\n\t\t\temail\n\t\t\trole\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery Me {\n\t\tme {\n\t\t\t_id\n\t\t\temail\n\t\t\trole\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery Users(\n\t\t$filter: UserFilter\n\t\t$skip: Float\n\t\t$limit: Float\n\t\t$search: String\n\t\t$sort: UserSort\n\t\t$sortDirection: SortDirection\n\t) {\n\t\tusers(\n\t\t\tfilter: $filter\n\t\t\tskip: $skip\n\t\t\tlimit: $limit\n\t\t\tsearch: $search\n\t\t\tsort: $sort\n\t\t\tsortDirection: $sortDirection\n\t\t) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\t...UserBase\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery Users(\n\t\t$filter: UserFilter\n\t\t$skip: Float\n\t\t$limit: Float\n\t\t$search: String\n\t\t$sort: UserSort\n\t\t$sortDirection: SortDirection\n\t) {\n\t\tusers(\n\t\t\tfilter: $filter\n\t\t\tskip: $skip\n\t\t\tlimit: $limit\n\t\t\tsearch: $search\n\t\t\tsort: $sort\n\t\t\tsortDirection: $sortDirection\n\t\t) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\t...UserBase\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation EditUser(\n\t\t$id: ID!\n\t\t$isActive: Boolean!\n\t\t$email: String!\n\t\t$isEmailVerified: Boolean!\n\t\t$phone: String!\n\t\t$isPhoneVerified: Boolean!\n\t\t$name: String!\n\t) {\n\t\teditUser(\n\t\t\tid: $id\n\t\t\tisActive: $isActive\n\t\t\temail: $email\n\t\t\tisEmailVerified: $isEmailVerified\n\t\t\tphone: $phone\n\t\t\tisPhoneVerified: $isPhoneVerified\n\t\t\tname: $name\n\t\t) {\n\t\t\t...UserBase\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation EditUser(\n\t\t$id: ID!\n\t\t$isActive: Boolean!\n\t\t$email: String!\n\t\t$isEmailVerified: Boolean!\n\t\t$phone: String!\n\t\t$isPhoneVerified: Boolean!\n\t\t$name: String!\n\t) {\n\t\teditUser(\n\t\t\tid: $id\n\t\t\tisActive: $isActive\n\t\t\temail: $email\n\t\t\tisEmailVerified: $isEmailVerified\n\t\t\tphone: $phone\n\t\t\tisPhoneVerified: $isPhoneVerified\n\t\t\tname: $name\n\t\t) {\n\t\t\t...UserBase\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation RemoveUser($userId: ID!) {\n\t\tremoveUser(userId: $userId) {\n\t\t\t_id\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation RemoveUser($userId: ID!) {\n\t\tremoveUser(userId: $userId) {\n\t\t\t_id\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery UsersStatResp {\n\t\tusersStat {\n\t\t\tregistered\n\t\t\tphoneVerified\n\t\t\temailVerified\n\t\t\tnotActivated\n\t\t\tozonKeys\n\t\t\twbKeys\n\t\t\tuserWithAnalyticsKey\n\t\t}\n\n\t\textStats {\n\t\t\tclickedFromExtNotificationCount\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery UsersStatResp {\n\t\tusersStat {\n\t\t\tregistered\n\t\t\tphoneVerified\n\t\t\temailVerified\n\t\t\tnotActivated\n\t\t\tozonKeys\n\t\t\twbKeys\n\t\t\tuserWithAnalyticsKey\n\t\t}\n\n\t\textStats {\n\t\t\tclickedFromExtNotificationCount\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery UserToken($id: ID!) {\n\t\tuserToken(id: $id)\n\t}\n',
): (typeof documents)['\n\tquery UserToken($id: ID!) {\n\t\tuserToken(id: $id)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery RegistrationStats($dateRange: DateRangeInput!, $type: UserTypeFilter) {\n\t\tregistrationStats(dateRange: $dateRange, type: $type) {\n\t\t\tgroupedByTime\n\t\t\tregistrationStats {\n\t\t\t\tdate\n\t\t\t\tnewRegistrations\n\t\t\t\temailsConfirmed\n\t\t\t\tphonesConfirmed\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery RegistrationStats($dateRange: DateRangeInput!, $type: UserTypeFilter) {\n\t\tregistrationStats(dateRange: $dateRange, type: $type) {\n\t\t\tgroupedByTime\n\t\t\tregistrationStats {\n\t\t\t\tdate\n\t\t\t\tnewRegistrations\n\t\t\t\temailsConfirmed\n\t\t\t\tphonesConfirmed\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation ConfirmPhoneByCode($code: String!, $phone: String!) {\n\t\tconfirmPhoneByCode(code: $code, phone: $phone) {\n\t\t\ttoken\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation ConfirmPhoneByCode($code: String!, $phone: String!) {\n\t\tconfirmPhoneByCode(code: $code, phone: $phone) {\n\t\t\ttoken\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation SendPhoneVerificationCodeBySms($phone: String!) {\n\t\tsendPhoneVerificationCodeBySms(phone: $phone)\n\t}\n',
): (typeof documents)['\n\tmutation SendPhoneVerificationCodeBySms($phone: String!) {\n\t\tsendPhoneVerificationCodeBySms(phone: $phone)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation LoginByPhone($phone: String!) {\n\t\tloginByPhone(phone: $phone, asAdmin: true)\n\t}\n',
): (typeof documents)['\n\tmutation LoginByPhone($phone: String!) {\n\t\tloginByPhone(phone: $phone, asAdmin: true)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery AnalyticsKeyStats($dateRange: DateRangeInput!) {\n\t\tanalyticsKeyStats(dateRange: $dateRange) {\n\t\t\tgroupedByTime\n\t\t\tanalyticsKeyStats {\n\t\t\t\tdate\n\t\t\t\twbKeyCount\n\t\t\t\tozonKeyCount\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery AnalyticsKeyStats($dateRange: DateRangeInput!) {\n\t\tanalyticsKeyStats(dateRange: $dateRange) {\n\t\t\tgroupedByTime\n\t\t\tanalyticsKeyStats {\n\t\t\t\tdate\n\t\t\t\twbKeyCount\n\t\t\t\tozonKeyCount\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery ActiveUsersStatsDAU($dateRange: DateRangeInput!, $device: DeviceFilter) {\n\t\tactiveUsersStatsDAU: activeUsersStat(dateRange: $dateRange, device: $device) {\n\t\t\tdate\n\t\t\tcount\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery ActiveUsersStatsDAU($dateRange: DateRangeInput!, $device: DeviceFilter) {\n\t\tactiveUsersStatsDAU: activeUsersStat(dateRange: $dateRange, device: $device) {\n\t\t\tdate\n\t\t\tcount\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery ActiveUsersStatsMAU($dateRange: DateRangeInput!, $device: DeviceFilter) {\n\t\tactiveUsersStatsMAU: activeUsersStat(\n\t\t\tdateRange: $dateRange\n\t\t\tmonthly: true\n\t\t\tdevice: $device\n\t\t) {\n\t\t\tdate\n\t\t\tcount\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery ActiveUsersStatsMAU($dateRange: DateRangeInput!, $device: DeviceFilter) {\n\t\tactiveUsersStatsMAU: activeUsersStat(\n\t\t\tdateRange: $dateRange\n\t\t\tmonthly: true\n\t\t\tdevice: $device\n\t\t) {\n\t\t\tdate\n\t\t\tcount\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery GetUtmSourceStats($input: UtmSourceStatsInput!) {\n\t\tgetUtmSourceStats(input: $input) {\n\t\t\tsource\n\t\t\tdateToShow\n\t\t\tpaymentAmount\n\t\t\tpaymentCount\n\t\t\tregistrationCount\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery GetUtmSourceStats($input: UtmSourceStatsInput!) {\n\t\tgetUtmSourceStats(input: $input) {\n\t\t\tsource\n\t\t\tdateToShow\n\t\t\tpaymentAmount\n\t\t\tpaymentCount\n\t\t\tregistrationCount\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation UpdateUtmSource($input: UpdateUtmSourceInput!) {\n\t\tupdateUtmSource(input: $input) {\n\t\t\t_id\n\t\t\tsource\n\t\t\tmedium\n\t\t\tcampaign\n\t\t\tterm\n\t\t\tcontent\n\t\t\tprice\n\t\t\tcomment\n\t\t\tusersCount\n\t\t\tpaymentAmount\n\t\t\tpaymentCount\n\t\t\tpayersCount\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation UpdateUtmSource($input: UpdateUtmSourceInput!) {\n\t\tupdateUtmSource(input: $input) {\n\t\t\t_id\n\t\t\tsource\n\t\t\tmedium\n\t\t\tcampaign\n\t\t\tterm\n\t\t\tcontent\n\t\t\tprice\n\t\t\tcomment\n\t\t\tusersCount\n\t\t\tpaymentAmount\n\t\t\tpaymentCount\n\t\t\tpayersCount\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation CreateUtmSource($input: CreateUtmSourceInput!) {\n\t\tcreateUtmSource(input: $input) {\n\t\t\t_id\n\t\t\tsource\n\t\t\tmedium\n\t\t\tcampaign\n\t\t\tterm\n\t\t\tcontent\n\t\t\tprice\n\t\t\tcomment\n\t\t\tusersCount\n\t\t\tpaymentAmount\n\t\t\tpaymentCount\n\t\t\tpayersCount\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation CreateUtmSource($input: CreateUtmSourceInput!) {\n\t\tcreateUtmSource(input: $input) {\n\t\t\t_id\n\t\t\tsource\n\t\t\tmedium\n\t\t\tcampaign\n\t\t\tterm\n\t\t\tcontent\n\t\t\tprice\n\t\t\tcomment\n\t\t\tusersCount\n\t\t\tpaymentAmount\n\t\t\tpaymentCount\n\t\t\tpayersCount\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery GetUtmSourceList($input: UtmListOptionsInput!) {\n\t\tgetUtmSourceList(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\t_id\n\t\t\t\tprice\n\t\t\t\tcomment\n\t\t\t\troot\n\t\t\t\tsource\n\t\t\t\tcampaign\n\t\t\t\tmedium\n\t\t\t\tterm\n\t\t\t\tcontent\n\t\t\t\tusersCount\n\t\t\t\tpaymentAmount\n\t\t\t\tpaymentCount\n\t\t\t\tpayersCount\n\t\t\t\tcampaignStats {\n\t\t\t\t\t_id\n\t\t\t\t\tprice\n\t\t\t\t\tcomment\n\t\t\t\t\troot\n\t\t\t\t\tsource\n\t\t\t\t\tcampaign\n\t\t\t\t\tmedium\n\t\t\t\t\tterm\n\t\t\t\t\tcontent\n\t\t\t\t\tusersCount\n\t\t\t\t\tpaymentAmount\n\t\t\t\t\tpaymentCount\n\t\t\t\t\tpayersCount\n\t\t\t\t\tmediumStats {\n\t\t\t\t\t\t_id\n\t\t\t\t\t\tprice\n\t\t\t\t\t\tcomment\n\t\t\t\t\t\troot\n\t\t\t\t\t\tsource\n\t\t\t\t\t\tcampaign\n\t\t\t\t\t\tmedium\n\t\t\t\t\t\tterm\n\t\t\t\t\t\tcontent\n\t\t\t\t\t\tusersCount\n\t\t\t\t\t\tpaymentAmount\n\t\t\t\t\t\tpaymentCount\n\t\t\t\t\t\tpayersCount\n\t\t\t\t\t\tcontentStats {\n\t\t\t\t\t\t\t_id\n\t\t\t\t\t\t\tprice\n\t\t\t\t\t\t\tcomment\n\t\t\t\t\t\t\troot\n\t\t\t\t\t\t\tsource\n\t\t\t\t\t\t\tcampaign\n\t\t\t\t\t\t\tmedium\n\t\t\t\t\t\t\tterm\n\t\t\t\t\t\t\tcontent\n\t\t\t\t\t\t\tusersCount\n\t\t\t\t\t\t\tpaymentAmount\n\t\t\t\t\t\t\tpaymentCount\n\t\t\t\t\t\t\tpayersCount\n\t\t\t\t\t\t\ttermStats {\n\t\t\t\t\t\t\t\t_id\n\t\t\t\t\t\t\t\tprice\n\t\t\t\t\t\t\t\tcomment\n\t\t\t\t\t\t\t\troot\n\t\t\t\t\t\t\t\tsource\n\t\t\t\t\t\t\t\tcampaign\n\t\t\t\t\t\t\t\tmedium\n\t\t\t\t\t\t\t\tterm\n\t\t\t\t\t\t\t\tcontent\n\t\t\t\t\t\t\t\tusersCount\n\t\t\t\t\t\t\t\tpaymentAmount\n\t\t\t\t\t\t\t\tpaymentCount\n\t\t\t\t\t\t\t\tpayersCount\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery GetUtmSourceList($input: UtmListOptionsInput!) {\n\t\tgetUtmSourceList(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\t_id\n\t\t\t\tprice\n\t\t\t\tcomment\n\t\t\t\troot\n\t\t\t\tsource\n\t\t\t\tcampaign\n\t\t\t\tmedium\n\t\t\t\tterm\n\t\t\t\tcontent\n\t\t\t\tusersCount\n\t\t\t\tpaymentAmount\n\t\t\t\tpaymentCount\n\t\t\t\tpayersCount\n\t\t\t\tcampaignStats {\n\t\t\t\t\t_id\n\t\t\t\t\tprice\n\t\t\t\t\tcomment\n\t\t\t\t\troot\n\t\t\t\t\tsource\n\t\t\t\t\tcampaign\n\t\t\t\t\tmedium\n\t\t\t\t\tterm\n\t\t\t\t\tcontent\n\t\t\t\t\tusersCount\n\t\t\t\t\tpaymentAmount\n\t\t\t\t\tpaymentCount\n\t\t\t\t\tpayersCount\n\t\t\t\t\tmediumStats {\n\t\t\t\t\t\t_id\n\t\t\t\t\t\tprice\n\t\t\t\t\t\tcomment\n\t\t\t\t\t\troot\n\t\t\t\t\t\tsource\n\t\t\t\t\t\tcampaign\n\t\t\t\t\t\tmedium\n\t\t\t\t\t\tterm\n\t\t\t\t\t\tcontent\n\t\t\t\t\t\tusersCount\n\t\t\t\t\t\tpaymentAmount\n\t\t\t\t\t\tpaymentCount\n\t\t\t\t\t\tpayersCount\n\t\t\t\t\t\tcontentStats {\n\t\t\t\t\t\t\t_id\n\t\t\t\t\t\t\tprice\n\t\t\t\t\t\t\tcomment\n\t\t\t\t\t\t\troot\n\t\t\t\t\t\t\tsource\n\t\t\t\t\t\t\tcampaign\n\t\t\t\t\t\t\tmedium\n\t\t\t\t\t\t\tterm\n\t\t\t\t\t\t\tcontent\n\t\t\t\t\t\t\tusersCount\n\t\t\t\t\t\t\tpaymentAmount\n\t\t\t\t\t\t\tpaymentCount\n\t\t\t\t\t\t\tpayersCount\n\t\t\t\t\t\t\ttermStats {\n\t\t\t\t\t\t\t\t_id\n\t\t\t\t\t\t\t\tprice\n\t\t\t\t\t\t\t\tcomment\n\t\t\t\t\t\t\t\troot\n\t\t\t\t\t\t\t\tsource\n\t\t\t\t\t\t\t\tcampaign\n\t\t\t\t\t\t\t\tmedium\n\t\t\t\t\t\t\t\tterm\n\t\t\t\t\t\t\t\tcontent\n\t\t\t\t\t\t\t\tusersCount\n\t\t\t\t\t\t\t\tpaymentAmount\n\t\t\t\t\t\t\t\tpaymentCount\n\t\t\t\t\t\t\t\tpayersCount\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n'];

export function graphql(source: string) {
	return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
	TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
