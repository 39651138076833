import React, {useCallback, useMemo, useState} from 'react';
import {bool, number, object, SchemaOf} from 'yup';
import {
	Alert,
	AlertTitle,
	Button,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormHelperText,
	Grid,
	InputAdornment,
	InputBaseComponentProps,
	TextField,
	Typography,
} from '@mui/material';
import {FormikHelpers, FormikProvider, useFormik} from 'formik';
import {mapValues, omit} from 'lodash';

import Checkbox from 'components/common/ui/Checkbox';
import Tooltip from 'components/common/ui/Tooltip';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import {useEditTariffPermissionMutation} from 'api/tariffs';
import appConsts from 'constants/appConsts';
import css from './EditTariffPermissionsModal.pcss';
import {
	EditTariffPermissionMutationVariables,
	TariffPermissionsType,
} from '../../../../gqlApp/graphql';

interface IEditTariffPermissionsModal {
	permissions?: TariffPermissionsType | null;
	isOpen: boolean;
	onClose: () => void;
	tariff: string;
	tariffId: string;
}

interface IFormikValues extends Omit<EditTariffPermissionMutationVariables, 'tariffId'> {}

const validationSchema: SchemaOf<IFormikValues> = object().shape({
	historyDays: number().nullable(),
	hideBrands: bool().nullable(),
	hideSellers: bool().nullable(),
	favoritesGroupsLimit: number().nullable(),
	productsGroupLimit: number().nullable(),
	hideTopProducts: bool().nullable(),
	hideTopCategories: bool().nullable(),
	favoritesSummary: bool().nullable(),
	sbSummary: bool().nullable(),
	sbTopProducts: bool().nullable(),
	sbNewItems: bool().nullable(),
	sbAllProducts: bool().nullable(),
	sbPresence: bool().nullable(),
	analyticsAccounts: number().nullable(),
	searchQueriesLimit: number().nullable(),
	hideOzon: bool().nullable(),
	analyticsSalesHistoryDays: number().nullable(),
	analyticsSOHistoryDays: number().nullable(),
	exportProducts: number().nullable(),
	hideExtProductHistory: bool().nullable(),
	hideAnalyticsBlock: bool().nullable(),
	hideProductsSummary: bool().nullable(),
	hideSeo: bool().nullable(),
	favoritesLimit: number().nullable(),
});

const EditTariffPermissionsModal: React.FC<IEditTariffPermissionsModal> = ({
	isOpen,
	onClose,
	permissions,
	tariff,
	tariffId,
}) => {
	const [error, setError] = useState('');
	const initialValues: IFormikValues = useMemo(
		() => omit(permissions, 'tariffId'),
		[permissions],
	);

	const handleClose = () => {
		setError('');
		onClose();
	};

	const [editTariffPermissionMutation, {loading}] = useEditTariffPermissionMutation();

	const [openAlert, setOpenAlert] = useState(
		!localStorage.getItem(appConsts.STORAGE.ALERT.PERMISSIONS_CLOSED) && true,
	);

	const onSubmitHandler = (args: IFormikValues, actions: FormikHelpers<IFormikValues>) => {
		editTariffPermissionMutation({
			variables: {
				tariffId,
				...(mapValues(args, (v) =>
					typeof v === 'string' ? parseInt(v, 10) : v,
				) as IFormikValues),
			},
		})
			.then(() => {
				setError('');
				onClose();
			})
			.catch((err) => {
				console.log(JSON.stringify(err));
				actions.setSubmitting(false);
				setError('Ошибка запроса');
			});
	};

	const handleDelete = (actions: FormikHelpers<IFormikValues>) => () => {
		actions.setSubmitting(true);
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: onSubmitHandler,
	});

	const numericTypeInputProps = {
		inputMode: 'numeric',
		pattern: '[0-9]*',
	} as InputBaseComponentProps;

	const unitsInputProps = (units?: string) => ({
		endAdornment: <InputAdornment position="end">{units}</InputAdornment>,
	});

	const handleInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) =>
			formik.setFieldValue(
				e.target.name,
				e.target.value,
				!!Object.keys(formik.errors).length,
			),
		[formik],
	);

	return (
		<FormikProvider value={formik}>
			<Dialog open={isOpen} onClose={onClose} maxWidth={'lg'}>
				<DialogTitle>
					{' '}
					Разрешения тарифа <b>{tariff}</b>
				</DialogTitle>

				<DialogContent>
					<Collapse in={openAlert}>
						<Alert
							severity={'info'}
							onClose={() => {
								setOpenAlert(false);

								localStorage.setItem(
									appConsts.STORAGE.ALERT.PERMISSIONS_CLOSED,
									'true',
								);
							}}
						>
							<AlertTitle> Для ввода </AlertTitle>
							<b>Закрыть доступ</b> - введите в поле 0 или поставьте галочку <br />
							<b>Ограничить доступ </b>- введите значения (от 1 до ~180) <br />{' '}
							<b>Открыть доступ </b>- оставьте значение в поле пустым или уберите
							галочку
						</Alert>
					</Collapse>

					<form onSubmit={formik.handleSubmit}>
						<Grid container style={{marginTop: 20}}>
							{/*<Grid item xs={6} key={'Категории'} className={css.tariffSection}>*/}
							{/*	<DialogContentText>*/}
							{/*		<b>Бот Shopstat в телеграмм</b>*/}
							{/*	</DialogContentText>*/}
							{/*	<Grid container alignItems="center" spacing={2}>*/}
							{/*		{' '}*/}
							{/*		<Grid item xs>*/}
							{/*			{' '}*/}
							{/*		</Grid>*/}
							{/*	</Grid>*/}
							{/*</Grid>*/}

							<Grid item xs={6} key={'Мои продажи'} className={css.tariffSection}>
								<DialogContentText>
									<b>Мои продажи</b>
								</DialogContentText>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										{' '}
										Ключи WB / Ozon{' '}
										<Tooltip
											small={true}
											content={
												<div>
													Чтобы ограничить добавление ключей озон,
													поставьте галочку в поле{' '}
													<b>Скрыть раздел Озон</b>
												</div>
											}
										>
											<EmojiObjectsOutlinedIcon className={css.icon} />
										</Tooltip>
									</Grid>
									<Grid item xs>
										<TextField
											name="analyticsAccounts"
											inputProps={{
												...numericTypeInputProps,
											}}
											InputProps={{...unitsInputProps('шт')}}
											margin="normal"
											value={formik.values.analyticsAccounts}
											onChange={handleInputChange}
											error={!!formik.errors.analyticsAccounts}
											helperText={formik.errors?.analyticsAccounts}
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									<Grid item xs>
										Продажи{' '}
										<Tooltip
											small={true}
											content={
												<ul>
													<li>
														<b>Динамика продаж </b>(4 графика: Выручка,
														Заказали (сумма), К оплате продавцу,
														Заказали (товар) )
													</li>
													<li>
														<b>История продаж</b> (Календарь, Динамика
														продаж, Статистика продаж, К оплате по
														брендам)
													</li>
													<li>
														<b>Карта продаж </b> (Регион, продажи,
														выручка)
													</li>
													<li>
														<b>Логистика</b> (Диаграмма, таблица К
														оплате за логистику)
													</li>
												</ul>
											}
										>
											<EmojiObjectsOutlinedIcon className={css.icon} />
										</Tooltip>
									</Grid>
									<Grid item xs>
										<TextField
											name="analyticsSalesHistoryDays"
											inputProps={{
												...numericTypeInputProps,
											}}
											InputProps={{...unitsInputProps('дней')}}
											margin="normal"
											value={formik.values.analyticsSalesHistoryDays}
											onChange={handleInputChange}
											error={!!formik.errors.analyticsSalesHistoryDays}
											helperText={formik.errors?.analyticsSalesHistoryDays}
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										{' '}
										Заказы{' '}
										<Tooltip
											small={true}
											content={
												<ul>
													<li>
														<b>История заказов</b> (Календарь, Динамика
														заказов, Статистика заказов)
													</li>
													<li>
														<b>Планировщик заказа WB </b> (ozon в
														разработке)
													</li>
													<li>
														<b>Склад WB</b> (ozon в разработке)
													</li>
													<li>
														<b>Движение товара WB</b> (ozon в
														разработке)
													</li>
												</ul>
											}
										>
											<EmojiObjectsOutlinedIcon className={css.icon} />
										</Tooltip>
									</Grid>
									<Grid item xs>
										<TextField
											name="analyticsSOHistoryDays"
											inputProps={{
												...numericTypeInputProps,
											}}
											InputProps={{...unitsInputProps('дней')}}
											margin="normal"
											value={formik.values.analyticsSOHistoryDays}
											onChange={handleInputChange}
											error={!!formik.errors.analyticsSOHistoryDays}
											helperText={formik.errors?.analyticsSOHistoryDays}
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										Скрыть сводку
										<Tooltip
											small={true}
											content={
												<div>
													Сводка Мои продажи
													{/*<li>Сводка в телеграмм Боте Shopstat</li>*/}
												</div>
											}
										>
											<EmojiObjectsOutlinedIcon className={css.icon} />
										</Tooltip>
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											label={''}
											name="hideAnalyticsBlock"
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={6} key={'Маркетплейсы'} className={css.tariffSection}>
								<DialogContentText>
									{' '}
									<b>Маркетплейсы</b>
								</DialogContentText>

								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										{' '}
										Скрыть Поставщиков{' '}
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											name="hideSellers"
											checked={formik.values.hideSellers ?? false}
											label=""
										/>
									</Grid>
								</Grid>

								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										{' '}
										Скрыть Бренды
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											name="hideBrands"
											checked={formik.values.hideBrands ?? false}
											label=""
										/>
									</Grid>
								</Grid>

								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										{' '}
										Экспорт товаров в CSV
									</Grid>
									<Grid item xs>
										<TextField
											name="exportProducts"
											inputProps={{
												...numericTypeInputProps,
											}}
											InputProps={{...unitsInputProps('страниц')}}
											margin="normal"
											value={formik.values.exportProducts}
											onChange={handleInputChange}
											error={!!formik.errors.exportProducts}
											helperText={formik.errors?.exportProducts}
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									<Grid item xs>
										{' '}
										Категории и карточка товара{' '}
										<Tooltip
											small={true}
											content={
												<>
													{' '}
													Главная:
													<ul>
														<li>
															<b>Сводка по маркетплейсам </b>
														</li>
														<li>
															<b>Оборот маркетплейсов </b>(график)
														</li>
													</ul>
													Категории:
													<ul>
														<li>
															<b>Подкатегории</b> (Диаграмма Продажи,
															Диаграмма Выручка, Общая таблица)
														</li>
														<li>
															<b>Бренды</b> (Диаграмма Продажи,
															Диаграмма Выручка, Общая таблица)
														</li>
														<li>
															<b>Тренд</b> (Диаграмма Сравнение,
															Таблица)
														</li>
														<li>
															<b>По дням</b> (Диаграмма Сравнение,
															Таблица)
														</li>
													</ul>
													Карточка товара:
													<ul>
														<li>
															<b>Динамика по продажам</b>
														</li>
														<li>
															<b>Отслеживания изменений контента</b>
														</li>
														<li>
															<b>Статистика товара</b>
														</li>
														<li>
															<b>Категории и позиции</b>
														</li>
														<li>
															<b>Продажи и остатки по складам</b>
														</li>
													</ul>
												</>
											}
										>
											<EmojiObjectsOutlinedIcon className={css.icon} />
										</Tooltip>
									</Grid>
									<Grid item xs>
										<TextField
											name="historyDays"
											inputProps={{
												...numericTypeInputProps,
												title: 'Целые числа',
											}}
											InputProps={{...unitsInputProps('дней')}}
											margin="normal"
											value={formik.values.historyDays}
											onChange={handleInputChange}
											error={!!formik.errors.historyDays}
											helperText={formik.errors.historyDays}
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										{' '}
										Скрыть Топ товаров за вчерашний день{' '}
									</Grid>{' '}
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											label={''}
											name="hideTopProducts"
											checked={formik.values.hideTopProducts ?? false}
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										Скрыть Топ категорий за вчерашний день{' '}
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											label={''}
											name="hideTopCategories"
											checked={formik.values.hideTopCategories ?? false}
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs className={css.inDevelop}>
										FBS{' '}
										<Tooltip
											content={
												<div>Возможность просматривать продажи без FBS</div>
											}
										>
											<EmojiObjectsOutlinedIcon className={css.icon} />
										</Tooltip>
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											disabled
											label={''}
											name=""
											checked={false}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={6} key={'Карточка товара'} className={css.tariffSection}>
								<DialogContentText>
									<b>Карточка товара</b>
								</DialogContentText>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										{' '}
										Кол-во запросов в "Позиции товара по поисковым запросам"{' '}
									</Grid>
									<Grid item xs>
										<TextField
											name="searchQueriesLimit"
											inputProps={{
												...numericTypeInputProps,
											}}
											InputProps={{...unitsInputProps('шт')}}
											margin="normal"
											value={formik.values.searchQueriesLimit}
											onChange={handleInputChange}
											error={!!formik.errors.searchQueriesLimit}
											helperText={formik.errors?.searchQueriesLimit}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid
								item
								xs={6}
								key={'Карточка продавца / бренда'}
								className={css.tariffSection}
							>
								<DialogContentText>
									<b>Карточка продавца / бренда</b>
								</DialogContentText>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										{' '}
										Скрыть Cводку{' '}
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											name="sbSummary"
											checked={formik.values.sbSummary ?? false}
											label=""
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										{' '}
										Скрыть Топ 5 товаров (Данные за 30 дней){' '}
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											name="sbTopProducts"
											checked={formik.values.sbTopProducts ?? false}
											label=""
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										Скрыть Присутствие{' '}
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											name="sbPresence"
											checked={formik.values.sbPresence ?? false}
											label=""
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										{' '}
										Скрыть Новые товары{' '}
									</Grid>{' '}
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											name="sbNewItems"
											checked={formik.values.sbNewItems ?? false}
											label=""
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										{' '}
										Скрыть Все товары
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											name="sbAllProducts"
											checked={formik.values.sbAllProducts ?? false}
											label=""
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								item
								xs={6}
								key={'Расширение для браузера'}
								className={css.tariffSection}
							>
								<DialogContentText>
									<b>Расширение для браузера</b>
								</DialogContentText>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										Скрыть Превью КТ. Категории
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											label={''}
											name="hideProductsSummary"
										/>
									</Grid>
								</Grid>

								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										Скрыть графики Цены и продажи. Выручка{' '}
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											label={''}
											name="hideExtProductHistory"
											checked={formik.values.hideExtProductHistory ?? false}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={6} key={'Избранное'} className={css.tariffSection}>
								<DialogContentText>
									<b>Избранное</b>
								</DialogContentText>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										{' '}
										Кол-во групп{' '}
									</Grid>
									<Grid item xs>
										<TextField
											name="favoritesGroupsLimit"
											type="text"
											inputProps={{
												...numericTypeInputProps,
											}}
											InputProps={{...unitsInputProps('шт')}}
											margin="normal"
											value={formik.values.favoritesGroupsLimit}
											onChange={handleInputChange}
											error={!!formik.errors.favoritesGroupsLimit}
											helperText={formik.errors?.favoritesGroupsLimit}
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										Кол-во товаров в группе
									</Grid>
									<Grid item xs>
										<TextField
											name="productsGroupLimit"
											inputProps={{
												...numericTypeInputProps,
											}}
											InputProps={{...unitsInputProps('шт')}}
											margin="normal"
											value={formik.values.productsGroupLimit}
											onChange={handleInputChange}
											error={!!formik.errors.productsGroupLimit}
											helperText={formik.errors?.productsGroupLimit}
										/>
									</Grid>
								</Grid>
								<Grid container alignItems="center" spacing={2}>
									<Grid item xs>
										Всего товаров
									</Grid>
									<Grid item xs>
										<TextField
											name="favoritesLimit"
											inputProps={{
												...numericTypeInputProps,
											}}
											InputProps={{...unitsInputProps('шт')}}
											margin="normal"
											value={formik.values.favoritesLimit}
											onChange={handleInputChange}
											error={!!formik.errors.favoritesLimit}
											helperText={formik.errors?.favoritesLimit}
										/>
									</Grid>
								</Grid>

								<Grid container alignItems="center" spacing={2}>
									<Grid item xs>
										Скрыть сводку{' '}
										<Tooltip
											small={true}
											content={
												<div>Итоговые значения в нижней части таблицы</div>
											}
										>
											<EmojiObjectsOutlinedIcon className={css.icon} />
										</Tooltip>
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											name="favoritesSummary"
											checked={formik.values.favoritesSummary ?? false}
											label=""
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={6} key={'SEO'} className={css.tariffSection}>
								<DialogContentText>
									<b>Общие</b>
								</DialogContentText>

								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										Скрыть раздел Озон{' '}
										<Tooltip
											content={
												<>
													<b>Закроется доступ к следующим разделам:</b>{' '}
													<br />
													<br />
													Мои продажи Ozon:
													<ul>
														<li>
															<b>Динамика продаж </b>(4 графика:
															Выручка, Заказали (сумма), К оплате
															продавцу, Заказали (товар) )
														</li>
														<li>
															<b>История продаж</b> (Календарь,
															Динамика продаж, Статистика продаж, К
															оплате по брендам)
														</li>
														<li>
															<b>История заказов</b> (Календарь,
															Динамика заказов, Статистика заказов)
														</li>
														<li>
															<b>Карта продаж</b>
														</li>
														<li>
															<b>
																Планировщик заказа, Склад, Движение
																товара, Логистика
															</b>{' '}
															(в разработке, ограничений нет)
														</li>
													</ul>{' '}
													Маркетплейс Ozon:
													<ul>
														<li>
															<b>Общая статистика по Ozon </b>
															(график / таблица)
														</li>
														<li>
															<b>Топ товаров за вчерашний день </b>
															(таблица)
														</li>
														<li>
															<b>Топ категорий за вчерашний день </b>
															(таблица)
														</li>
													</ul>
													Категории Ozon:
													<ul>
														<li>
															<b>Подкатегории</b> (Диаграмма Продажи,
															Диаграмма Выручка, Общая таблица)
														</li>
														<li>
															<b>Бренды</b> (Диаграмма Продажи,
															Диаграмма Выручка, Общая таблица)
														</li>
														<li>
															<b>Тренд</b> (Диаграмма Сравнение,
															Таблица)
														</li>
														<li>
															<b>По дням</b> (Диаграмма Сравнение,
															Таблица)
														</li>
													</ul>
													Карточка товара Ozon:
													<ul>
														<li>
															<b>Динамика по продажам</b>
														</li>
														<li>
															<b>Отслеживания изменений контента</b>
														</li>
														<li>
															<b>Статистика товара</b>
														</li>
														<li>
															<b>Категории и позиции</b>
														</li>
														<li>
															<b>Продажи и остатки по складам</b>
														</li>
													</ul>
													Карточка продавца / бренда Ozon:
													<ul>
														<li>
															<b>Сводка</b>
														</li>
														<li>
															<b>Топ 5 товаров (Данные за 30 дней)</b>
														</li>
														<li>
															<b>Присутствие</b>
														</li>
														<li>
															<b>Новые товары продавца</b>
														</li>
														<li>
															<b>Все товары</b>
														</li>
													</ul>
													Поиск по поставщикам Ozon
													<br />
													Поиск по брендам Ozon
													<br />
												</>
											}
										>
											<PriorityHighRoundedIcon className={css.icon} />
										</Tooltip>
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											label={''}
											name="hideOzon"
											checked={formik.values.hideOzon ?? false}
										/>
									</Grid>
								</Grid>

								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs className={css.inDevelop}>
										Личный аналитик
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											name=""
											disabled
											checked={false}
											label=""
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={6} key={'SEO'} className={css.tariffSection}>
								<DialogContentText>
									<b>SEO</b>
								</DialogContentText>

								<Grid container alignItems="center" spacing={2}>
									{' '}
									<Grid item xs>
										Скрыть все функции SEO
										<Tooltip
											small={true}
											content={
												<div>
													Сравнение товаров. Поисковые запросы по товару.
													Товары по поисковому запросу
												</div>
											}
										>
											<EmojiObjectsOutlinedIcon className={css.icon} />
										</Tooltip>
									</Grid>
									<Grid item xs>
										<Checkbox
											className={css.checkbox}
											label={''}
											name="hideSeo"
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						{!formik.isSubmitting && error && (
							<DialogContent>
								<Typography variant="body2" gutterBottom component="div">
									<FormHelperText error>{error}</FormHelperText>
								</Typography>
							</DialogContent>
						)}

						<DialogActions>
							<Grid container>
								<Grid item sm>
									<Button disabled onClick={handleDelete(formik)}>
										Удалить
									</Button>
								</Grid>

								<Grid item>
									<Button style={{marginRight: 10}} onClick={handleClose}>
										Отменить
									</Button>

									<Button
										variant="contained"
										color="primary"
										type="submit"
										disabled={loading}
									>
										Изменить
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</form>
				</DialogContent>
			</Dialog>
		</FormikProvider>
	);
};

export default EditTariffPermissionsModal;
