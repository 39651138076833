import {useQuery, useMutation, useLazyQuery} from '@apollo/client';

import apolloClient from 'utils/apolloClient';
import {graphql} from '../gqlApp';
import {AddTariffToUserMutation, AddTariffToUserMutationVariables} from '../gqlApp/graphql';

graphql(`
	fragment Permissions on TariffPermissionsType {
		historyDays
		hideBrands
		hideSellers
		favoritesGroupsLimit
		productsGroupLimit
		hideTopProducts
		hideTopCategories
		favoritesSummary
		sbSummary
		sbTopProducts
		sbNewItems
		sbAllProducts
		sbPresence
		analyticsAccounts
		searchQueriesLimit
		hideOzon
		analyticsSalesHistoryDays
		analyticsSOHistoryDays
		exportProducts
		hideExtProductHistory
		hideAnalyticsBlock
		hideProductsSummary
		hideSeo
		favoritesLimit
	}
`);

graphql(`
	fragment Tariff on TariffType {
		_id
		name
		description
		price
		price3
		price6
		price12
		isTrial
		isActive
		isPublic
		isDefault
		duration
		marked
		color
		permissions {
			...Permissions
		}
	}
`);

const TARIFFS_QUERY = graphql(`
	query Tariffs {
		tariffs {
			...Tariff
		}
	}
`);

export const useTariffs = () => useQuery(TARIFFS_QUERY);
export const useTariffsLazyQuery = () => useLazyQuery(TARIFFS_QUERY);

const ACTIVE_TARIFFS_QUERY = graphql(`
	query ActiveTariffs {
		activeTariffs {
			...Tariff
		}
	}
`);

export const useActiveTariffs = () => useQuery(ACTIVE_TARIFFS_QUERY);

const ADD_TARIFF_TO_USER_MUTATION = graphql(`
	mutation AddTariffToUser(
		$userId: String!
		$tariffId: String!
		$paymentType: PaymentTypeEnum
		$withPayment: Boolean
	) {
		addTariffToUser(
			userId: $userId
			tariffId: $tariffId
			paymentType: $paymentType
			withPayment: $withPayment
		) {
			paidTariff {
				userId
				tariffId
				fromDate
				toDate
				totalPaid
				datePaid
			}

			user {
				_id
				email
				role
				createdAt
				telegram
				isActive
				activeTariffId
			}
		}
	}
`);

export const addTariffToUserMutation = (variables: AddTariffToUserMutationVariables) =>
	apolloClient.mutate<AddTariffToUserMutation, AddTariffToUserMutationVariables>({
		variables,
		mutation: ADD_TARIFF_TO_USER_MUTATION,
	});

const ADD_TARIFF_MUTATION = graphql(`
	mutation AddTariff($newTariffInput: AddTariffInput!) {
		addTariff(newTariffInput: $newTariffInput) {
			...Tariff
		}
	}
`);

export const useAddTariffMutation = () =>
	useMutation(ADD_TARIFF_MUTATION, {
		refetchQueries: ['Tariffs'],
	});

const EDIT_TARIFF_MUTATION = graphql(`
	mutation EditTariff($tariffId: String!, $editTariffInput: EditTariffInput!) {
		editTariff(tariffId: $tariffId, editTariffInput: $editTariffInput) {
			...Tariff
		}
	}
`);

export const useEditTariffMutation = () => useMutation(EDIT_TARIFF_MUTATION);

const EDIT_TARIFF_PERMISSION_MUTATION = graphql(`
	mutation EditTariffPermission(
		$historyDays: Int
		$hideBrands: Boolean
		$hideSellers: Boolean
		$favoritesGroupsLimit: Int
		$productsGroupLimit: Int
		$hideTopProducts: Boolean
		$hideTopCategories: Boolean
		$favoritesSummary: Boolean
		$sbSummary: Boolean
		$sbTopProducts: Boolean
		$sbNewItems: Boolean
		$sbAllProducts: Boolean
		$sbPresence: Boolean
		$analyticsAccounts: Int
		$searchQueriesLimit: Int
		$hideOzon: Boolean
		$analyticsSalesHistoryDays: Int
		$analyticsSOHistoryDays: Int
		$exportProducts: Int
		$hideExtProductHistory: Boolean
		$tariffId: String!
		$hideAnalyticsBlock: Boolean
		$hideProductsSummary: Boolean
		$hideSeo: Boolean
		$favoritesLimit: Int
	) {
		editTariffPermission(
			historyDays: $historyDays
			hideBrands: $hideBrands
			hideSellers: $hideSellers
			favoritesGroupsLimit: $favoritesGroupsLimit
			productsGroupLimit: $productsGroupLimit
			hideTopProducts: $hideTopProducts
			hideTopCategories: $hideTopCategories
			favoritesSummary: $favoritesSummary
			sbSummary: $sbSummary
			sbTopProducts: $sbTopProducts
			sbNewItems: $sbNewItems
			sbAllProducts: $sbAllProducts
			sbPresence: $sbPresence
			analyticsAccounts: $analyticsAccounts
			searchQueriesLimit: $searchQueriesLimit
			hideOzon: $hideOzon
			analyticsSalesHistoryDays: $analyticsSalesHistoryDays
			analyticsSOHistoryDays: $analyticsSOHistoryDays
			exportProducts: $exportProducts
			hideExtProductHistory: $hideExtProductHistory
			tariffId: $tariffId
			hideAnalyticsBlock: $hideAnalyticsBlock
			hideProductsSummary: $hideProductsSummary
			hideSeo: $hideSeo
			favoritesLimit: $favoritesLimit
		) {
			...Tariff
		}
	}
`);

export const useEditTariffPermissionMutation = () => useMutation(EDIT_TARIFF_PERMISSION_MUTATION);

const ADD_FREE_DAYS_TO_TARIFF = graphql(`
	mutation AddFreeDaysToTariff($input: FreeDaysInput!) {
		addFreeDaysToTariff(input: $input)
	}
`);

export const useAddFreeDaysToTariff = () => useMutation(ADD_FREE_DAYS_TO_TARIFF);
