import React from 'react';

import TariffTable from 'components/tariffs/table/TariffTable';
import AppWrapper from 'components/common/AppWrapper';

const TariffsPage: React.FC = () => (
	<AppWrapper title="Тарифы">
		<TariffTable />
	</AppWrapper>
);

export default TariffsPage;
