import React, {useMemo, useState} from 'react';
import {IconButton} from '@mui/material';
import Icon from '@mdi/react';
import {mdiSquareEditOutline, mdiShieldEditOutline} from '@mdi/js';
import {Button, ContentBlock, TableAdaptive} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {ITableColumn} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';

import Tooltip from 'components/common/ui/Tooltip';
import EditTariffModal from 'components/tariffs/table/modals/EditTariffModal';
import AddTariffModal from 'components/tariffs/table/modals/AddTariffModal';
import EditTariffPermissionsModal from 'components/tariffs/table/modals/EditTariffPermissionsModal';
import {useTariffs} from 'api/tariffs';
import theme from '../../../muiTheme';

export interface ITariffTableRow {
	_id: string;
	name: string;
	description: string;
	price: number;
	isActive: string;
	duration: number;
	isPublic: string;
	isDefault: string;
}

const columns: ITableColumn[] = [
	{name: 'manage', width: 80, title: '', sortingEnabled: false},
	{name: 'name', title: 'Название'},
	{name: 'description', title: 'Описание', sortingEnabled: false},
	{name: 'price', title: 'Стоимость', align: 'right'},
	{name: 'isActive', title: 'Активный'},
	{name: 'duration', title: 'Дней', align: 'right'},
	{name: 'isPublic', title: 'Публичный'},
	{name: 'isDefault', title: 'Основной'},
];

const TariffTable: React.FC = () => {
	const {loading: tariffLoading, data: tariffData, error} = useTariffs();
	const [editedTariff, setEditedTariff] = useState('');
	const [isOpenAddTariffModal, setOpenAddTariffModal] = useState(false);
	const [editedTariffPermission, setEditedTariffPermission] = useState('');

	const handleEditedTariff = (tariffId: string) => setEditedTariff(tariffId);
	const handleEditedTariffModalClose = () => setEditedTariff('');

	const handleEditedTariffPermission = (tariffId: string) => setEditedTariffPermission(tariffId);
	const handleEditedTariffPermissionModalClose = () => setEditedTariffPermission('');

	const handleClickAddTariff = () => setOpenAddTariffModal(true);
	const handleAddTariffModalClose = () => setOpenAddTariffModal(false);

	const rows: ITariffTableRow[] = useMemo(() => {
		if (!tariffData) {
			return [];
		}

		return tariffData.tariffs.map((tariff) => ({
			_id: tariff._id,
			manage: (
				<>
					<EditTariffModal
						tariff={tariff}
						isOpen={editedTariff === tariff._id}
						onClose={handleEditedTariffModalClose}
					/>
					<Tooltip content={'Редактировать основные настройки'} defaultPlacement={true}>
						<IconButton onClick={() => handleEditedTariff(tariff._id)}>
							<Icon
								path={mdiSquareEditOutline}
								size={0.8}
								color={theme.palette.primary.main}
							/>
						</IconButton>
					</Tooltip>

					<EditTariffPermissionsModal
						permissions={tariff.permissions}
						tariffId={tariff._id}
						tariff={tariff.name}
						isOpen={editedTariffPermission === tariff._id}
						onClose={handleEditedTariffPermissionModalClose}
					/>
					<Tooltip content={'Редактировать разрешения'} defaultPlacement={true}>
						<IconButton onClick={() => handleEditedTariffPermission(tariff._id)}>
							<Icon
								path={mdiShieldEditOutline}
								size={0.8}
								color={theme.palette.primary.main}
							/>
						</IconButton>
					</Tooltip>
				</>
			),
			name: tariff.name,
			description: tariff.description,
			price: tariff.price,
			duration: tariff.duration || 0,
			isActive: tariff.isActive ? 'Да' : 'Нет',
			isPublic: tariff.isPublic ? 'Да' : 'Нет',
			isDefault: tariff.isDefault ? 'Да' : 'Нет',
		}));
	}, [editedTariff, editedTariffPermission, tariffData]);

	return (
		<ContentBlock
			loading={tariffLoading}
			error={!!error}
			label={<Button onClick={handleClickAddTariff}>Добавить тариф</Button>}
		>
			<AddTariffModal isOpen={isOpenAddTariffModal} onClose={handleAddTariffModalClose} />

			<TableAdaptive rows={rows} columns={columns} />
		</ContentBlock>
	);
};

export default TariffTable;
