import * as React from 'react';
import {Field} from 'formik';
import CheckboxMUI from '@mui/material/Checkbox';

import {FormControlLabel} from '@mui/material';
import css from './Checkbox.pcss';

interface ICheckbox {
	label: string;
	name?: string;
	value?: boolean;
	checked?: boolean;
	validate?: (value: any) => string | Promise<void> | undefined;
	className?: string;
	disabled?: boolean;
}

const Checkbox: React.FunctionComponent<ICheckbox> = (props) => (
	<FormControlLabel
		className={css.checkbox}
		control={
			<Field {...props} type="checkbox">
				{({field}: any) => <CheckboxMUI {...props} {...field} />}
			</Field>
		}
		label={props.label}
	/>
);

export default Checkbox;
