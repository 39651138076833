import React from 'react';

import AppWrapper from 'components/common/AppWrapper';
import UserTable from '../users/table/UserTable';

const UsersPage: React.FC = () => (
	<AppWrapper title="Пользователи">
		<UserTable />
	</AppWrapper>
);

export default UsersPage;
