import React, {useMemo} from 'react';
import {GeneralStatisticsBlockProps} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import KeyApiChartBlock from 'components/keyAPI/KeyApiChartBlock';
import {useUsersStatQuery} from 'api/users';
import AppWrapper from 'components/common/AppWrapper';

const KeyApiPage: React.FC = () => {
	const {loading, data: usersStatData} = useUsersStatQuery();

	const blocks: GeneralStatisticsBlockProps[] = useMemo(
		() => [
			{
				label: 'Пользователи с ключами API',
				value: usersStatData?.usersStat.userWithAnalyticsKey,
				loading,
			},
			{
				label: 'Всего ключей API',
				value:
					(usersStatData?.usersStat.ozonKeys ?? 0) +
					(usersStatData?.usersStat.wbKeys ?? 0),
				loading,
			},
			{
				label: 'Ключи API Wb',
				value: usersStatData?.usersStat.wbKeys,
				loading,
			},
			{
				label: 'Ключи API Ozon',
				value: usersStatData?.usersStat.ozonKeys,
				loading,
			},
		],
		[
			loading,
			usersStatData?.usersStat.ozonKeys,
			usersStatData?.usersStat.userWithAnalyticsKey,
			usersStatData?.usersStat.wbKeys,
		],
	);

	return (
		<AppWrapper title="Ключи API" blocksStat={blocks}>
			<KeyApiChartBlock />
		</AppWrapper>
	);
};

export default KeyApiPage;
