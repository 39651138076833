import React, {useMemo} from 'react';
import {FormikHelpers, useFormik} from 'formik';
import {Grid, TextField, Theme, useMediaQuery} from '@mui/material';
import {ApolloError} from '@apollo/client';
import {array, number, object, SchemaOf, string} from 'yup';
import {DatePicker} from '@mui/lab';
import {Button} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import css from './PromoCodeForm.pcss';
import {CreatePromoCodeInput, PaymentTypeEnum} from '../../gqlApp/graphql';

interface IPromoCodeForm {
	onSubmit: (
		values: CreatePromoCodeInput,
		formikHelpers: FormikHelpers<CreatePromoCodeInput>,
	) => void | (Promise<any> & (() => void));
	loading: boolean;
	error?: ApolloError;
	onCancel?: () => void;
	editPromoCode?: CreatePromoCodeInput;
}

const validationSchema: SchemaOf<CreatePromoCodeInput> = object().shape({
	description: string().trim().required('Описание необходимо'),

	code: string().trim().required('Код необходим'),

	discount: number()
		.required('Скидка обязательна')
		.max(68, 'Скидка должна быть <= 68%')
		.min(1, 'Скидка должна быть >= 1%'),

	paymentType: array(),

	utmSource: string().nullable(),

	limitCount: number().nullable(),

	endDate: number().nullable(),

	userIds: array(),
});

const PromoCodeForm: React.FC<IPromoCodeForm> = ({onSubmit, loading, error, editPromoCode}) => {
	const isFromMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const getPaymentType = (val: string): PaymentTypeEnum => {
		switch (val) {
			case '1':
				return PaymentTypeEnum.One;

			case '3':
				return PaymentTypeEnum.Quarter;

			case '6':
				return PaymentTypeEnum.Halfyear;

			case '12':
				return PaymentTypeEnum.Year;
		}

		return PaymentTypeEnum.One;
	};

	const initialValues = useMemo<CreatePromoCodeInput>(
		() =>
			editPromoCode
				? {
						...editPromoCode,
						paymentType: editPromoCode.paymentType?.map((type) =>
							getPaymentType(type.slice(5)),
						),
				  }
				: {
						code: '',
						description: '',
						discount: 0,
						endDate: undefined,
						limitCount: undefined, //число
						paymentType: undefined, //Array<PaymentTypeEnum>
						userIds: undefined, // массив строк
						utmSource: undefined, // строка
				  },
		[editPromoCode],
	);

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container spacing={isFromMd ? 1 : 3}>
				<Grid item xs={isFromMd ? 12 : 6}>
					<TextField
						name="description"
						label="Описание *"
						defaultValue={formik.values.description}
						variant={'outlined'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.description && !!formik.errors.description}
						helperText={formik.touched.description && formik.errors.description}
					/>
				</Grid>

				<Grid item xs={isFromMd ? 1 : 6}>
					<TextField
						name="code"
						label="Код *"
						defaultValue={formik.values.code}
						variant={'outlined'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.code && !!formik.errors.code}
						helperText={formik.touched.code && formik.errors.code}
					/>
				</Grid>
			</Grid>

			<br />

			<Grid container spacing={isFromMd ? 1 : 3}>
				<Grid item xs={isFromMd ? 12 : 6}>
					<TextField
						name="discount"
						type="number"
						label="Скидка / % *"
						InputProps={{inputProps: {min: 0, max: 100}}}
						defaultValue={formik.values.discount}
						variant={'outlined'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.discount && !!formik.errors.discount}
						helperText={formik.touched.discount && formik.errors.discount}
					/>
				</Grid>

				<Grid item xs={isFromMd ? 1 : 6}>
					<TextField
						name="utmSource"
						label="Метка для статистики"
						defaultValue={formik.values.utmSource}
						variant={'outlined'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.utmSource && !!formik.errors.utmSource}
						helperText={formik.touched.utmSource && formik.errors.utmSource}
					/>
				</Grid>
			</Grid>

			<br />

			<Grid container spacing={isFromMd ? 1 : 3}>
				<Grid item xs={isFromMd ? 12 : 6}>
					<TextField
						name="paymentType"
						label="Типы оплат"
						placeholder="Через запятую: 1,3,6,12"
						defaultValue={formik.values.paymentType}
						variant={'outlined'}
						onChange={(e) =>
							formik.setFieldValue(
								e.target.name,
								e.target.value
									.trim()
									.replace(/\s/g, '')
									.split(',')
									.filter((val) => ['1', '3', '6', '12'].includes(val))
									.map((val) => getPaymentType(val)),
							)
						}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.paymentType && !!formik.errors.paymentType}
						helperText={formik.touched.paymentType && formik.errors.paymentType}
					/>
				</Grid>

				<Grid item xs={isFromMd ? 1 : 6}>
					<TextField
						name="limitCount"
						type="number"
						label="Лимит применений"
						defaultValue={formik.values.limitCount}
						variant={'outlined'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.limitCount && !!formik.errors.limitCount}
						helperText={formik.touched.limitCount && formik.errors.limitCount}
					/>
				</Grid>
			</Grid>

			<br />

			<Grid container spacing={isFromMd ? 1 : 3}>
				<Grid item xs={6}>
					<DatePicker
						label="Дата окончания"
						mask={'__.__.____'}
						onChange={(date) => formik.setFieldValue('endDate', +date)}
						value={formik.values.endDate || null}
						minDate={new Date()}
						renderInput={(params) => (
							<TextField
								{...params}
								fullWidth
								error={formik.touched.endDate && !!formik.errors.endDate}
								// helperText={formik.touched.endDate && formik.errors.endDate}
								inputProps={{...params.inputProps, placeholder: 'dd.MM.yyyy'}}
							/>
						)}
					/>
				</Grid>
			</Grid>

			<br />

			<Grid container spacing={isFromMd ? 1 : 3}>
				<Grid item xs={12}>
					<TextField
						name="userIds"
						label="IDs пользователей"
						defaultValue={formik.values.userIds}
						variant={'outlined'}
						onChange={(e) =>
							e.target.value &&
							formik.setFieldValue(
								e.target.name,
								e.target.value.trim().replace(/\s/g, '').split(','),
							)
						}
						onBlur={formik.handleBlur}
						fullWidth
						error={formik.touched.userIds && !!formik.errors.userIds}
						helperText={formik.touched.userIds && formik.errors.userIds}
					/>
				</Grid>
			</Grid>

			<br />

			{error && (
				<>
					<div className={css.error}>{error.message}</div>

					<br />
				</>
			)}

			<Grid container spacing={3}>
				<Grid item xs={isFromMd}>
					<Button
						type="submit"
						size={'large'}
						loading={loading}
						disabled={loading}
						fullWidth
					>
						Создать
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default PromoCodeForm;
