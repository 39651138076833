import React from 'react';
import {BrowserRouter, Routes as RoutesRRM, Route} from 'react-router-dom';

import LoginByPhonePage from 'components/auth/LoginByPhonePage';
import UsersPage from 'components/pages/UsersPage';
import Error404Page from 'components/common/Error404Page';
import DashboardPage from 'components/pages/DashboardPage';
import CallsPopularActionsPage from 'components/pages/CallsPopularActionsPage';
import CallsActiveUsersPage from 'components/pages/CallsActiveUsersPage';
import CallsUserStatsPage from 'components/pages/CallsUserStatsPage';
import PollsPage from 'components/pages/PollsPage';
import KeyApiPage from 'components/pages/KeyApiPage';
import LoginByEmailPage from 'components/auth/LoginByEmailPage';
import NotifsPage from 'components/pages/NotifsPage';
import TariffsPage from 'components/tariffs/TariffsPage';
import PaymentPage from 'components/pages/PaymentPage';
import PromoCodesPage from 'components/pages/PromoCodesPage';
import AuthLayoutRoute from 'layouts/AuthLayoutRoute';
import ClientLayoutRoute from 'layouts/ClientLayoutRoute';
import UtmSourcePage from 'components/pages/UtmSourcePage';
import routeUrls from 'constants/routeUrls';

const Routes: React.FC = () => (
	<BrowserRouter>
		<RoutesRRM>
			{/* Авторизация */}
			<Route
				path={routeUrls.AUTH_LOGIN}
				element={
					<AuthLayoutRoute>
						<LoginByPhonePage />
					</AuthLayoutRoute>
				}
			/>
			<Route
				path={routeUrls.AUTH_LOGIN_BY_EMAIL}
				element={
					<AuthLayoutRoute>
						<LoginByEmailPage />
					</AuthLayoutRoute>
				}
			/>

			{/* Админка */}
			<Route
				path={routeUrls.APP_DASHBOARD}
				element={
					<ClientLayoutRoute>
						<DashboardPage />
					</ClientLayoutRoute>
				}
			/>
			<Route
				path={routeUrls.APP_USERS}
				element={
					<ClientLayoutRoute>
						<UsersPage />
					</ClientLayoutRoute>
				}
			/>
			<Route
				path={routeUrls.APP_KEY_API}
				element={
					<ClientLayoutRoute>
						<KeyApiPage />
					</ClientLayoutRoute>
				}
			/>

			<Route
				path={routeUrls.APP_POLLS}
				element={
					<ClientLayoutRoute>
						<PollsPage />
					</ClientLayoutRoute>
				}
			/>
			<Route
				path={routeUrls.APP_PROMO_CODES}
				element={
					<ClientLayoutRoute>
						<PromoCodesPage />
					</ClientLayoutRoute>
				}
			/>
			<Route
				path={routeUrls.APP_PAYMENT}
				element={
					<ClientLayoutRoute>
						<PaymentPage />
					</ClientLayoutRoute>
				}
			/>
			<Route
				path={routeUrls.APP_TARIFFS}
				element={
					<ClientLayoutRoute>
						<TariffsPage />
					</ClientLayoutRoute>
				}
			/>

			<Route
				path={routeUrls.APP_NOTIFS}
				element={
					<ClientLayoutRoute>
						<NotifsPage />
					</ClientLayoutRoute>
				}
			/>

			<Route
				path={routeUrls.API_CALLS_POPULAR_ACTIONS}
				element={
					<ClientLayoutRoute>
						<CallsPopularActionsPage />
					</ClientLayoutRoute>
				}
			/>
			<Route
				path={routeUrls.API_CALLS_ACTIVE_USERS}
				element={
					<ClientLayoutRoute>
						<CallsActiveUsersPage />
					</ClientLayoutRoute>
				}
			/>
			<Route
				path={routeUrls.API_CALLS_USER_STATS}
				element={
					<ClientLayoutRoute>
						<CallsUserStatsPage />
					</ClientLayoutRoute>
				}
			/>

			<Route
				path={routeUrls.APP_UTM_SOURCE}
				element={<ClientLayoutRoute children={<UtmSourcePage />} />}
			/>

			{/* Errors */}
			<Route
				path="*"
				element={
					<ClientLayoutRoute>
						<Error404Page />
					</ClientLayoutRoute>
				}
			/>
		</RoutesRRM>
	</BrowserRouter>
);

export default Routes;
